import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints, standardTransition } from 'config/layout/layout';
import { ENABLE_PERSONAL_PAGE } from 'config/featureFlags/featureFlags';

const NavbarMenuContainer = styled.nav`
    text-align: left;
    display: ${ENABLE_PERSONAL_PAGE ? 'grid' : 'flex'};
    grid-template-columns: ${ENABLE_PERSONAL_PAGE ? 'auto 1fr' : 'none'};
    grid-template-rows: 30px; 
    align-items: center;
    justify-content: space-between;
    align-content: center;


    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: none;
    }

    & > ul {
        width: auto;
        padding-right: 30px;
        list-style-type: none;
        display: inline-flex;
        justify-content: space-between;
        max-width: 530px;
        width: 100%;

        & > li {
            & > a {
                color: ${globalColors.fontColors.black};
                text-decoration: none;
                display: block;
                border-bottom: 3px solid rgba(98, 200, 49, 0);
                ${standardTransition}

                &:hover {
                    border-bottom: 3px solid rgba(98, 200, 49, 1);
                }

                &.active {
                    border-bottom: 3px solid
                        ${globalColors.backgrounds.green.base};
                }
            }
        }
    }
`;

export { NavbarMenuContainer };
