import /* webpackMode: "eager" */ bg from './flags/BG.svg';
import /* webpackMode: "eager" */ ch from './flags/CH.svg';
import /* webpackMode: "eager" */ cz from './flags/CZ.svg';
import /* webpackMode: "eager" */ dk from './flags/DK.svg';
import /* webpackMode: "eager" */ eu from './flags/EU.svg';
import /* webpackMode: "eager" */ gb from './flags/GB.svg';
import /* webpackMode: "eager" */ hr from './flags/HR.svg';
import /* webpackMode: "eager" */ hu from './flags/HU.svg';
import /* webpackMode: "eager" */ lt from './flags/LT.svg';
import /* webpackMode: "eager" */ mx from './flags/MX.svg';
import /* webpackMode: "eager" */ no from './flags/NO.svg';
import /* webpackMode: "eager" */ pl from './flags/PL.svg';
import /* webpackMode: "eager" */ ro from './flags/RO.svg';
import /* webpackMode: "eager" */ se from './flags/SE.svg';
import /* webpackMode: "eager" */ sg from './flags/SG.svg';
import /* webpackMode: "eager" */ us from './flags/US.svg';

export interface RoundedCountryFlagProps {
    name: RoundedCountryFlags;
    size?: number;
}

export enum RoundedCountryFlags {
    BG = 'BG',
    CH = 'CH',
    CZ = 'CZ',
    DK = 'DK',
    EU = 'EU',
    GB = 'GB',
    HR = 'HR',
    HU = 'HU',
    LT = 'LT',
    MX = 'MX',
    NO = 'NO',
    PL = 'PL',
    RO = 'RO',
    SE = 'SE',
    SG = 'SG',
    US = 'US',
}

export const roundedFlagsPaths: Record<RoundedCountryFlags, string> = {
    BG: bg,
    CH: ch,
    CZ: cz,
    GB: gb,
    PL: pl,
    LT: lt,
    DK: dk,
    EU: eu,
    HR: hr,
    HU: hu,
    MX: mx,
    NO: no,
    RO: ro,
    SE: se,
    SG: sg,
    US: us
};
