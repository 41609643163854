/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { FreeSepaPromoPolicyPDF } from 'assets/files';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';
import { PromoPolicyWrapper } from '../Promo.styled';

export const FreeSepaPromo = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Free SEPA Payments Promo Rules</LegalTitle>

            <div>
                <Tooltip name="tnc-download-btn" label="Download promotion terms">
                    <RoundUrlButton
                        primary
                        external
                        link={FreeSepaPromoPolicyPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>
            Effective date: 1
            <span className="dateNote">st</span>
            {' '}
            October 2024
        </LastUpdate>

        <LegalContent>
            <PromoPolicyWrapper>
                <h4>1. What is the Free SEPA Payments Promo?</h4>

                The Free SEPA Payments Promo, or simply – the Promo, lets eligible clients make SEPA (Euro) payments without paying the SEPA fees, if they meet certain criteria. It’s a great way to save on the SEPA transfers!

                <h4>2. Why should I read these Rules?</h4>

                The Free SEPA Payments Promo is subject to the Free SEPA Payments Promo Rules, or simply – the Rules. These Rules explain how the Promo works, its benefits, and any conditions or restrictions. It's important to know what is included to get the most out of it!

                <h4>3. What can I get from the Free SEPA Payments Promo?</h4>

                If you meet all the criteria during certain calendar month within the Promo period, we will
                {' '}
                <u><b>waive your SEPA payment fees for all outgoing and incoming SEPA payments</b></u>
                {' '}
                for the next month
                <span className="note">1</span>
                .

                <h4>4. How can I get the free SEPA payments?</h4>

                To get the free SEPA Payments for the whole calendar month during the Promo period, you need to meet all below criteria in the previous month:

                <ul>
                    <li>
                        Keep a
                        {' '}
                        <u><b>balance of at least €1,000</b></u>
                        {' '}
                        in your account (on every day of the month).
                    </li>
                    <li>
                        Make at least
                        {' '}
                        <u><b>one payment (SEPA or international).</b></u>
                    </li>
                    <li>
                        Your business
                        {' '}
                        <u>
                            <b>
                                account is opened on or before December 31
                                <span className="note">st</span>
                                , 2024.
                            </b>
                        </u>
                    </li>
                </ul>

                <h4>5. How do I apply?</h4>

                The good news is that you don’t have to do anything to apply. We will check everything for you and simply waive your SEPA fees when you qualify.

                <h4>6. When does the Free SEPA Payments Promo start and end?</h4>

                The Promo starts on the
                {' '}
                <u>
                    <b>
                    1
                        <span className="note">st</span>
                        {' '}
                    of October 2024
                    </b>
                </u>
                , which means that you can get the Free SEPA Payments starting from the 1
                <span className="note">st</span>
                {' '}
                of November 2024.
                <br />
                <br />
                The Promo ends on the
                {' '}
                <u>
                    <b>
                    31
                        <span className="note">st</span>
                        {' '}
                    of December 2025
                    </b>
                </u>
                , which means that December 2025 is the last month within which your can benefit from the Free SEPA Payments.

                <h4>7. Who is eligible for the Free SEPA Payments Promo?</h4>

                All OKEO Business Clients on standard (non-custom) Pricing Plan are eligible for the Promo
                <span className="note">2</span>
                {' '}
                if their OKEO account is active, in good standing, and free of any restrictions or holds.

                <h4>8. Can OKEO change the Promo terms?</h4>

                From time to time, OKEO can amend or modify the Rules. If there are important changes, you’ll be notified via email or another suitable method. The changes will take effect immediately, unless specified otherwise.

                <h4>9. Can OKEO terminate my participation in the Promo?</h4>

                OKEO can terminate your participation if:
                <ul>
                    <li>You no longer meet the eligibility criteria, or </li>
                    <li>Your account is suspended, restricted, or terminated based on OKEO Terms and Conditions, or </li>
                    <li>OKEO decides to end the promotion for all clients or its specific group. </li>
                </ul>
                <h4>10. What happens if the Promo ends? </h4>

                If the Promo ends, you will no longer get the free SEPA payments, and standard SEPA fees will apply as per OKEO Product and Fee Terms. However, if you have already earned a fee waiver, it will stay valid for the rest of that month.

                <h4>11. What laws apply to this promotion?</h4>

                This promotion is governed by the laws of the Republic of Lithuania.

                <h4>12. What happens if there is a dispute?</h4>

                We try to avoid any disputes and believe that all misunderstandings can be resolved amicably. However, if any disputes related to the Rules arise and they cannot be resolved amicably, they will be handled by the court with jurisdiction over the Okeo Payments UAB headquarters in Vilnius, Lithuania.

                <h4>13. How can I contact OKEO for questions?</h4>

                If you have any questions or concerns about the Free SEPA Payments Promo or these Rules, feel free to reach out to us at:
                {' '}
                <a href="mailto:hello@okeo.com">hello@okeo.com</a>
                .

                <br />
                <br />
                <br />
                <span className="annotation">
                    <span className="note">1</span>
                    {' '}
                    For any limitations on the number of free SEPA payments, please check our Fair Pricing Policy and Premium Plan Limits as per Product and Fee Terms at
                    {' '}
                    <a href="https://okeo.com/pricing">https://okeo.com/pricing</a>
                    .
                </span>
                <br />
                <span className="annotation">
                    <span className="note">2</span>
                    {' '}
                    OKEO standard (non-custom) Pricing Plans are available on
                    {' '}
                    <a href="https://okeo.com/pricing">https://okeo.com/pricing</a>
                    .
                </span>
            </PromoPolicyWrapper>
        </LegalContent>
    </LegalWrapper>
);
