import { Country, CountryOption } from "./CountryDropdown.types";

export const createCountriesOptions = (countries: Country) => Object.entries(countries).map(([key, value]) => ({
    key,
    value: key,
    // @ts-ignore
    name: value[key],
}));

export const filterCountries = (countries: CountryOption[], searchText: string): CountryOption[] => {
    const lowerSearchText = searchText.toLowerCase();

    return countries.filter((country) => {

        const lowerValue = country.value.toLowerCase();
        const lowerName = country.name.toLowerCase();

        return lowerValue.includes(lowerSearchText) || lowerName.includes(lowerSearchText);
    });
};
