import styled from 'styled-components';

import { NAVBAR_TRANS_SELECT_CONTAINER_Z_INDEX } from 'config/layout/layout';

const NavbarTranslationSelectContainer = styled.div`
    z-index: ${NAVBAR_TRANS_SELECT_CONTAINER_Z_INDEX};
    position: relative;
`;

export { NavbarTranslationSelectContainer };
