import styled from 'styled-components';

import { globalColors } from 'assets/globalColors';
import { BANNER_Z_INDEX, breakPoints, standardTransition } from 'config/layout/layout';


const BannerWrapper = styled.div<{ shouldHide?: boolean }>`
    z-index: ${BANNER_Z_INDEX};
    background: rgba(0, 0, 0, .3);
    display: ${({ shouldHide }) => shouldHide && 'none'};
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;

    .buttons {
        display: flex;
        gap: 10px;

        @media screen and (max-width: ${breakPoints.mobile}px) {
            justify-content: center;
        }
    }
`;

const HeaderImgHeightMobile = 60;
const HeaderImgHeightMobileByWidth = 80;

const Banner = styled.div<{
    opened?: boolean;
    deviceHeight?: number;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
}>`
    position: fixed;
    display: ${({ opened }) => (opened ? 'grid' : 'none')};
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.67)
        radial-gradient(closest-side at 50% 50%, #c2ff9473 0%, #ffffff00 100%)
        0% 0% no-repeat padding-box;
    grid-auto-rows: min-content;
    align-content: center;
    justify-content: center;
    z-index: ${BANNER_Z_INDEX};

    @media screen and (max-height: 820px) {
        align-content: start;
    }

    .bannerWrapper {
        padding: 15px;
        max-width: 546px;
        background-color: ${globalColors.backgrounds.white};
        max-height: max-height: 100vh;
        overflow-y: auto;
        min-height: 470px;
        ${standardTransition};

        @media screen and (max-width: ${breakPoints.mobile}px) {
            padding: 15px;
        }

        @media screen and (max-height: 820px) {
            padding: 10px;
        }
    }

    .bannerContent {
        padding: 0 0 20px;
        text-align: left;

        @media screen and (max-width: ${breakPoints.mobile}px) {
            padding: 15px 0 30px;
            max-height: ${({ deviceHeight }) => (deviceHeight ? `${deviceHeight - HeaderImgHeightMobileByWidth - 45}px` : '100%')};
        }

        @media screen and (max-height: 740px) {
            padding: 10px 0 20px;
            max-height: ${({ deviceHeight }) => (deviceHeight ? `${deviceHeight - HeaderImgHeightMobile - 30}px` : '100%')};
        }

        .buttonWrapper {
            padding: 0 40px;
            text-align: right;

            @media screen and (max-width: ${breakPoints.mobile}px) {
                padding: 0 25px;
                text-align: center;
            }
        }
    }

    .close-wrapper {
        text-align: right;

        .close-icon {
            cursor: pointer;
            background-color: white;
            border-radius: 50%;
            display: inline-flex;
        }
    }
`;

const Form = styled.form<{ bordered?: boolean }>`
    padding: 0 15px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    font-size: 12px;
    max-width: 350px;
`;

const BannerTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 25px;
`;

const StatusWindow = styled.div`
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    gap: 25px;
`;

export default {
    Form,
    Banner,
    BannerTitle,
    StatusWindow,
    BannerWrapper,
};

