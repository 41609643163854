import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { WelcomeGirlLowResWebp, WelcomeGirlWebp } from 'assets/images';
import useOnScreen from 'hooks/useIsVisible';

import { Button } from 'components/atoms/Button/Button';
import Companies from 'components/atoms/Companies/Companies';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { ONBOARDING_URL } from 'config/environment';
import { ID } from 'config/layout/layout.types';

import ImageLoader from 'components/atoms/ImageLoader/ImageLoader';
import {
    LeftWelcomeContent,
    RightWelcomeContent,
    Underline,
    WelcomeButtonsWrapper,
    WelcomeContentWrapper,
    WelcomeText,
    WelcomeTitle,
} from './Welcome.styled';
import { WelcomeProps } from './Welcome.types';

export const Welcome: React.FC<WelcomeProps> = ({ t }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <SectionWrapper id={ID.Welcome} additionalStyles="overflow: hidden;">
            <>
                <WelcomeContentWrapper>
                    <LeftWelcomeContent>
                        <WelcomeTitle>
                            <div style={{ display: 'inline' }}>
                                <Trans t={t} i18nKey="title.label" />
                            </div>
                            <Underline ref={ref} isVisible={isVisible} additionalStyles="padding-bottom: 5px;">
                                {t('title.underlinedLabel')}
                            </Underline>
                        </WelcomeTitle>
                        <WelcomeText>{t('text.label')}</WelcomeText>
                        <WelcomeButtonsWrapper>
                            <a href={ONBOARDING_URL} target="_blank" rel="noreferrer">
                                <Button label={t('buttons.join.label')} />
                            </a>
                            {/* <a href={OKEO_VIDEO_URL} target="_blank" rel="noreferrer">
                                <Button label={t('buttons.watch.label')} buttonTheme={ButtonTheme.OUTLINE_ORANGE} />
                            </a> */}
                        </WelcomeButtonsWrapper>
                    </LeftWelcomeContent>
                    <RightWelcomeContent>
                        <ImageLoader alt="welcome-girl-image" lowResSrc={WelcomeGirlLowResWebp} highResSrc={WelcomeGirlWebp} />
                    </RightWelcomeContent>

                </WelcomeContentWrapper>

                <Companies />
            </>
        </SectionWrapper>
    );
};
