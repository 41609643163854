import React from 'react';
import { Trans } from 'react-i18next';
import { globalColors } from 'assets/globalColors';
import { TickSVG } from 'assets/icons/TickSVG';
import {
    PricingTableAdditionalInfo,
    PricingTableCell,
    PricingTableHeading,
    PricingTableRow,
    PricingTableTitle,
    PricingTableWrapper,
} from 'pages/Pricing/Pricing.styled';
import { PlanVariant, PricingRowContent } from 'pages/Pricing/Pricing.types';

import { stringToAttributeString } from 'utils/string-tools';

import { PricingTableTabletProps } from '../PricingTable.types';

// @ts-ignore

export const PricingTableTablet: React.FC<PricingTableTabletProps> = ({ translation, planType, noHeader }) => translation && Object.values(translation)
    // @ts-ignore
    .map((table: PricingTableContent) => {

        const tableHeadingContent = table.content.map((row: PricingRowContent, index: number) => (

            <PricingTableHeading key={stringToAttributeString(row.headingColumn)} index={index}>
                <div>
                    <Trans>
                        {row.headingColumn}
                    </Trans>
                </div>

                {row.additionalInfo && (
                    <PricingTableAdditionalInfo>
                        <Trans>
                            {row.additionalInfo}
                        </Trans>
                    </PricingTableAdditionalInfo>
                )}
            </PricingTableHeading>
        ));

        const firstColumn = table.content.map((row: PricingRowContent, index: number) => (

            <PricingTableCell key={`firstColumn-${stringToAttributeString(row.headingColumn)}`} className="free" index={index}>
                {row.column2 === '<greentick>' ? <TickSVG fill={globalColors.backgrounds.green.light!} /> : (
                    <Trans>
                        {row.column2}
                    </Trans>
                )}
            </PricingTableCell>
        ));

        const secondColumn = table.content.map((row: PricingRowContent, index: number) => (

            <PricingTableCell key={`secondColumn-${stringToAttributeString(row.headingColumn)}`} className="lite" index={index}>
                {row.column3 === '<yellowtick>' ? <TickSVG fill="#FFD00E" /> : (
                    <Trans>
                        {row.column3}
                    </Trans>
                )}
            </PricingTableCell>
        ));

        const thirdColumn = table.content.map((row: PricingRowContent, index: number) => (

            <PricingTableCell key={`thirdColumn-${stringToAttributeString(row.headingColumn)}`} className="premium" index={index}>
                {row.column4 === '<orangetick>' ? <TickSVG fill={globalColors.backgrounds.orange.base} /> : (
                    <Trans>
                        {row.column4}
                    </Trans>
                )}
            </PricingTableCell>
        ));

        return (
            <PricingTableWrapper>
                {!noHeader && <PricingTableTitle>{table.title}</PricingTableTitle>}
                <PricingTableRow>

                    {tableHeadingContent}

                    {planType === PlanVariant.FREE && firstColumn}
                    {planType === PlanVariant.LITE && secondColumn}
                    {planType === PlanVariant.PREMIUM && thirdColumn}

                </PricingTableRow>
            </PricingTableWrapper>
        );
    });
