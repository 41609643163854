import React from 'react';

import { OkeoIconVariant } from 'components/atoms/OkeoIcon/OkeoIcon.types';

const OkeoSVG: React.FC<{ fill1: string, fill2: string, variant: OkeoIconVariant }> = ({
    fill1,
    fill2,
    variant,
}) => (
    <svg id={`${fill1}${fill2}`} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="29.006" height="18.984" viewBox="0 0 29.006 18.984">
        <defs>
            <linearGradient id={variant} x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor={fill1} />
                <stop offset="1" stopColor={fill2} />
            </linearGradient>
        </defs>
        <g id="Group_556" data-name="Group 556" transform="translate(0 0)">
            <path id="Path_95" data-name="Path 95" d="M532.545,1099.245a5.709,5.709,0,0,0-4.111-1.673,5.811,5.811,0,0,0-4.216,1.712,5.421,5.421,0,0,0-1.779,4.072,5.244,5.244,0,0,0,1.807,3.94,5.878,5.878,0,0,0,4.187,1.691,5.624,5.624,0,0,0,4.082-1.729,5.52,5.52,0,0,0,1.759-4.045A5.334,5.334,0,0,0,532.545,1099.245Zm5.374,3.958a9.242,9.242,0,0,1-2.811,6.655,8.979,8.979,0,0,1-6.559,2.859,9.5,9.5,0,0,1-6.864-2.839,8.992,8.992,0,0,1-2.935-6.674,8.845,8.845,0,0,1,2.916-6.51,9.158,9.158,0,0,1,6.634-2.849,9.393,9.393,0,0,1,6.751,2.811A8.851,8.851,0,0,1,537.919,1103.2Z" transform="translate(-518.75 -1093.731)" fillRule="evenodd" />
            <path id="Path_507" data-name="Path 507" d="M1732.755,826.6l6.024,9.446-6.024,9.446H1728.1l5.957-9.446L1728.1,826.6Z" transform="translate(-1709.773 -826.601)" fillRule="evenodd" fill={`url(#${variant})`} />
        </g>
    </svg>

);

export default OkeoSVG;
