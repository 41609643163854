import styled from 'styled-components';

export const CookiesPolicyWrapper = styled.div`
    .t1 tr > *:nth-child(1) {
        width:25%;
    }
    .t1 tr > *:nth-child(2) {
        width:75%;
    }
    .t2 tr > *:nth-child(1) {
        width:25%;
    }
    .t2 tr > *:nth-child(2) {
        width:65%;
    }
    .t2 tr > *:nth-child(3) {
        width:10%;
    }
`;
