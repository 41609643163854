import { Trans } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';

import useOnScreen from 'hooks/useIsVisible';
import { AccumulativeVideo } from 'assets/videos';
import { HappyCoupleJpg } from 'assets/images';
import { TickSVG } from 'assets/icons/TickSVG';
import { ID } from 'config/layout/layout.types';
import { globalColors } from 'assets/globalColors';
import { breakPoints } from 'config/layout/layout';
import { stringToAttributeString } from 'utils/string-tools';
import { ONBOARDING_ACCUMULATIVE_URL } from 'config/environment';

import { Button } from 'components/atoms/Button/Button';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import useWindowSize from 'hooks/useWindowSize';
import Styled from './Account.styled';
import { AccountListElement, AccountProps } from './Account.types';


export const Account: React.FC<AccountProps> = ({ t }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const { width } = useWindowSize();
    const isDescriptionWithBreak = width && (width >= 555);

    const accountList: AccountListElement[] = t('list', { returnObjects: true });

    const renderItems = Object.values(accountList).map((listElement) => (
        <li key={`accumulative-accountAccumulative-info-${stringToAttributeString(listElement.title)}`}>
            <TickSVG fill={globalColors.backgrounds.green.light!} />

            {listElement.title}
        </li>
    ));

    const [autoplayBlocked, setAutoplayBlocked] = useState(false);

    useEffect(() => {
        const video = document.createElement('video');

        video.muted = true;
        video.autoplay = true;
        video.playsInline = true;

        video.play()
            .then(() => {
                video.pause();
                video.remove();
            })
            .catch(() => {
                setAutoplayBlocked(true);
                video.remove();
            });
    }, []);

    return (
        <SectionWrapper id={ID.Account} additionalStyles="overflow: hidden;">

            <Styled.ContentWrapper>
                <Styled.VideoOverlay />

                <Styled.LeftContent>
                    <Styled.Title>
                        <div style={{ display: 'inline' }}>
                            <Trans t={t} i18nKey="title.label" />
                        </div>
                        <Underline ref={ref} isVisible={isVisible} additionalStyles="padding-bottom: 5px; text-transform: uppercase;">
                            {t('title.underlinedLabel')}
                        </Underline>
                    </Styled.Title>
                    <Styled.MainText>
                        <ul>
                            {renderItems}
                        </ul>
                    </Styled.MainText>
                    <Styled.ButtonsWrapper>
                        <a href={ONBOARDING_ACCUMULATIVE_URL} target="_blank" rel="noreferrer">
                            <Button label={t('buttons.join.label')} />
                        </a>
                    </Styled.ButtonsWrapper>

                    <Styled.Text>
                        <Trans t={t} i18nKey={isDescriptionWithBreak ? "textWithBreak" : "text"} />
                        <Styled.LearnMoreButton>
                            <a href="https://help.okeo.com/hc/en-us/articles/19982344237073-What-is-Accumulative-Account-and-how-it-works" target="_blank" rel="noreferrer">
                                {t('learnMore')}
                            </a>
                        </Styled.LearnMoreButton>
                    </Styled.Text>

                </Styled.LeftContent>

                <Styled.RightContent>
                    {autoplayBlocked ? (
                        <picture>
                            <source srcSet={HappyCoupleJpg} media={`(max-width: ${breakPoints.tablet}px)`} />
                            <img src={HappyCoupleJpg} alt="" />
                        </picture>
                    ) : (
                        <Styled.Video
                            autoPlay
                            muted
                            loop
                            playsInline
                        >
                            <source src={AccumulativeVideo} type="video/mp4" />
                        </Styled.Video>
                    )}
                </Styled.RightContent>

            </Styled.ContentWrapper>
        </SectionWrapper>
    );
};
