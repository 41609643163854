import React from 'react';

import { StyledButton } from './RoundUrlButton.styled';
import { RoundUrlButtonProps } from './RoundUrlButton.types';

export const RoundUrlButton: React.FC<RoundUrlButtonProps> = ({
    link,
    primary,
    children,
    download,
    disabled,
    external,
    additionalStyles,
}) => (
    // @ts-ignore
    <StyledButton
        href={link}
        download={download}
        target={external ? '_blank' : '_self'}
        disabled={disabled}
        additionalStyles={additionalStyles}
        primary={primary}
    >
        {children}
    </StyledButton>
);
