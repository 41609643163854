import React from 'react';

import { Seo } from 'utils/Seo';

import Card from './sections/Card/Card';
import Trust from './sections/Trust/Trust';
import Account from './sections/Account/Account';
import Benefits from './sections/Benefits/Benefits';
import Payments from './sections/Payments/Payments';
import HowToOpen from './sections/HowToOpen/HowToOpen';
import Calculator from './sections/Calculator/Calculator';
import FaqPersonal from './sections/FAQPersonal/FaqPersonal';
import DedicatedIban from './sections/DedicatedIban/DedicatedIban';
import PricingPersonal from './sections/PricingPersonal/PricingPersonal';
import NotificationsAndLimits from './sections/NotificationsAndLimits/NotificationsAndLimits';

import { HomePersonalProps } from './HomePersonal.types';


export const HomePersonal: React.FC<HomePersonalProps> = () => (
    <>
        <Seo />
        <Account />
        <Benefits />
        <DedicatedIban />
        <Payments />
        <Calculator />
        <NotificationsAndLimits />
        <HowToOpen />
        <Card />
        <PricingPersonal />
        <Trust />
        <FaqPersonal />
    </>
);
