/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ComplaintsPolicyPDF } from 'assets/files';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import { ComplaintsPolicyWrapper } from 'pages/Legal/ComplaintsPolicy/ComplaintsPolicy.styled';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';

export const ComplaintsPolicy: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Complaints Policy</LegalTitle>

            <div>
                <Tooltip name="tnc-download-btn" label="Download Complaints Policy">
                    <RoundUrlButton
                        primary
                        external
                        link={ComplaintsPolicyPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>Last update: 27 March 2024</LastUpdate>

        <LegalContent>
            <ComplaintsPolicyWrapper>
                <h2>Glossary</h2>

                <table className="t1">
                    <thead>
                        <tr>
                            <th>
                            Term
                            </th>
                            <th>
                                Meaning
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><strong>Complaint</strong></td>
                            <td>Okeo Payments defines a complaint as 'any expression of dissatisfaction that relates to Okeo Payments and requires a formal response'.</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Complaint</strong>
                                {' '}
(as defined by the Bank of Lithuania)
                            </td>
                            <td>A complaint is a 'written application from an individual stating that their rights or legitimate interests have been violated in relation to a possible breach of law by a financial market participant'.</td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Dispute</strong>
                                {' '}
(as defined by the Bank of Lithuania)
                            </td>
                            <td>A dispute is a written application of a consumer to the Bank of Lithuania regarding non-compliance with the terms of an agreement between the person and the financial market participant. It is investigated during an out-of-court procedure at the Bank of Lithuania in accordance with the Rules of the Procedure of Out of Court Settlement in the Bank of Lithuania of Disputes between Consumers and Financial Market Participants.</td>
                        </tr>
                        <tr>
                            <td><strong>Business days</strong></td>
                            <td>Official working days (excluding weekends, bank holidays) in Lithuania.</td>
                        </tr>
                    </tbody>
                </table>

                <h2>Introduction</h2>

                “Okeo Payments, UAB” (also referred to as “we”, “us”, “Okeo Payments”, “Company”) is an electronic money institution licensed by the Bank of Lithuania (
                <a href="https://www.lb.lt/en/enforcement-measures-1/view_license?id=1967" rel="noopener noreferrer" target="_blank">license no. 71</a>
), incorporated and existing in Vilnius, Lithuania, company registration number 305219549, having its registered office at Gedimino pr. 20, LT-01103, Vilnius.
                <br />
                <br />
                Okeo Payments is committed to ensure the highest standards of service to all our customers. However, on some occasions you may feel that we have failed to meet your expectations. Therefore, we want to have the opportunity to put things right for you and take steps, where appropriate, to make sure that any mistakes are fixed or prevented from happening in the future.
                <br />
                <br />
                We recognize that many concerns will be raised informally, and dealt with quickly. Our aim is to resolve informal concerns promptly and efficiently.
                <br />
                <br />
                An informal approach is appropriate when it can be achieved. But if concerns cannot be satisfactorily resolved informally, then the formal complaints procedure should be followed.
                <br />
                <br />
                This policy underscores our commitment to accountability, fairness and maintaining a positive relationship with our customers.

                <h2>Purpose</h2>

                This Complaints Policy aims to explain formal complaint handling procedure at Okeo Payments and is designed to ensure that your formal concerns are addressed politely, promptly, fairly, consistently, respectfully and free of charge.

                <h2>Submitting a complaint</h2>

                You can always contact Okeo Payments by sending us an email at
                {' '}
                <a href="mailto:hello@okeo.com">hello@okeo.com</a>
. Our friendly Customer Support Team will happily assist you in resolving any issue you may have encountered when using our services. It has been our experience that Customer Support Team here at Okeo Payments helps customers resolve their requests in the most effective manner.
                <br />
                <br />
                If you still feel disappointed after contacting our Customer Support Team or you believe that we didn’t meet your expectation and you want to make a formal complaint, please send it to
                {' '}
                <a href="mailto:complaints@okeo.com">complaints@okeo.com</a>
. You should submit it no later than within 3 months from learning about a violation of your rights.
                <br />
                <br />
                Please make sure that you include the following information in your request:

                <ul>
                    <li>Your name and surname,</li>
                    <li>Your contact details (email address),</li>
                    <li>The date of the incident or issue that led to the complaint,</li>
                    <li>Description of your issue (try to be as specific as possible and add supporting documentation that could help us resolve the case efficiently and fairly),</li>
                    <li>Your claim,</li>
                    <li>A Power of Attorney if you’re represented by another person.</li>
                </ul>

                The complaint should be submitted in the Lithuanian or English language.
                <br />
                <br />
                If the information, provided in the complaint is incomplete or incomprehensible, our employee may contact the complainant for additional information.
                <br />
                <br />
                Please be aware that we reserve the right to refrain from handling the complaints that are anonymous or otherwise not meeting the above stated rules.

                <h2>Fraud Complaints</h2>

                At Okeo Payments we prioritize the security of our customers and strive to maintain a transparent and trustworthy environment. This policy outlines our commitment to address any fraud-related concerns promptly and efficiently. Your confidence in our services is of utmost importance, and we encourage you to report any suspicious activity to ensure a secure experience.
                <br />
                <br />
                If you believe you became a victim of a fraud, please write to us at hello@okeo.com. We will do necessary steps to investigate your concerns and ensure the secure experience.

                <h2>After you submit your complaint</h2>

                After receiving your complaint, we will confirm it with you via e-mail which will contain the information on the time limit for our final reply. If the information you provide us with is sufficient to adequately assess the situation, we will do our best to provide you with a final answer as soon as possible. The final reply’s time frame may vary according to the complexity of the case, but will take no longer than 15 business days.
                <br />
                <br />
                If due to the case complexity, lack of the supporting evidence or documents, the need for some additional investigation or other reason, we won’t be able to provide you with the final answer within 15 business days, we will let you know when to expect our final reply. In this case, the final reply will be provided no later than within 35 business days since we first received your complaint. We will also indicate the reason for the extended time frame of our final reply.
                <br />
                <br />
                Please be aware that we will communicate with you in English or Lithuanian.

                <h2>Out-of-court dispute resolution authority related to the financial service user’s rights or legal interests arising from contracts or related relations </h2>

                If the reply from Okeo Payments does not satisfy you or you do not receive any reply from us within 15 business days, you have the right to submit a request to the Bank of Lithuania to settle a dispute with us within one year from when you contacted us regarding the matter.Please be aware that if you miss this deadline, you can no longer apply regarding the matter.
                <br />
                <br />
                Your application can be submitted in the following ways:

                <ul>
                    <li>via the electronic dispute settlement facility E-Government Gateway;</li>
                    <li>
by completing a Consumer Application Form and sending it to the Supervision Service of the Bank of Lithuania, Totorių g. 4, LT01121 Vilnius, e-mail:
                        {' '}
                        <a href="mailto:prieziura@lb.lt">prieziura@lb.lt</a>
;
                    </li>
                    <li>
                    by filing out a free-form application and sending it to the Supervision Service, Totorių g. 4, LT01121, Vilnius, e-mail:
                        {' '}
                        <a href="mailto:prieziura@lb.lt">prieziura@lb.lt</a>
;
                    </li>
                </ul>

                Whatever the way you choose to submit your application, it and its annexes must be either in the official language of the country, i.e., Lithuanian, or in the English language.
                <br />
                <br />
                Please be aware that the dispute settlement process will be conducted in the official language of
                the country, i.e., Lithuanian.
                <br />
                <br />
                Applications submitted electronically to the Bank of Lithuania must be signed with a qualified electronic signature or submitted through the Bank of Lithuania's electronic consumer dispute resolution system.
                <br />
                <br />
                More information is available on the website of the Bank of Lithuania:
                <br />
                <br />
                <a href="https://www.lb.lt/en/dbc-settle-a-dispute-with-a-financial-service-provider" rel="noopener noreferrer" target="_blank">https://www.lb.lt/en/dbc-settle-a-dispute-with-a-financial-service-provider</a>
                .

                <h2>Out-of-court complaint authority regarding breached regulatory requirements related to the provision of financial services</h2>

                You can also make a complaint to the Bank of Lithuania if you believe that we have violated your rights or legal interests related to non-compliance with legal acts applicable to a financial market participant.
                <br />
                <br />
                In this case you can file a complaint by:

                <ul>
                    <li>
sending an application in a free form or a completed application
                        {' '}
                        <a href="https://www.lb.lt/uploads/documents/files/musu-veikla/finansu-rinkos-dalyviu-prieziura/skundai/Skundas-del-finansu-rinkos-dalyvio-veiksmu_EN.docx" rel="noopener noreferrer" target="_blank">form</a>
                        {' '}
by post or by visiting the Bank of Lithuania at: Totorių g. 4, LT01121 Vilnius;
                    </li>
                    <li>
                        {' '}
e-mailing a completed application
                        {' '}
                        <a href="https://www.lb.lt/uploads/documents/files/musu-veikla/finansu-rinkos-dalyviu-prieziura/skundai/Skundas-del-finansu-rinkos-dalyvio-veiksmu_EN.docx" rel="noopener noreferrer" target="_blank">form</a>
                        {' '}
to
                        {' '}
                        <a href="mailto:prieziura@lb.lt" rel="noopener noreferrer" target="_blank">prieziura@lb.lt</a>
                    </li>
                </ul>

                Whichever way you choose, the complaint and its attachments must be signed and submitted in the official language of the country, i.e., Lithuanian, or in the English language.
                <br />
                <br />
                Please be aware that the dispute settlement process will be conducted in the official language of the country, i.e., Lithuanian.
                <br />
                <br />
                More information is available on the website of the Bank of Lithuania:
                {' '}
                <a href="https://www.lb.lt/en/dbc-settle-a-dispute-with-a-financial-service-provider" rel="noopener noreferrer" target="_blank">https://www.lb.lt/en/dbc-settle-a-dispute-with-a-financial-service-provider</a>
.

                <h2>Learning from complaints</h2>

                All complaints received by Okeo Payments are analysed and will usually be stored for the retention period of 3 years (since Okeo Payments takes a decision on it). Complaint related statistics are used to improve our products and services. We review the information gathered from complaints regularly and consider whether and how our services could be improved or internal policies and procedures updated.

                <h2>Confidentiality</h2>

                Your privacy is our priority. Therefore, Okeo Payments will treat your personal information as confidential, respecting data protection legislation, as well as our internal policies and procedures. You can find more regarding the data protection and processing in our
                {' '}
                <a href="http://okeo.com/legal/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
.
                <h2>Adoption</h2>

                We regularly review this Complaints Policy and reserve the right to modify it at any time in accordance with applicable laws and regulations.

                Any changes and clarifications will take effect immediately upon their publication on our website.

                Please review this Complaints Policy from time to time to stay updated on any changes

                <h2>Regulations & Other Sources</h2>

                BOARD OF THE BANK OF LITHUANIA RESOLUTION ON THE APPROVAL OF THE RULES FOR THE OUT-OF-COURT PROCEDURE OF SETTLEMENT OF THE DISPUTES BETWEEN CONSUMERS AND FINANCIAL MARKET PARTICIPANTS IN THE BANK OF LITHUANIA 26 January 2012 No 03-23 Vilnius

                <br />
                <br />

                REPUBLIC OF LITHUANIA LAW ON THE BANK OF LITHUANIA 1 December 1994 No I-678 Vilnius (As last amended on 10 November 2016 – No XII-2771)

                <br />
                <br />

                THE BOARD OF DIRECTORS OF THE BANK OF LITHUANIA RESOLUTION ON THE APPROVAL OF THE PROCEDURE FOR THE SUBMISSION OF COMPLAINTS ON VIOLATIONS OF LEGAL ACTS REGULATING THE FINANCIAL MARKET 2016 November 29 No. 03-167 Vilnius

                <br />
                <br />
                <a href="https://www.lb.lt/en/complaints-against-a-financial-service-provider" rel="noopener noreferrer" target="_blank">https://www.lb.lt/en/complaints-against-a-financial-service-provider</a>

                <br />
                <a href="https://www.lb.lt/en/dbc-settle-a-dispute-with-a-financial-service-provider" rel="noopener noreferrer" target="_blank">https://www.lb.lt/en/dbc-settle-a-dispute-with-a-financial-service-provider</a>

                <br />
                <br />

                BOARD OF THE BANK OF LITHUANIA RESOLUTION ON THE APPROVAL OF THE RULES FOR THE HANDLING OF COMPLAINTS RECEIVED BY THE FINANCIAL MARKET PARTICIPANTS 6 June 2013 No 03-105 Vilnius

                <br />
            </ComplaintsPolicyWrapper>
        </LegalContent>
    </LegalWrapper>
);
