import React from 'react';
import ReactTooltip from 'react-tooltip';

import { TooltipProps } from './Tooltip.types';

export const Tooltip: React.FC<TooltipProps> = ({
    name,
    label,
    children,
    type = 'dark',
    place = 'bottom',
    effect = 'solid',
}) => (
    <>
        <div data-tip data-for={name}>
            {children}
        </div>
        <ReactTooltip id={name} effect={effect} place={place} type={type}>
            <span>{label}</span>
        </ReactTooltip>
    </>
);
