import React from 'react';

import { ID } from 'config/layout/layout.types';
import { breakPoints } from 'config/layout/layout';
import { ONBOARDING_PERSONAL_URL } from 'config/environment';
import { DedicatedIbanImage, DedicatedIbanMobileJpg } from 'assets/images';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './DedicatedIban.styled';
import { DedicatedIbanProps } from './DedicatedIban.types';


export const DedicatedIban: React.FC<DedicatedIbanProps> = ({ t }) => (
    <SectionWrapper id={ID.DedicatedIban}>
        <Styled.ContentWrapper>
            <Styled.LeftContent>
                <Styled.Title>
                    {t('title.label')}
                </Styled.Title>
                <Styled.Text>
                    {t('text.label')}
                </Styled.Text>
                <div>
                    <a href={ONBOARDING_PERSONAL_URL} target="_blank" rel="noreferrer">
                        <ChevronButton label={t('buttons.open.label')} />
                    </a>
                </div>
            </Styled.LeftContent>

            <Styled.RightContent>
                <picture>
                    <source srcSet={DedicatedIbanMobileJpg} media={`(max-width: ${breakPoints.mobile}px)`} />
                    <source srcSet={DedicatedIbanImage} media={`(max-width: ${breakPoints.tablet}px)`} />
                    <img src={DedicatedIbanImage} alt="Woman holding tablet and glasses" />
                </picture>
            </Styled.RightContent>
        </Styled.ContentWrapper>
    </SectionWrapper>
);
