import React from 'react';
import { Link } from 'react-router-dom';

import { ID } from 'config/layout/layout.types';
import { pricingPersonalPath } from 'config/path/path';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './Payments.styled';
import { PaymentsProps } from './Payments.types';
import { PaymentsAnimation } from './PaymentsAnimation/PaymentsAnimation.layout';


export const Payments: React.FC<PaymentsProps> = ({ t }) => (
    <SectionWrapper id={ID.Payments} additionalStyles="overflow: visible !important;">
        <Styled.ContentWrapper>
            <Styled.LeftContent>
                <PaymentsAnimation />
            </Styled.LeftContent>

            <Styled.RightContent>
                <Styled.Title>
                    {t('title.label')}
                </Styled.Title>

                <Styled.Text>
                    {t('text.label')}
                </Styled.Text>

                <div>
                    <Link to={pricingPersonalPath}>
                        <ChevronButton label={t('buttons.check.label')} />
                    </Link>
                </div>
            </Styled.RightContent>
        </Styled.ContentWrapper>
    </SectionWrapper>
);
