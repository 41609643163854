import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints, standardTransition } from 'config/layout/layout';

const MapSection = styled.div`
    display: grid;
    grid-template-columns: 400px 1fr;
    height: 600px;
    margin-top: 90px;

    @media (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 300px 1fr;
        height: 500px;
    }

    @media (max-width: ${breakPoints.mobile}px) {
        height: 1100px;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }
`;

const InfoBox = styled.div`
    background: ${globalColors.backgrounds.white};
    font-size: 16px;
    padding: 50px;
    filter: drop-shadow(0px 3px 10px rgba(0,0,0,0.16));
    position: relative;
    z-index: 1;

    @media (max-width: ${breakPoints.tablet}px) {
        font-size: 14px;
        padding: 30px 40px;
    }
`;

const Title = styled.div`
    font-size: 19px;
    font-weight: bold;
    padding-bottom: 20px;

    @media (max-width: ${breakPoints.tablet}px) {
        font-size: 16px;
    }
`;

const Address = styled.div`
    font-weight: 500;
    padding-bottom: 15px;
`;

const UnderlinedLabel = styled.div`
    font-weight: 700;
    font-size: 24px;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (max-width: ${breakPoints.tablet}px) {
        font-size: 19px;
    }
`;

const PinWrapper = styled.div`
    ${standardTransition};
`;

const OfficeBox = styled.div<{ fill?: string }>`
    display: grid;
    grid-template-columns: 30px 1fr;
    gap: 15px;
    padding-bottom: 30px;
    cursor: pointer;

    svg {
        fill: ${({ fill }) => fill};
    }

    &:hover {
        ${PinWrapper} {
            transform: scale(1.25);
        }

        svg {
            fill: ${globalColors.fontColors.green.dark};
        }
    }
`;

export default {
    MapSection,
    InfoBox,
    Title,
    Address,
    UnderlinedLabel,
    OfficeBox,
    PinWrapper
};
