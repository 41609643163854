import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import Main from 'pages/Main/Main';

import { CookiesPreferencesProvider } from 'store/providers/CookiesPreferencesProvider/CookiesPreferencesProvider';
import { OkeoIconSet } from '@okeo/components';

const App: React.FC = () => (
    <CookiesPreferencesProvider>
        <Router>
            <CookiesProvider>
                <Main />
                <OkeoIconSet />
            </CookiesProvider>
        </Router>
    </CookiesPreferencesProvider>
);

export default App;
