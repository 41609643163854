import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { standardTransition } from 'config/layout/layout';

const DropdownContainer = styled.div``;

const DropdownOptionIcon = styled.div`
    width: 22px;
    height: 22px;

    img {
        width: 100%;
        height: auto;
    }
`;

const DropdownOptionContainer = styled.div<{ displayLabel?: boolean; }>`
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;
    gap: 7.5px;
    align-items: center;

    ${({ displayLabel }) => displayLabel && 'grid-template-columns: 1fr auto auto;'}
`;

const DropdownOptionWrapper = styled.div`
    display: grid;
    gap: 10px;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding: 10px 15px;
    ${standardTransition}

    &:hover {
        background: linear-gradient(
            169deg,
            #6dde36 0%,
            rgba(73, 165, 30, 0.73) 100%
        );
    }

    cursor: pointer;
`;

const DropdownOptions = styled.div`
    position: absolute;
    background: ${globalColors.backgrounds.white};
    display: grid;
    gap: 1px;
    left: -10px;
    top: calc(100% + 5px);
    box-shadow: #0000000a 1px 1px 8px;
    max-height: 220px;
    overflow: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: #d1d1d1;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    scrollbar-width: thin;
    scrollbar-color: #d1d1d1 transparent;
`;

const DropdownOptionText = styled.div`
    text-transform: uppercase;
`;

export {
    DropdownContainer,
    DropdownOptionIcon,
    DropdownOptionContainer,
    DropdownOptionWrapper,
    DropdownOptions,
    DropdownOptionText,
};
