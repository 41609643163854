import styled from 'styled-components';

import {
    breakPoints,
    standardBottomBorder,
    standardTransition,
    standardTransitionTime
} from 'config/layout/layout';
import { globalColors } from 'assets/globalColors';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';


const Title = styled.div`
    font-size: 32px;
    letter-spacing: 0px;
    line-height: 48px;
    font-weight: 400;
    text-align: center;
    padding-top: 50px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 24px;
        line-height: 36px;
        padding-top: 43px;
    }

    ${Underline}{
        font-weight: 600;
        padding-bottom: 5px;
        text-transform: uppercase;
    }
`;

const ContentWrapper = styled.div`
    background-color: #F0F2EF;
    padding-bottom: 36px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr;
        padding-bottom: 0;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 40px 0 160px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 50px 0;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        flex-direction: column;
        text-align: center;
        padding: 50px 0 55px;
        align-items: center;
    }

    button {
        width: fit-content;
        min-width: 210px;
    }
`;

const UpperContent = styled.div`
    display: grid;
    grid-template-columns: 450px 1fr;

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 15px;

        li {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            justify-content: center;
            gap: 25px;
        }
    }

    img {
        width: 100%;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            display: block;
            max-width: 350px;
            margin: 0 auto;
        }
    }
`;

const LowerContent = styled.div`
    max-width: 768px;
    margin: 0 auto;

    ul {
        list-style-type: none;
        font-size: 16px;
        line-height: 24px;
        padding: 0;
        margin: 0;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            font-size: 15px;
            line-height: 24px;
        }
    }

    li {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: ${breakPoints.mobile}px) {
            padding: 11px 0px 11px 10px;
            grid-template-columns: 1fr;
            color: ${globalColors.fontColors.grey.dark};

            ${standardBottomBorder};
        }

        .title {
            ${standardBottomBorder}
            font-weight: 300;
            display: flex;
            align-items: center;
            padding-right: 15px;

            @media screen and (max-width: ${breakPoints.mobile}px) {
                border: none;
                padding: 0;
                margin-bottom: 7px;
            }
        }

        .description {
            color: ${globalColors.fontColors.black};
            border-bottom: 1px solid ${globalColors.backgrounds.green.base};
            padding: 18px 10px;
            text-align: center;
            background-color: white;
            font-weight: 500;

            @media screen and (max-width: ${breakPoints.mobile}px) {
                border: none;
                background-color: unset;
                padding: 0;
                text-align: left;
                font-weight: 600;
            }
        }
    }
`;

const SwitcherContainer = styled.div<{ mobile?: boolean }>`
    padding: 25x 0 53px;
    text-align: center;
    display: ${({ mobile }) => (mobile ? 'none' : 'block')};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: ${({ mobile }) => (mobile ? 'block' : 'none')};
    }
`;

const SwitcherWrapper = styled.div`
    overflow: hidden;
    padding: 1px 4px 1px 0px;
    border-radius: 21px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
`;

const SwitcherContentWrapper = styled.div<{ isChecked: boolean }>`
    display: inline-flex;
    height: 38px;
    border-radius: 21px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 8px 20px;
    background-color: #eaeaea;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    width: 305px;
    ${standardTransition};
    margin-bottom: 1rem;

    > div {
        position: relative;
        font-size: 15px;
        z-index: 1;
        height: 100%;
        width:100%;
        &:first-child {
            margin-left:-1.5rem;
            ${({ isChecked }) => (isChecked && `
                color: #ffff;
                font-weight: 600;
            `)};
            &:hover {
                ${({ isChecked }) => (!isChecked && `
                 color: ${globalColors.backgrounds.green.light};  
            `)};}
            
        }

        &:last-child {
            text-align: right;

            ${({ isChecked }) => (!isChecked && `
                color: #ffff;
                font-weight: 600;
            `)};

            &:hover {
                ${({ isChecked }) => (isChecked && `
                 color: ${globalColors.backgrounds.green.light};  
            `)};}
        }
    }


    &:after {
        height: 38px;
        border: 1px solid #D3D3D3;
        border-radius: 21px;
        position: absolute;
        background:${globalColors.backgrounds.green.light} 0% 0% no-repeat padding-box;
        top: 0;
        bottom: 0;
        margin: auto;
        content: '';
        display: block;
        transition: ${standardTransitionTime};
     

        ${({ isChecked }) => (isChecked ? 'left: 0' : 'left: 143px')};
        ${({ isChecked }) => (isChecked ? 'width: 134px' : 'width: 164px')};
    }

   
`;

export default {
    Title,
    Text,
    ContentWrapper,
    ButtonsWrapper,
    UpperContent,
    LowerContent,
    SwitcherWrapper,
    SwitcherContainer,
    SwitcherContentWrapper,
};
