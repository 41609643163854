import { useEffect } from "react";
import LinkedInTag from "react-linkedin-insight";

import { LINKEDIN_PARTNER_ID } from "config/environment";
import { removeLinkedInInsightCookies } from "./LinkedInInsight.helpers";

export const LinkedInInsight: React.FC = () => {
    useEffect(() => {
        if (LINKEDIN_PARTNER_ID) {
            if (!window.location.href.includes("localhost")) {
                LinkedInTag.init(LINKEDIN_PARTNER_ID, "dc", false);
            } else {
                // eslint-disable-next-line no-console
                console.warn(
                    "LinkedIn Insight is not initialized for localhost"
                );
            }
        }

        return () => {
            removeLinkedInInsightCookies();
        };
    }, []);

    return null;
};
