import React, { FC } from 'react';

import { SpinnerPaletteRingProps } from './SpinnerPaletteRing.types';
import Styled from './SpinnerPaletteRing.styled';

export const SpinnerPaletteRing: FC<SpinnerPaletteRingProps> = (props) => {
    const { strokeWidth = 10, size = 200 } = props;

    return (
        <Styled.Spinner strokeWidth={strokeWidth} size={size}>
            <span />
            <span />
            <span />
            <span />
        </Styled.Spinner>
    );
};
