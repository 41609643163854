import { deleteCookieByName } from "../Cookies.helpers";
import { LINKEDIN_COOKIE_NAME } from "../Cookies.types";

const getLinkedInInsightCookies = () => {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());

    const linkedInInsightRelatedCookies = [LINKEDIN_COOKIE_NAME];
    const regex = new RegExp(
        `(${linkedInInsightRelatedCookies.join("|")})`,
        "i"
    );

    const linkedInInsightCookies = cookies.filter((cookie) => regex.test(cookie));

    return linkedInInsightCookies.map((cookie) => cookie.slice(0, cookie.indexOf("=")).trim());
};

const removeLinkedInInsightCookies = () => {
    const linkedInInsightCookies = getLinkedInInsightCookies();

    if (linkedInInsightCookies.length > 0) {
        linkedInInsightCookies.forEach((cookieName) => {
            deleteCookieByName(cookieName);
        });
    }
};

export {
    removeLinkedInInsightCookies
};
