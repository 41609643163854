import styled from 'styled-components';
import { breakPoints, tabletHorizontalPadding } from 'config/layout/layout';
import { PALETTE } from '@okeo/components';


const Title = styled.div`
    font-size: 34px;
    letter-spacing: 0px;
    line-height: 64px;
    font-weight: 600;
    display: inline-block;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 27px;
        letter-spacing: 0px;
        line-height: 35px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        font-size: 24px;
        line-height: 33px;
    }
`;

const MainText = styled.div`
    font-size: 16px;
    line-height: 29px;
    padding: 5px 0;

    ul {
        list-style: none;
        padding: 0;
        display: grid;
        gap: 15px;

        li {
            display: flex;
            align-items: center;
            justify-content: start;
            gap: 15px;
        }
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;
    }
`;

const Text = styled(MainText)`
    font-weight: 300;
`;

const VideoOverlay = styled.div`
    position: absolute;
    width: 460px;
    background-color: white;
    height: 105%;
    top: 0;
    left: 0;
    z-index: 2;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: none;
    }
`;

const ContentWrapper = styled.div`
    padding: 60px 0 0px;
    white-space: nowrap;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: flex;
        flex-direction: column-reverse;
        white-space: unset;
        padding: 0;
        margin: 0 -${tabletHorizontalPadding}px;
        overflow: visible;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 0 0 45px;
        margin: 0;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 30px;
    margin-bottom: 20px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        justify-content: center;
    }
`;

const LeftContent = styled.div`
    width: 635px;
    padding: 20px 65px 60px 0;
    display: inline-block;
    background-color: white;
    white-space: break-spaces;
    position: relative;
    z-index: 3;
    margin-bottom: 60px;
    box-shadow: 43px 23px 30px #0000000F;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        margin: 0 auto;
        box-shadow: none;
        padding: 35px 50px 40px;
        width: 580px;
        margin-top: -220px;
        margin-bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        transform: translateY(60%);
        z-index: 2;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 10px 35px 0;
        width: 100%;
        margin-top: unset;
        position: static;
        transform: unset;
    }
`;
const RightContent = styled.div`
    display: inline-block;

    img {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 740px;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        height: 410px;
        width: 100%;
        display: block;
        margin-left: 0;
        margin: 0;
    
        img {
            display: none;
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        height: 260px;
        width: 100%;
        margin: 0 0 30px;
    }

    @media screen and (max-width: 400px) {
        margin: 0;
    }
`;

const Video = styled.video`
    height: 741px;
    position: absolute;
    right: -300px;
    top: 0;
    z-index: 1;

    @media screen and (max-width: 1200px) {
        right: -350px;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        width: 100%;
        height: auto;
        position: static;
    }
`;

const LearnMoreButton = styled.a`
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
    color: ${PALETTE.paper[999]};

    a {
        color: ${`${PALETTE.paper[999]} !important`};
    }

    &:hover,
    &:active,
    &:visited {
            color: ${`${PALETTE.paper[999]} !important`};
        }

    :visited {
        color: ${PALETTE.paper[999]};
    }
`;

export default {
    Text,
    Title,
    Video,
    LeftContent,
    RightContent,
    VideoOverlay,
    ButtonsWrapper,
    ContentWrapper,
    LearnMoreButton,
    MainText
};
