import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

import { getDeviceByWidth } from 'config/layout/layout';
import { Device } from 'config/layout/layout.types';

// import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/react-splide/css';

import { companiesCarouselOptions, renderCompaniesCarousel } from './Companies.helpers';
import { CompaniesCarousel, CompaniesContentWrapper } from './Companies.styled';
import { CompaniesProps } from './Companies.types';

export const Companies: React.FC<CompaniesProps> = () => {
    const { width } = useWindowSize();

    const device = getDeviceByWidth(width);

    let navbarContent;

    switch (device) {
        case Device.Tablet:
            navbarContent = (
                <CompaniesContentWrapper>
                    <CompaniesCarousel>
                        {renderCompaniesCarousel({
                            perPage: 4,
                            // interval: 2000,
                            ...companiesCarouselOptions,
                        })}
                    </CompaniesCarousel>
                </CompaniesContentWrapper>
            );
            break;

        case Device.Mobile:
            navbarContent = (
                <CompaniesContentWrapper>
                    <CompaniesCarousel>
                        {renderCompaniesCarousel({
                            perPage: 2,
                            // interval: 2000,
                            ...companiesCarouselOptions,
                        })}
                    </CompaniesCarousel>
                </CompaniesContentWrapper>
            );
            break;

        default:
            navbarContent = (
                <CompaniesContentWrapper>
                    <CompaniesCarousel>
                        {renderCompaniesCarousel({
                            ...companiesCarouselOptions,
                            perPage: 6,
                            // rewind: false,
                            // preloadPages: 2,
                            // perMove: 1,
                            // pagination: false,
                            easing: 'linear',
                            // lazyLoad: 'nearby',
                            // pauseOnHover: false,
                            gap: 40,

                            breakpoints: {
                                1300: {
                                    perPage: 5,
                                },
                            },
                        })}
                    </CompaniesCarousel>
                </CompaniesContentWrapper>
            );
    }

    return navbarContent;
};
