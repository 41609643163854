import React from 'react';
import { getPlanTypeButtonTheme, getPlanTypeIconVariant } from 'pages/Pricing/Pricing.helpers';
import {
    PricingIconTabWrapper,
    PricingTabBoldedLabel,
    PricingTabCost,
    PricingTabPeriod,
    PricingTabPlanType,
    PricingTabSign,
    PricingTabWrapper
} from 'pages/Pricing/Pricing.styled';

import { Button } from 'components/atoms/Button/Button';
import OkeoIcon from 'components/atoms/OkeoIcon/OkeoIcon.layout';
import { ONBOARDING_URL } from 'config/environment';

import { PricingTabProps } from '../PricingTabs.types';

export const PricingTab: React.FC<PricingTabProps> = ({ planType, t, pricingType }) => (
    <PricingTabWrapper>
        <PricingIconTabWrapper>
            <OkeoIcon variant={getPlanTypeIconVariant(planType)} />
        </PricingIconTabWrapper>

        <PricingTabBoldedLabel>
            {t(`tabs${pricingType}.${planType}.boldedLabel`)}
        </PricingTabBoldedLabel>
        <PricingTabPlanType>
            {t(`tabs${pricingType}.${planType}.planType`)}
        </PricingTabPlanType>

        <div>
            <PricingTabCost>{t(`tabs${pricingType}.${planType}.cost`)}</PricingTabCost>
            <PricingTabSign>{t(`tabs${pricingType}.${planType}.sign`)}</PricingTabSign>
        </div>

        <PricingTabPeriod>{t(`tabs${pricingType}.${planType}.period`)}</PricingTabPeriod>

        <a href={ONBOARDING_URL} target="_blank" rel="noreferrer">
            <Button
                buttonTheme={getPlanTypeButtonTheme(planType)}
                label={t(`tabs${pricingType}.${planType}.button.label`)}
            />
        </a>
    </PricingTabWrapper>
);
