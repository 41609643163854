import styled from "styled-components";

const ImageWrapper = styled.div``;

const ImageHighRes = styled.img<{ isLoaded: boolean }>`
    position: absolute;
    /* display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')}; */
    transition: 'opacity 500ms ease-in 50ms';
`;

const ImageLowRes = styled.img<{ isLoaded: boolean }>`
    position: absolute;
    /* display: ${({ isLoaded }) => (isLoaded ? 'block' : 'none')}; */
    transition: 'opacity 500ms ease-in 50ms';
`;

export { ImageWrapper, ImageHighRes, ImageLowRes };
