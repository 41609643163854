import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { personalRoutePath, defaultRoutePath, accumulativeRoutePath } from 'config/path/path';
import { getPageType, PageType, setPageType } from 'config/path/path.helpers';

import Styled from './PageTypeSwitcher.styled';


export const PageTypeSwitcher: React.FC<{ closeMenuHandler: () => void }> = ({ closeMenuHandler }) => {
    const { pathname } = useLocation();

    const pageType = getPageType();
    const isChecked = pageType === PageType.BUSINESS || pageType === PageType.ACCUMULATIVE;

    useEffect(() => {
        setPageType();
    }, []);

    const onClickHandler = (type?: string) => {
        setPageType(type);
        closeMenuHandler();
    };

    return (
        <Styled.Wrapper>
            {!pathname.includes(accumulativeRoutePath) && (
                <Styled.ContentWrapper isChecked={isChecked}>
                    <Link to={defaultRoutePath} onClick={() => (onClickHandler(PageType.BUSINESS))}>
                    Business
                    </Link>

                    <Link to={personalRoutePath} onClick={() => (onClickHandler(PageType.PERSONAL))}>
                    Personal
                    </Link>
                </Styled.ContentWrapper>
            )}

        </Styled.Wrapper>
    );
};
