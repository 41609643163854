import React from 'react';

const TransferArrows: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="46" height="68" viewBox="0 0 46 68">
        <defs>
            <clipPath id="a"><rect width="22.159" height="8.273" /></clipPath>
            <clipPath id="b"><rect width="9.721" height="1.754" /></clipPath>
        </defs>
        <g transform="translate(-936 -3276)">
            <rect width="1" height="68" transform="translate(959 3276)" fill="#dcdcdc" />
            <g transform="translate(936 3287)">
                <g fill="#f0f2ef" stroke="#dcdcdc" strokeWidth="1">
                    <circle cx="23" cy="23" r="23" stroke="none" />
                    <circle cx="23" cy="23" r="22.5" fill="none" />
                </g>
                <g transform="translate(12.705 13.134)">
                    <path d="M4.123,1.243,6.135,3.26H0V5.013H6.135L4.123,7.025,5.361,8.273,9.5,4.134,5.361,0Z" transform="translate(12.659 10.216)" fillRule="evenodd" />
                    <g clipPath="url(#a)"><path d="M0,4.134,4.139,8.273,5.377,7.025,3.365,5.013H22.159V3.26H3.365L5.377,1.243,4.139,0Z" transform="translate(0 0)" fillRule="evenodd" /></g>
                    <g transform="translate(0.716 13.475)" clipPath="url(#b)"><path d="M0,1.754H1.875V0H0Zm3.9,0H5.771V0H3.9ZM7.846,0V1.754H9.721V0Z" transform="translate(0 0)" fillRule="evenodd" /></g>
                </g>
            </g>
        </g>
    </svg>
);

export default TransferArrows;
