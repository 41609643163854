import styled, { css } from 'styled-components';

const hyperlinkStyles = css`
    cursor: pointer;
    text-decoration: none;
`;

export const PageHeading = styled.h1`
`;

export const SectionHeading = styled.h2`
`;

export const Paragraph = styled.p`
`;

export const Emphasis = styled.em`
    font-style: italic;
`;

export const Underline = styled.u`
    text-decoration: underline;
`;

export const Quotation = styled.q`
    margin: 0;
    font-style: italic;
`;

export const Code = styled.code`
    font-family: monospace;
    font-size: 12px;
`;

export const Anchor = styled.a`
    ${hyperlinkStyles};
`;

export const Strong = styled.span`
    text-decoration: none;
`;

export const UnorderedList = styled.ul`
    margin: 0;
    list-style: disc;
`;

export const OrderedList = styled.ol`
    margin: 0;
    list-style: decimal;
`;

export const ListItem = styled.li`
    list-style: inherit;
`;

export const Divider = styled.hr`
    margin-top: 50px;
    margin-bottom: 30px;
    border-style: none;
`;

export default {
    PageHeading,
    Divider,
    SectionHeading,
    Paragraph,
    Underline,
    Emphasis,
    Quotation,
    Code,
    Anchor,
    UnorderedList,
    OrderedList,
    ListItem,
    Strong,
};
