import React, { useEffect, useState } from "react";

import CloseIcon from "assets/icons/Close";
import { IconError, IconSuccess } from "assets/icons";
import useWindowSize from "hooks/useWindowSize";
import Input from "components/atoms/Input/Input";
import { Button } from 'components/atoms/Button/Button';
import Checkbox from "components/atoms/Checkbox/Checkbox";
import CardsDropdown from "components/atoms/CardsDropdown/CardsDropdown";
import CountryDropdown from 'components/atoms/CountryDropdown/CountryDropdown';
import { CardOption } from "components/atoms/CardsDropdown/CardsDropdown.types";
import { CountryOption } from 'components/atoms/CountryDropdown/CountryDropdown.types';
import { SpinnerPaletteRing } from "components/atoms/Spinners/SpinnerPaletteRing.layout";

import Styled from "./CardBanner.styled";
import { cardFormInitialState, signUpForCards } from "./CardBanner.form";
import { CardBannerProps, CardFormState, CardFormStatus } from "./CardBanner.types";


const CardBanner: React.FC<CardBannerProps> = ({ opened, closeAction, t }) => {

    useEffect(() => {
        if (opened) {
            document.querySelector("body")!.classList.add("noScroll");
        } else {
            document.querySelector("body")!.classList.remove("noScroll");
        }

        return () => {
            document.querySelector("body")!.classList.remove("noScroll");
        };
    }, [opened]);

    const { height } = useWindowSize();

    const [formStatus, setFormStatus] = useState<CardFormStatus>(CardFormStatus.ACTIVE);

    const [cardForm, updateCardForm] = useState<CardFormState>(cardFormInitialState);

    const handleCitizenshipChange = (option: CountryOption) => {
        updateCardForm({ ...cardForm, citizenship: option.value });
    };
    const handleCardTypeChange = (option: CardOption) => {
        updateCardForm({ ...cardForm, card: option.value });
    };
    const handleNameChange = (name: string) => {
        updateCardForm({ ...cardForm, name });
    };
    const handleEmailChange = (email: string) => {
        updateCardForm({ ...cardForm, email });
    };
    const handlePolicyPrivacyChange = (checked: boolean) => {
        updateCardForm({ ...cardForm, isPrivacyPolicyAccepted: checked });
    };

    const handleCloseAction = () => {
        setFormStatus(CardFormStatus.ACTIVE);
        updateCardForm(cardFormInitialState);
        closeAction();
    };

    const shouldHide = !opened;

    const formCanBeSubmited = cardForm.card.length
    && cardForm.citizenship.length
    && cardForm.email.length
    && cardForm.isPrivacyPolicyAccepted
    && cardForm.name.length;

    let content;

    switch (formStatus) {
        case CardFormStatus.PENDING:
            content = (
                <Styled.StatusWindow>
                    <Styled.BannerTitle>
                        {t('banner.state.pending')}
                    </Styled.BannerTitle>

                    <SpinnerPaletteRing size={200} strokeWidth={10} />
                </Styled.StatusWindow>
            );
            break;

        case CardFormStatus.FAILED:
            content = (
                <Styled.StatusWindow>
                    <Styled.BannerTitle>
                        {t('banner.state.failed')}
                    </Styled.BannerTitle>

                    <img src={IconError} alt="Error icon" width="80px" />
                </Styled.StatusWindow>
            );
            break;

        case CardFormStatus.SUCCESS:
            content = (
                <Styled.StatusWindow>
                    <Styled.BannerTitle>
                        {t('banner.state.success')}
                    </Styled.BannerTitle>

                    <img src={IconSuccess} alt="Success icon" width="80px" />
                </Styled.StatusWindow>
            );
            break;

        case CardFormStatus.ACTIVE:
        default:
            content = (
                <>
                    <Styled.BannerTitle>
                        {t('banner.title')}
                    </Styled.BannerTitle>

                    <Styled.Form>
                        <Input
                            name="name"
                            label={t('banner.fields.name')}
                            onChange={handleNameChange}
                        />

                        <Input
                            name="email"
                            type="email"
                            label={t('banner.fields.email')}
                            onChange={handleEmailChange}
                        />

                        <CountryDropdown label={t('banner.fields.citizenship')} onChange={handleCitizenshipChange} />

                        <CardsDropdown label={t('banner.fields.cards')} onChange={handleCardTypeChange} />

                        <Checkbox
                            name="isPrivacyPolicyAccepted"
                            onChange={handlePolicyPrivacyChange}
                            label={t('banner.fields.privacyPrivacy')}
                        />

                        <Button
                            disabled={!formCanBeSubmited}
                            onClick={() => {
                                setFormStatus(CardFormStatus.PENDING);
                                signUpForCards(cardForm, setFormStatus);
                            }}
                            label={t('buttons.signUp')}
                            additionalStyles="margin-top: 15px;"
                        />
                    </Styled.Form>
                </>
            );
    }

    return (
        <>
            <Styled.BannerWrapper shouldHide={shouldHide} />

            <Styled.Banner
                opened={opened}
                deviceHeight={height}
                onClick={({
                    target,
                    currentTarget,
                }: React.MouseEvent<HTMLElement>) => {
                    if (target === currentTarget) {
                        handleCloseAction();
                    }
                }}
            >
                <div className="bannerWrapper">
                    <div className="close-wrapper">
                        <CloseIcon
                            onClick={() => handleCloseAction()}
                        />
                    </div>

                    <div className="bannerContent">
                        {content}
                    </div>
                </div>
            </Styled.Banner>
        </>
    );
};

export default CardBanner;
