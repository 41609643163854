import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

import { getDeviceByWidth } from 'config/layout/layout';
import { Device } from 'config/layout/layout.types';

import {
    PricingTabsWrapper,
} from '../Pricing.styled';
import { PlanVariant } from '../Pricing.types';

import { PricingTab } from './PricingTab/PricingTab';
import { PricingTabsCaroussel } from './PricingTabsCaroussel/PricingTabsCaroussel';
import {
    pricingCarouselOptions,
    pricingMobileCarouselOptions,
} from './PricingTabs.helpers';
import { PricingTabsCarrouselWrapper } from './PricingTabs.styled';
import { PricingTabsProps } from './PricingTabs.types';

const PricingTabs: React.FC<PricingTabsProps> = ({ t, switchPlanType, pricingType }) => {

    const { width } = useWindowSize();

    const device = getDeviceByWidth(width);

    let pricingTabsContent;

    switch (device) {
        case Device.Mobile:
            pricingTabsContent = (
                <PricingTabsCarrouselWrapper>
                    <PricingTabsCaroussel
                        options={pricingMobileCarouselOptions}
                        t={t}
                        switchPlanType={switchPlanType}
                        pricingType={pricingType}
                    />
                </PricingTabsCarrouselWrapper>
            );
            break;

        case Device.Tablet:
            pricingTabsContent = (
                <PricingTabsCarrouselWrapper>
                    <PricingTabsCaroussel
                        options={pricingCarouselOptions}
                        t={t}
                        switchPlanType={switchPlanType}
                        pricingType={pricingType}
                    />
                </PricingTabsCarrouselWrapper>
            );

            break;

        default:
            pricingTabsContent = (
                <PricingTabsWrapper>
                    <PricingTab planType={PlanVariant.FREE} t={t} pricingType={pricingType} />
                    <PricingTab planType={PlanVariant.LITE} t={t} pricingType={pricingType} />
                    <PricingTab planType={PlanVariant.PREMIUM} t={t} pricingType={pricingType} />
                </PricingTabsWrapper>
            );
    }

    return pricingTabsContent;
};

export default PricingTabs;
