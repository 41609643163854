import { accumulativeRoutePath, personalRoutePath } from "config/path/path";

export const PageType = {
    PERSONAL: 'PERSONAL',
    BUSINESS: 'BUSINESS',
    ACCUMULATIVE: 'ACCUMULATIVE'
};

export function getPageType() {
    return window.sessionStorage.getItem('pageType');
}


const checkPageType = () => {
    const location = window.location.pathname;
    let routePath: string = PageType.BUSINESS;

    if (location.includes(accumulativeRoutePath)) {
        routePath = PageType.ACCUMULATIVE;
    } else if (location.includes(personalRoutePath)) {
        routePath = PageType.PERSONAL;
    } else {
        routePath = PageType.BUSINESS;
    }

    return routePath;
};


export function setPageType(type?: string) {
    let pageType = PageType.BUSINESS;

    const existingPageType = getPageType();

    if (existingPageType === null) {
        pageType = checkPageType();
    } else if (type) {
        pageType = type;
    } else if (existingPageType) {
        pageType = checkPageType();
    }

    return window.sessionStorage.setItem('pageType', pageType);
}
