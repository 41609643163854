import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints } from 'config/layout/layout';

export const BePartTitle = styled.div`
    font-size: 38px;
    letter-spacing: 0px;
    line-height: 50px;
    font-weight: 700;
    text-transform: uppercase;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 28px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        line-height: 35px;
    }
`;

export const BePartText = styled.div`
    max-width: 690px;
    font-size: 19px;
    line-height: 27px;
    font-weight: 300;
    margin: 0 auto;
    padding: 50px 0 55px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;
        max-width: 550px;
    }
`;

export const BePartContentWrapper = styled.div`
    color: ${globalColors.fontColors.white};
    text-align: center;
    padding: 105px 0 100px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 65px 0 75px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 55px 0 65px;
    }
`;
