import styled from 'styled-components';

import {
    mainWidth,
    breakPoints,
    desktopHorizontalPadding,
    mobileHorizontalPadding,
    tabletHorizontalPadding,
} from 'config/layout/layout';
import { ID } from 'config/layout/layout.types';

import { SectionWrapperProps } from './SectionWrapper.types';


export const MainWidth = styled.div`
    max-width: ${mainWidth}px;
    padding: 0 ${desktopHorizontalPadding}px;
    margin: 0 auto;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 0 ${tabletHorizontalPadding}px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 0 ${mobileHorizontalPadding}px;
    }
`;


export const StyledSectionWrapper = styled.section<Omit<SectionWrapperProps, 'id'>>`
    background: ${({ backgroundColor }) => backgroundColor};
    background-image: url(${({ backgroundImage }) => backgroundImage});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    ${({ additionalStyles }) => additionalStyles};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        overflow: hidden;
    }

    &#${ID.PricingPersonal} {
        @media screen and (max-width: ${breakPoints.tablet}px) {
            background-color: #F0F2EF;
        }
    }

    &#${ID.Account} {
        padding: 62px 0 76px;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            padding: 0;
            overflow: visible;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            ${MainWidth} {
                padding: 0;
            }
        }
    }

    &#${ID.Card} {
        @media screen and (max-width: 900px) {
            ${MainWidth} {
                padding: 0;
            }
        }
    }
`;
