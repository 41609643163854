import { ReactCookieProps } from "react-cookie";

import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "config/environment";

const COOKIES_PREFERENCES_COOKIE_NAME = "cookies_preferences";
const GOOGLE_ANALYTICS_COOKIE_NAME = "_ga";
const ZENDESK_COOKIE_NAME = "__zlcmid";
const FACEBOOK_PIXEL_COOKIE_NAME = "_fbp";
const GOOGLE_TAG_MANAGER_COOKIE_NAME = "_gcl_au";
const GOOGLE_ANALYTICS_COOKIE_NAME_WITH_ID = `_ga_${GOOGLE_ANALYTICS_MEASUREMENT_ID}`;
const LINKEDIN_COOKIE_NAME = "ln_or";
const HOTJAR_COOKIE_NAME = "_hj";

const COOKIES_NAMES = [
    COOKIES_PREFERENCES_COOKIE_NAME,
    GOOGLE_ANALYTICS_COOKIE_NAME,
    ZENDESK_COOKIE_NAME,
    FACEBOOK_PIXEL_COOKIE_NAME,
    GOOGLE_TAG_MANAGER_COOKIE_NAME,
    GOOGLE_ANALYTICS_COOKIE_NAME_WITH_ID,
    LINKEDIN_COOKIE_NAME,
];

const secondLevelDomain = window.location.hostname
    .split(".")
    .slice(-2)
    .join(".");

const DEFAULT_COOKIE_SET_OPTIONS = {
    // it's the highest possible value, regarding RFC 2616
    maxAge: 2147483647,
    domain: secondLevelDomain,
};

interface CookiesPreferences {
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
}

interface Cookies {
    [COOKIES_PREFERENCES_COOKIE_NAME]?: CookiesPreferences;
    [ZENDESK_COOKIE_NAME]?: string;
    [FACEBOOK_PIXEL_COOKIE_NAME]?: string;
    [GOOGLE_ANALYTICS_COOKIE_NAME]?: string;
    [GOOGLE_TAG_MANAGER_COOKIE_NAME]?: string;
    [LINKEDIN_COOKIE_NAME]?: string;
    // [GOOGLE_ANALYTICS_COOKIE_NAME_WITH_ID]?: string;
}

export {
    COOKIES_NAMES,
    /* ----------------- */
    LINKEDIN_COOKIE_NAME,
    COOKIES_PREFERENCES_COOKIE_NAME,
    FACEBOOK_PIXEL_COOKIE_NAME,
    GOOGLE_ANALYTICS_COOKIE_NAME,
    GOOGLE_TAG_MANAGER_COOKIE_NAME,
    HOTJAR_COOKIE_NAME,
    /* ----------------- */
    DEFAULT_COOKIE_SET_OPTIONS
};
export type { Cookies, CookiesPreferences };
export type CookiesProps = ReactCookieProps;
