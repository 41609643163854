
enum AccumulativeBannerState {
    OPEN = "OPEN",
    CLOSED = "CLOSED"
}

const ACCUMULATIVE_BANNER_SESSION_STATE_NAME = 'accumulativeBanner';


interface AccumulativeBannerProps {
    accumulativeBannerState: AccumulativeBannerState, setAccumulativeBannerState: React.Dispatch<AccumulativeBannerState>
}

export type { AccumulativeBannerProps };

export { AccumulativeBannerState, ACCUMULATIVE_BANNER_SESSION_STATE_NAME };
