export enum Device {
    Desktop = 'desktop',
    Tablet = 'tablet',
    Mobile = 'mobile',
}

export enum ID {
    Zendesk = 'zendesk-container',
    CookieBanner = 'cookie-banner',
    Smart = 'section-smart',
    Empower = 'section-empower',
    FAQ = 'section-faq',
    Welcome = 'section-welcome',
    Root = 'root',
    Pricing = 'section-pricing',

    Account = 'section-account',
    Benefits = 'section-benefits',
    DedicatedIban = 'section-dedicatedIban',
    Payments = 'section-payments',
    Card = 'section-card',
    PricingPersonal = 'section-pricing-personal',
    PricingAccumulative = 'section-pricing-accumulative',
    Trust = 'section-trust',
    Calculator = 'section-calculator',
    NotificationsAndLimits = 'section-notifications-and-limits',
    HowToOpen = 'section-how-to-open',
}
