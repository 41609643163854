import styled from 'styled-components';

import { standardTransition } from 'config/layout/layout';

import { ChevronDirection } from './Chevron.types';

const directionRotate = (direction: ChevronDirection) => {
    switch (direction) {
        case ChevronDirection.UP:
            return '180deg';
        case ChevronDirection.RIGHT:
            return '-90deg';
        case ChevronDirection.LEFT:
            return '90deg';
        default:
            return '0deg';
    }
};

const ChevronContainer = styled.span.attrs<{ direction: ChevronDirection; margin?: string }>(
    ({ direction, margin }) => ({ direction, margin }),
)`
    transform: rotate(${({ direction }) => directionRotate(direction)});
    ${standardTransition};
    display: inline-block;
    ${({ margin }) => margin && `margin: ${margin}`};
`;

export default ChevronContainer;
