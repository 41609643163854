import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { globalColors } from 'assets/globalColors';
import { EmpowerGirlPng } from 'assets/images';

import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { ID } from 'config/layout/layout.types';
import { pricingRoutePath } from 'config/path/path';

import {
    EmpowerBackgroundSpacing,
    empowerBackgroundStyles,
    EmpowerContentWrapper,
    EmpowerText,
    EmpowerTitle,
    scrolledEmpowerBackgroundStyles,
} from './Empower.styled';
import { EmpowerProps } from './Empower.types';

export const Empower: React.FC<EmpowerProps> = ({ t }) => {

    const [fullyVisable, setFullyVisable] = useState(false);

    const scrollHandler = () => {
        const distanceToTopScreen = document.getElementById(ID.Empower)!.getBoundingClientRect().top;
        const currentNavBarHeight = document.querySelector('header')!.offsetHeight;

        if (distanceToTopScreen <= currentNavBarHeight) setFullyVisable(true); else {
            setFullyVisable(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler, true);

        return () => {
            window.removeEventListener('scroll', scrollHandler, true);
        };
    }, []);

    const additionalStyles = fullyVisable ? scrolledEmpowerBackgroundStyles : empowerBackgroundStyles;

    return (
        <SectionWrapper
            id={ID.Empower}
            backgroundColor={globalColors.backgrounds.grey.base}
            backgroundImage={EmpowerGirlPng}
            additionalStyles={additionalStyles}
        >
            <>
                <EmpowerBackgroundSpacing />

                <EmpowerContentWrapper>
                    <EmpowerTitle>
                        {t('title.label')}
                    </EmpowerTitle>

                    <EmpowerText>
                        {t('text.label')}
                    </EmpowerText>

                    <Link to={pricingRoutePath} style={{ position: 'relative' }}>
                        <ChevronButton label={t('buttons.check.label')} />
                    </Link>
                </EmpowerContentWrapper>
            </>
        </SectionWrapper>
    );
};
