/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Trans } from 'react-i18next';
import React, { useRef, useState } from 'react';

import useOnScreen from 'hooks/useIsVisible';
import { ID } from 'config/layout/layout.types';
import { Button } from 'components/atoms/Button/Button';
import { stringToAttributeString } from 'utils/string-tools';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { ONBOARDING_ACCUMULATIVE_URL } from 'config/environment';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { ProductAndFeeTermsPDF } from 'assets/files';
import { ButtonTheme } from 'components/atoms/Button/Button.types';

import Styled from './PricingAccumulative.styled';
import { PricingPersonalListElement, PricingAccumulativeProps, } from './PricingAccumulative.types';


export const PricingAccumulative: React.FC<PricingAccumulativeProps> = ({ t }) => {

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    const [residencyType, setResidencyType] = useState(true);

    const pricingList: PricingPersonalListElement[] = t('pricingList', { returnObjects: true });
    const pricingListEEA: PricingPersonalListElement[] = t('pricingListEEA', { returnObjects: true });

    const selectedPricingList = residencyType ? pricingListEEA : pricingList;

    const renderItems = Object.values(selectedPricingList).map((listElement) => (
        <li key={`accumulative-Pricing-info-${stringToAttributeString(listElement.title)}`}>
            <div className="title">{listElement.title}</div>
            <div className="description">{listElement.description}</div>
        </li>
    ));


    const residencySwitcher = (
        <Styled.SwitcherWrapper>
            <Styled.SwitcherContentWrapper isChecked={residencyType}>
                <div role="button" onClick={() => setResidencyType(true)}>EEA</div>

                <div role="button" onClick={() => setResidencyType(false)}>Non-EEA</div>
            </Styled.SwitcherContentWrapper>
        </Styled.SwitcherWrapper>
    );

    return (
        <SectionWrapper id={ID.PricingAccumulative} additionalStyles="overflow: hidden; display: flex; justify-content: center; background-color: #F0F2EF;">
            <>
                <Styled.Title>
                    <div>
                        <Trans t={t} i18nKey="title.label" />
                    </div>
                    <Underline ref={ref} isVisible={isVisible}>
                        {t('title.underlinedLabel')}
                    </Underline>
                </Styled.Title>

                <Styled.SwitcherContainer>
                    {residencySwitcher}
                </Styled.SwitcherContainer>

                <Styled.ContentWrapper>
                    <Styled.LowerContent>
                        <Styled.SwitcherContainer mobile>
                            {residencySwitcher}
                        </Styled.SwitcherContainer>

                        <ul>
                            {renderItems}
                        </ul>
                    </Styled.LowerContent>
                </Styled.ContentWrapper>

                <Styled.ButtonsWrapper>
                    <a href={ProductAndFeeTermsPDF} download>
                        <Button
                            label={t('buttons.download.label')}
                            buttonTheme={ButtonTheme.OUTLINE_ORANGE}
                        />
                    </a>

                    <a href={ONBOARDING_ACCUMULATIVE_URL} target="_blank" rel="noreferrer">
                        <Button label={t('buttons.join.label')} />
                    </a>
                </Styled.ButtonsWrapper>
            </>
        </SectionWrapper>
    );
};
