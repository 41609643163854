import styled from 'styled-components';

export const ComplaintsPolicyWrapper = styled.div`
    .t1 tr > *:nth-child(1) {
        width:25%;
    }
    .t1 tr > *:nth-child(2) {
        width:75%;
    }
`;
