/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { CookiesPolicyPDF } from 'assets/files';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import { CookiesPolicyWrapper } from 'pages/Legal/CookiesPolicy/CookiesPolicy.styled';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';

export const CookiesPolicy: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Cookies Policy</LegalTitle>

            <div>
                <Tooltip name="tnc-download-btn" label="Download Cookies Policy">
                    <RoundUrlButton
                        primary
                        external
                        link={CookiesPolicyPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>Last update: 21 March 2024</LastUpdate>

        <LegalContent>
            <CookiesPolicyWrapper>
                <h2>Purpose and scope</h2>
                “Okeo Payments, UAB” (also referred to as "we", "us", "Okeo Payments", "Company") is an electronic money institution licensed by the Bank of Lithuania (license no. 71), incorporated and existing in Vilnius, Lithuania, company registration number 305219549, having its registered address at Gedimino pr. 20, LT-01103, Vilnius.

                Okeo Payments is committed to protect the privacy of your personal data in accordance with the applicable laws, including the General Data Protection Regulation (2016/679) and other applicable legal acts. We value your privacy and we only collect and use your personal data in accordance with our
                {' '}
                <a href="https://www.okeo.com/legal/privacy-policy">Privacy Policy</a>
, this Cookies Policy and binding provisions of the law.

                This Cookies Policy explains what cookies are, what type of cookies we use when you visit our website at
                {' '}
                <a href="http://okeo.com/">www.okeo.com</a>
                {' '}
(hereinafter – the Website), what information we collect using the cookies and how that information is used.

                <h2>What are cookies</h2>

                Cookies and other similar technologies are small text files generated by a website and saved by your browser when you visit various websites on the internet. We use cookies on our Website too.

                We might also use other similar technologies such as web beacons, tags, scripts, pixels, local storage etc. for the purposes described herein (for convenience all such technologies shall be referred to as “cookies”).

                With the help of cookies, we may understand how you are interacting with our Website, determine your preferences and improve your online experience.

                <h2>What cookies do we use</h2>

                Some cookies that we use are allocated to your device only for the duration of your visit on the Website, and these are called “session based” cookies. These cookies automatically expire at the end of the session – when the web browser is closed. Other cookies that we use, known as “persistent cookies”, consist of text files sent by a web server to a web browser, and are subsequently stored by the web browser. They remain valid until their expiry date (unless deleted by the user before the expiry date).

                There are cookies created and used directly by our Website, the so-called “first-party cookies”. The information stored in them can be read only by our Website. The “Third party cookies” are cookies that are placed on our Website when we use the external services.
                <br />
                <br />
                We have four types of cookies on our Website depending on their purpose:

                <table className="t1">
                    <thead>
                        <tr>
                            <th>
                            Type of cookies
                            </th>
                            <th>
                                Description
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1. Necessary Cookies  </td>
                            <td>Necessary cookies are essential to ensure proper functioning of our Website. Without them, our Website may not be able to provide services to our users standards. Because necessary cookies are needed to provide our services, you cannot turn them off. However, if you decide that you do not wish to receive cookies from us, you may block or delete them by changing your browser settings.</td>
                        </tr>
                        <tr>
                            <td>2. Preference Cookies</td>
                            <td>Preference Cookies enable a website to remember information that changes the way our Website behaves or looks, like your preferred language or the region that you are in.</td>
                        </tr>
                        <tr>
                            <td>3. Statistics Cookies</td>
                            <td>Statistics Cookies are used to analyse how our Website is being accessed, used or is performing, in order to provide you with the best user experience and to maintain, operate and continuously improve our Website. Information related to the Statistics Cookies that we use is collected and reported anonymously.</td>
                        </tr>
                        <tr>
                            <td>4. Marketing Cookies</td>
                            <td>Marketing Cookies are used to provide you with more relevant advertising. They are often served by third parties and are specifically designed to gather information on your device to display advertisements on topics that are potentially more interesting for you.</td>
                        </tr>
                    </tbody>
                </table>

                More information about the cookies used on our Website is provided below:

                <table className="t2">
                    <thead>
                        <tr>
                            <th>Cookie name</th>
                            <th>Purpose</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1. __cf_bm
                            </td>
                            <td>This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.</td>
                            <td>1 day</td>
                        </tr>
                        <tr>
                            <td>2. __cfruid</td>
                            <td>This cookie is a part of the services provided by Cloudflare - Including load-balancing, deliverance of website content and serving DNS connectionfor website operators.</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>3. __zlcmid</td>
                            <td>
                                This cookie preserves users states across page requests.
                            </td>
                            <td>1 year</td>
                        </tr>

                        <tr>
                            <td>4. _ga</td>
                            <td>
                            This cookie registers a unique ID that is used to generate statistical data on how the visitor uses the website.
                            </td>
                            <td>2 year</td>
                        </tr>
                        <tr>
                            <td>5. _ga_*</td>
                            <td>
                            This cookie is used by Google Analytics to collect data on the
                            number of times a user has visited the website as well as dates
                            for the first and most recent visit.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>6. persist:root</td>
                            <td>This cookie stores visitor credentials in an encrypted cookie in order to allow the visitor to stay logged in on re-entry, if the visitor has accepted the 'stay logged in'-button.</td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>7. __zlcstore</td>
                            <td>This cookie is necessary for the chat-box function.</td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>8. _gcl_au</td>
                            <td>This cookie is used by Google to store and track conversions.</td>
                            <td>3 months</td>
                        </tr>
                        <tr>
                            <td>9. _hjSessionUser_*</td>
                            <td>This cookie is used by Hotjar to store a unique user's session ID.</td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>10. _hjSession_*</td>
                            <td>This cookie is used by Hotjar to store a unique user ID.</td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>11. bcookie</td>
                            <td>
                            This cookie is used by the social networking service, LinkedIn, for tracking the use of embedded services.
                            </td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>12. cookies_preferences</td>
                            <td>
                            This cookie is used to store okeo website user cookies consent selection.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>13. dfpfpt </td>
                            <td>
                            This cookie is used to prevent abuse in payment workflows for LinkedIn
                            </td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>14. fptctx2</td>
                            <td>
                            This cookie is used to prevent abuse in payment workflows for LinkedIn
                            </td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>15. lang</td>
                            <td>
                                This cookie is set by LinkedIn when a webpage contains an embedded "Follow us" panel.
                            </td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>16. li_gc</td>
                            <td>
                            This cookie is used by Linkedin to store the user's cookie consent state for the current domain.
                            </td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>17. li_mc</td>
                            <td>
                            This cookie is used by Linkedin to store the user's cookie consent state for the current domain
                            </td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>18. li_sugr</td>
                            <td>
                            This cookie is used by Linkedin to make probabilistic match of a user's identity.
                            </td>
                            <td>90 days</td>
                        </tr>

                        <tr>
                            <td>19. liap</td>
                            <td>
                            This cookie is used by Linkedin to denote the logged in status of a member
                            </td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>20. lidc</td>
                            <td>
                                This cookie is used by the social networking
                                service, LinkedIn, for tracking the use of
                                embedded services.
                            </td>
                            <td>1 day</td>
                        </tr>
                        <tr>
                            <td>21. tr</td>
                            <td>
                            This cookie is used by Facebook to deliver a series of
                            advertisement products such as real time bidding from third
                            party advertisers.
                            </td>
                            <td>Session</td>
                        </tr>

                        <tr>
                            <td>22. _fbp</td>
                            <td>
                                This cookie is used by Facebook to deliver a
                                series of advertisement products such as real
                                time bidding from third party Cookie scan report
                                - 3/5 advertisers.
                            </td>
                            <td>3 months</td>
                        </tr>
                        <tr>
                            <td>23. ZD-suid</td>
                            <td>
                            This cookie determines arbitrary settings for the user's preferences when browsing Zendesk knowledge base.
                            </td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>24. ZD-build</td>
                            <td>
                            This cookie issues unique ID that identifies the user on recurring visits.
                            </td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>24. ZD-suid</td>
                            <td>This cookie issues unique ID that identifies the user's session.</td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>25. ZD-store</td>
                            <td>
                                This cookie registers whether the self-service assistant Zen desk Answer Bot has been displayed to the website user.
                            </td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>26. i/adsct</td>
                            <td>
                                This cookie is used by Twitter.com in order to
                                determine the number of visitors accessing the
                                website through Twitter advertisement content.
                            </td>
                            <td>Session</td>
                        </tr>
                        <tr>
                            <td>27. personalization_id</td>
                            <td>
                                This cookie is set by Twitter - the cookie allows
                                the visitor to share content from the website on
                                to their Twitter profile.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>28. muc_ads</td>
                            <td>
                                This cookie collects data on user behaviour and
                                interaction in order to optimize the website and
                                make advertisement on the website more
                                relevant.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>29. i18nextLng</td>
                            <td>
                            This cookie determines the preferred language of the visitor. Allows the website to set the preferred language upon the visitor's re-entry
                            </td>
                            <td>Persistent</td>
                        </tr>
                        <tr>
                            <td>30. 1P_JAR</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 month</td>
                        </tr>
                        <tr>
                            <td>31. AEC</td>
                            <td>
                            This cookie is used by Google to ensure that requests within a browsing session are made by the user, and not by other sites.
                            </td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>32. APISID</td>
                            <td>
                            This cookie is used by Google to display personalised advertisements on Google sites.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>34. AnalyticsSyncHistory</td>
                            <td>
                                This cookie is used in connection with data synchronization with third-party analysis service.
                            </td>
                            <td>1 month</td>
                        </tr>
                        <tr>
                            <td>34. CONSENT</td>
                            <td>
                            This cookie is used by Google to store a user’s cookie preferences.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>34. DV</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 day</td>
                        </tr>

                        <tr>
                            <td>35. HSID</td>
                            <td>
                            This cookie is used by Google to provide fraud prevention.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>36. IDE</td>
                            <td>
                            This cookie is used by Google to display ads.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>36. NID</td>
                            <td>
                            This cookie is used by Google to display personalized ads on non-Google sites.

                            </td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>36. OTZ</td>
                            <td>
                            This cookie is used by Google Analytics to track website information.
                            </td>
                            <td>1 hour</td>
                        </tr>
                        <tr>
                            <td>37. SAPISID</td>
                            <td>
                            This cookie is used by Google to play YouTube videos on the website.
                            </td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>38. SEARCH_SAMESITE </td>
                            <td>
                            This cookie is used to send correct data to Google
                            </td>
                            <td>6 months</td>
                        </tr>
                        <tr>
                            <td>39. SID </td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting and fraud prevention.
                            </td>
                            <td>2 years</td>
                        </tr>
                        <tr>
                            <td>40. SIDCC</td>
                            <td>
                            This cookie is used by Google to provide the identification of trusted web traffic.
                            </td>
                            <td>1 year</td>
                        </tr>
                        <tr>
                            <td>41. SOCS</td>
                            <td>
                            This cookie is used by Google to store a user’s state regarding their cookies choices.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>41. SSID</td>
                            <td>
                            This cookie is used by Google to play YouTube videos on the website.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>41. UserMatchHistory</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 month</td>
                        </tr>
                        <tr>
                            <td>42. __Secure-1PAPISID</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>43. __Secure-1PSID</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>44. __Secure-1PSIDCC</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>45. __Secure-1PSIDTS</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>46. __Secure-1PSIDTS</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>47. __Secure-3PSID</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>13 months</td>
                        </tr>
                        <tr>
                            <td>47. __Secure-3PSIDCC</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 Year</td>
                        </tr>
                        <tr>
                            <td>48. __Secure-3PSIDTS</td>
                            <td>
                            This cookie is used by Google to provide ad delivery or retargeting.
                            </td>
                            <td>1 Year</td>
                        </tr>
                    </tbody>
                </table>

                Our Website may display content from the external service providers, such as YouTube, Facebook, Twitter or LinkedIn, e.g. after using these providers’ “Like” button or the link to these providers’ websites. Third-parties’ cookie policies shall be applied when you view this content by clicking on it. You can find more about the cookie rules here:

                <ul>
                    <li>https://www.facebook.com/policies/cookies/</li>
                    <li>https://help.twitter.com/en/rules-and-policies/twitter-cookies</li>
                    <li>https://www.linkedin.com/legal/cookie-policy</li>
                    <li>https://policies.google.com/technologies/cookies?hl=lt</li>
                </ul>

                Our Website may have the links to the other third-party websites. We do not control how cookies are placed on third party websites, even if you are redirected to them from our Website. If you click on a link on our Website and it redirects you to the other websites, you have to evaluate how these third parties use their cookies.

                <h2>How to manage cookies</h2>

                There are number of ways to manage cookies:

                <h3>Cookie Settings on our Website</h3>

                If you wish to manage the cookies used by us, you may select the cookie settings in our cookie banner (by clicking on “Manage cookies”) before browsing our Website.

                In the cookie banner you may manage all cookies placed on our Website, except strictly necessary cookies. The Website may not function properly without the strictly necessary cookies.

                To renew or change your cookie consent
                {' '}
                <a href="https://okeo.com/#cookie-banner">click here</a>
.

                <h3>Using Your Internet Browser</h3>

                The majority of internet browsers allow you to change your cookies settings. In order to understand these settings, use of the links below or the “Help” option in your browser:

                <ul>
                    <li>
                     Cookie settings in Microsoft Edge (https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09),
                    </li>
                    Cookie settings in Firefox (https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences),
                    <li>Cookie settings in Chrome (https://support.google.com/chrome/answer/95647?hl=en),</li>
                    <li>
                        Cookie settings in Safari iOS and MacOS (https://support.apple.com/en-us/105082, https://support.apple.com/en-is/guide/safari/sfri11471/17.0/mac/14.0)
                    </li>
                </ul>

                If you delete the cookies, all settings controlled by these cookies will be cleared and you will need to set them again when you visit our Website at some later point.

                <h2>Contact us or our DPO</h2>

                You may contact us by writing an email at
                {' '}
                <a href="mailto:hello@okeo.com">hello@okeo.com</a>
                {' '}
                or by mailing us at Okeo Payments, UAB address at Gedimino ave. 20, LT-01103, Vilnius, Lithuania.

                You can contact our Data Protection Officer by writing an e-mail at:
                {' '}
                <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>

                <h2>Changes to Cookies Policy</h2>

                We regularly review this Policy and reserve the right to modify it at any time in accordance with applicable laws. Any changes and clarifications will take effect immediately upon their publication on our Website.

                Please review the Cookies Policy from time to time to stay updated on the changes.
            </CookiesPolicyWrapper>
        </LegalContent>
    </LegalWrapper>
);
