import {
    getEnvironment,
    getKubernetesRuntimeVariable,
    getVersionDetails,
    parseHelmMapToJson,
    setUrl,
} from './environment.helpers';
import { Environment, KubernetesRuntimeEnvVariable } from './environment.types';

/* no destructing because whole ‘process.env’ text is substituted by DefinePlugin during build */
const env = process.env; // eslint-disable-line prefer-destructuring

export const ENVIRONMENT: Environment = getEnvironment(env.NODE_ENV);

export const VERSION_DETAILS = getVersionDetails(env);


// TO_DO Extend runtime variables
export const ONBOARDING_PERSONAL_URL = "https://join.okeo.com/personal";

export const HELP_PAGE_URL = "https://help.okeo.com/hc/en-us";

export const OKEO_VIDEO_URL = 'https://www.youtube.com/';

export const ZENDESK_KEY = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.ZENDESK_KEY
);
export const GOOGLE_ANALYTICS_MEASUREMENT_ID = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.GOOGLE_ANALYTICS_MEASUREMENT_ID
);
export const GOOGLE_MAP_KEY = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.GOOGLE_MAP_KEY
);
export const GOOGLE_MAP_ID = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.GOOGLE_MAP_ID
);
export const GOOGLE_TAG_MANAGER_ID = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.GOOGLE_TAG_MANAGER_ID
);
export const LINKEDIN_PARTNER_ID = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.LINKEDIN_PARTNER_ID
);
export const HOTJAR_ID = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.HOTJAR_ID
);
export const HOTJAR_SNIPPET_VERSION = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.HOTJAR_SNIPPET_VERSION
);

const feUrlToBeUrl = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.FE_URL_TO_BE_URL
);

const dnsToBackendUrlMap = parseHelmMapToJson(feUrlToBeUrl);

export const API_URL = setUrl(dnsToBackendUrlMap);

const myOkeoFeUrlMap = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.MY_OKEO_FE_URL
);
const dnsToMyOkeoUrlMap = parseHelmMapToJson(myOkeoFeUrlMap);

export const MY_OKEO_URL = setUrl(dnsToMyOkeoUrlMap);

const onboardingFeUrlMap = getKubernetesRuntimeVariable(
    KubernetesRuntimeEnvVariable.ONBOARDING_FE_URL
);
const dnsToOnboardingUrlMap = parseHelmMapToJson(onboardingFeUrlMap);

export const ONBOARDING_URL = setUrl(dnsToOnboardingUrlMap);

export const ONBOARDING_ACCUMULATIVE_URL = `${ONBOARDING_URL}accumulative`;
