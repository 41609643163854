import { WithTranslation } from "react-i18next";

enum CardBannerState {
    OPENED = "opened",
    CLOSED = "closed",
}

interface CardBannerOwnProps {
    opened: boolean;
    closeAction: () => void;
}

type CardBannerProps = CardBannerOwnProps & WithTranslation;

export type { CardBannerOwnProps, CardBannerProps };

export { CardBannerState };

export interface CardFormState {
    citizenship: string;
    card: string;
    name: string;
    email: string;
    isPrivacyPolicyAccepted: boolean;
}

export interface AddContactRequest {
    emailAddress: string;
    citizenship: string;
    firstName: string;
    campaignTypes: string[];
    isPrivacyPolicyAccepted: boolean;
}

export enum CardFormStatus {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}
