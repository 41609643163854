import React from 'react';

import { FadeInWrapperContainer } from './FadeInWrapper.styled';
import { FadeInWrapperProps } from './FadeInWrapper.types';

export const FadeInWrapper: React.FC<FadeInWrapperProps> = ({
    active, children, zIndex, hided
}) => (
    <FadeInWrapperContainer active={active} zIndex={zIndex} hided={hided}>
        {children}
    </FadeInWrapperContainer>
);
