import styled from 'styled-components';
import { breakPoints } from 'config/layout/layout';

const smallDesktopBreakpoint = '1255px';

const Title = styled.h3`
    font-size: 28px;
    letter-spacing: 0px;
    line-height: 38px;
    font-weight: 600;
    margin: 0 0 45px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 22px;
        line-height: 29px;
    }
`;

const Text = styled.div`
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    margin: 0 0 45px;
    max-width: 540px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 24px;
    }
`;

const ContentWrapper = styled.div`
    padding: 80px 0 120px;
    display: grid;
    grid-template-columns: 555px 1fr;
    gap: 30px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: flex;
        flex-direction: column-reverse;
        padding: 50px 0 325px;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 50px 0;
    }
`;

const LeftContent = styled.div`
    width: 588px;
    padding-top: 82px;

    @media screen and (max-width: ${smallDesktopBreakpoint}) {
        width: 100%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        margin: 0 auto;
        box-shadow: none;
        padding: 0;
        width: 100%;
        max-width: 500px;
    }
`;
const RightContent = styled.div`
    display: flex;
    justify-content: center;

    img {
        width: calc(100% + 20px);
        max-width: 650px;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            max-width: 470px;
        }
    }
`;


export default {
    Text,
    Title,
    LeftContent,
    RightContent,
    ContentWrapper,
};
