import { globalColors } from 'assets/globalColors';

import {
    PlanType,
    PlanVariant,
    PricingMobileRowContent,
} from '../../../Pricing.types';

export const rowExists = (row: PricingMobileRowContent, planType: PlanType) => {
    switch (planType) {
        case PlanVariant.FREE:
            return Boolean(row.free);
        case PlanVariant.LITE:
            return Boolean(row.lite);
        case PlanVariant.PREMIUM:
            return Boolean(row.premium);

        default:
            return false;
    }
};

export const additionalInfoExists = (row: PricingMobileRowContent, planType: PlanType) => {
    switch (planType) {
        case PlanVariant.FREE:
            return Boolean(row.additionalInfoFree);
        case PlanVariant.LITE:
            return Boolean(row.additionalInfoLite);
        case PlanVariant.PREMIUM:
            return Boolean(row.additionalInfoPremium);

        default:
            return false;
    }
};

export const setMobileTextPerPlanType = (row: PricingMobileRowContent, planType: PlanType) => {
    switch (planType) {
        case PlanVariant.FREE:
            return row.free;
        case PlanVariant.LITE:
            return row.lite;
        case PlanVariant.PREMIUM:
            return row.premium;

        default:
            return '';
    }
};

export const additionalInfoPerPlanType = (row: PricingMobileRowContent, planType: PlanType) => {
    switch (planType) {
        case PlanVariant.FREE:
            return row.additionalInfoFree;
        case PlanVariant.LITE:
            return row.additionalInfoLite;
        case PlanVariant.PREMIUM:
            return row.additionalInfoPremium;

        default:
            return '';
    }
};

export const getTickColorPerPlanType = (planType: PlanType) => {
    switch (planType) {
        case PlanVariant.FREE:
            return globalColors.backgrounds.green.light!;
        case PlanVariant.LITE:
            return '#FFD00E';
        case PlanVariant.PREMIUM:
            return globalColors.backgrounds.orange.base;

        default:
            return globalColors.backgrounds.green.light!;
    }
};
