import styled from 'styled-components';

export const ScrollListWrapper = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    gap: 30px;
`;

export const ScrollListIconWrapper = styled('span')`
    align-items: center;
`;
