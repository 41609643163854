import styled from 'styled-components';

const Version = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        width: 500px;
    }
`;

const ItemBackground = styled.div`
    min-height: 82px;
    max-height: 82px;
    filter: drop-shadow(rgba(0, 0, 0, 0.16) 2px 2px 0px);
    background: rgb(249, 249, 249);
    margin: 0px 50px 10px 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 50px;
    
    div {
        width: 50%;
    }

    @media (max-width: 768px) {
        margin: 10px 0;
        justify-content: center;
        flex-direction: column;
    }
`;

export default { Version, ItemBackground };
