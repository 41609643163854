import React, { useState } from 'react';

import Styled from './Checkbox.styled';
import { CheckboxProps } from './Checkbox.types';


const Checkbox: React.FC<CheckboxProps> = ({ label, name, onChange }) => {

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        onChange(!isChecked);
    };

    return (
        <Styled.CustomCheckboxWrapper>
            <Styled.HiddenCheckbox
                id={`${name}-checkbox`}
                name={name}
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            <Styled.CustomLabel htmlFor={`${name}-checkbox`}>
                <Styled.CheckboxBackground />
                <Styled.Checkmark isChecked={isChecked} />

                {label}
            </Styled.CustomLabel>
        </Styled.CustomCheckboxWrapper>
    );
};

export default Checkbox;
