const deleteCookieByNameAndDomain = (name: string, domain: string) => {
    document.cookie = `${name}=; domain=${domain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const deleteCookieByName = (name: string) => {
    document.cookie = `${name}=; domain=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export {
    deleteCookieByNameAndDomain,
    deleteCookieByName
};
