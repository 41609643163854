import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { standardTransition, ZENDESK_Z_INDEX } from 'config/layout/layout';

const ZendeskChatContainer = styled.div<{ isCookieBannerOpen?: boolean }>`
    position: fixed;
    bottom: ${({ isCookieBannerOpen }) => (isCookieBannerOpen ? '120px' : '30px')};
    width: 57px;
    right: 30px;
    z-index: ${ZENDESK_Z_INDEX};

    ${standardTransition};

    &.down {
        bottom: 30px;
    }
`;

const ZendeskButton = styled.button`
    border-radius: 50%;
    height: 57px;
    width: 57px;
    border: ${globalColors.backgrounds.grey.dark} solid 1px;
    line-height: 0;
    cursor: pointer;
    fill: ${globalColors.fontColors.orange};
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.16));
    background-color: ${globalColors.backgrounds.white};
    ${standardTransition};

    &:hover {
        background-color: ${globalColors.backgrounds.orange.base};
        fill: ${globalColors.fontColors.white};
        border-color: ${globalColors.backgrounds.orange.base};
    }
`;

export { ZendeskButton, ZendeskChatContainer };
