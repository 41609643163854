import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { PlanVariant } from 'pages/Pricing/Pricing.types';

import { PricingTab } from '../PricingTab/PricingTab';
import { getPlanTypeByPrevOrder } from '../PricingTabs.helpers';
import { PricingTabCarousselProps } from '../PricingTabs.types';

export const PricingTabsCaroussel: React.FC<PricingTabCarousselProps> = ({
    options,
    t,
    switchPlanType,
    pricingType
}) => (
    <Splide
        options={options}
        className="pricing-tabs"
        onMoved={(splide, prev) => {
            switchPlanType(getPlanTypeByPrevOrder(prev));
        }}
    >
        <SplideSlide className="free">
            <PricingTab planType={PlanVariant.FREE} t={t} pricingType={pricingType} />
        </SplideSlide>

        <SplideSlide className="lite">
            <PricingTab planType={PlanVariant.LITE} t={t} pricingType={pricingType} />
        </SplideSlide>

        <SplideSlide className="premium">
            <PricingTab planType={PlanVariant.PREMIUM} t={t} pricingType={pricingType} />
        </SplideSlide>
    </Splide>
);
