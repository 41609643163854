import axios from 'axios';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import useWindowSize from 'hooks/useWindowSize';
import { pricingPersonalPath } from 'config/path/path';
import { getDeviceByWidth } from 'config/layout/layout';
import { Device, ID } from 'config/layout/layout.types';
import { Button } from 'components/atoms/Button/Button';
import TransferArrows from 'assets/icons/TransferArrows';
import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { ApiType } from 'config/environment/environment.helpers';
import { API_URL, ONBOARDING_PERSONAL_URL } from 'config/environment';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown.types';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import TransferArrowsHorizontal from 'assets/icons/TransferArrowsHorizontal';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import TranslationWithMarkup from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup';

import {
    roundToFourDecimalPlaces,
    doesSameCalculationExist,
    findObjectWithCurrencies,
    sendCurrenciesDropdownOptions,
    recieveCurrenciesDropdownOptions,
    formatCurrencyToNumeric,
    formatMoney,
} from './Calculator.helpers';
import Styled from './Calculator.styled';
import { CalculateFxRateResponse, CalculatorProps, ExistingFxRates } from './Calculator.types';

export const Calculator: React.FC<CalculatorProps> = ({ t }) => {

    const { width } = useWindowSize();
    const device = getDeviceByWidth(width);
    const isMobile = device === Device.Mobile;

    const [ipLocationCurrency, updateIpLocationCurrency] = useState<string | null>(null);

    const [multiplier, updateMultiplier] = useState<number>(1);
    const [recieve, setRecieve] = useState<string>(formatMoney(1000));
    const [send, setSend] = useState<string>(formatMoney(formatCurrencyToNumeric(recieve) / multiplier));

    const [selectedRecieveCurrency, updateSelectedRecieveCurrency] = useState(recieveCurrenciesDropdownOptions[2]);
    const [selectedSendCurrency, updateSelectedSendCurrency] = useState(sendCurrenciesDropdownOptions[0]);

    const [fee, updateFee] = useState({ feeAmount: 1, feeCurrency: 'EUR' });
    const [fxRates, updateFxRates] = useState<ExistingFxRates>([]);

    const getIpapiData = () => {
        axios.get(`https://ipapi.co/json/`)
            .then((response) => updateIpLocationCurrency(response.data.currency))
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error: ', error);
            });
    };

    useEffect(() => {
        getIpapiData();
    }, []);

    useEffect(() => {
        updateSelectedRecieveCurrency(recieveCurrenciesDropdownOptions[1]);

    }, [ipLocationCurrency]);

    const handleRecieveBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSend(formatMoney(send));
        setRecieve(formatMoney(e.target.value));
    };

    const handleSendBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRecieve(formatMoney(recieve));
        setSend(formatMoney(e.target.value));
    };

    const handleCurrencyChange = (option: DropdownOption) => {
        updateSelectedRecieveCurrency(option);
    };

    const handleSendCurrencyChange = (option: DropdownOption) => {
        updateSelectedSendCurrency(option);
    };

    const handleRecieveValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^,.0-9]/g, '').replace(",", ".") || '';

        const newSendValue = Number(numericValue.toLocaleString()) / multiplier;


        setRecieve(numericValue.toLocaleString());
        setSend(formatMoney(newSendValue));
    };

    const handleSendValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/[^,.0-9]/g, '').replace(",", ".") || '';

        const newRecieveValue = Number(numericValue.toLocaleString()) * multiplier;

        setSend(numericValue.toLocaleString());
        setRecieve(formatMoney(newRecieveValue));
    };

    const getFxRates = () => {
        // nosemgrep:nodejs_scan.javascript-ssrf-rule-node_ssrf
        axios.get(`${API_URL}/${ApiType.FX_RATES}/${selectedSendCurrency.label}/${selectedRecieveCurrency.label}`)
            .then((response) => {

                const { feeAmount, feeCurrency, totalRate } = response.data as CalculateFxRateResponse;

                updateMultiplier(totalRate);
                updateFee({ feeAmount, feeCurrency });

                const timestamp = new Date().getTime();
                const newFxRate = {
                    ...response.data,
                    selectedSendCurrency: selectedSendCurrency.label,
                    selectedRecieveCurrency: selectedRecieveCurrency.label,
                    updated: timestamp,
                };

                const updatedFxRates: ExistingFxRates = [...fxRates, ...[newFxRate]];

                updateFxRates(updatedFxRates);
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log('error: ', error);
            });
    };

    useEffect(
        () => setSend(formatMoney((formatCurrencyToNumeric(recieve) / multiplier).toFixed(2))),
        [multiplier]
    );

    useEffect(() => {
        if (ipLocationCurrency) {
            if (!doesSameCalculationExist(fxRates, selectedSendCurrency.label, selectedRecieveCurrency.label)) {
                getFxRates();
            } else {
                const existObject = findObjectWithCurrencies(fxRates, selectedSendCurrency.label, selectedRecieveCurrency.label)!;

                updateMultiplier(existObject.totalRate);
                updateFee({ feeAmount: existObject.feeAmount, feeCurrency: existObject.feeCurrency });
            }
        }
    }, [selectedRecieveCurrency, selectedSendCurrency, ipLocationCurrency]);


    return (
        <SectionWrapper id={ID.Calculator} backgroundColor="#F0F2EF" additionalStyles="overflow: hidden;">
            <Styled.ContentWrapper>
                <Styled.Title>
                    <Trans t={t} i18nKey={isMobile ? "title.mobileLabel" : "title.label"} />
                </Styled.Title>

                <Styled.Text>
                    <Trans t={t} i18nKey="text.label" />
                </Styled.Text>

                <Styled.GreenLightWrapper>
                    <Styled.CalculatorWrapper>
                        <Styled.CalculatorContent>
                            <div>
                                {t('calculator.recieve')}

                                <Styled.InputWrapper>
                                    <input
                                        type="text"
                                        name="recieve"
                                        value={recieve}
                                        onBlur={handleRecieveBlur}
                                        onChange={handleRecieveValueChange}
                                    />

                                    <Styled.DropdownWrapper>
                                        {ipLocationCurrency && (
                                            <Dropdown
                                                selected={selectedRecieveCurrency}
                                                options={recieveCurrenciesDropdownOptions}
                                                onOptionSelect={handleCurrencyChange}
                                                displayLabel
                                            />
                                        )}

                                    </Styled.DropdownWrapper>
                                </Styled.InputWrapper>

                                <div style={{ paddingLeft: '11px' }}>
                                    <TranslationWithMarkup
                                        t={t}
                                        translationKey="calculator.recieveTime"
                                    />
                                </div>
                            </div>

                            <Styled.TransferInfoWrapper>
                                <div>
                                    {isMobile ? <TransferArrowsHorizontal /> : <TransferArrows />}
                                </div>

                                <Styled.TransferInfo>
                                    <div>
                                        {`1 ${selectedSendCurrency.label} = ${roundToFourDecimalPlaces(1 * multiplier)} ${selectedRecieveCurrency.label}`}
                                    </div>

                                    <div>
                                        {`1 ${selectedRecieveCurrency.label} = ${roundToFourDecimalPlaces(1 / multiplier)} ${selectedSendCurrency.label}`}
                                    </div>
                                </Styled.TransferInfo>
                            </Styled.TransferInfoWrapper>

                            <div>
                                {t('calculator.send')}

                                <Styled.InputWrapper>
                                    <input
                                        type="text"
                                        name="send"
                                        value={send}
                                        onBlur={handleSendBlur}
                                        onChange={handleSendValueChange}
                                    />

                                    <Styled.DropdownWrapper>
                                        <Dropdown
                                            selected={selectedSendCurrency}
                                            options={sendCurrenciesDropdownOptions}
                                            onOptionSelect={handleSendCurrencyChange}
                                            displayLabel
                                        />
                                    </Styled.DropdownWrapper>
                                </Styled.InputWrapper>

                                <div style={{ paddingLeft: '11px' }}>
                                    <TranslationWithMarkup
                                        t={t}
                                        translationKey="calculator.paymentFee"
                                        values={{ paymentFee: fee.feeAmount, feeCurrency: fee.feeCurrency }}
                                    />
                                </div>
                            </div>
                        </Styled.CalculatorContent>

                        <Styled.ButtonWrapper>
                            <a href={ONBOARDING_PERSONAL_URL} target="_blank" rel="noreferrer">
                                <Button label={t('buttons.start')} additionalStyles="min-width: 211px;" />
                            </a>
                        </Styled.ButtonWrapper>
                    </Styled.CalculatorWrapper>
                    <Styled.GreenLight />
                </Styled.GreenLightWrapper>

                <div className="pricing-button-wrapper">
                    <Link to={pricingPersonalPath}>
                        <ChevronButton label={t('buttons.pricing')} margin="0" />
                    </Link>
                </div>
            </Styled.ContentWrapper>
        </SectionWrapper>
    );
};
