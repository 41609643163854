import React from 'react';
import { WithT } from 'i18next';
import { NavLink, useLocation } from 'react-router-dom';

import { getMenuItems } from 'config/menu/main/main';

import { PageTypeSwitcher } from 'components/atoms/PageTypeSwitcher/PageTypeSwitcher';
import { ENABLE_PERSONAL_PAGE } from 'config/featureFlags/featureFlags';
import { NavbarMenuContainer } from './NavbarMenu.styled';



const getActiveClassName = (path: string, hash: string, pathname: string): string | undefined => {

    if (hash && path.includes(hash)) return 'active';

    if (pathname && path === pathname) return 'active';

    return '';
};

export const NavbarMenu: React.FC<WithT> = ({ t }) => {

    const { hash, pathname } = useLocation();


    const menuItems = getMenuItems(t).map(({ label, url }) => (
        <li key={label}>
            <NavLink
                to={url}
                className={({ isActive }) => (isActive
                    ? getActiveClassName(url, hash, pathname)
                    : undefined)}
            >
                {label}
            </NavLink>
        </li>
    ));

    return (
        <NavbarMenuContainer>
            {ENABLE_PERSONAL_PAGE && <PageTypeSwitcher closeMenuHandler={() => null} /> }
            <ul>
                {menuItems}
            </ul>
        </NavbarMenuContainer>
    );
};
