import React from 'react';

import Chevron from '../Chevron/Chevron';
import { ChevronDirection } from '../Chevron/Chevron.types';

import Styled from './ChevronButton.styled';
import { ChevronButtonProps } from './ChevronButton.types';

export const ChevronButton: React.FC<ChevronButtonProps> = ({
    label = '',
    direction = ChevronDirection.RIGHT,
    margin,
    onClick,
}) => (
    <Styled.Button type="button" margin={margin} onClick={onClick}>
        {label}

        <Chevron
            margin="0 0 0 10px"
            direction={direction}
        />
    </Styled.Button>
);
