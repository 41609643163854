import styled from 'styled-components';

import { breakPoints, TAB_LIST_Z_INDEX } from 'config/layout/layout';

export const TabListWrapper = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 40px;
    margin-bottom: 50px;
    position: relative;
    z-index: ${TAB_LIST_Z_INDEX};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        margin-top: 30px;
        margin-bottom: 35px;
    }
`;
