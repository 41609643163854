import { WithT } from 'i18next';

import { Device } from 'config/layout/layout.types';

export enum HamburgerMenuState {
    Closed,
    Open,
}

export interface HamburgerNavbarMenuOwnProps {
    device: Device;
}

export type HamburgerNavbarMenuProps = HamburgerNavbarMenuOwnProps & WithT;
