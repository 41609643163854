import React from 'react';
import RoundedCountryFlag from "assets/icons/RoundedCountryFlag/RoundedCountryFlag.layout";
import { RoundedCountryFlags } from "assets/icons/RoundedCountryFlag/RoundedCountryFlag.types";
import { DropdownOption } from "components/atoms/Dropdown/Dropdown.types";
import { ExistingFxRates } from "pages/HomePersonal/sections/Calculator/Calculator.types";
import accounting from 'accounting';
import axios from 'axios';

export const doesSameCalculationExist = (array: ExistingFxRates, selectedSendCurrency: string, selectedRecieveCurrency: string) => {
    const currentTime = Date.now();

    return array.some((obj) => (
        obj.selectedSendCurrency === selectedSendCurrency
        && obj.selectedRecieveCurrency === selectedRecieveCurrency
        && (currentTime - obj.updated) < 30 * 60 * 1000 // 30 minutes
    ));
};

export const findObjectWithCurrencies = (array: ExistingFxRates, selectedSendCurrency: string, selectedRecieveCurrency: string) => array.find((obj) => (
    obj.selectedSendCurrency === selectedSendCurrency
        && obj.selectedRecieveCurrency === selectedRecieveCurrency
));

export const roundToFourDecimalPlaces = (number: number) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(number)) {
        return NaN;
    }

    const rounded = Number(number.toFixed(4));

    const roundedString = rounded.toString();
    const trimmed = parseFloat(roundedString);

    return trimmed;
};

export const standardCurrencyFormatting = {
    symbol: '',
    precision: 2,
    thousand: ' ',
    decimal: '.'
};

export const sendCurrenciesDropdownOptions: DropdownOption[] = [
    { value: 'EUR', label: 'EUR', icon: <RoundedCountryFlag name={RoundedCountryFlags.EU} /> },
    { value: 'PLN', label: 'PLN', icon: <RoundedCountryFlag name={RoundedCountryFlags.PL} /> },
    { value: 'GBP', label: 'GBP', icon: <RoundedCountryFlag name={RoundedCountryFlags.GB} /> },
    { value: 'USD', label: 'USD', icon: <RoundedCountryFlag name={RoundedCountryFlags.US} /> },
];

export const recieveCurrenciesDropdownOptions: DropdownOption[] = [
    { value: 'EUR', label: 'EUR', icon: <RoundedCountryFlag name={RoundedCountryFlags.EU} /> },
    { value: 'PLN', label: 'PLN', icon: <RoundedCountryFlag name={RoundedCountryFlags.PL} /> },
    { value: 'GBP', label: 'GBP', icon: <RoundedCountryFlag name={RoundedCountryFlags.GB} /> },
    { value: 'USD', label: 'USD', icon: <RoundedCountryFlag name={RoundedCountryFlags.US} /> },
    { value: 'BGN', label: 'BGN', icon: <RoundedCountryFlag name={RoundedCountryFlags.BG} /> },
    { value: 'CHF', label: 'CHF', icon: <RoundedCountryFlag name={RoundedCountryFlags.CH} /> },
    { value: 'CZK', label: 'CZK', icon: <RoundedCountryFlag name={RoundedCountryFlags.CZ} /> },
    { value: 'DKK', label: 'DKK', icon: <RoundedCountryFlag name={RoundedCountryFlags.DK} /> },
    { value: 'HUF', label: 'HUF', icon: <RoundedCountryFlag name={RoundedCountryFlags.HU} /> },
    { value: 'NOK', label: 'NOK', icon: <RoundedCountryFlag name={RoundedCountryFlags.NO} /> },
    { value: 'RON', label: 'RON', icon: <RoundedCountryFlag name={RoundedCountryFlags.RO} /> },
    { value: 'SEK', label: 'SEK', icon: <RoundedCountryFlag name={RoundedCountryFlags.SE} /> },
];

export const formatCurrencyToNumeric = (currency: string) => {

    if (typeof currency === 'number') {
        return currency;
    }

    const cleanCurrency = currency.replace(/[^0-9.]/g, '');

    const [integerPart, decimalPart] = cleanCurrency.split('.');

    const decimal = decimalPart ? decimalPart.padEnd(2, '0') : '00';

    const integer = integerPart.replace(/^0+/, '');

    const numericValue = parseFloat(`${integer}.${decimal}`);

    return numericValue;
};

export const formatMoney = (value: number | string) => accounting.formatMoney(value, standardCurrencyFormatting);

export const getIpapiData = async () => {
    const { data } = await axios.get(`https://ipapi.co/json/`);

    return data;
};


