import { useEffect } from 'react';

import useIpLocation from 'hooks/useIpLocation';
import { TranslationKey } from 'config/i18n/i18n.types';
import { getLanguagePreference } from 'utils/languagePreference';

import { accumulativeRoutePath } from 'config/path/path';
import { useLocation } from 'react-router-dom';
import { changeLanguagePreference } from './LanguagePreferences.helpers';

// TODO: Persist ipapi response to do not send requests within every page enter/refresh
export const LanguagePreferences: React.FC = () => {
    const ipLocation = useIpLocation()?.toLowerCase() as TranslationKey;
    const languagePreference = getLanguagePreference();
    const { pathname } = useLocation();
    const locationLanguage = Object.values(TranslationKey).includes(ipLocation) ? ipLocation : TranslationKey.EN;

    const isAccumulative = pathname.includes(accumulativeRoutePath);

    useEffect(() => {
        // INFO: accumulative page is only in english
        if (isAccumulative) return;

        if (languagePreference) {
            changeLanguagePreference(languagePreference);
        } else if (ipLocation) {
            changeLanguagePreference(locationLanguage);
        }
    }, [ipLocation]);

    return null;
};
