export enum TranslationNamespace {
    // common
    NAVBAR = 'navbar',
    BEPART = 'bepart',
    FOOTER = 'footer',

    // business
    FAQ = 'faq',
    WELCOME = 'welcome',
    EMPOWER = 'empower',
    WHY_OKEO = 'why-okeo',
    SMART = 'smart',

    // personal
    ACCOUNT = 'account',
    BENEFITS = 'benefits',
    DEDICATED_IBAN = 'dedicatedIban',
    PAYMENTS = 'payments',
    OPEN = 'open',
    CARD = 'card',
    PRICING_PERSONAL = 'pricingPersonal',
    TRUST = 'trust',
    CALCULATOR = 'calculator',
    NOTIFICATIONS_AND_LIMITS = 'notificationsAndLimits',
    HOW_TO_OPEN = 'HOW_TO_OPEN',
    FAQ_PERSONAL = 'FAQ_PERSONAL',

    // accumulative
    ACCOUNT_ACCUMULATIVE = 'ACCOUNT_ACCUMULATIVE',
    OPEN_ACCUMULATIVE = 'OPEN_ACCUMULATIVE',
    PRICING_ACCUMULATIVE = 'PRICING_ACCUMULATIVE',
    HOW_TO_OPEN_ACCUMULATIVE = 'HHOW_TO_OPEN_ACCUMULATIVE',

    // other
    PRICING = 'pricing',
    COUNTRIES = 'countries',
    CONTACT = 'contact',
    DICTIONARIES = 'dictionaries',
}

export enum TranslationKey {
    EN = 'en',
    PL = 'pl',
    LT = 'lt',
}

export const i18nNamespaces: TranslationNamespace[] = [
    TranslationNamespace.NAVBAR,
    TranslationNamespace.BEPART,
    TranslationNamespace.FAQ,
    TranslationNamespace.WELCOME,
    TranslationNamespace.EMPOWER,
    TranslationNamespace.WHY_OKEO,
    TranslationNamespace.SMART,
    TranslationNamespace.PRICING,
    TranslationNamespace.FOOTER,
    TranslationNamespace.COUNTRIES,
    TranslationNamespace.CONTACT,
    TranslationNamespace.ACCOUNT,
    TranslationNamespace.BENEFITS,
    TranslationNamespace.DEDICATED_IBAN,
    TranslationNamespace.PAYMENTS,
    TranslationNamespace.OPEN,
    TranslationNamespace.CARD,
    TranslationNamespace.PRICING_PERSONAL,
    TranslationNamespace.TRUST,
    TranslationNamespace.CALCULATOR,
    TranslationNamespace.NOTIFICATIONS_AND_LIMITS,
    TranslationNamespace.HOW_TO_OPEN,
    TranslationNamespace.FAQ_PERSONAL,
    TranslationNamespace.DICTIONARIES,
];

export const languages: TranslationKey[] = [
    TranslationKey.EN,
    TranslationKey.PL,
    TranslationKey.LT,
];
