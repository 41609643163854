import React, { useEffect, useState } from "react";
import { ImageWrapper, ImageHighRes } from "./ImageLoader.styled";

const ImageLoader = ({
    alt, lowResSrc, highResSrc
} : { alt: string, lowResSrc: string, highResSrc: string }) => {
    const [imgSrc, setImgSrc] = useState(lowResSrc || highResSrc);

    useEffect(() => {
        const img = new Image();

        img.src = lowResSrc;

        img.onload = () => {
            setImgSrc(highResSrc);
        };
    }, [highResSrc]);

    const isLoaded = !!(lowResSrc && imgSrc === lowResSrc);

    return (
        <ImageWrapper>
            <ImageHighRes
                src={imgSrc}
                alt={alt}
                className="image"
                isLoaded={isLoaded}
            />
        </ImageWrapper>
    );
};



export default ImageLoader;
