import React from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';

import { getDeviceByWidth } from 'config/layout/layout';
import { Device } from 'config/layout/layout.types';
import { legalPath } from 'config/path/path';

import HamburgerNavbarMenu from './HamburgerNavbarMenu/HamburgerNavbarMenu';
import NavbarButtons from './NavbarButtons/NavbarButtons';
import NavbarLogo from './NavbarLogo/NavbarLogo';
import NavbarMenu from './NavbarMenu/NavbarMenu';
import NavbarTranslationSelect from './NavbarTranslationSelect/NavbarTranslationSelect';
import {
    NavbarContainer,
    NavbarContent,
    NavbarMobileMenu,
    NavbarTabletMenu,
} from './Navbar.styled';
import { NavbarProps } from './Navbar.types';

export const Navbar: React.FC<NavbarProps> = ({ t }) => {
    const { width } = useWindowSize();

    const device = getDeviceByWidth(width);

    let navbarContent;

    const location = useLocation();

    const isLegalRoute = location.pathname.includes(legalPath);

    switch (device) {
        case Device.Tablet:
            navbarContent = (
                <NavbarTabletMenu>
                    <HamburgerNavbarMenu t={t} device={device} />
                </NavbarTabletMenu>
            );

            break;
        case Device.Mobile:
            navbarContent = (
                <NavbarMobileMenu>
                    <HamburgerNavbarMenu t={t} device={device} />
                </NavbarMobileMenu>
            );
            break;
        default:
            navbarContent = (
                <>
                    <NavbarMenu t={t} />
                    <NavbarButtons t={t} />
                    <NavbarTranslationSelect t={t} />
                </>
            );
    }

    return (
        <NavbarContainer>
            <NavbarContent>
                <NavbarLogo />
                {!isLegalRoute && navbarContent}
            </NavbarContent>
        </NavbarContainer>
    );
};
