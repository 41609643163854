/* eslint-disable no-console */
import axios from "axios";

import { API_URL } from "config/environment";
import { ApiType } from "config/environment/environment.helpers";
import { AddContactRequest, CardFormState, CardFormStatus } from "pages/HomePersonal/sections/Card/CardBanner/CardBanner.types";

export const signUpForCards = (formData: CardFormState, setCardFormStatus: React.Dispatch<React.SetStateAction<CardFormStatus>>) => {

    const data: AddContactRequest = {
        emailAddress: formData.email,
        citizenship: formData.citizenship,
        firstName: formData.name,
        campaignTypes: [formData.card],
        isPrivacyPolicyAccepted: formData.isPrivacyPolicyAccepted,
    };

    // nosemgrep:nodejs_scan.javascript-ssrf-rule-node_ssrf
    axios.put(`${API_URL}/${ApiType.EMAIL}/campaign/contact`, data)
        .then((response) => {
            setCardFormStatus(CardFormStatus.SUCCESS);
            console.log('add response', response);
        })
        .catch((error) => {
            setCardFormStatus(CardFormStatus.FAILED);
            console.log('error: ', error);
        });
};

export const cardFormInitialState = {
    name: '',
    email: '',
    citizenship: '',
    card: '',
    isPrivacyPolicyAccepted: false,
};

