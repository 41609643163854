import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import useOnScreen from 'hooks/useIsVisible';
import useWindowSize from 'hooks/useWindowSize';
import { Device, ID } from 'config/layout/layout.types';
import { getDeviceByWidth } from 'config/layout/layout';
import { stringToAttributeString } from 'utils/string-tools';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './Benefits.styled';
import { BenefitsListElement, BenefitsProps } from './Benefits.types';
import { benefitsCarouselImages, benefitsCarouselOptions } from './Benefits.helpers';


export const Benefits: React.FC<BenefitsProps> = ({ t }) => {

    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const { width } = useWindowSize();
    const device = getDeviceByWidth(width);
    const isMobile = device === Device.Mobile;

    const benefitsList: BenefitsListElement[] = t('list', { returnObjects: true });

    const renderSlides = Object.values(benefitsList).map((listElement, index) => (
        <SplideSlide key={`benefits-section-slide-${stringToAttributeString(listElement.subtitle)}`}>
            <div className="slide-content-wrapper">
                <Styled.SlideImage backgroundImage={benefitsCarouselImages[index]} />

                <div className="slide-content">
                    <div className="title">{listElement.title}</div>
                    <div className="subtitle">{listElement.subtitle}</div>
                    <div className="description">{listElement.description}</div>
                </div>
            </div>
        </SplideSlide>
    ));

    const backgroundColor = isMobile ? '#EDEFEB' : '#F0F2EF';

    return (
        <SectionWrapper id={ID.Benefits} additionalStyles={`background-color: ${backgroundColor};`}>

            <Styled.ContentWrapper>
                <Styled.Title>
                    <Underline additionalStyles="padding-bottom: 5px;" ref={ref} isVisible={isVisible}>
                        {t('title.underlinedLabel')}
                    </Underline>

                    <div style={{ display: 'inline' }}>
                        <Trans t={t} i18nKey="title.label" />
                    </div>
                </Styled.Title>

                <Styled.CarrouselWrapper>
                    <Splide
                        options={benefitsCarouselOptions}
                        className="benefits-section-carousel"
                    >
                        {renderSlides}
                    </Splide>
                </Styled.CarrouselWrapper>

            </Styled.ContentWrapper>
        </SectionWrapper>
    );
};
