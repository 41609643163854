export enum ChevronDirection {
    UP = 'up',
    DOWN = 'down',
    LEFT = 'left',
    RIGHT = 'right',
}

export interface ChevronProps {
    direction: ChevronDirection;
    fillColor?: string;
    margin?: string;
    width?: number;
    height?: number;
}
