import React from 'react';
import { Circle } from 'assets/icons/Circle';

import Chevron from 'components/atoms/Chevron/Chevron.layout';
import { ChevronDirection } from 'components/atoms/Chevron/Chevron.types';

import { ScrollListIconWrapper } from '../ScrollList.styled';

import { ScrollListItemWrapper } from './ScrollListItem.styled';
import { ScrollListItemProps } from './ScrollListItem.types';

export const ScrollListItem: React.FC<ScrollListItemProps> = ({ text, isActive }) => (
    <ScrollListItemWrapper isActive={isActive}>
        <ScrollListIconWrapper>
            {isActive
                ? <Chevron direction={ChevronDirection.RIGHT} height={12} width={16} />
                : <Circle />}
        </ScrollListIconWrapper>
        {text}
    </ScrollListItemWrapper>
);
