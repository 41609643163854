import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import {
    disableGoogleAnalytics, removeGoogleAnalyticsCookies,
} from 'pages/Main/Cookies/GoogleAnalytics/GoogleAnalytics.helpers';
import {
    GOOGLE_ANALYTICS_MEASUREMENT_ID,
} from 'config/environment';

const useGaTracker = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (GOOGLE_ANALYTICS_MEASUREMENT_ID) {
            if (!window.location.href.includes('localhost')) {
                ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);

                disableGoogleAnalytics(false);

                setInitialized(true);
            } else {
                // eslint-disable-next-line no-console
                console.warn(
                    'Google Analytics are not initialized for localhost'
                );
            }
        }

        return () => {
            setInitialized(false);
            disableGoogleAnalytics(true);
            ReactGA.reset();
            removeGoogleAnalyticsCookies();
        };
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.send({
                hitType: 'pageview',
                page: `${location.pathname}${location.search}`,
            });
        }
    }, [initialized, location]);
};

export default useGaTracker;
