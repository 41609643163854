import React from 'react';
import useWindowSize from 'hooks/useWindowSize';

import { getDeviceByWidth } from 'config/layout/layout';
import { Device } from 'config/layout/layout.types';

import { TablesWrapper } from '../Pricing.styled';

import { PricingTableDesktop } from './PricingTableDesktop/PricingTableDesktop';
import { PricingTableMobile } from './PricingTableMobile/PricingTableMobile';
import { PricingTableTablet } from './PricingTableTablet/PricingTableTablet';
import { PricingTableProps } from './PricingTable.types';

export const PricingTable: React.FC<PricingTableProps> = ({
    noHeader,
    planType,
    translation,
    mobileTranslation,
}) => {

    const { width } = useWindowSize();

    const device = getDeviceByWidth(width);

    let pricingTableContent;

    switch (device) {
        case Device.Tablet:
            pricingTableContent = (
                <PricingTableTablet translation={translation} planType={planType} noHeader={noHeader} />
            );

            break;
        case Device.Mobile:
            pricingTableContent = (
                <PricingTableMobile translation={mobileTranslation} planType={planType} noHeader={noHeader} />
            );
            break;
        default:
            pricingTableContent = (
                <PricingTableDesktop translation={translation} noHeader={noHeader} />
            );
    }

    return (
        <TablesWrapper>
            {pricingTableContent}
        </TablesWrapper>
    );
};
