import { globalColors } from 'assets/globalColors';
import styled, { css } from 'styled-components';

import { greenButtonTheme, outlineOrangeButtonTheme } from 'components/atoms/Button/Button.styled';
import { standardTransition } from 'config/layout/layout';

import { RoundUrlButtonProps } from './RoundUrlButton.types';

const disabledStyles = css`
    pointer-events: none;
`;

export const StyledButton = styled.a<RoundUrlButtonProps>`
    border-radius: 50%;
    width: 45px;
    height: 45px;
    border: 1px solid ${globalColors.backgrounds.grey.darker};
    text-align: center;
    line-height: 1;
    font-size: 13px;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    ${({ primary }) => (primary ? greenButtonTheme : outlineOrangeButtonTheme)}


    ${standardTransition};
    ${({ disabled }) => disabled && disabledStyles};
    ${({ additionalStyles }) => additionalStyles && additionalStyles};
`;

// ${ ({ buttonTheme }) => buttonTheme === ButtonTheme.GREEN && greenTheme };
// ${ ({ buttonTheme }) => buttonTheme === ButtonTheme.YELLOW && yellowTheme };
// ${ ({ buttonTheme }) => buttonTheme === ButtonTheme.ORANGE && orangeTheme };
// ${ ({ buttonTheme }) => buttonTheme === ButtonTheme.OUTLINE_ORANGE && outlineOrangeTheme };
// ${ ({ disabled }) => disabled && disabledStyles };
// ${ ({ margin }) => margin && `margin: ${margin}` };
// ${ ({ additionalStyles }) => additionalStyles && additionalStyles };
