import { globalColors } from 'assets/globalColors';
import styled, { css } from 'styled-components';

import { StyledButton } from 'components/atoms/Button/Button.styled';
import {
    breakPoints,
    HAMBURGER_NAVBAR_MENU_CONTENT_Z_INDEX,
    standardTransition,
} from 'config/layout/layout';

import { desktopNavbarHeight, tabletNavbarHeight } from '../Navbar.styled';

import { HamburgerMenuState } from './HamburgerNavbarMenu.types';

export const HamburgerNavbarMenuContainer = styled.div`
    display: none;

    .close-icon,
    .hamburger-icon {
        &:hover {
            cursor: pointer;
            opacity: 0.6;
        }
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        justify-items: end;
        grid-gap: 25px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
    }
`;

export const JoinButtonWrapper = styled.div`
    display: inline-block;

    ${StyledButton} {
        font-size: 12px;
        padding: 0 20px;
        min-width: 110px;
        height: 37px;
        font-weight: 500;
    }
`;

const menuOpenStyles = css`
    right: 0;
`;

export const MobileNavbarIconWrapper = styled.div`
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        cursor: pointer;
    }
`;

export const HamburgerNavbarMenuContent = styled.div<{
    menuState: HamburgerMenuState;
}>`
    position: fixed;
    right: -100vw;
    z-index: ${HAMBURGER_NAVBAR_MENU_CONTENT_Z_INDEX};
    width: 100%;
    display: grid;
    grid-template-rows: 6fr 2fr;
    height: calc(100vh - 66px);
    top: ${desktopNavbarHeight};
    background-color: white;
    border-top: 1px solid #e2e2e2;
    grid-template-rows: 1fr auto;
    padding: 40px 80px 70px;
    overflow-y: auto;
    
    ${standardTransition};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        top: ${tabletNavbarHeight};
        ${({ menuState }) => menuState === HamburgerMenuState.Open && menuOpenStyles}
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 40px 35px 100px;
    }

    ul {
        right: 100vw;
        flex-direction: column;
        width: 100%;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-top: 24px;
            padding: 10px;

            ${standardTransition};

            &:hover {
                color: ${globalColors.backgrounds.green.light};
            }
        }

        a {
            color: ${globalColors.fontColors.black};
            font-size: 21px;
            text-decoration: none;
        }
    }
`;
