import { useCallback, useEffect, useState } from 'react';

export const useScrollDown = () => {
    const [y, setY] = useState(document.scrollingElement!.scrollHeight);
    const [scrollDown, setScrollDown] = useState(false);

    const handleNavigation = useCallback(() => {

        if (y > window.scrollY) {
            setScrollDown(false);
        } else if (y < window.scrollY) {
            setScrollDown(true);
        }

        setY(window.scrollY);
    }, [y]);

    useEffect(() => {

        window.addEventListener('scroll', handleNavigation);

        return () => {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);

    return scrollDown;
};
