import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints } from 'config/layout/layout';

export const TabListElementContent = styled.div`
    font-size: 16px;
    line-height: 23px;
    color: ${globalColors.fontColors.black};
    font-weight: 300;
    padding-top: 18px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        font-size: 15px;
        line-height: 24px;
    }
`;

export const TabListElementWrapper = styled.div<{ index?: number }>`
    padding: 18px 18px 25px;
    border-bottom: 1px solid ${globalColors.backgrounds.grey.darker};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 18px 0px 25px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 13px 5px 17px 1px;
    }
`;

export const TabListElementTitle = styled.div`
    font-size: 19px;
    line-height: 34px;
    font-weight: 400;
    display: grid;
    grid-template-columns: 1fr 20px;
    grid-gap: 20px;
    cursor: pointer;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 16px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        font-size: 15px;
    }
`;

export const TabListTitleWrapper = styled.div<{ bolded?: boolean }>`
    font-weight: ${({ bolded }) => bolded && '600'};
`;

export const Div = styled.div``;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr 1fr;
    }

    img, span {
        vertical-align: middle;
        margin-right: 10px;
        filter: drop-shadow(0 0 1px ${globalColors.fontColors.grey.dark});
    }
`;

export const Bold = styled.b`
    font-weight: 500;
`;
