/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ApplicantsPrivacyPolicyLtPDF } from 'assets/files';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import ApplicationsPrivacyPolicyVersionSelector from 'pages/Legal/ApplicantsPrivacyPolicy/ApplicationsPrivacyPolicyVersionSelector/ApplicationsPrivacyPolicyVersionSelector';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';

export const ApplicantsPrivacyPolicyLT: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Kandidatų privatumo politika</LegalTitle>

            <div>
                <Tooltip name="tnc-download-btn" label="Atsisiųsti pareiškėjų privatumo politiką">
                    <RoundUrlButton
                        primary
                        external
                        link={ApplicantsPrivacyPolicyLtPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>

                <ApplicationsPrivacyPolicyVersionSelector />
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>Informacija atnaujinta: 2024.08.29</LastUpdate>

        <LegalContent>
            <h2>Tikslas ir taikymo sritis</h2>
            Ši Kandidatų asmens duomenų privatumo politika (toliau – „Politika“) skirta supažindinti su informacija apie Jūsų, kaip kandidato į laisvas darbo vietas Okeo Payments, UAB, asmens duomenų tvarkymą.
            <br />
            <br />
            Mes suprantame, kad asmens duomenų apsauga yra labai svarbi ir įsipareigojame gerbti Jūsų – kandidato į Okeo Payments, UAB darbuotojus, privatumą ir asmens duomenų apsaugą.
            <h2>Sąvokos</h2>
            <strong>Kandidatas</strong>
            {' '}
– asmuo, pretenduojantis į darbo vietą Bendrovėje.
            <br />
            <br />
            <strong>Jūs</strong>
            {' '}
– Kandidatas.
            <br />
            <br />
            <strong>Mes arba Bendrovė</strong>
            {' '}
– Okeo Payments, UAB, juridinio asmens kodas 305219549, buveinės adresas Gedimino pr. 20, LT-01103, Vilnius.
            <br />
            <br />
            <strong>Kandidato duotas sutikimas</strong>
            {' '}
– laisva valia duotas, konkretus ir nedviprasmiškas tinkamai informuotas Jūsų valios išreiškimas pareiškimu arba vienareikšmiais veiksmais kuriais Jus sutinkate, kad būtų tvarkomi su Jumis susiję asmens duomenys. Informuojame, kad sutikimas yra laikomas duotu pateikiant savo CV ir kitus su konkrečia darbo vieta susijusius dokumentus.

            <h2>Duomenys, kuriuos renkame</h2>
            Priklausomai nuo darbo pozicijos renkame ir tvarkome įvairių rūšių asmens duomenis. Žemiau pateikiamos pagrindinės, tačiau ne visos Bendrovės tvarkomų asmens duomenų kategorijos:
            <ul>
                <li>
                    <strong>Asmens tapatybės duomenys:</strong>
                    {' '}
vardas, pavardė, gimimo data (amžius).
                </li>
                <li>
                    <strong>Duomenys apie kvalifikaciją ir patirtį:</strong>
                    {' '}
informacija apie Jūsų darbo patirtį (darbovietė, darbo laikotarpis, pareigos, atsakomybės ir (ar) pasiekimai), informacija apie Jūsų išsilavinimą (mokymo įstaiga, mokymosi laikotarpis, įgytas išsilavinimas ir (ar) kvalifikacija), informacija apie kvalifikacijos kėlimą (išklausyti mokymai, įgyti sertifikatai), informacija apie kalbų mokėjimą, informacinių technologijų, vairavimo įgūdžius, kitas kompetencijas, taip pat kita informacija, kurią pateikėte savo gyvenimo aprašyme (CV), motyvaciniame laiške ar kituose kandidatavimo dokumentuose arba kurią gavome iš trečiųjų asmenų.
                </li>
                <li>
                    <strong>Kontaktiniai duomenys:</strong>
                    {' '}
gyvenamosios vietos adresas, korespondencijos adresas (jeigu skiriasi nuo nuolatinės gyvenamosios vietos) telefono numeris, el. pašto adresas.
                </li>
                <li>
                    <strong>Rekomendacijos, darbdavių atsiliepimai:</strong>
                    {' '}
Jus rekomenduojantis ar atsiliepimą teikiantis asmuo, jo kontaktas, rekomendacijos ar atsiliepimo turinys.
                </li>
            </ul>

            <h2>Asmens duomenų tvarkymo tikslai ir teisinis pagrindas</h2>
            Žemiau nurodytoje lentelėje pateikiami Jūsų asmens duomenų tvarkymo tikslai, teisinis pagrindas ir tvarkomų asmens duomenų kategorijos.
            <table>
                <thead>
                    <tr>
                        <th>
                            Duomenų tvarkymo tikslas
                        </th>
                        <th>
                            Teisinis duomenų tvarkymo pagrindas
                        </th>
                        <th>
                            Tvarkomų asmens duomenų kategorijos
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Kandidatų į darbuotojus atrankos vykdymas</td>
                        <td>Kandidato duotas sutikimas</td>
                        <td>
                            <ul>
                                <li>Asmens tapatybės duomenys;</li>
                                <li>Kontaktiniai duomenys;</li>
                                <li>Duomenys apie kvalifikaciją ir patirtį;</li>
                                <li>Rekomendacijos ir darbdavių atsiliepimai;</li>
                                <li>Kita pateikta informacija (pvz., nuorodos į socialinių tinklalapių paskyras, kurias pateikia Kandidatas).</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2>Iš kur gauname Jūsų asmens duomenis</h2>
            Didelę dalį Jūsų asmens duomenų gauname tiesiogiai iš Jūsų, pavyzdžiui, kai Jūs siunčiate gyvenimo aprašymą (CV) ir (ar) motyvacinį laišką bei kitus kandidatavimo dokumentus.
            <br />
            <br />
            Informaciją apie Jūsų kandidatūrą, Jūsų gyvenimo aprašymą (CV) ir (ar) kitus kandidatavimo dokumentus taip pat galime gauti iš darbo biržos, įdarbinimo agentūrų, darbo paieškos internetinių portalų, karjeros socialinių tinklų ir (ar) kitų darbo paieškos, atrankos ir (ar) tarpininkavimo paslaugas teikiančių subjektų.
            <br />
            <br />
            Mes galime rinkti Jūsų asmens duomenis, susijusius su kvalifikacija, profesiniais gebėjimais ir dalykinėmis savybėmis, iš buvusio darbdavio prieš tai informavę Jus, o iš esamo darbdavio – tik su Jūsų sutikimu.
            <br />
            <br />
            Tam tikrą informaciją galime taip pat gauti ir iš viešojo administravimo subjektų, registrų, duomenų bazių ir kitų šaltinių.
            <h2>Kam gali būti atskleisti Jūsų duomenys</h2>
            Jūsų Asmens duomenys gali būti perduodami:
            <ul>
                <li>Lietuvos bankui ir kitiems viešojo administravimo subjektams;</li>
                <li>Teisės, finansų, mokesčių, personalo administravimo, buhalterinės apskaitos patarėjams ir pan.;</li>
                <li>Tretiesiems asmenims, kurie laikydamiesi Mūsų nurodymų teikia mums su Kandidatų atranka, jų vertinimu ir vidaus administravimu susijusias paslaugas;</li>
                <li>Kitiems asmenims, kuriems duomenys reikalingi vykdant jiems taikomas teisines prievoles, esant teisėtam interesui ar gavus Jūsų sutikimą.</li>
            </ul>
            <h2>Jūsų teisės</h2>
            Jūs, kaip duomenų subjektas, turite teisę:
            <ul>
                <li>žinoti (būti informuotas) apie savo duomenų tvarkymą (teisė žinoti);</li>
                <li>susipažinti su savo duomenimis ir kaip jie yra tvarkomi (teisė susipažinti);</li>
                <li>reikalauti ištaisyti arba papildyti neišsamius asmens duomenis (teisė reikalauti ištaisyti);</li>
                <li>reikalauti ištrinti su Jumis susijusius asmens duomenis (teisė „būti pamirštam&quot;);</li>
                <li>reikalauti, kad Bendrovė apribotų su Jumis susijusių asmens duomenų tvarkymą (teisė apriboti);</li>
                <li>bet kuriuo metu nesutikti, kad būtų tvarkomi Jūsų asmens duomenys, jei tai daroma viešojo intereso labui arba tai būtina siekiant teisėtų Bendrovės arba trečiosios šalies interesų (teisė nesutikti);</li>
                <li>gauti su Jumis susijusius duomenis, kuriuos pateikėte Bendrovei, susistemintu, įprastai naudojamu ir kompiuterio skaitomu formatu, ir juos persiųsti kitam duomenų valdytojui (teisė į duomenų perkeliamumą);</li>
                <li>pateikti skundą Lietuvos Respublikos Valstybinei duomenų apsaugos inspekcijai.</li>
            </ul>
            <h2>Jūsų teisių įgyvendinimo tvarka</h2>
            Norėdami įgyvendinti savo teises ir gauti atsakymus į Jums aktualius klausimus, prašome kreiptis į mus
            {' '}
            <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>
.
            <br />
            <br />
            Jūsų prašymas bus išnagrinėtas per 30 kalendorinių dienų nuo prašymo pateikimo dienos. Šis terminas gali būti pratęstas 60 kalendorinių dienų, iš anksto pranešant Jums, jei prašymas susijęs su didele Asmens duomenų apimtimi, kitais tuo pačiu metu nagrinėjamais prašymais. Atsakymas Jums bus pateiktas el-paštu, nebent nurodysite kitą pageidaujamą formą.
            <br />
            <br />
            Mes galime atsisakyti patenkinti Jūsų prašymą, jei taikoma BDAR nustatyta išimtis ir (arba) duomenų subjektų naudojimosi apribojimas ir (arba) Jūsų prašymas yra akivaizdžiai nepagrįstas arba neproporcingas. Jei atsisakysime patenkinti Jūsų prašymą, tokio atsisakymo priežastį pateiksime Jums raštu.

            <h2>Duomenų saugojimo terminas</h2>

            Jeigu Jūsų kandidatūra darbui pas mus bus sėkmingai patvirtinta ir sudarysime darbo sutartį, Jūsų asmens duomenys bus perduoti ir tvarkomi pagal Mūsų darbuotojų asmens duomenų tvarkymo taisykles.
            <br />
            <br />
            Jeigu Jūsų neatrinksime darbui pas Mus, Jūsų pateikti asmens duomenys bus saugomi iki atrankos proceso pabaigos.
            <br />
            <br />
            Tam tikrais atvejais Jūsų asmens duomenis galime saugoti ilgiau:
            <ul>
                <li>jei būtina, kad galėtumėm apsiginti nuo reikalavimų, pretenzijų ar ieškinių ar pasinaudoti savo teisėmis pareikšti reikalavimus, pretenzijas ar ieškinius;</li>
                <li>esama pagrįstų įtarimų dėl neteisėtos veiklos;</li>
                <li>asmens duomenys būtini tinkamam ginčo, skundo išsprendimui;</li>
                <li>gaunamas Jūsų prašymas susipažinti su savo asmens duomenimis;</li>
                <li>esant kitiems teisės aktuose numatytiems pagrindams.</li>
            </ul>
            <h2>Teisė paduoti skundą</h2>
            Jei Jūs manote, kad Jūsų asmens duomenys tvarkomi pažeidžiant Jūsų teises ir teisėtus interesus, nustatytus galiojančiuose teisės aktuose, Jūs galite pateikti skundą priežiūros institucijai – Valstybinei duomenų apsaugos inspekcijai. Valstybinės duomenų apsaugos inspekcijos skundų nagrinėjimo tvarką galite rasti čia:
            {' '}
            <a href="https://vdai.lrv.lt/lt/kiti/atmintines/atmintine-asmenims-ketinantiems-kreiptis-i-valstybine-duomenu-apsaugos-inspekcija-del-skundo-pateikimo/" target="_blank" rel="noreferrer">https://vdai.lrv.lt/lt/kiti/atmintines/atmintine-asmenims-ketinantiems-kreiptis-i-valstybine-duomenu-apsaugos-inspekcija-del-skundo-pateikimo</a>
.
            <h2>Kaip saugomi Jūsų duomenys</h2>
            Patvirtiname, kad asmens duomenys Bendrovėje tvarkomi laikantis galiojančių duomenų apsaugos įstatymų reikalavimų, taip pat kontroliuojančiųjų institucijų nurodymų. Mes įgyvendiname visas reikalingas technines ir administracines priemones, kad surinkti duomenys būtų apsaugoti nuo praradimo, neleistino naudojimo ir pakeitimų. Mūsų darbuotojai ir personalo atrankos partneriai yra raštiškai įsipareigoję neatskleisti trečiosioms šalims ir neplatinti darbo vietoje gaunamos informacijos, įskaitant ir informaciją apie Kandidatus.
            <h2>Duomenų apsaugos pareigūno kontaktai</h2>
            Jei turite klausimų dėl duomenų tvarkymo, galite susisiekti su mūsų duomenų apsaugos pareigūnu šiuo adresu:
            {' '}
            <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>
.
            <h2>Politikos atnaujinimai</h2>
            Mes nuolat peržiūrime šią Politiką ir Jums pranešime apie esminius jos pakeitimus.
        </LegalContent>
    </LegalWrapper>
);
