import React from 'react';

const ZendeskIcon: React.FC<{
    width?: number;
    height?: number;
}> = ({ width, height }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 27.749 25.894"
    >
        <g id="chat" transform="translate(-2 -4.25)">
            <path
                id="Path_508"
                data-name="Path 508"
                d="M17.567,29.8l-1.693-.973,3.893-6.812h5.839a1.946,1.946,0,0,0,1.946-1.946V8.393a1.946,1.946,0,0,0-1.946-1.946H6.143A1.946,1.946,0,0,0,4.2,8.393V20.071a1.946,1.946,0,0,0,1.946,1.946H14.9v1.946H6.143A3.893,3.893,0,0,1,2.25,20.071V8.393A3.893,3.893,0,0,1,6.143,4.5H25.606A3.893,3.893,0,0,1,29.5,8.393V20.071a3.893,3.893,0,0,1-3.893,3.893H20.9Z"
                transform="translate(0 0)"
                strokeWidth="0.5"
            />
            <path
                id="Path_509"
                data-name="Path 509"
                d="M9,11.25H24.571V13.2H9Z"
                transform="translate(-0.911 -0.911)"
                strokeWidth="0.5"
            />
            <path
                id="Path_510"
                data-name="Path 510"
                d="M9,18h9.732v1.946H9Z"
                transform="translate(-0.911 -1.822)"
                strokeWidth="0.5"
            />
        </g>
    </svg>
);

ZendeskIcon.defaultProps = {
    width: 27.749,
    height: 25.894,
};

export default ZendeskIcon;
