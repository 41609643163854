import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { MainWidth } from 'components/atoms/SectionWrapper/SectionWrapper.styled';
import { breakPoints, standardTransition } from 'config/layout/layout';

export const LastUpdate = styled.div`
    color: ${globalColors.backgrounds.grey.darker};
    font-size: 13px;
    padding-left: 5px;

    .dateNote {
        font-size: 11px;
        vertical-align: top;
    }
`;

export const LegalTitle = styled.h1`
    margin-top: 0;
`;

export const TitleWithButtonsRow = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        padding-bottom: 35px;
    }

    > div {
        display: flex;
        align-items: center;
        gap: 15px;
    }
`;

export const LegalContent = styled.div`
    padding-top: 20px;

    a {
        color: ${globalColors.fontColors.green.light};
        ${standardTransition};
        text-decoration: none;

        &:hover,
        &:active {
            color: ${globalColors.fontColors.green.dark};
        }
    }
`;

export const LegalWrapper = styled(MainWidth)`
    padding-top: 55px;
    padding-bottom: 55px;

     @media screen and (max-width: ${breakPoints.mobile}px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .table-1-5 {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 10px;
        border-bottom: 1px solid ${globalColors.backgrounds.grey.darker};
        font-size: 14px;
        align-items: center;
        height: 100%;
    }

    .table-1-5 br {
        display: none;
    }

    .table-1-5 > div {
        padding: 10px;
    }

    .table-1-5 p:empty {
        display: none;
    }

    .list-table {
        display: grid;
        grid-template-columns: 50px 1fr;
        grid-gap: 10px;
        padding-bottom: 10px;
    }

    .list-table .list-table {
        grid-template-columns: 60px 1fr;
    }

    .list-table .list-table:first-child {
        margin-top: 15px;
    }

    .list-table br {
        display: none;
    }

    .list-table h3 {
        margin: 0;
    }

    .list-table p:empty {
        display: none;
    }

    .list-table-header {
        font-size: 1.17em;
        font-weight: bold;
        margin-top: 35px;
        align-items: center;
    }

    .list-table-first {
        margin-top: 10px;
    }

    .table-header {
        border-bottom: 1px solid ${globalColors.backgrounds.green.base};
        border-top: 2px solid ${globalColors.backgrounds.green.base};
    }

    .table-1-5 > div:first-child {
        border-right: 1px solid ${globalColors.backgrounds.grey.darker};
        padding-right: 10px;
        display: grid;
        align-content: center;
        height: 100%;

        @media (max-width: ${breakPoints.mobile}px) {
            padding-left: 0;
            min-width: 120px;
        }
    }

    table {
        border-top: 2px solid ${globalColors.backgrounds.green.base};
        background: #fff;
        font-size: 14px;
        margin-bottom: 40px;
        margin-top: 20px;
        border-collapse: collapse;
    }
    table td {
        padding: 10px;
        border-bottom: 1px solid ${globalColors.backgrounds.grey.darker};
    }

    table th {
        padding: 10px;
    }

    table td + td,
    table th + th {
        border-left: 1px solid ${globalColors.backgrounds.grey.darker};
    }

    thead th{
        border-bottom: 1px solid ${globalColors.backgrounds.green.base};
    }

    h3 {
        font-weight: 500;
    }
`;
