import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints, standardTransition } from 'config/layout/layout';

const Button = styled.button<{ margin?: string }>`
    border: none;
    font-size: 19px;
    line-height: 29px;
    font-weight: 500;
    padding: 10px 13px;
    background: none;
    cursor: pointer;
    margin: ${({ margin }) => margin || '0 0 0 -13px'};
    ${standardTransition}

    &:hover {
        color: ${globalColors.backgrounds.green.light};
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
    }
`;

export default { Button };
