import React from 'react';

import {
    PaymentsSectionJpg,
    PaymentsElement1Png,
    PaymentsElement2Png,
    PaymentsElement3Png,
    PaymentsSectionMobileJpg,
} from 'assets/images';
import { breakPoints } from 'config/layout/layout';

import Styled from './PaymentsAnimation.styled';


export const PaymentsAnimation: React.FC = () => (
    <Styled.Wrapper>
        <picture>
            <source srcSet={PaymentsSectionMobileJpg} media={`(max-width: ${breakPoints.mobile}px)`} />

            <img src={PaymentsSectionJpg} alt="Our webpage on smartphone" />
        </picture>

        <img className="element element-1" src={PaymentsElement1Png} alt="Our webpage on smartphone" />

        <img className="element element-2" src={PaymentsElement2Png} alt="Our webpage on smartphone" />

        <img className="element element-3" src={PaymentsElement3Png} alt="Our webpage on smartphone" />
    </Styled.Wrapper>
);
