import styled from 'styled-components';
import { breakPoints, standardTransition } from 'config/layout/layout';
import { globalColors } from 'assets/globalColors';

const Title = styled.h3`
    font-size: 32px;
    letter-spacing: 0px;
    line-height: 46px;
    font-weight: 500;
    margin: 0 auto 37px;
    max-width: 678px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 22px;
        line-height: 30px;
        max-width: 467px;
        margin: 0 auto 22px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        margin: 0 auto 26px;
    }
`;

const Text = styled.div`
    font-size: 19px;
    font-weight: 300;
    line-height: 27px;
    margin: 0 auto 35px;
    max-width: 700px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 24px;
        margin: 0 auto 26px;
        max-width: 467px;
    }
`;

const ContentWrapper = styled.div`
    text-align: center;
    padding: 70px 0 10px;

    .pricing-button-wrapper {

        button {
            font-size: 16px;
            position: relative;
            z-index: 2;
            padding-top: 30px;
            min-width: 300px;
            padding-bottom: 30px;
        }
    }
`;

const GreenLightWrapper = styled.div`
    position: relative;
`;

const CalculatorWrapper = styled.div`
    max-width: 1045px;
    padding: 49px 55px 45px;
    background-color: white;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    box-shadow: 0px 7px 36px #0000001D;
    z-index: 1;
    position: relative;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        max-width: 100%;
        padding: 37px 40px 33px;
        font-size: 15px;
        line-height: 22px;
    }

    @media screen and (max-width: 850px) {
        padding: 37px 20px 33px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 33px 15px 38px;
    }
`;

const GreenLight = styled.div`
    position: absolute;
    width: 1922px;
    height: 851px;
    background: transparent radial-gradient(closest-side at 50% 50%, #C2FF94F8 0%, #C2FF9400 100%) 0% 0% no-repeat padding-box;
    opacity: 0.4;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
`;

const ButtonWrapper = styled.div`
    padding-bottom: 25px;
`;

const CalculatorContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 183px 1fr;
    text-align: left;
    padding-bottom: 40px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr 133px 1fr;
        padding-bottom: 28px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
    }

    span {
        font-weight: 500;
    }
`;

const InputWrapper = styled.div`
    border: 1px solid #DCDCDC;
    padding: 16px 20px 18px;
    margin: 9px 0 13px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    ${standardTransition};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        margin: 10px 0 7px;
        padding: 17px 15px;
    }

    :hover {
        border-color: ${globalColors.backgrounds.green.base};
    }
    
    input {
        border: none;
        font-size: 25px;
        font-weight: 500;
        outline: none;
        -moz-appearance: textfield;
        width: 100%;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            font-size: 18px;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const DropdownWrapper = styled.div`
    position: relative;

    .dropdown-label {
        font-size: 16px;
        font-weight: 300;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            font-size: 15px;
        }
    }

    .dropdown-wrapper {
        display: flex;
        padding-right: 20px;
        flex-direction: row-reverse;
    }

    .dropdown-icon {
        width: 30px;
        height: 30px;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            width: 22px;
            height: 22px;
        }
    }
`;

const TransferInfoWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 13px;
    padding-top: 37px;

    @media screen and (max-width: ${breakPoints.mobile}px) {
        flex-direction: row;
        padding-top: 22px;
        padding-bottom: 18px;
    }

    @media screen and (max-width: 400px) {
        display: grid;
        grid-template-columns: minmax(80px, 30vw) 1fr;
        gap: 10px;
    }

    svg {
        max-width: 100%;
    }
`;

const TransferInfo = styled.div`
    color: #43B50A;
    font-size: 14px;
    font-weight: 400;

    > div {
        width: max-content;
    }
`;

export default {
    Text,
    Title,
    GreenLight,
    InputWrapper,
    TransferInfo,
    ButtonWrapper,
    ContentWrapper,
    DropdownWrapper,
    GreenLightWrapper,
    CalculatorWrapper,
    CalculatorContent,
    TransferInfoWrapper,
};
