import React, { useRef } from 'react';
import { SystemPng } from 'assets/images';
import useOnScreen from 'hooks/useIsVisible';

import { ID } from 'config/layout/layout.types';
import { TabList } from 'components/atoms/TabList/TabList';
import { ListElement } from 'components/atoms/TabList/TabList.types';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './FaqPersonal.styled';
import { FaqPersonalProps } from './FaqPersonal.types';

export const FaqPersonal: React.FC<FaqPersonalProps> = ({ t }) => {
    const faqList: ListElement[] = t('list', { returnObjects: true });

    const underLineRef = useRef();

    const isUnderLineVisible = useOnScreen(underLineRef);

    return (
        <SectionWrapper id={ID.FAQ} additionalStyles="overflow: hidden; background-color: #F0F2EF;">
            <Styled.ContentWrapper>
                <div>
                    <Styled.Title>{t('title.label')}</Styled.Title>
                    <Styled.TitleBolded>
                        <Underline ref={underLineRef} isVisible={isUnderLineVisible}>
                            {t('title.labelBolded')}
                        </Underline>
                    </Styled.TitleBolded>
                    <TabList t={t} listData={faqList} />
                    <div>
                        <a href="https://help.okeo.com/" target="_blank" rel="noreferrer"><ChevronButton label={t('buttons.check.label')} /></a>
                    </div>
                </div>

                <Styled.RelativeWrapper>
                    <Styled.ContentAbsoluteImage src={SystemPng} alt={t('backgroundAlt.label')} />
                </Styled.RelativeWrapper>
            </Styled.ContentWrapper>
        </SectionWrapper>
    );
};
