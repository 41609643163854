/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';

import { emailRegexPattern } from 'components/atoms/Input/Input.helpers';

import Styled from './Input.styled';
import { InputProps } from './Input.types';


const Input: React.FC<InputProps> = ({
    name,
    type,
    label,
    value,
    onChange,
    autoFocus,
}) => {

    const [isFocused, setisFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value || '');
    const [isValid, setIsValid] = useState(true);

    const handleFocus = () => {
        setisFocused(true);
    };

    const validateEmail = () => {
        setIsValid(emailRegexPattern.test(inputValue));

        if (emailRegexPattern.test(inputValue) === false) {
            setInputValue('');
            onChange('');
        }
    };

    const handleBlur = () => {
        setisFocused(false);

        if (type === 'email') {
            validateEmail();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        onChange(event.target.value);
    };

    return (
        <Styled.InputWrapper isValid={isValid}>
            <input
                type={type || 'text'}
                name={name}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleInputChange}
                value={inputValue}
                autoFocus={autoFocus}
            />

            <span className={`${isFocused || inputValue ? 'active' : ''}`}>
                {label}
            </span>
        </Styled.InputWrapper>
    );
};

export default Input;
