import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import useOnScreen from 'hooks/useIsVisible';
import useWindowSize from 'hooks/useWindowSize';
import { Device, ID } from 'config/layout/layout.types';
import { stringToAttributeString } from 'utils/string-tools';
import { breakPoints, getDeviceByWidth } from 'config/layout/layout';
import { TrustSectionJpg, TrustSectionMobileJpg, TrustSectionTabletJpg } from 'assets/images';

import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { pricingMobileCarouselOptions } from 'pages/Pricing/PricingTabs/PricingTabs.helpers';
import { TranslationWithMarkup } from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup.layout';

import {
    TrustLi,
    TrustText,
    TrustTitle,
    TrustSubtitle,
    LeftTrustContent,
    RightTrustContent,
    TrustContentWrapper,
    TrustCarrouselWrapper,
} from './Trust.styled';
import { TrustListElement, TrustProps } from './Trust.types';


export const Trust: React.FC<TrustProps> = ({ t }) => {

    const { width } = useWindowSize();
    const device = getDeviceByWidth(width);
    const isDesktop = device === Device.Desktop;

    const trustList: TrustListElement[] = t('list', { returnObjects: true });

    const ref1 = useRef<HTMLLIElement>(null);
    const ref2 = useRef<HTMLLIElement>(null);
    const ref3 = useRef<HTMLLIElement>(null);
    const isVisible1 = useOnScreen(ref1);
    const isVisible2 = useOnScreen(ref2);
    const isVisible3 = useOnScreen(ref3);

    const renderSlides = Object.values(trustList).map((listElement) => (
        <SplideSlide key={`personal-trust-info-${stringToAttributeString(listElement.title)}`}>
            <div className="title">{listElement.title}</div>
            <div className="text">
                <TranslationWithMarkup translationKey={listElement.text} />
            </div>
        </SplideSlide>
    ));


    const imageContent = (
        <RightTrustContent>
            <picture>
                <source srcSet={TrustSectionTabletJpg} media={`(max-width: ${breakPoints.tablet}px)`} />
                <source srcSet={TrustSectionMobileJpg} media={`(max-width: ${breakPoints.mobile}px)`} />
                <img src={TrustSectionJpg} alt="Woman holding tablet and glasses" />
            </picture>
        </RightTrustContent>
    );

    return (
        <SectionWrapper id={ID.Trust}>

            <TrustContentWrapper>
                <LeftTrustContent>
                    <TrustTitle>
                        <div style={{ display: 'inline' }}>
                            <Trans t={t} i18nKey="title.label" />
                        </div>

                        <Underline isVisible>
                            {t('title.underlinedLabel')}
                        </Underline>
                    </TrustTitle>
                    <TrustSubtitle>
                        {t('subtitle')}
                    </TrustSubtitle>

                    {!isDesktop && (
                        <>
                            {imageContent}

                            <TrustCarrouselWrapper>
                                <Splide
                                    options={pricingMobileCarouselOptions}
                                    className="trust-section-carousel"
                                >
                                    {renderSlides}
                                </Splide>
                            </TrustCarrouselWrapper>
                        </>
                    )}

                    {isDesktop && (
                        <TrustText>
                            <ul>
                                <TrustLi key={`personal-trust-info-${stringToAttributeString(Object.values(trustList)[0].title)}`} ref={ref1} isVisible={isVisible1}>
                                    <div className="title">{Object.values(trustList)[0].title}</div>
                                    <div className="text">
                                        <TranslationWithMarkup translationKey={Object.values(trustList)[0].text} />
                                    </div>
                                </TrustLi>

                                <TrustLi key={`personal-trust-info-${stringToAttributeString(Object.values(trustList)[1].title)}`} ref={ref2} isVisible={isVisible2}>
                                    <div className="title">{Object.values(trustList)[1].title}</div>
                                    <div className="text">
                                        <TranslationWithMarkup translationKey={Object.values(trustList)[1].text} />
                                    </div>
                                </TrustLi>

                                <TrustLi key={`personal-trust-info-${stringToAttributeString(Object.values(trustList)[2].title)}`} ref={ref3} isVisible={isVisible3}>
                                    <div className="title">{Object.values(trustList)[2].title}</div>
                                    <div className="text">
                                        <TranslationWithMarkup translationKey={Object.values(trustList)[2].text} />
                                    </div>
                                </TrustLi>
                            </ul>
                        </TrustText>
                    )}
                </LeftTrustContent>

                {isDesktop && imageContent}

            </TrustContentWrapper>
        </SectionWrapper>
    );
};
