import React from 'react';

import { AdditionalStyles } from '../SectionWrapper/SectionWrapper.types';

export interface ButtonProps {
    label?: string;
    type?: 'button' | 'submit' | 'reset';
    children?: React.ReactNode;
    onClick?: () => void;
    buttonTheme?: ButtonTheme;
    disabled?: boolean;
    margin?: string;
    additionalStyles?: AdditionalStyles;
}

export enum ButtonTheme {
    GREEN = 'GREEN',
    YELLOW = 'YELLOW',
    ORANGE = 'ORANGE',
    OUTLINE_ORANGE = 'OUTLINE_ORANGE',
}
