import styled from 'styled-components';

import { breakPoints, LEFT_WHY_OKEO_CONTENT_Z_INDEX } from 'config/layout/layout';

export const WhyOkeoTitle = styled.div`
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 68px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 28px;
        margin-bottom: 34px;
    }
`;

export const whyOkeoTrackLength = 3500;

export const ScrollTrack = styled.div`
    // height: ${whyOkeoTrackLength}px;
`;

export const WhyOkeoContentWrapper = styled.div<{ imageHeight: number }>`
    top: 150px;
    padding: 110px 0;
    display: grid;
    grid-template-columns: 45% 1fr;

    @media screen and (max-width: 1680px) {
        top: 115px;
    }

    @media screen and (max-width: 1300px) {
        top: 100px;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr 1fr;
        padding-top: 54px;
        top: 16vw;
        padding-bottom: 50px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        padding: 35px 0 55px;
        top: -${({ imageHeight }) => ((imageHeight - 150) || 200)}px;
    }
`;

export const LeftWhyOkeoContent = styled.div`
    position: relative;
    height: 100%;
    padding-top: 50px;

    & > img.team {
        z-index: ${LEFT_WHY_OKEO_CONTENT_Z_INDEX};
        width: 145%;
        position: absolute;
        left: -35%;
        top: -20%;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            width: 140%;
            left: -33%;
            top: -10%;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            position: static;
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
            top: 0;
        }

        @media screen and (max-width: 580px) {
            position: absolute;
            height: 100%;
            width: auto;
            left: 50%;
            transform: translate(calc(-50%), 0);
        }

        @media screen and (max-width: 380px) {
            height: auto;
            width: 440px;
        }
    }

    & > svg.okeo-account {
        position: absolute;
        top: 0;
        width: 100%;
        filter: drop-shadow(-5px 5px 4px rgb(0 0 0 / 0.4));

        @media screen and (max-width: ${breakPoints.mobile}px) {
            width: clamp(300px, 80vw, 500px);
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        height: unset;
        overflow-x: hidden;
    }

    @media screen and (max-width: 580px) {
        height: 400px;
    }
`;
export const RightWhyOkeoContent = styled.div`

    @media screen and (max-width: ${breakPoints.mobile}px) {
        max-width: 450px;
        margin: 0 auto;
    }
`;
