import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "config/environment";
import { deleteCookieByNameAndDomain } from "../Cookies.helpers";
import {
    FACEBOOK_PIXEL_COOKIE_NAME,
    GOOGLE_ANALYTICS_COOKIE_NAME,
    GOOGLE_TAG_MANAGER_COOKIE_NAME,
} from "../Cookies.types";

const getGoogleAnalyticsCookies = () => {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());

    const googleAnalyticsRelatedCookies = [
        GOOGLE_ANALYTICS_COOKIE_NAME,
        FACEBOOK_PIXEL_COOKIE_NAME,
        GOOGLE_TAG_MANAGER_COOKIE_NAME,
    ];
    const regex = new RegExp(
        `(${googleAnalyticsRelatedCookies.join("|")})`,
        "i"
    );

    const googleAnalyticsCookies = cookies.filter((cookie) => regex.test(cookie));

    return googleAnalyticsCookies.map((cookie) => cookie.slice(0, cookie.indexOf("=")).trim());
};

export const disableGoogleAnalytics = (disable: boolean) => {
    (window as unknown as { [key: string]: unknown })[
        `ga-disable-${GOOGLE_ANALYTICS_MEASUREMENT_ID}`
    ] = disable;
};

export const removeGoogleAnalyticsCookies = () => {
    const googleAnalyticsCookies = getGoogleAnalyticsCookies();

    const secondLevelDomain = window.location.hostname
        .split(".")
        .slice(-2)
        .join(".");

    if (googleAnalyticsCookies.length > 0) {
        googleAnalyticsCookies.forEach((cookieName) => {
            deleteCookieByNameAndDomain(cookieName, secondLevelDomain);
        });
    }
};
