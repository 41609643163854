import React from 'react';
import useWindowSize from 'hooks/useWindowSize';
import { pricingCarouselOptions, pricingMobileCarouselOptions } from 'pages/Pricing/PricingTabs/PricingTabs.helpers';

import { getDeviceByWidth } from 'config/layout/layout';
import { Device } from 'config/layout/layout.types';

import { ContactTab } from './ContactTab/ContactTab';
import { ContactTabsCaroussel } from './ContactTabsCaroussel/ContactTabsCaroussel';
import { ContactTabsCarrouselWrapper, ContactTabsWrapper } from './ContactTabs.styled';
import { ContactTabsProps } from './ContactTabs.types';

const ContactTabs: React.FC<ContactTabsProps> = ({ t }) => {

    const { width } = useWindowSize();

    const device = getDeviceByWidth(width);

    const contactTiles: [] = t('tabs', { returnObjects: true });

    let pricingTabsContent;

    switch (device) {
        case Device.Mobile:
            pricingTabsContent = (
                <ContactTabsCarrouselWrapper>
                    <ContactTabsCaroussel
                        options={pricingMobileCarouselOptions}
                        contactTiles={contactTiles}
                    />
                </ContactTabsCarrouselWrapper>
            );
            break;

        case Device.Tablet:
            pricingTabsContent = (
                <ContactTabsCarrouselWrapper>
                    <ContactTabsCaroussel
                        options={pricingCarouselOptions}
                        contactTiles={contactTiles}
                    />
                </ContactTabsCarrouselWrapper>
            );

            break;

        default:
            pricingTabsContent = (
                <ContactTabsWrapper>
                    {contactTiles.map((tile, index) => <ContactTab tile={tile} index={index} />)}
                </ContactTabsWrapper>
            );
    }

    return pricingTabsContent;
};

export default ContactTabs;
