import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints, FAQ_IMAGE_Z_INDEX } from 'config/layout/layout';

const desktopBreakPoint = 1400;

export const FaqTitle = styled.div`
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 50px;
    color: #000000;
    font-weight: 400;
    text-transform: uppercase;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        line-height: 28px;
    }
`;

export const FaqTitleBolded = styled.div`
    font-size: 38px;
    letter-spacing: 0px;
    line-height: 50px;
    color: #000000;
    font-weight: 600;
    text-transform: uppercase;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 28px;
    }
`;

export const FaqContentWrapper = styled.div`
    color: ${globalColors.fontColors.black};
    padding: 90px 0 110px;
    display: grid;
    grid-template-columns: 640px 1fr;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr;
    }
`;

export const FaqImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FaqImage = styled.img`
    width: 553px;
    height: 529px;

    @media screen and (max-width: ${desktopBreakPoint}px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr;
        padding: 65px 0 50px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 50px 0 50px;
    }
`;

export const FaqContentAbsoluteImage = styled.img`
    position: absolute;
    height: 550px;
    top: 120px;
    left: 50px;
    z-index: ${FAQ_IMAGE_Z_INDEX};

    @media screen and (max-width: ${desktopBreakPoint}px) {
        height: 450px;
        left: 35px;
    }
`;

export const FaqRelativeWrapper = styled.div`
    position: relative;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        display: none;
    }
`;
