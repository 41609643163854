import styled, { css } from 'styled-components';

import { breakPoints } from 'config/layout/layout';

const activeStyles = css`
    transform: translateY(0);
    opacity: 1;

    @media (max-width: ${breakPoints.tablet}px) {
        transform: translateY(0);
    }
`;

const hidedStyles = css`
    transform: translateY(107px);
    opacity: 0;
    z-index: 0;

    @media (max-width: ${breakPoints.tablet}px) {
        transform: translateY(68px);
        opacity: 0;
    }
`;

export const FadeInWrapperContainer = styled.div<{ active: boolean; zIndex?: number; hided?: boolean; }>`
    opacity: 0;
    transform: translateY(-107px);
    transition: 0.6s ease;
    z-index: ${({ zIndex }) => zIndex && zIndex};
    position: relative;

    @media (max-width: ${breakPoints.tablet}px) {
        transform: translateY(-68px);
    }

    ${({ active }) => active && activeStyles};
    ${({ hided }) => hided && hidedStyles};
`;
