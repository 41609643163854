import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { standardBorder, standardTransition } from 'config/layout/layout';

const DropdownLabel = styled.div<{ active: boolean }>`
    position: absolute;
    left: 10px;
    z-index: 4;
    display: block;
    width: max-content;
    line-height: 100%;
    padding: 0px 12px 0px 10px;
    user-select: none;
    white-space: nowrap;
    cursor: text;
    pointer-events: none;
    color: rgb(127, 127, 127);
    transition: all 0.2s ease 0s;
    font-size: 14px;
    top: 20px;
    background-color: rgba(255, 255, 255, 0);

    ${({ active }) => active && `
        top: -6px;
        font-size: 11px;
        background-color: rgba(255, 255, 255, 1);
    `}
`;

const DropdownContainer = styled.div`
    position: relative;
    ${standardBorder}

    input {
        border: none !important;
        min-width: auto !important;
    }

    &:hover {
        border: 1px solid #6dde36;

        ${DropdownLabel} {
            color: #6dde36;
        }
    }
`;

const DropdownOptionIcon = styled.div`
    width: 26px;
    height: 26px;

    img {
        width: 100%;
        height: auto;
    }
`;

const DropdownOptionContainer = styled.div<{ displayLabel?: boolean; }>`
    display: grid;
    grid-template-columns: 1fr auto;
    cursor: pointer;
    gap: 7.5px;
    align-items: center;
    min-height: 55px;

    ${({ displayLabel }) => displayLabel && 'grid-template-columns: 1fr auto auto;'}
`;

const DropdownOptionWrapper = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #6dde36;
    cursor: pointer;
    ${standardTransition}

    &:hover {
        background: linear-gradient(
            169deg,
            #6dde36 0%,
            rgba(73, 165, 30, 0.73) 100%
        );
    }

    &:last-child {
        border: none;
    }
`;

const SelectedOptonWrapper = styled(DropdownOptionWrapper)`
    border: none;

    &:hover {
        background: none;
    }
`;

const DropdownOptions = styled.div`
    position: absolute;
    background: ${globalColors.backgrounds.white};
    display: grid;
    left: -1px;
    right: 1px;
    width: calc(100% + 2px);
    top: 100%;
    box-shadow: #0000000a 1px 1px 8px;
    max-height: 220px;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid #6dde36;
    z-index: 7;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: #d1d1d1;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    scrollbar-width: thin;
    scrollbar-color: #d1d1d1 transparent;
`;

const DropdownOptionText = styled.div`

`;

const SearchInput = styled.input`
    outline: none;
    padding-left: 23px;
    border: none;
`;

export {
    SearchInput,
    DropdownLabel,
    DropdownContainer,
    DropdownOptionIcon,
    DropdownOptionContainer,
    DropdownOptionWrapper,
    DropdownOptions,
    DropdownOptionText,
    SelectedOptonWrapper,
};
