import React from 'react';

import { RoundedCountryFlagProps, roundedFlagsPaths } from './RoundedCountryFlag.types';

const RoundedCountryFlag: React.FC<RoundedCountryFlagProps> = ({
    name,
    size = 20,
}) => (
    <img
    // TODO: Handle translations
        alt={`Flag of ${name}`}
        src={roundedFlagsPaths[name]}
        width={`${size}px`}
        height={`${size}px`}
    />
);

export default RoundedCountryFlag;
