import { Options } from "@splidejs/splide";

import {
    BenefitsSectionSlide1,
    BenefitsSectionSlide2,
    BenefitsSectionSlide3,
    BenefitsSectionSlide4,
    BenefitsSectionSlide5
} from "assets/images";
import { pricingMobileCarouselOptions } from "pages/Pricing/PricingTabs/PricingTabs.helpers";


export const benefitsCarouselOptions: Options = {
    ...pricingMobileCarouselOptions,
    perMove: 1,
    autoplay: true,
    interval: 5000,
};

export const benefitsCarouselImages = [
    BenefitsSectionSlide1,
    BenefitsSectionSlide2,
    BenefitsSectionSlide3,
    BenefitsSectionSlide4,
    BenefitsSectionSlide5,
];
