import {
    Environment,
    KubernetesRuntimeEnvVariable,
    MappingObject,
    ReactAppBuildEnvVariable,
    VersionDetails,
} from './environment.types';

export const getEnvironment = (env: string | undefined): Environment => {
    switch (env) {
        case 'development':
            return Environment.DEVELOPMENT;
        case 'production':
            return Environment.PRODUCTION;
        default:
            throw new Error(`Unknown environment: ${env}`);
    }
};

export const getVersionDetails = (env: NodeJS.ProcessEnv): VersionDetails => {
    const ciJobStartedAt = env[ReactAppBuildEnvVariable.CI_JOB_STARTED_AT] || 'unknown';
    const ciCommitTimestamp = env[ReactAppBuildEnvVariable.CI_COMMIT_TIMESTAMP] || 'unknown';
    const ciCommitShortSha = env[ReactAppBuildEnvVariable.CI_COMMIT_SHORT_SHA] || 'unknown';

    return { ciJobStartedAt, ciCommitTimestamp, ciCommitShortSha };
};


export enum ApiType {
    FX_RATES = `api/fxrate/v1/fxRate`,
    EMAIL = `api/email/v1`,
}

export const getKubernetesRuntimeVariable = (
    variable: KubernetesRuntimeEnvVariable
): string => {
    if (
        variable.includes('__ENV__')
        && !window.location.href.includes('localhost')
    ) {
        throw new Error(`Missing environment variable: ${variable}`);
    } else if (window.location.href.includes('localhost')) {
        const variableWithoutPrefix = variable.replace('__ENV__', 'REACT_APP_');

        const localVariable = process.env[variableWithoutPrefix];

        if (!localVariable) {
            throw new Error(`Missing local environment variable: ${variableWithoutPrefix}`);
        }

        return localVariable;
    }

    return variable;
};

export const setUrl = (mapingObject: MappingObject) => {
    const currentHostname = window.location.hostname;

    if (Object.prototype.hasOwnProperty.call(mapingObject, currentHostname)) {
        return mapingObject[currentHostname];
    }

    throw new Error(`Couldn't find matching API URL for: ${currentHostname}`);
};

export const parseHelmMapToJson = (mappingString: string | undefined): Record<string, string> => {

    if (!mappingString) {
        throw new Error('Helm map is not defined!');
    }

    const cleanedString = mappingString.replace(/map|\[|\]/g, '');

    const array = cleanedString.split(' ');

    const result: Record<string, string> = {};

    array.forEach((item) => {
        const keyValuePairs = item.match(/^(\S+):(\S+:\/\/\S+)/);

        if (keyValuePairs && keyValuePairs.length > 0) {
            result[keyValuePairs[1].trim()] = keyValuePairs[2].trim();
        }
    });

    return result;
};
