import { globalColors } from 'assets/globalColors';
import styled, { css } from 'styled-components';

import { additionalDesktopWelcomeSectionBreakpoint2 } from 'components/atoms/WelcomeGirl/WelcomeGirl.styled';
import { breakPoints, LEFT_WELCOME_CONTENT_Z_INDEX } from 'config/layout/layout';

const additionalMobileWelcomeSectionBreakpoint1 = 450;
const additionalMobileWelcomeSectionBreakpoint2 = 370;

export const drawUnderlineAnimation = () => css`
    @keyframes drawUnderline {
        from {
            width: 0%;
        }
        to {
            width: 100%;
        }
    }

    animation-name: drawUnderline;
    animation-duration: 0.75s;
    animation-delay: 0.5s;
    animation-timing-function: cubic-bezier(0.47, 0, 0.54, 0.97);
    animation-fill-mode: forwards;
    left: 0;
`;

export const WelcomeTitle = styled.div`
    font-size: 44px;
    letter-spacing: 0px;
    line-height: 64px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 27px;
        letter-spacing: 0px;
        line-height: 35px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        font-size: 28px;
    }

    @media screen and (max-width: ${additionalMobileWelcomeSectionBreakpoint2}px) {
        font-size: 7.3vw;
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Underline = styled.div<{ isVisible: boolean; ref?: any; additionalStyles?: string }>`
    display: inline-block;
    position: relative;
    padding-bottom: 5px;

    ${({ additionalStyles }) => additionalStyles && additionalStyles};

    &:after {
        content: '';
        border-bottom: 4px ${globalColors.backgrounds.yellow.light} solid;
        bottom: 0;
        display: block;
        position: absolute;
        ${({ isVisible }) => isVisible && drawUnderlineAnimation()}
    }
`;

export const WelcomeText = styled.div`
    font-size: 18px;
    line-height: 29px;
    padding: 35px 0;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;
    }
`;

export const WelcomeContentWrapper = styled.div`
    padding-top: 65px;
    display: grid;
    grid-template-columns: 600px 1fr;

    @media screen and (max-width: ${additionalDesktopWelcomeSectionBreakpoint2}px) {
        grid-template-columns: 555px 1fr;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 375px 1fr;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        gap: 50px;
    }
`;

export const WelcomeButtonsWrapper = styled.div`
    display: flex;
    gap: 30px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        gap: 25px;
    }

    @media screen and (max-width: ${additionalMobileWelcomeSectionBreakpoint1}px) {
        flex-direction: column;
        gap: 20px;
    }
`;

export const LeftWelcomeContent = styled.div`
    position: relative;
    z-index: ${LEFT_WELCOME_CONTENT_Z_INDEX};

    @media screen and (max-width: ${breakPoints.mobile}px) {
        max-width: 450px;
        margin: 0 auto;
    }
`;
export const RightWelcomeContent = styled.div`
    position: relative;

    img {
        position: absolute;
        width: 160%;
        top: -45px;
        left: 5%;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            min-width: 550px;
        }

        @media screen and (max-width: 950px) {
            top: 0;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            top: -15px;
            height: 550px;
            width: auto;
            left: 11%;
        }

        @media screen and (max-width: ${additionalMobileWelcomeSectionBreakpoint1}px) {
            left: 0;
        }

        @media screen and (max-width: 380px) {
            left: -25px;
            height: 480px;
            top: -45px;
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-row-start: 1;
        height: 320px;
    }
`;

export const Gradient = styled.div`
    background: radial-gradient(circle closest-side at 70% 50%, #c2ff948a 0%, white 100%);
`;
