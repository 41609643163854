import { changeLanguage } from "i18next";
import { setLanguagePreference } from "utils/languagePreference";

const changeLanguagePreference = (languagePreference: string) => {
    changeLanguage(languagePreference);
    setLanguagePreference(languagePreference);
};


export { changeLanguagePreference };
