import React from 'react';

import { useCookiesPreferences } from 'store/providers/CookiesPreferencesProvider/CookiesPreferencesProvider';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER_ID } from 'config/environment';
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics';
import Hotjar from './Hotjar/Hotjar';
import LinkedInInsight from './LinkedInInsight/LinkedInInsight';
import { COOKIES_PREFERENCES_COOKIE_NAME, CookiesProps } from './Cookies.types';
import { LanguagePreferences } from './LanguagePreferences/LanguagePreferences.layout';

export const Cookies: React.FC<CookiesProps> = ({ allCookies }) => {
    const { cookiesPreferences } = useCookiesPreferences();

    let content: JSX.Element[] = [];

    const buildContent = (preferences: boolean, statistics: boolean, marketing: boolean) => {
        const result = [];

        if (!window.location.href.includes('localhost') && GOOGLE_TAG_MANAGER_ID) {
            TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
        } else {
            // eslint-disable-next-line no-console
            console.warn(
                'Google Tag Manager is not initialized for localhost'
            );
        }

        if (preferences) {
            result.push(<LanguagePreferences />);
        }

        if (statistics) {
            result.push(<GoogleAnalytics />);
            result.push(<LinkedInInsight />);
            result.push(<Hotjar />);
        }

        if (marketing) {
            result.push();
        }

        return result;
    };

    if (allCookies && allCookies[COOKIES_PREFERENCES_COOKIE_NAME]) {
        const { preferences, statistics, marketing } = allCookies[COOKIES_PREFERENCES_COOKIE_NAME] || {};

        content = buildContent(preferences, statistics, marketing);

    } else if (cookiesPreferences) {
        const { preferences, statistics, marketing } = cookiesPreferences || {};

        content = buildContent(preferences, statistics, marketing);
    }

    return (
        <>
            {content.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <React.Fragment key={index}>{item}</React.Fragment>
            ))}
        </>
    );
};
