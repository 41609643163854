import styled from "styled-components";

import { errorColor, standardBorder } from "config/layout/layout";


const InputWrapper = styled.div<{ isValid?: boolean }>`
    position: relative;

    input {
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        outline: none;
        ${standardBorder}
        min-height: 55px;
        padding: 8px 10px;
        padding-left: 23px;
        min-width: 250px;
        font-size: 14px;
    }

    span {
        position: absolute;
        top: 20px;
        left: 10px;
        z-index: 4;
        display: block;
        width: max-content;
        font-size: 14px;
        line-height: 100%;
        padding: 0px 12px 0px 10px;
        user-select: none;
        white-space: nowrap;
        cursor: text;
        pointer-events: none;
        background-color: rgba(255, 255, 255, 0);
        color: rgb(127, 127, 127);
        transition: all 0.2s ease 0s;

        &.active {
            font-size: 11px;
            top: -6px;
            background-color: rgba(255, 255, 255, 1);
        }
    }

     &:hover {
        input {
            border-color: #6dde36;
        }

        span {
            color: #6dde36;
        }
    }

    ${({ isValid }) => !isValid && `
        input {
            border-color: ${errorColor};
        }

        span {
            color: ${errorColor};
        }

        &:hover {
            input {
                border-color: ${errorColor};
            }

            span {
                color: ${errorColor};
            }
        }
    `}

`;

export default { InputWrapper };
