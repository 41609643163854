/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { PrivacyPolicyPDF } from 'assets/files';
import { FilesIcon } from 'assets/icons/FilesIcon';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';
import { PrivacyPolicyWrapper } from 'pages/Legal/PrivacyPolicy/PrivacyPolicy.styled';

import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Seo } from 'utils/Seo';

export const PrivacyPolicy: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Privacy Policy</LegalTitle>

            <div>
                <Tooltip name="tnc-history-btn" label="Go to previous version">
                    <RoundUrlButton
                        external
                        link="https://help.okeo.com/hc/en-us/articles/7402054701713-Privacy-Policy-April-2022-archive-"
                    >
                        <FilesIcon />
                    </RoundUrlButton>
                </Tooltip>

                <Tooltip name="tnc-download-btn" label="Download Privacy Policy">
                    <RoundUrlButton
                        primary
                        external
                        link={PrivacyPolicyPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>Last update: June 2023</LastUpdate>

        <LegalContent>
            <PrivacyPolicyWrapper>
                <h2>Purpose and scope</h2>
                <strong>“Okeo Payments, UAB”</strong>
                {' '}
                (also referred to as
                {' '}
                <strong>we, us, Okeo Payments, Company</strong>
                ) is an electronic money institution licensed by the Bank of Lithuania (
                <a href="https://www.lb.lt/en/enforcement-measures-1/view_license?id=1967" target="_blank" rel="noopener noreferrer">license no. 71</a>
                ), incorporated and existing in Vilnius, Lithuania, company registration number 305219549, having its registered office at Gedimino pr. 20, LT-01103, Vilnius.
                <br />
                <br />

                Okeo Payments is committed to protect the privacy of your personal data in accordance with the applicable laws, including the General Data Protection Regulation (2016/679) (hereinafter – GDPR), the
                Law on Money Laundering and Terrorist Financing Prevention of the Republic of Lithuania, Law on Legal protection of personal data of the Republic of Lithuania and other applicable legal acts referred to in this Policy as the data protection law. We value your privacy and we only collect and use your personal data in accordance with this Policy and binding provisions of the law.
                <br />
                <br />
                In this Privacy Policy we provide you with explanation on what kind of personal data we collect when providing you with our e-money services (issuance, distribution and redemption of electronic money), payment services, currency conversion services and other ancillary services (further jointly referred to as “Services”). We render the Services via our internet banking platform My OKEO (
                <a href="https://my.okeo.com/" target="_blank" rel="noopener noreferrer">https://my.okeo.com</a>
                ), our online onboarding application Join OKEO (
                <a href="https://join.okeo.com" target="_blank" rel="noopener noreferrer">https://join.okeo.com</a>
                ), our web page at
                {' '}
                <a href="https://okeo.com/" target="_blank" rel="noopener noreferrer">www.okeo.com</a>
                , our developer portal (for Open Banking) at
                {' '}
                <a href="https://developer.okeo.com/" target="_blank" rel="noopener noreferrer">https://developer.okeo.com/</a>
                {' '}
                and through communication channels (e.g. email and chat while providing the Client support). In addition, in this Policy we describe how we use your personal data, with whom it can be shared, how we protect it and other important aspects of data processing when using our Services.
                <br />
                <br />
                When writing
                {' '}
                <strong>"you"</strong>
                , we mean you as – a potential, existing or former client, our client’s employee or other parties, such as beneficial owners, authorised representatives, business partners, other associated parties or person contacting us using e–mail or other communication measures.
                <br />
                <br />
                Please carefully read the following Policy and if you have any questions regarding processing of your data you can contact our Data Protection Officer at
                {' '}
                <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>
                . We keep this Policy under regular review and publish updates on our web page
                {' '}
                <a href="https://okeo.com/" target="_blank" rel="noopener noreferrer">www.okeo.com</a>
                . Please review this Privacy Policy from time to time to stay up to date with the changes.
                <h2>Personal Data Controller</h2>
                Under the data protection law, Okeo Payments is the Data Controller responsible for handling your personal data processed in relation to the Services. In this context the term “personal data” means any information which can be used to personally identify you (e.g. a combination of your name and postal address).
                <br />
                <br />
                As a Data Controller, we are responsible for ensuring security of your personal data made available to us, in particular to prevent unauthorized access to your data. We are also responsible for ensuring all users with the opportunity to benefit their rights regarding their own personal data, like the right to access or erase. When processing personal data, we follow the principles of a) legality, fairness and transparency; b) purpose limitation; c) data minimization; d) accuracy; e) storage limitation ; f) integrity and confidentiality.
                <h2>What information we collect, for what purposes and on what legal basis</h2>
                <h3>Categories of personal data being processed</h3>

                <table className="t1">
                    <thead>
                        <tr>
                            <th>Type of information</th>
                            <th>Personal data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1. Basic personal data</td>
                            <td>
                                <ul>
                                    <li>First, last, middle names</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>2. Data enabling us to adhere to anti-money laundering and counter-terrorist financing regulatory requirements and to ensure compliance with the international sanctions</td>
                            <td>
                                <ul>
                                    <li>National identification number, date of birth, country of birth, address, citizenship, gender</li>
                                    <li>Passport or ID card copy and its details (e.g. type, number, issuance place and date, expiry date, MRZ code, signature)</li>
                                    <li>Visually scanned or photographed image of your face or image that you provide through a mobile or desktop camera while using identity verification solution, video recordings for identification</li>
                                    <li>Information whether you are a politically exposed person</li>
                                </ul>

                                Depending on the client profile, we may additionally collect information on:

                                <ul>
                                    <li>Evidence of beneficial ownership</li>
                                    <li>Your role in the company that you represent</li>
                                    <li>Source of funds (information on origin of funds to be kept in the account or used in the payment transactions)</li>
                                    <li>Source of wealth (information on how wealth was obtained)</li>
                                    <li>Tax information (tax residence, tax identification number)</li>
                                    <li>Details of bank account/-s in another financial institutions</li>
                                    <li>Number of shares held, voting rights or share capital part</li>
                                    <li>Purpose of the business relationship and expected usage of the Services</li>
                                    <li>Description of your business activities, including LinkedIn, e-commerce platform profile</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>3. Data enabling us to adhere to other applicable regulatory requirements</td>
                            <td>
                                <ul>
                                    <li>Data that the Company is required to provide to public authorities, such as state tax inspectorate, courts, including data on payments and personal data held by the Company.</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>4. Monetary operations details</td>
                            <td>
                                <ul>
                                    <li>Currency, amount, description of payment, location, date, time, your IP address</li>
                                    <li>Payer’s and payee's name, account IBAN number, address</li>
                                    <li>Messages and documents sent or received with the payment</li>
                                    <li>Unique number of your payment account in our system</li>
                                </ul>

                            </td>
                        </tr>
                        <tr>
                            <td>5. Details of your activities in My OKEO, Join OKEO and developer portal</td>
                            <td>
                                <ul>
                                    <li>History of the actions performed with reference to your payment account when using the Services (e.g. inviting new user, changing permissions of existing users, changing own authorization limits)</li>
                                    <li>Technical information, including the internet protocol (IP) address used to connect your computer to the internet, your log-in information (e.g. login time), the browser type and version, the time-zone setting, the operating system and platform, the type of device you use, a unique device identifier (for example, the MAC address of the device's wireless network interface)</li>
                                </ul>

                            </td>
                        </tr>
                        <tr>
                            <td>6. Contact details</td>
                            <td>
                                <ul>
                                    <li>Phone number, email, correspondence address</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>7. Communication details</td>
                            <td>
                                <ul>
                                    <li>Content of emails correspondence or any other form of communication with us (i.e., live chat)</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>8. Special category data</td>
                            <td>
                                <ul>
                                    <li>Biometric data while performing online identification</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h3>Purposes and legal basis for personal data processing</h3>

                <table className="t2">
                    <thead>
                        <tr>
                            <th>Purpose</th>
                            <th>Legal basis</th>
                            <th>Categories of personal data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1. To conclude a contract or to carry out pre-contractual measures at your request</td>
                            <td>
                                <ul>
                                    <li>Taking necessary steps prior to entering a contract</li>
                                    <li>Legal obligations</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Data enabling us to adhere to anti-money laundering and counter-terrorist financing regulatory requirements and ensure compliance with international sanctions</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                </ul>

                            </td>
                        </tr>
                        <tr>
                            <td>2. To fulfil the contract</td>
                            <td>
                                <ul>
                                    <li>Contract performance</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Monetary operations details</li>
                                    <li>Details of your activities in My OKEO, Join OKEO, and developer portal</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>3. To prevent, discover, investigate and report potential money laundering, terrorism financing, breach of international sanctions and other related violations, including potentially suspicious transactions and the Services abuse</td>
                            <td>
                                <ul>
                                    <li>Legal obligations</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Data enabling us to adhere to anti-money laundering and counter-terrorist financing regulatory requirements and to ensure compliance with international sanctions</li>
                                    <li>Monetary operations details</li>
                                    <li>Details of your activities in My OKEO, Join OKEO, and developer portal</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                </ul>

                                The scope of processed personal data depends on the client's profile, specific situation and may include all or some of personal data specified above
                            </td>
                        </tr>
                        <tr>
                            <td>4. To comply with other legal requirements under applicable legislation </td>
                            <td>
                                <ul>
                                    <li>Legal obligations</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Data enabling us to adhere to anti-money laundering and counter-terrorist financing regulatory requirements and to ensure compliance with international sanctions</li>
                                    <li>Data enabling us to adhere to other applicable regulatory requirements</li>
                                    <li>Monetary operations details</li>
                                    <li>Details of your activities in My OKEO, Join OKEO, and developer portal</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                </ul>

                            </td>
                        </tr>
                        <tr>
                            <td>5. To identify you remotely </td>
                            <td>
                                <ul>
                                    <li>Your consent</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Special category data (e.g. biometric data).</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>6. To prevent, limit and investigate any misuse or unlawful use or disturbance of the Services or to establish, exercise and defend legal claims</td>
                            <td>
                                <ul>
                                    <li>Legitimate interest</li>
                                    <li>Legal obligations</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Data enables us to adhere to anti-money laundering and counter-terrorist financing regulatory requirements and ensure compliance with international sanctions</li>
                                    <li>Monetary operations details</li>
                                    <li>Details of your activities in My OKEO, Join OKEO, and developer portal</li>
                                    <li>Data obtained and/or created in order to fulfill the requirements of other applicable regulatory requirements</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                </ul>

                            </td>
                        </tr>
                        <tr>
                            <td>7. To ensure adequate provision of the Services, the safety of information within the Services, or our legitimate business interests, such as enabling us to improve and deliver a better Service</td>
                            <td>
                                <ul>
                                    <li>Legitimate interest</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Details of your activities in My OKEO, Join OKEO, and developer portal</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>8. To provide an answer when you contact us through our website or other communication measures</td>
                            <td>
                                <ul>
                                    <li>Your consent</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Basic personal data</li>
                                    <li>Communication details</li>
                                    <li>Contact details</li>
                                    <li>Other personal data you provided</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
                We do not process special category data related to your health, ethnicity, or religious or political beliefs unless required by law or in specific circumstances where, for example, you reveal such data while using the Services (e.g., in payments details).
                <br />
                <br />
                The definitions used above are understood as follows:
                <br />
                <br />
                <strong> Legitimate Interest: </strong>
                {' '}
                Okeo Payments legitimate interests are our business needs in conducting and managing our Services to create better benefits for our clients, increase the quality of our services, and at the same to ensure ours and our clients' interests.
                <br />
                <br />
                <strong> Contract performance: </strong>
                {' '}
                Processing your personal data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
                <br />
                <br />
                <strong> Legal Obligations: </strong>
                {' '}
                Processing your personal data where it is necessary for compliance with a legal or regulatory obligations that we are subject to.
                <br />
                <br />
                <strong> Consent: </strong>
                {' '}
                Your consent shall mean any freely given, specific, informed and unambiguous indication of your wishes by which you, by a statement or by a clear affirmative action, signify your agreement to the processing of personal data relating to you. We can request from you a consent for processing when we do not have another legal basis for processing of your data.
                <br />
                <br />
                If you provide us personal data about other people (such as your spouse or family) or you ask us to share their personal data with third parties, you confirm that you have brought this Policy to their attention beforehand.
                <h2>How we obtain your personal data</h2>
                We collect information that you provide directly to us when you:
                <ul>
                    <li>Fill in our forms;</li>
                    <li>Open a payment account or use any other of the Services;</li>
                    <li>Correspond with us;</li>
                    <li>Speak with a member of our Customer Support  team;</li>
                    <li>Contact us in other ways or for other reasons.</li>
                </ul>
                We may collect personal data from third parties. In particular:
                <ul>
                    <li>We collect personal data from third parties such as public or private registers and databases. This includes information to help us check your identity, if applicable, information about your business, and information relating to your transactions (including the beneficiary of the payment)</li>
                    <li>Occasionally we will use publicly available information about you and, if applicable, your business from publicly available sources (e.g., media, online registers and directories) and websites for purposes related to Client due diligence processes</li>
                    <li>We may collect personal data when it is provided to us by a third party which is connected to you or is dealing with us, for example, business partners, subcontractors, service providers, merchants, etc.</li>
                    <li>We may collect personal data from banks or other financial institutions in case the personal data is received while executing payment operations</li>
                    <li>We may collect personal data from other entities which we collaborate with</li>
                </ul>
                <h2>Our identification tools</h2>
                In order to perform your identity verification, we are using the services provided by “Ondato UAB”. This service provider takes the photo images or video records of your face and your ID document that you provide through a dedicated website using the camera. For more information on “Ondato UAB” please read its Privacy Policy.
                <br />
                <br />
                “Ondato UAB” solution is used for comparing live photographic data or video record of yourself and your ID document, to comply with legal obligations (e.g. implementation of the obligations under the Law on Money Laundering and Terrorist Financing Prevention of the Republic of Lithuania and other fraud and crime prevention purposes) and risk management obligations.
                <br />
                <br />
                The result of the face similarity (match or mismatch) will be retained for as long as it is necessary to carry out verification and for the period required by anti-money laundering laws.
                <br />
                <br />
                Your face similarity check is a process of comparing data acquired at the time of the verification, i.e. this is a one-time user authorization by comparing person's photos to each other. Your facial template is not created, recorded or stored. It is not possible to regenerate the raw data from the retained information.
                <br />
                <br />
                When using the  “Ondato UAB” services, the personal data is used for your identification, since “Ondato UAB” compares the image of the person in the identity document and the person captured in the photo. This process shall allow us to verify your identity more precisely and make the process quicker and easier to execute. If you do not feel comfortable with this identification method you may contact us by email at
                {' '}
                <a href="mailto:hello@okeo.com">hello@okeo.com</a>
                {' '}
                for an alternative way to identify yourself.

                <h2>Direct marketing</h2>
                We may use our existing clients’ email for our similar goods or services marketing, unless you object to the use of your email for this purpose. You are granted with a clear, free of charge and easily realisable possibility to object or withdraw from such use of your contact details.
                <br />
                <br />
                In other cases, we may use your personal data for the purpose of direct marketing, if you give us your prior consent regarding such use of the data. We provide a clear, free-of-charge and easily realisable possibility for you at any time not to give your consent or to withdraw your given consent for sending marketing communication put forward by us.

                <h2>Automated decision making</h2>
                In some cases, we may use automated decision-making which refers to a decision taken solely on the basis of automated processing of your personal data.
                <br />
                <br />
                Automated decision-making refers to the processing using, for example, a software code or an algorithm, which does not require human intervention.
                <br />
                <br />
                We may use forms of automated decision making on processing your personal data for some services and products. You can request a manual review of the accuracy of an automated decision in case you are not satisfied with it.
                <br />
                <br />
                For more information about your rights please see the section
                {' '}
                <strong>Your rights</strong>
                .
                <h2>How we share your personal data</h2>
                We sometimes need to provide your personal information to third parties for a better performance of our Services to you. These third parties (data processors) include:
                <ul>
                    <li>
                        our external IT software providers (e.g.
                        {' '}
                        <a href="https://www.mambu.com/">Mambu GmbH</a>
                        ), in order to provide you with top-class payment solutions;
                    </li>
                    <li>communication services providers, in order to help us send you emails, push notifications and text messages;</li>
                    <li>
                        analytics providers and search information providers (e.g.
                        {' '}
                        <a href="https://cloud.google.com/maps-platform/">Google Maps API</a>
                        {' '}
                        refer for more details on
                        {' '}
                        <a href="https://policies.google.com/privacy">Google Privacy Policy</a>
                        );
                    </li>
                    <li>
                        infrastructure service providers (e.g.
                        {' '}
                        <a href="https://cloud.google.com/">Google Cloud Platform</a>
                        {' '}
                        services);
                    </li>
                    <li>
                        supervising authorities (e.g.
                        {' '}
                        <a href="https://www.lb.lt/en/">Bank of Lithuania</a>
                        ,
                        {' '}
                        <a href="http://www.fntt.lt/en/">Financial Crime Investigation Service</a>
                        );
                    </li>
                    <li>people or companies that you transfer money to;</li>
                    <li>other financial institutions, when you make outbound payments, perform currency conversion or receive payments;</li>
                    <li>payment systems’ operators (e.g. CENTROlink payment system operated by the Bank of Lithuania);</li>
                    <li>our lawyers or other external consultants, in order to perform activities required by law (e.g. internal or external audit);</li>
                    <li>other entities if the personal data may be shared with them under the contract which is concluded between you and us;</li>
                    <li>other entities under an agreement with us.</li>
                </ul>

                We may also need to share your personal data with other third-party organisations or authorities:

                <ul>
                    <li>If we must do so under any law or regulation</li>
                    <li>If we decide to sell our business</li>
                    <li>In connection with criminal or fraud investigations</li>
                    <li>To enforce our rights (and those of clients or others) - in connection with legal claims</li>
                </ul>

                <h2>International transfer of personal data</h2>
                In case your personal data is transferred outside the EEA, we will take necessary steps to ensure that your data is treated securely and in accordance with this Privacy Policy and we will ensure that it is protected and transferred in a manner consistent with the legal requirements applicable to the personal data. This can be done in a number of different ways, for example:
                <ul>
                    <li>the country to which we send the personal data, a territory or one or more specified sectors within that third country, or the international organization is approved by the European Commission as having an adequate level of protection;</li>
                    <li>the recipient has signed or contains in its terms of the service (service agreement) standard data protection clauses which are approved by the European Commission;</li>
                    <li>special permission has been obtained from a supervisory authority.</li>
                </ul>
                We may transfer personal data to a third country by taking other measures if it ensures appropriate safeguards as indicated in the GDPR.
                <br />
                <br />
                We also inform you that some of the cookies used on our website may transfer the personal data collected to third countries (e.g. USA). The transfer of cookies to third countries is carried out in order to ensure the functioning of our internet banking platform My OKEO, Join OKEO and our website to improve your experience and to provide you with communications that we consider relevant to you. For more information, please see our Cookies policy.
                <h2>How we protect your personal data</h2>
                The safety of your data is our top priority. Your data is processed and stored by us with utmost care. A variety of logical and physical security measures are used to keep your personal data safe and prevent unauthorized access, usage, or disclosure of it (the list indicated below is not exhaustive):
                <ul>
                    <li>Your personal data is stored in secured environment. Data is automatically encrypted prior to being written to disk. Each encryption key is itself encrypted with a set of master keys. Keys and encryption policies are managed the same way, in the same keystore, as for Google’s production services;</li>
                    <li>We use access control policies and segregation of duties which ensure that only restricted group of employees have access to your personal data. Staff is continuously trained about the importance of data safety and how to handle the data properly;</li>
                    <li>Your password is kept in secured datastore, encrypted using BCrypt. BCrypt assigns a random number unique to each user, known as the “salt”, that is then combined with the user password. Bcrypt then repeatedly uses a block cipher for a specific number of iterations on the combined salt” + password to generate a key. Password can’t be deciphered without the key.</li>
                    <li>All transactions you make through our platform after you log in are encrypted.</li>
                    <li>HTTP Communication between your browser and our Services is secured using TLS 1.2 certificates,</li>
                    <li>All data stored in our platform is a subject of back-up on ongoing basis.</li>
                </ul>
                While we take all the above steps to keep your data safe, you should take precautions to:
                <ul>
                    <li>Securely transmit your information to our My OKEO platform and other applications</li>
                    <li>Keep your credentials (login and password) to My OKEO platform confidential and do not share them with anyone</li>
                </ul>

                <h2>How long we keep your personal data</h2>
                We will keep your personal data for as long as it is needed for the purposes for which your data was collected and processed, and not longer than it is required by the applicable laws and regulations. This means that we store your data for as long as it is necessary for providing the Services and as required by the retention requirements in the laws and regulations. If the legislation of the Republic of Lithuania does not provide any period of retention of personal data, this period shall be determined by us, taking into account the legitimate purpose of the data retention, the legal basis and the principles of lawful processing of the personal data.
                <br />
                <br />
                The terms of data retention of the personal data for the purposes of the processing of the personal data as specified in this Privacy Policy are as follows:
                <ul>
                    <li>We keep personal data as long as your consent remains in force, if there are no other legal requirements which shall be fulfilled with regard to the personal data processing. We reserve the right to retain records of any consent given and withdrawn for a period of time necessary to protect our rights.</li>
                    <li>In case of the conclusion and execution of contracts – we keep personal data until the contract concluded between you and us remains in force and up to 10 years after the relationship between you and us has ended.</li>
                    <li>The personal data collected for the implementation of the obligations under the Law on Money Laundering and Terrorist Financing Prevention shall be stored in accordance with the Law on Prevention of Money Laundering and Terrorist Financing of the Republic of Lithuania up to 8 (eight) years. The retention period may be extended for a period not exceeding 2 (two) years, provided there is a reasoned request from a competent authority.</li>
                </ul>

                In the cases when the terms of data keeping are indicated in the legislative regulations, the legislative regulations are applied.
                <br />
                <br />
                Your personal data might be stored longer if:
                <ul>
                    <li>It is necessary for us to defend ourselves against claims, demands, or actions and exercise our rights</li>
                    <li>There is a reasonable suspicion of an unlawful act that is being investigated</li>
                    <li>Your personal data is necessary for the proper resolution of a dispute/complaint</li>
                    <li>Under another statutory basis</li>
                </ul>
                <h2>Your rights</h2>
                <strong>The right to be informed</strong>
                . You have the right to be provided with clear, transparent and easily understandable information about how we use your personal data.
                <br />
                <br />
                <strong> The right to access</strong>
                . You have the right to request from us the copies of your personal data. Where your requests are excessive, in particular if they are being sent with a repetitive character, we may refuse to act on the request, or charge a reasonable fee taking into account the administrative costs for providing the information. The assessment of the excessiveness of the request will be made by us.
                <br />
                <br />
                <strong> The right to rectification</strong>
                . You have the right to request us to correct or update your personal data at any time, in particular if your personal data is incomplete or incorrect.
                <br />
                <br />
                <strong> The right to data portability</strong>
                . The personal data provided by you is portable. You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
                <br />
                <br />
                <strong> The right to be forgotten</strong>
                . When there is no good reason for us to process your personal data anymore, you can ask us to delete your data. We will take reasonable steps to respond to your request. If your personal data is no longer needed and we are not required by law to retain it, we will delete, destroy or permanently de-identify it.
                <br />
                <br />
                <strong> The right to restrict processing</strong>
                . You have the right to restrict the processing of your personal data in certain situations (e.g. you want us to investigate whether it is accurate; we no longer need your personal data, but you want us to continue holding it for you in connection with a legal claim).
                <br />
                <br />
                <strong> The right to object processing.</strong>
                {' '}
                Under certain circumstances you have the right to object to certain types of processing (e.g. receiving notification emails). However, if you object using personal data which we need in order to provide our Services, we may need to close your payment account as we will not be able to provide the Services.
                <br />
                <br />
                <strong> The right to file a complaint with a supervisory authority.</strong>
                {' '}
                You have the right to file a complaint directly the State Data Protection Inspectorate of Lithuania if you believe that the personal data is processed in a way that violates your rights and legitimate interests stipulated by applicable legislation. You may apply in accordance with the procedures for handling complaints that are established by the State Data Protection Inspectorate and which may be found by this link: https://vdai.lrv.lt/lt/veiklos-sritys-1/skundu-nagrinejimas.
                <br />
                <br />
                <strong> Rights related to automated decision-making.</strong>
                {' '}
                You have the right not to be subject to a decision which is based solely on automated processing and which produces legal or other significant effects. In particular, you have the right:
                <ul>
                    <li>to obtain human intervention;</li>
                    <li>to express point of view;</li>
                    <li>to obtain an explanation of the decision reached after an assessment; and</li>
                    <li>to challenge such a decision.</li>
                </ul>
                <strong>Right to withdraw your consent. </strong>
                You have the right to withdraw your consent at any time. The withdrawal of consent shall not affect the lawfulness of data processing based on your consent before its withdrawal.
                <br />
                <br />
                If you would like to exercise any of these rights, please contact us at our email:
                {' '}
                <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>
                .
                For security reasons, we will not be able to process your request if we are not sure of your identity, so we may ask you for proof of it.
                <br />
                <br />
                Your requests shall be fulfilled, or fulfilment of your requests shall be refused by specifying the reasons for such refusal, within 30 (thirty) calendar days from the date of submission of the request meeting our internal rules and GDPR. The aforementioned time frame may be extended for 60 (thirty) calendar days by giving prior notice to you if the request is related to a great scope of personal data or other simultaneously examined requests. A response to you will be provided in a form of your choosing as the requester.
                <br />
                <br />
                We may refuse to satisfy your request if the exception or limitation to the exercise of data subjects' right set out in the GDPR apply, or if your request is found to be manifestly unfounded or disproportionate. If we refuse to satisfy your request, we will give you our reason for such refusal in writing.
                <br />

                <h2>Cookies policy</h2>
                If you access our information or Services through our website, you should be aware that we use Cookies. All cookies used on our website (other than necessary cookies) require your consent.
                <br />
                <br />
                For more information on how to control your Cookie settings and browser settings or how to delete Cookies on your hard drive, please read the Cookies Policy available on our
                {' '}
                <a href="http://www.okeo.com">website</a>
                .
                <h2>Changes of this Privacy Policy</h2>
                We regularly review this Privacy Policy and reserve the right to modify it at any time in accordance with applicable laws and regulations. Any changes and clarifications will take effect immediately upon their publication on our
                {' '}
                <a href="http://www.okeo.com">website</a>
                .
                <br />
                <br />
                Please review this Privacy Policy from time to time to stay updated on any changes.
                <h2>Contact us</h2>
                You may contact us by writing to us an email at
                {' '}
                <a href="mailto:hello@okeo.com">hello@okeo.com</a>
                {' '}
                or post us at Okeo Payments, UAB address: Gedimino pr. 20, LT-01103, Vilnius, Lithuania.
                <h2>Our Data Protection Officer</h2>
                Our Data Protection Officer (hereinafter – the DPO) continuously monitors our privacy compliance and communicates with us on data protection matters relevant to the provision of our Services. You may contact our DPO regarding all issues relating to our Company’s processing of your personal data and the exercise of your data protection rights by sending an email to the address:
                {' '}
                <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>
                .
            </PrivacyPolicyWrapper>
        </LegalContent>
    </LegalWrapper>
);
