import styled from 'styled-components';
import { globalColors } from 'assets/globalColors';
import { TrustSectionMobileJpg } from 'assets/images';
import { breakPoints, mobileHorizontalPadding, standardTransition } from 'config/layout/layout';
import { defaultCarouselPaginationStyles } from 'components/atoms/Companies/Companies.styled';

const smallDesktopBreakpoint = '1255px';

export const TrustTitle = styled.h3`
    font-size: 32px;
    letter-spacing: 0px;
    line-height: 50px;
    font-weight: 600;
    display: inline-block;
    margin: 0;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        display: block;
    }
`;

export const TrustSubtitle = styled.h4`
    font-size: 24px;
    line-height: 50px;
    font-weight: 400;
    margin: 12px 0 0;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        text-align: center;
        display: block;
        font-size: 18px;
        line-height: 24px;
        margin: 24px 0 40px;
    }
`;

export const TrustText = styled.div`
    font-size: 16px;
    line-height: 24px;

    ul {
        list-style: none;
        padding: 0;
        display: grid;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;

        .text {
            br {
                display: none;
            }
        }
    }
`;

export const TrustLi = styled.li<{ isVisible: boolean }>`
    padding: 24px;
    ${standardTransition};
    position: relative;

    .title {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 24px;
        font-weight: 600;
    }

    &:after {
        content: '';
        display: block;
        width: 50px;
        height: 3px;
        background-color: ${globalColors.backgrounds.green.base};
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
    }

    @keyframes borderAnimation {
        0% {
            width: 0;
        }
        100%  {
            width: 100%;
        }
    }

    ${({ isVisible }) => isVisible && `
        &:after {
            animation: borderAnimation 0.6s 1s forwards;
        }
    `}
`;

export const TrustContentWrapper = styled.div`
    padding: 0 0 110px;
    display: grid;
    grid-template-columns: 590px 1fr;
    gap: 100px;

    @media screen and (max-width: ${smallDesktopBreakpoint}) {
        grid-template-columns: 1fr 45%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr;
        background-color: white;
        padding: 50px 0;
    }
`;

export const LeftTrustContent = styled.div`
    width: 588px;
    padding-top: 82px;

    @media screen and (max-width: ${smallDesktopBreakpoint}) {
        width: 100%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        margin: 0 auto;
        box-shadow: none;
        padding: 0;
        width: 100%;
    }
`;
export const RightTrustContent = styled.div`
    img {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: 505px;
        margin-top: -39px;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            margin: 20px auto 0;
            display: block;
            max-width: 470px;
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        height: 70vw;
        max-height: 350px;
        width: calc(100% + 70px);
        display: block;
        margin-left: 0;
        background-image: url(${TrustSectionMobileJpg});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 0 -35px;
    
        img {
            display: none;
        }
    }
`;

export const TrustCarrouselWrapper = styled.div`
    max-width: 100%;
    padding-bottom: 30px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        max-width: 470px;
        margin: 0 auto;
    }

    .trust-section-carousel {

        .splide__track {
            padding: 30px 0 15px;
            max-width: calc(100vw - ${2 * mobileHorizontalPadding}px);
            margin: 0 auto;
        }

        .splide__slide {
            padding-bottom: 30px;

            .title {
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 24px;
                font-weight: bold;
            }

            .text {
                font-size: 16px;
                line-height: 24px;
                font-weight: 300;

                br {
                    display: none;
                }
            }

            border-bottom: 3px solid ${globalColors.backgrounds.green.base};
        }

        .splide__pagination {
            bottom: -25px;
            width: 200px;
            margin: auto;

            ${defaultCarouselPaginationStyles};
        }
    }
`;
