import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { globalColors } from 'assets/globalColors';
import {
    Tab1Jpg, Tab2Jpg, Tab3Jpg, Tab4Jpg,
} from 'assets/images/tabs';
import useOnScreen from 'hooks/useIsVisible';

import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { TAB_1_Z_INDEX, TAB_2_Z_INDEX, TAB_3_Z_INDEX } from 'config/layout/layout';
import { ID } from 'config/layout/layout.types';
import { pricingRoutePath } from 'config/path/path';

import { FadeInWrapper } from './FadeInWrapper/FadeInWrapper';
import {
    SmartContentWrapper,
    SmartInnerWrapper,
    SmartSubTitle,
    SmartTab,
    SmartTabsWrapper,
    SmartText,
    SmartTitle,
    SmartTitleBolded,
    SmartTitleWrapper,
} from './Smart.styled';
import { SmartProps } from './Smart.types';

export const Smart: React.FC<SmartProps> = ({ t }) => {
    const underLineRef = useRef();
    const tabListRef = useRef();
    const isUnderLineVisible = useOnScreen(underLineRef);
    const isTabListVisible = useOnScreen(tabListRef);

    const [activeTab, updateActiveTab] = useState(0);

    const numberOfTabs = 4;
    const interval = 800;
    const accelerateTime = 30;

    useEffect(() => {
        // Function that counts how many tabs should be visible
        const tabsAnimation = () => {
            if (activeTab < numberOfTabs * 2) {
                // If number of active tab is lower or equal to doubled numberOfTabs display 1 one tab
                updateActiveTab(activeTab + 1);
            } else {
                // If active tab number is greater than (2 * numberOfTabs) activeTab is set to initialState;
                updateActiveTab(1);
            }
        };

        const tabsAnimationHandler = () => isTabListVisible && setTimeout(tabsAnimation, (interval - accelerateTime * activeTab));

        // Run function every interval
        tabsAnimationHandler();
    }, [isTabListVisible, activeTab]);

    return (
        <SectionWrapper id={ID.Smart} backgroundColor={globalColors.backgrounds.grey.light}>
            <SmartInnerWrapper>
                <SmartTitleWrapper>
                    <SmartTitle>{t('title.label')}</SmartTitle>
                    <SmartTitleBolded>
                        {t('title.labelBolded')}
                        <Underline ref={underLineRef} isVisible={isUnderLineVisible}>
                            {t('title.underlinedLabel')}
                        </Underline>
                    </SmartTitleBolded>
                </SmartTitleWrapper>
                <SmartContentWrapper>
                    <div>
                        <SmartSubTitle>
                            <Trans t={t} i18nKey="subtitle.label" />
                        </SmartSubTitle>
                        <SmartText>
                            {t('text.label')}
                        </SmartText>

                        <div>
                            <Link to={pricingRoutePath}>
                                <ChevronButton label={t('buttons.check.label')} />
                            </Link>
                        </div>
                    </div>

                    {/*
                        For now there is no mapping or counting amount of SmartTab
                        remember to update numberOfTabs in any case - currently: 4
                    */}
                    <SmartTabsWrapper ref={tabListRef}>

                        <FadeInWrapper
                            active={activeTab > 0}
                            // If number is greater that numberOfTabs hide tab by tab from the 1st one
                            hided={activeTab > numberOfTabs}
                            zIndex={TAB_1_Z_INDEX}
                        >
                            <SmartTab align="end">
                                <img src={Tab1Jpg} alt={t('tabs.1.alt')} />
                            </SmartTab>
                        </FadeInWrapper>

                        <FadeInWrapper
                            hided={activeTab > numberOfTabs + 1}
                            active={activeTab > 1}
                            zIndex={TAB_2_Z_INDEX}
                        >
                            <SmartTab align="center">
                                <img src={Tab2Jpg} alt={t('tabs.2.alt')} />
                            </SmartTab>
                        </FadeInWrapper>

                        <FadeInWrapper
                            active={activeTab > 2}
                            hided={activeTab > numberOfTabs + 2}
                            zIndex={TAB_3_Z_INDEX}
                        >
                            <SmartTab align="center">
                                <img src={Tab3Jpg} alt={t('tabs.3.alt')} />
                            </SmartTab>
                        </FadeInWrapper>

                        <FadeInWrapper
                            hided={activeTab > numberOfTabs + 3}
                            active={activeTab > 3}
                        >
                            <SmartTab align="center">
                                <img src={Tab4Jpg} alt={t('tabs.4.alt')} />
                            </SmartTab>
                        </FadeInWrapper>
                    </SmartTabsWrapper>
                </SmartContentWrapper>
            </SmartInnerWrapper>
        </SectionWrapper>
    );
};
