export enum Environment {
    DEVELOPMENT = 'development',
    PRODUCTION = 'production',
}

export interface VersionDetails {
    ciJobStartedAt: string;
    ciCommitTimestamp: string;
    ciCommitShortSha: string;
}

export enum ReactAppBuildEnvVariable {
    CI_JOB_STARTED_AT = 'REACT_APP_CI_JOB_STARTED_AT',
    CI_COMMIT_TIMESTAMP = 'REACT_APP_CI_COMMIT_TIMESTAMP',
    CI_COMMIT_SHORT_SHA = 'REACT_APP_CI_COMMIT_SHORT_SHA',
    FAST_REFRESH = 'FAST_REFRESH',
    NODE_ENV = 'NODE_ENV',
    PUBLIC_URL = 'PUBLIC_URL',
    WDS_SOCKET_HOST = 'WDS_SOCKET_HOST',
    WDS_SOCKET_PATH = 'WDS_SOCKET_PATH',
    WDS_SOCKET_PORT = 'WDS_SOCKET_PORT',
}

/* Has to be in format of `__ENV__’ + ‘KubernetesRuntimeEnvVariable’ */
export enum KubernetesRuntimeEnvVariable {
    MY_OKEO_FE_URL = '__ENV__MY_OKEO_FE_URL',
    FE_URL_TO_BE_URL = '__ENV__FE_URL_TO_BE_URL',
    ONBOARDING_FE_URL = '__ENV__ONBOARDING_FE_URL',
    ZENDESK_KEY = '__ENV__ZENDESK_KEY',
    GOOGLE_ANALYTICS_MEASUREMENT_ID = '__ENV__GOOGLE_ANALYTICS_MEASUREMENT_ID',
    GOOGLE_TAG_MANAGER_ID = '__ENV__GOOGLE_TAG_MANAGER_ID',
    GOOGLE_MAP_KEY = '__ENV__GOOGLE_MAP_KEY',
    GOOGLE_MAP_ID = '__ENV__GOOGLE_MAP_ID',
    LINKEDIN_PARTNER_ID = '__ENV__LINKEDIN_PARTNER_ID',
    HOTJAR_ID = '__ENV__HOTJAR_ID',
    HOTJAR_SNIPPET_VERSION = '__ENV__HOTJAR_SNIPPET_VERSION',
}

export interface MappingObject {
    [key: string]: string;
}

