import React, { useEffect, useState } from "react";

import { WithTranslation } from "react-i18next";

import { freeSepaPromoPolicyRoutePath } from "config/path/path";
import CloseIcon from "assets/icons/Close";
import { Button, ComponentSize } from "@okeo/components";
import { getDeviceByWidth } from "config/layout/layout";
import useWindowSize from "hooks/useWindowSize";
import { Device } from "config/layout/layout.types";
import { ONBOARDING_URL } from "config/environment";
import { PromoBannerOwnProps } from "./PromoBanner.types";

import { Banner } from "./PromoBanner.styled";
import { Promo } from "./assets";

const PROMO_END_TIME = "2025-01-01";
const PROMO_STORAGE_KEY = "promoBannerLastShown";
const PROMO_VISIBILITY_INTERVAL = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds

export const PromoBanner = ({
    deviceHeight,
    t,
}: PromoBannerOwnProps & WithTranslation) => {
    const [isPromoVisible, setIsPromoVisible] = useState(false);

    const { width } = useWindowSize();
    const device = getDeviceByWidth(width);
    const isMobile = device === Device.Mobile;

    const checkPromoBannerVisibility = () => {
        const lastShown = localStorage.getItem(PROMO_STORAGE_KEY);
        const now = new Date().getTime();
        const promoEndTime = new Date(PROMO_END_TIME).getTime();

        if (now <= promoEndTime) {
            if (!lastShown || Number.isNaN(Number(lastShown))) {
                setIsPromoVisible(true);
            } else if (now - Number(lastShown) >= PROMO_VISIBILITY_INTERVAL) {
                setIsPromoVisible(true);
            }
        }
    };

    useEffect(() => {
        checkPromoBannerVisibility();
    }, []);

    const handleClose = () => {
        const now = new Date().getTime();

        localStorage.setItem(PROMO_STORAGE_KEY, now.toString());
        setIsPromoVisible(false);
    };

    return (
        <Banner opened={isPromoVisible} deviceHeight={deviceHeight}>
            <div className="bannerWrapper">
                <div className="close">
                    <CloseIcon onClick={handleClose} />
                </div>
                <img src={Promo} alt="promo" />
                <div className="bannerContent">
                    <div className="title">{t("promo.title")}</div>

                    <div className="box">
                        <b>{t("promo.line1")}</b>
                        <p>
                            {t("promo.line2")}
                            <br />
                            <br />
                            {t("promo.line3")}
                            <br />
                            {t("promo.line4")}
                        </p>
                        <p>{t("promo.line5")}</p>
                        <h3 className="center">{t("promo.line6")}</h3>
                    </div>

                    <a
                        className="openAccount"
                        href={ONBOARDING_URL}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Button
                            size={
                                isMobile
                                    ? ComponentSize.COMPACT
                                    : ComponentSize.REGULAR
                            }
                            label={t("promo.openAccount")}
                        />
                    </a>
                    <a
                        href={freeSepaPromoPolicyRoutePath}
                        onClick={handleClose}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("promo.learnMore")}
                    </a>
                </div>
            </div>
        </Banner>
    );
};
