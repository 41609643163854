import styled from "styled-components";

export const PromoPolicyWrapper = styled.div`
    .annotation {
        font-size: 14px;
    }

    .note {
        font-size: 12px;
        vertical-align: top;
    }

    b {
        font-weight: 600;
    }

    h4 {
        font-weight: 600;
    }
`;
