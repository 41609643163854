import React, { useEffect } from 'react';

import {
    accumulativeRoutePath, applicantsPrivacyPolicyLTRoutePath, applicantsPrivacyPolicyRoutePath, complaintsPolicyRoutePath, cookiesPolicyRoutePath, personalRoutePath, privacyPolicyRoutePath, termsAndConditionsPersonalRoutePath, termsAndConditionsRoutePath
} from 'config/path/path';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CloseIcon from 'assets/icons/Close';
import { TranslationNamespace } from 'config/i18n/i18n.types';
import { useScrollDown } from 'hooks/useScrollDown';

import Styled from './AccumulativeBanner.styled';
import { getAccumulativeBannerSessionState, setAccumulativeBannerSessionState } from './AccumulativeBanner.helpers';
import { AccumulativeBannerProps, AccumulativeBannerState } from './AccumulativeBanner.types';

const accumulativeBannerExceptionRoutes = [accumulativeRoutePath, personalRoutePath, termsAndConditionsPersonalRoutePath, termsAndConditionsRoutePath, complaintsPolicyRoutePath, cookiesPolicyRoutePath, applicantsPrivacyPolicyLTRoutePath, applicantsPrivacyPolicyRoutePath, privacyPolicyRoutePath];

const AccumulativeBanner = ({ accumulativeBannerState, setAccumulativeBannerState } : AccumulativeBannerProps) => {
    const scrollingDown = useScrollDown();
    const { pathname } = useLocation();
    const { t } = useTranslation([TranslationNamespace.ACCOUNT_ACCUMULATIVE]);

    const currentBannerState = getAccumulativeBannerSessionState();

    const setClosed = () => {
        setAccumulativeBannerState(AccumulativeBannerState.CLOSED);
        setAccumulativeBannerSessionState(AccumulativeBannerState.CLOSED);
    };

    useEffect(() => {
        setAccumulativeBannerSessionState(AccumulativeBannerState.OPEN);

    }, [currentBannerState]);

    const pathIncludedInExceptionRoutes = accumulativeBannerExceptionRoutes.some((path) => pathname.includes(path));

    const showBanner = accumulativeBannerState === AccumulativeBannerState.OPEN && !pathIncludedInExceptionRoutes;

    return (
        <Styled.Wrapper showBanner={showBanner} scrollingDown={scrollingDown && showBanner}>

            <Styled.InnerWrapper>
                {t('banner.text')}
                <Styled.ReadMoreLink to={accumulativeRoutePath}>
                    {t('banner.learnMore', 'Learn more')}
                </Styled.ReadMoreLink>
            </Styled.InnerWrapper>

            <Styled.CloseButton onClick={() => setClosed()}>
                <CloseIcon />
            </Styled.CloseButton>

        </Styled.Wrapper>
    );
};

export default AccumulativeBanner;
