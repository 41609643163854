import React from 'react';
import { globalColors } from 'assets/globalColors';
import OkeoSVG from 'assets/icons/OkeoSVG';

import { OkeoIconProps, OkeoIconVariant } from './OkeoIcon.types';

const OkeoIcon: React.FC<OkeoIconProps> = ({ variant }) => {
    let icon;

    switch (variant) {
        case OkeoIconVariant.GREEN:
            icon = (
                <OkeoSVG fill1="#7af63e" fill2={globalColors.backgrounds.green.light!} variant={variant} />
            );
            break;

        case OkeoIconVariant.YELLOW:
            icon = (
                <OkeoSVG fill1={globalColors.backgrounds.yellow.light as string} fill2="#ffd500" variant={variant} />
            );
            break;

        case OkeoIconVariant.ORANGE:
            icon = (
                <OkeoSVG fill1="#ffae29" fill2={globalColors.backgrounds.orange.base} variant={variant} />
            );
            break;

        default:
            icon = (
                <OkeoSVG fill1="#7af63e" fill2={globalColors.backgrounds.green.light!} variant={variant} />
            );
    }

    return icon;
};

export default OkeoIcon;
