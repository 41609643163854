/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ApplicantsPrivacyPolicyPDF } from 'assets/files';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import ApplicationsPrivacyPolicyVersionSelector from 'pages/Legal/ApplicantsPrivacyPolicy/ApplicationsPrivacyPolicyVersionSelector/ApplicationsPrivacyPolicyVersionSelector';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';

export const ApplicantsPrivacyPolicy: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Applicants Privacy Policy</LegalTitle>

            <div>
                <Tooltip name="tnc-download-btn" label="Download Applicants Privacy Policy">
                    <RoundUrlButton
                        primary
                        external
                        link={ApplicantsPrivacyPolicyPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>

                <ApplicationsPrivacyPolicyVersionSelector />
            </div>
        </TitleWithButtonsRow>

        <TitleWithButtonsRow>
            <LastUpdate>Last update: 29 August 2024</LastUpdate>
        </TitleWithButtonsRow>

        <LegalContent>
            <h2>Purpose and scope</h2>
                This Applicant Privacy Policy (hereinafter - the “Policy”) is intended to acquaint you with the information about the processing of your personal data when you apply for a job position at Okeo Payments, UAB.
            <br />
            <br />
                We understand that the protection of personal data is very important and we are committed to respect your (the Applicant’s) privacy and personal data.
            <h2>Definitions</h2>
            <strong>Applicant</strong> – a person applying for a job position in the Company.
            <br /><br />
            <strong>You</strong> – an Applicant.
            <br /><br />
            <strong>We or the Company</strong> – Okeo Payments, UAB, legal entity code 305219549, registered office address at Gedimino ave. 20, LT-01103, Vilnius, Lithuania.
            <br /><br />
            <strong>Applicant’s consent</strong> – any freely given, specific, informed and unambiguous indication of Your wishes by which you, by a statement or by a clear affirmative action, signify agreement to the processing of personal data relating to you. Please be informed that your consent is given by submitting your CV and any other related documents to the particular position.
            <h2>Data we collect</h2>
                Depending on the job position, we collect and process various types of personal data. The following are the main, but not all, categories of personal data processed by the Company:
            <ul>
                <li><strong>Personal identification data</strong> – first name, last name, date of birth (age);</li>
                <li><strong>Data on qualification and experience</strong> – data on working experience (workplace, working period, duties, responsibilities or achievements), information on your education (institution of education, period, acquired degree or qualification), information on professional development courses (attended courses, obtained certificates), information on foreign language, informational technology, driving skills and other competences, as well as, other information provided by you in curriculum vitae (CV), motivational letter and other documents or which we received from the third parties;</li>
                <li><strong>Contact details</strong> – permanent residence address, correspondence address (if different from the permanent place of residence), telephone, email address;</li>
                <li><strong>Recommendations, employers' feedback</strong> – person recommending or providing feedback about the applicant, his/her contacts, content of the feedback or recommendation.</li>
            </ul>
            <h2>Purpose and legal basis to process the personal data</h2>
                The table below sets out the purposes of processing your personal data, the legal basis and the category of processed personal data.

            <table>
                <thead>
                    <tr>
                        <th>Purpose of personal data processing</th>
                        <th>Legal basis for personal data processing</th>
                        <th>Categories of personal data processed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Execution of recruitment process</td>
                        <td>Applicants’ consent;<br />Complying with obligations applicable to the Company</td>
                        <td>
                            <ul>
                                <li>Personal identification data;</li>
                                <li>Contact details;</li>
                                <li>Data on qualification and experience;</li>
                                <li>Recommendations, employers’ feedback;</li>
                                <li>Other provided information (e.g. link to social media account provided by Applicant).</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <h2>How we obtain Your personal data</h2>
                The major part of your personal data we obtain directly from you, for example, when you send a curriculum vitae (CV), cover letter or other application documents.
            <br />
            <br />
                Information about your candidacy, your curriculum vitae (CV) or other application documents can be also obtained from job search, recruitment or intermediary service providers, such as the Lithuanian Labour Exchange, employment agencies or career portals.
            <br />
            <br />
                We may collect your personal data relating to qualifications, professional abilities and qualities from the former employer after informing you, and from your current employer – only with your consent.
            <br />
            <br />
                We may also obtain certain information from public administration entities, registers, databases and other sources.
            <h2>How we can share Your personal data</h2>
                Your personal data may be transferred to:
            <ul>
                <li>The Bank of Lithuania and other public administration entities;</li>
                <li>Legal, financial, tax, personnel administration, accounting advisors, etc.;</li>
                <li>Third parties who, in accordance with Our instructions, provide us with the services related to the selection of Applicants, their evaluation and internal administration;</li>
                <li>Other persons who need the data in order to fulfil their legal obligations, with a legitimate interest or with your consent.</li>
            </ul>
            <h2>Your rights</h2>
                As a data subject, you have the right to:
            <ul>
                <li>know (be informed) about the processing of your data (right to know);</li>
                <li>access your data and obtain information how it is processed (right to access);</li>
                <li>demand the correction or supplementation of incomplete personal data (right to request correction);</li>
                <li>request the deletion of personal data relating to you (the right “to be forgotten”);</li>
                <li>require the Company to restrict the processing of personal data relating to you (right to restrict);</li>
                <li>not consent at any time to the processing of your personal data if this is in the public interest or necessary for the legitimate interests of the Company or a third party (right to object);</li>
                <li>receive data related to you that you have provided to the Company in a structured, commonly used and computer-readable format and transfer it to another data controller (right to data portability);</li>
                <li>submit a complaint to the State Data Protection Inspectorate of the Republic of Lithuania.</li>
            </ul>
            <h2>How You can exercise Your rights</h2>
                To exercise your rights and receive answers to your questions, please contact us at the email address
            {' '}
            <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>.
            <br />
            <br />
            Your request will be processed within 30 calendar days from the date on which the request is lodged. This period may be extended by 60 calendar days by notifying you in advance if the request concerns a large amount of personal data or if other requests are being processed at the same time. The answer shall be provided to you via email, unless you’ll indicate other preferred form.
            <br /><br />
            We may refuse to satisfy you request if the exception and/or limitation to the exercise of data subjects’ right set out in the GDPR apply, and/or if your request is found to be manifestly unfounded or disproportionate. If we refuse to satisfy your request, we will give you our reason for such refusal in writing.
            <h2>Data retention period</h2>
                If your application for a job position is successful and you enter into an employment contract with Us, your personal data shall be transferred and processed in accordance with Our Employee Data Processing Rules.
            <br />
            <br />
                If your application for a job position is not successful, then the personal data that you have provided will be stored until the end of the recruitment process.
            <br />
            <br />
                We may in some cases store Your personal data longer:
            <ul>
                <li>if necessary, to enable us to defend ourselves against claims and exercise our rights to issue claims or lawsuits;</li>
                <li>there are reasonable suspicions of illegal activity;</li>
                <li>personal data is necessary for proper resolution of a dispute or complaint;</li>
                <li>your request for access to your personal data is received;</li>
                <li>on other grounds provided for in the legislation.</li>
            </ul>
            <h2>Right to appeal</h2>
                If you consider that your personal data is being processed in violation of your rights and legitimate interests established in the applicable legal acts, then you may submit a complaint to the supervisory authority - the State Data Protection Inspectorate. The procedure for handling complaints in the State Data Protection Inspectorate can be found here:
            {' '}
            <a href="https://vdai.lrv.lt/lt/kiti/atmintines/atmintine-asmenims-ketinantiems-kreiptis-i-valstybine-duomenu-apsaugos-inspekcija-del-skundo-pateikimo/" target="_blank" rel="noreferrer">https://vdai.lrv.lt/lt/kiti/atmintines/atmintine-asmenims-ketinantiems-kreiptis-i-valstybine-duomenu-apsaugos-inspekcija-del-skundo-pateikimo</a>
.
            <h2>How your data is stored</h2>
                We confirm that personal data is processed in the Company in accordance with the requirements of the applicable data protection laws, as well as the instructions of the controlling authorities. The Company shall implement all necessary technical and administrative measures to protect the data collected by it from loss, unauthorized use and alteration. Our employees and HR partners have a written commitment not to disclose to third parties or disseminate information obtained in the workplace, including information about the Applicants.
            <h2>Contacts of the Data Protection Officer</h2>
                If you have any questions about data processing, you can contact our Data Protection Officer at
            {' '}
            <a href="mailto:dpo@okeo.com">dpo@okeo.com</a>
.
            <h2>Policy updates</h2>
                We are constantly reviewing this Policy and if we make substantial changes to the Policy, we let you know.
        </LegalContent>
    </LegalWrapper>
);
