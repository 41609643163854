import { globalColors } from 'assets/globalColors';
import styled, { css } from 'styled-components';

import { standardTransition } from 'config/layout/layout';

export const CompaniesContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10vw 0 6vw;
`;

export const defaultCarouselPaginationStyles = css`
    button.splide__pagination__page {
        background: ${globalColors.backgrounds.grey.dark};
        ${standardTransition};
        transform: unset;
        width: 10px;
        height: 10px;
        margin: 5px;

        &.is-active {
            background: ${globalColors.backgrounds.green.light};
        }
    }
`;

export const CompaniesCarousel = styled.div`
    max-width: 100%;

    li {
        display: grid;
        align-items: center;
        justify-content: center;
        height: 90px;

        img {
            max-width: 110px;
            max-height: 65px;
        }
    }

    .splide__pagination {
        top: 100px;

        ${defaultCarouselPaginationStyles};
    }
`;
