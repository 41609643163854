import { useState, useEffect } from 'react';
import axios from 'axios';

const useIpLocation = () => {
    const [ipLocationCurrency, updateIpLocationCurrency] = useState<string | null>(null);

    const getIpapiData = () => {
        axios.get(`https://ipapi.co/json/`)
            .then((response) => updateIpLocationCurrency(response.data.country_code))
            .catch((error) => {
                console.error('Error fetching IP location:', error);
            });
    };

    useEffect(() => {
        getIpapiData();
    }, []);

    return ipLocationCurrency;
};

export default useIpLocation;
