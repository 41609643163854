import React from "react";

import {
    CookiesPreferences
} from "pages/Main/Cookies/Cookies.types";
import {
    COOKIES_PREFERENCES_LOCAL_STORAGE_KEY,
    CookiesPreferencesContextProps,
    CookiesPreferencesProviderProps,
} from "./CookiesPreferencesProvider.types";
import { getInitialCookiesPreferencesFromLocalStorageState } from "./CookiesPreferencesProvider.helpers";

const CookiesPreferencesContext = React.createContext<CookiesPreferencesContextProps>({
    cookiesPreferences: undefined,
    setCookiesPreferences: (
        prevState: React.SetStateAction<CookiesPreferences | undefined>
    ) => prevState,
});

const CookiesPreferencesProvider = ({
    children,
}: CookiesPreferencesProviderProps) => {
    const [cookiesPreferences, setCookiesPreferences] = React.useState(
        getInitialCookiesPreferencesFromLocalStorageState()
    );
    const value = React.useMemo(
        () => ({ cookiesPreferences, setCookiesPreferences }),
        [cookiesPreferences, setCookiesPreferences],
    );

    React.useEffect(() => {
        if (cookiesPreferences) {
            localStorage.setItem(COOKIES_PREFERENCES_LOCAL_STORAGE_KEY, JSON.stringify(cookiesPreferences));
        } else {
            localStorage.removeItem(COOKIES_PREFERENCES_LOCAL_STORAGE_KEY);
        }
    }, [cookiesPreferences]);

    return (
        <CookiesPreferencesContext.Provider value={value}>
            {children}
        </CookiesPreferencesContext.Provider>
    );
};

const useCookiesPreferences = () => React.useContext(CookiesPreferencesContext);

export { CookiesPreferencesProvider, useCookiesPreferences };
