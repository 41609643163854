import styled from 'styled-components';
import { breakPoints } from 'config/layout/layout';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';

const additionalOverMobileBreakpoint = '900px';

const Title = styled.h3`
    font-size: 32px;
    letter-spacing: 0px;
    line-height: 48px;
    font-weight: 400;
    display: inline-block;
    margin: 0;
    padding-bottom: 50px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 24px;
        line-height: 38px;
        padding-bottom: 40px;
    }

    @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
        display: block;
    }

    ${Underline} {
        font-weight: 600;
    }
`;

const TextWrapper = styled.div`
    max-width: 700px;
    padding: 63px 70px 75px;
    background-color: white;
    position: relative;
    z-index: 2;
    margin-right: -100px;

    @media screen and (max-width: 1350px) {
        margin-right: -50px;
    }

    @media screen and (max-width: 1180px) {
        padding: 30px 35px 16px;
    }

    @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
        padding: 35px 35px 45px;
        margin-right: 0;
        text-align: center;
        max-width: unset;
    }
`;

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 42px;
    font-weight: 300;
    font-size: 18px;
    line-height: 33px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 16px;
    }

    li {
        display: flex;
        gap: 20px;
        margin-bottom: 12px;
        align-items: center;

        :last-child {
            margin-bottom: 0;
        }
    }
`;

const SectionWrapper = styled.div`
    padding: 108px 0 100px;

    @media screen and (max-width: 1180px) {
        padding: 108px 20px 100px;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 60px 20px 53px;
    }

    @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
        padding: 0;
    }
`;

const ContentWrapper = styled.div`
    background-color: #F0F2EF;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    padding-top: 74px;

    @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
        grid-template-columns: 1fr;
        padding-top: 15px;
    }
`;


const AnimationWrapper = styled.div`
    position: relative;
    z-index: 2;

    .hand {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 455px;
        height: 253px;

        @media screen and (max-width: 1180px) {
            width: 35vw;
            height: auto;
        }

        @media screen and (max-width: 1000px) {
            width: 100%;
        }

        @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
            width: 84%;
            position: static;
        }
    }

    .card {
        position: absolute;
        top: -10%;
        left: 45%;
        transform: translateX(-50%);
        width: 293px;
        height: 259px;
        animation-name: liftAndDrop;
        animation-duration: 3s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;

        @media screen and (max-width: 1180px) {
            width: 25vw;
            height: auto;
            top: -15%;
            animation-name: liftAndDropTablet;
        }

        @media screen and (max-width: 1000px) {
            width: calc(100% - 80px);
            bottom: 20vw;
            top: unset;
            animation-name: liftAndDropSmallTablet;
        }

        @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
            position: static;
            width: 50%;
            transform: translate(38%, 0%);
            animation-name: liftAndDropMobile;
        }
    }

     @keyframes liftAndDrop {
        0%, 100% {
            top: -6%;
        }
        50% {
            top: 5%;
        }
    }

    @keyframes liftAndDropTablet {
        0%, 100% {
            top: -5%;
        }
        50% {
            top: -15%;
        }
    }

    @keyframes liftAndDropSmallTablet {
        0%, 100% {
            bottom: 13vw;
        }
        50% {
            bottom: 20vw;
        }
    }

    @keyframes liftAndDropMobile {
        0%, 100% {
            transform: translate(38%, 30%);
        }
        50% {
            transform: translate(38%, 0%);
        }
    }
`;

const GreenLight = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: auto;

    @media screen and (max-width: ${additionalOverMobileBreakpoint}) {
        width: 100%;
        height: auto;
    }
`;

export default {
    List,
    Title,
    GreenLight,
    TextWrapper,
    ContentWrapper,
    SectionWrapper,
    AnimationWrapper,
};
