import React from 'react';
import { Link } from 'react-router-dom';
import { okeoLogo } from 'assets/logos';

import { defaultRoutePath } from 'config/path/path';

export const NavbarLogo: React.FC = () => (
    <div>
        <Link to={defaultRoutePath} style={{ display: 'block' }}>
            <img src={okeoLogo} alt="OKEO LOGO" style={{ display: 'block' }} />
        </Link>
    </div>
);
