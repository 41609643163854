import React, { MouseEventHandler } from 'react';
import { globalColors } from 'assets/globalColors';
import { MobileNavbarIconWrapper } from 'pages/Main/Navbar/HamburgerNavbarMenu/HamburgerNavbarMenu.styled';


const CloseIcon: React.FC<{
    onClick?: MouseEventHandler<SVGElement>;
    color?: string;
}> = ({ onClick, color = 'black' }) => (
    <MobileNavbarIconWrapper className="close-icon">
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="15.776"
            height="15.776"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        >
            <path d="M1.414 1.636l12.942 12.51" />
            <path d="M14.14 1.414L1.63 14.356" />
        </svg>
    </MobileNavbarIconWrapper>
);

CloseIcon.defaultProps = {
    color: globalColors.fontColors.black,
    onClick: () => {}
};

export default CloseIcon;
