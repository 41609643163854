import styled from 'styled-components';
import { breakPoints, standardTransition } from 'config/layout/layout';
import { globalColors } from 'assets/globalColors';

const Title = styled.h3`
    font-size: 28px;
    letter-spacing: 0px;
    line-height: 38px;
    font-weight: 500;
    margin: 0 0 36px;
    max-width: 442px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 22px;
        line-height: 29px;
        max-width: 100%;
    }
`;

const Text = styled.div`
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    margin: 0 0 36px;
    max-width: 442px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 24px;
        max-width: 100%;
    }
`;

const ButtonWrapper = styled.div`
    max-width: 442px;
    text-align: left;
    width: 100%;
`;

const ContentWrapper = styled.div`
    padding: 80px 0;

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 40px 0;
    }

    @media screen and (max-width: 400px) {
        margin: 0 -20px;
    }
`;

const NotificationsWrapper = styled.div`
    max-width: 1020px;
    margin: 0 auto;
    position: relative;
    text-align: right;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding-top: 130px;
    }
`;

const NotificationsContent = styled.div`
    background-color: #F0F2EF;
    max-width: 792px;
    width: 100%;
    padding: 50px 72px;
    text-align: left;
    margin-bottom: 70px;
    display: inline-flex;
    flex-direction: column;
    align-items: end;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 130px 40px 35px;
        max-width: 550px;
        margin: 0 auto 150px;
        display: block;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 63px 20px 20px;
        max-width: 550px;
        margin: 0 auto 150px;
        display: block;
    }
`;

const LimitsContent = styled(NotificationsContent)`
    margin-bottom: 0;
    align-items: start;
`;

const LimitsWrapper = styled.div`
    max-width: 1020px;
    margin: 0 auto;
    position: relative;
    text-align: left;

    img {
        width: 426px;
        position: absolute;
        top: 0;
        bottom: -10px;
        right: 0;
        margin: auto;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            width: 400px;
            top: -125px;
            bottom: unset;
            margin: unset;
            right: unset;
            left: 50%;
            transform: translate(-37%);
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            width: 100%;
            max-width: 350px;
            right: 50px;
            left: unset;
            transform: unset;
        }

        @media screen and (max-width: 550px) {
            right: 30px;
        }

        @media screen and (max-width: 420px) {
            right: 0px;
        }
    }
`;

const NotificationSettings = styled.ul`        
    list-style-type: none;
    padding: 0;
    margin: 0;
    background: #FFFFFF;
    box-shadow: 0px 13px 18px #00000014;
    padding: 60px 26px 26px;
    display: grid;
    gap: 10px;
    width: 390px;
    height: 195px;
    position: absolute;
    top: 0;
    bottom: 40px;
    margin: auto;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        top: 30px;
        bottom: unset;
        margin: unset;
        left: 47%;
        transform: translate(-50%);
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 45px 20px 25px;
        height: 145px;
        width: 75vw;
        max-width: 400px;
        min-width: max-content;
        font-size: 14px;
    }

    @media screen and (max-width: 550px) {
        transform: unset;
        left: 30px;
    }

    li {
        display: flex;
        gap: 10px;
        align-items: center;
    }
`;

const MainNotification = styled.div<{ isChecked: boolean }>`
    display: flex;
    gap: 10px;  
    box-shadow: 0px 13px 18px #00000014;
    padding: 15px; 
    background: #FFFFFF;
    position: absolute;
    top: 50px;
    left: -25px;
    z-index: 1;
    width: 364px;
    height: 77px;
    align-items: center;

    ${standardTransition};
    ${({ isChecked }) => isChecked && `color: ${globalColors.fontColors.green.light}`};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        top: 30px;
        bottom: unset;
        margin: unset;
        left: 50%;
        transform: translate(-70%, -50%)
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 10px;
        height: 51px;
        width: 64vw;
        max-width: 350px;
        min-width: max-content;
        font-size: 14px;
    }

    @media screen and (max-width: 550px) {
        transform: unset;
        left: 10px;
        top: 0
    }
`;


export default {
    Text,
    Title,
    LimitsWrapper,
    LimitsContent,
    ButtonWrapper,
    ContentWrapper,
    MainNotification,
    NotificationSettings,
    NotificationsContent,
    NotificationsWrapper,
};
