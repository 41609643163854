import styled from "styled-components";
import { BreakPoint, PALETTE } from "@okeo/components";

const PROMO_BANNER_Z_INDEX = 32;

export const Banner = styled.div<{
    opened?: boolean;
    deviceHeight?: number;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
}>`
    position: fixed;
    display: ${({ opened }) => (opened ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.67)
        radial-gradient(closest-side at 50% 50%, #c2ff9473 0%, #ffffff00 100%)
        0% 0% no-repeat padding-box;
    z-index: ${PROMO_BANNER_Z_INDEX};

    .bannerWrapper {
        width: 95vw;
        max-width: 506px;

        img {
            margin: -8px 0;
            width: 100%;
        }

        .close {
            width: 100%;
            height: auto;
            position: relative;

            .close-icon {
                background-color: transparent;
                position: absolute;
                right: 10px;
                top: 76px;
                cursor: pointer;
                svg {
                    stroke-width: 2.8;
                }
            }
        }

        .bannerContent {
            background-color: ${PALETTE.paper[0]};
            text-align: left;
            display: flex;
            flex-direction: column;
            padding: 36px;
            gap: 20px;

            @media screen and (max-width: ${BreakPoint.mobile}) {
                padding: 12px;
                gap: 10px;
                font-size: 14px;
            }

            .title {
                font-size: 28px;
                letter-spacing: 0;
                font-weight: 700;
                text-align: center;

                @media screen and (max-width: ${BreakPoint.mobile}) {
                    font-size: 24px;
                }

                @media screen and (max-height: 740px) {
                    font-size: 22px;
                }
            }

            .subTitle{
                color: ${PALETTE.success[100]};
                text-align: center;
                font-weight: 600;
                font-size: 20px;

                @media screen and (max-width: ${BreakPoint.mobile}) {
                    font-size: 14px;
                }
            }

            .center{
                text-align: center;
                font-weight: 600;
                font-size: 20px;
                margin: 0;

                @media screen and (max-width: ${BreakPoint.mobile}) {
                    font-size: 14px;
                }
            }

            a {
                font-weight: 600;
                color: ${PALETTE.success[100]};
                text-decoration: none;
            }

            .openAccount {
                align-self: center;
            }
        }
    }
`;
