import React, { useEffect, useRef, useState } from 'react';
import { TeamWebp } from 'assets/images';

import ScrollList from 'components/atoms/ScrollList/ScrollList';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import {
    LeftWhyOkeoContent,
    RightWhyOkeoContent,
    ScrollTrack,
    WhyOkeoContentWrapper, WhyOkeoTitle,
} from './WhyOkeo.styled';
import { WhyOkeoProps } from './WhyOkeo.types';

const additionalStyles = 'filter: drop-shadow(0px 17px 15px #0000000D); overflow: visible !important;';

export const WhyOkeo: React.FC<WhyOkeoProps> = ({ t }) => {

    const [imageHeight, updateImageHeight] = useState(0);

    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        updateImageHeight(imageRef.current?.clientHeight || 0);
    }, [imageHeight, imageRef]);

    return (
        <SectionWrapper
            id="why-okeo"
            additionalStyles={additionalStyles}
        >
            <ScrollTrack>
                <WhyOkeoContentWrapper id="whyContentWrapper" imageHeight={imageHeight}>
                    <LeftWhyOkeoContent>
                        <img
                            src={TeamWebp}
                            ref={imageRef}
                            className="team"
                            alt={t('image.alt')}
                        />
                    </LeftWhyOkeoContent>
                    <RightWhyOkeoContent>
                        <WhyOkeoTitle>{t('title.label')}</WhyOkeoTitle>
                        <ScrollList list={t('list', { returnObjects: true })} />
                    </RightWhyOkeoContent>

                </WhyOkeoContentWrapper>
            </ScrollTrack>
        </SectionWrapper>
    );
};
