import React from 'react';
import { MobileNavbarIconWrapper } from 'pages/Main/Navbar/HamburgerNavbarMenu/HamburgerNavbarMenu.styled';

export const HamburgerIcon: React.FC = () => (
    <MobileNavbarIconWrapper>
        <svg
            className="hamburger-icon"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="14"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        >
            <path d="M1 7h18M1 1h18M1 13h18" />
        </svg>
    </MobileNavbarIconWrapper>
);
