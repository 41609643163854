import { ReactNode } from 'react';

export interface DropdownOption {
    label: string;
    value: string;
    icon: ReactNode;
}

export interface DropdownProps {
    options: DropdownOption[];
    onOptionSelect: (option: DropdownOption) => void;
    selected: DropdownOption;
    displayLabel?: boolean;
}

export enum DropdownState {
    OPEN,
    CLOSED,
}
