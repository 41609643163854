import { Options } from '@splidejs/react-splide';

import { PlanVariant } from '../Pricing.types';

export const pricingCarouselOptions: Options = {
    rewind: true,
    autoplay: false,
    pagination: true,
    arrows: false,
    perPage: 3,
    type: 'loop',
    gap: 0,
    focus: 1,
    perMove: 1,
    interval: 300,
    flickPower: 20,
};

export const pricingMobileCarouselOptions: Options = {
    ...pricingCarouselOptions,
    perPage: 1,
    focus: 0,
    rewind: true,
    flickPower: 100,
    perMove: 2,
    gap: 0,
};

export const getPlanTypeByPrevOrder = (slideIndex: number) => {
    if (slideIndex === 0) return PlanVariant.FREE;

    if (slideIndex === 1) return PlanVariant.LITE;

    if (slideIndex === 2) return PlanVariant.PREMIUM;

    return PlanVariant.FREE;
};
