import styled from 'styled-components';

import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { defaultCarouselPaginationStyles } from 'components/atoms/Companies/Companies.styled';
import { breakPoints, mobileHorizontalPadding, standardTransition } from 'config/layout/layout';


export const Title = styled.h3`
    font-size: 32px;
    letter-spacing: 0px;
    line-height: 58px;
    font-weight: 400;
    display: inline-block;
    margin: 0;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 24px;
        line-height: 38px;
        position: relative;
        z-index: 1;

        ${Underline} {
            display: block;
            width: fit-content;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        ${Underline} {
            display: inline-block;
        }
    }
`;

export const ContentWrapper = styled.div`
    padding: 79px 0 70px;
    text-align: center;
    flex-direction: column;
    gap: 83px;
    text-align: center;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 300px 0 46px;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 60%;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            opacity: 0.396;
            background: transparent radial-gradient(closest-side at 50% 50%, #C2FF94F8 0%, #C2FF9400 100%) 0% 0% no-repeat padding-box;
            z-index: 0;
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 40px 0 46px;

        &:after {
            top: 0;
            margin: auto;
            background-image: radial-gradient(circle at center, rgba(194,255,148,0.97) 0%, rgba(194,255,148,0) 100%);

        }
    }
`;

export const CarrouselWrapper = styled.div`
    max-width: 100%;
    padding-bottom: 30px;
    margin-top: 80px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        margin-top: 20px;
        position: relative;
        z-index: 1;
    }

    .benefits-section-carousel {
        .splide__track {
            padding: 30px 0 50px;
            max-width: calc(100vw - ${2 * mobileHorizontalPadding}px);
            margin: 0 auto;
            overflow: visible;

            @media screen and (max-width: ${breakPoints.tablet}px) {
                padding: 30px 0 40px;
                max-width: 400px;
            }
        }

        .splide__arrows {
            position: absolute;
            width: 200px;
            bottom: -32px;
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 27px;
        }

        .splide__arrow {
            background-color: transparent;

            svg {
                fill: #A5A5A5;
            }
        }

        .splide__slide {

            .slide-content-wrapper {
                display: grid;
                grid-template-columns: 500px 1fr;
                opacity: 0.6;
                transform: scale(0.8);
                box-shadow: 0px 0px 0px #00000017;
                ${standardTransition};

                @media screen and (max-width: ${breakPoints.tablet}px) {
                    grid-template-columns: 1fr;
                }
            }

            &.is-active {
                .slide-content-wrapper {
                    opacity: 1;
                    transform: scale(1);
                    box-shadow: 0px 12px 22px #00000017;
                }
            }

            .slide-content {
                padding: 73px 78px;
                text-align: left;
                background-color: white;

                @media screen and (max-width: ${breakPoints.tablet}px) {
                    padding: 28px 15px;
                }
            }

            .title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 24px;
                font-weight: bold;
                margin-bottom: 4px;

                @media screen and (max-width: ${breakPoints.tablet}px) {
                    font-size: 22px;
                    line-height: 30px;
                }
            }

            .subtitle {
                font-size: 24px;
                line-height: 30px;
                font-weight: 400;
                margin-bottom: 50px;

                @media screen and (max-width: ${breakPoints.tablet}px) {
                    font-size: 18px;
                    line-height: 30px;
                    margin-bottom: 35px;
                }
            }

            .description {
                font-size: 15px;
                line-height: 22px;
                font-weight: 300;

                 @media screen and (max-width: ${breakPoints.tablet}px) {
                    line-height: 24px;
                }
            }
        }

        .splide__pagination {
            bottom: -25px;
            width: 200px;
            margin: auto;

            ${defaultCarouselPaginationStyles};
        }
    }
`;

const SlideImage = styled.div<{ backgroundImage: string }>`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(${({ backgroundImage }) => backgroundImage});

    @media screen and (max-width: ${breakPoints.tablet}px) {
        height: 180px;
        background-position: top center;
    }
`;

export default {
    Title,
    SlideImage,
    ContentWrapper,
    CarrouselWrapper,
};
