import React from 'react';
import { WithT } from 'i18next';

import { Button } from 'components/atoms/Button/Button';
import { getPageType, PageType } from 'config/path/path.helpers';
import { ButtonTheme } from 'components/atoms/Button/Button.types';
import { MY_OKEO_URL, ONBOARDING_PERSONAL_URL, ONBOARDING_URL } from 'config/environment';

import { NavbarButtonsContainer } from './NavbarButtons.styled';

export const NavbarButtons: React.FC<WithT> = ({ t }) => {

    const joinOkeoUrl = getPageType() === PageType.PERSONAL ? ONBOARDING_PERSONAL_URL : ONBOARDING_URL;

    return (
        <NavbarButtonsContainer>
            <a href={MY_OKEO_URL} target="_blank" rel="noreferrer">
                <Button label={`${t('buttons.login.label')}`} buttonTheme={ButtonTheme.OUTLINE_ORANGE} />
            </a>
            <a href={joinOkeoUrl} target="_blank" rel="noreferrer">
                <Button margin="0 0 0 20px" label={`${t('buttons.join.label')}`} />
            </a>
        </NavbarButtonsContainer>
    );
};
