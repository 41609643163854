import { Trans } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { LimitsPng } from 'assets/images';
import { ID } from 'config/layout/layout.types';
import { pricingPersonalPath } from 'config/path/path';
import { stringToAttributeString } from 'utils/string-tools';
import { Switcher } from 'components/atoms/Switcher/Switcher';
import { HELP_PAGE_URL } from 'config/environment';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './NotificationsAndLimits.styled';
import { NotificationsAndLimitsProps, NotificationsListElement } from './NotificationsAndLimits.types';


export const NotificationsAndLimits: React.FC<NotificationsAndLimitsProps> = ({ t }) => {

    const [isChecked, setIsChecked] = useState([true, false, false, false]);

    useEffect(() => {
        const toggleCheckedValues = () => {
            setIsChecked([true, true, true, true]);

            setTimeout(() => {
                setIsChecked((prevIsChecked) => {
                    const newIsChecked = [...prevIsChecked];

                    newIsChecked[2] = false;

                    return newIsChecked;
                });

                setTimeout(() => {
                    setIsChecked([false, false, false, false]);
                }, 1000);
            }, 2000);
        };

        const interval = setInterval(() => {
            toggleCheckedValues();
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, []);




    const notificationsList: NotificationsListElement[] = t('notifications.list', { returnObjects: true });

    const renderCardInfoItems = Object.values(notificationsList)
        .slice(1)
        .map((listElement, index) => (
            <li key={`personal-account-info-${stringToAttributeString(listElement.title)}`} className={`notification-${index}`}>
                <Switcher isChecked={isChecked[index]} onClick={() => null} />

                <div>
                    {listElement.title}
                </div>
            </li>
        ));


    return (
        <SectionWrapper id={ID.NotificationsAndLimits}>
            <Styled.ContentWrapper>
                <Styled.NotificationsWrapper>
                    <Styled.NotificationsContent>
                        <Styled.Title>
                            {t('notifications.title')}
                        </Styled.Title>

                        <Styled.Text>
                            {t('notifications.text')}
                        </Styled.Text>

                        <Styled.ButtonWrapper>
                            <a href={HELP_PAGE_URL} target="_blank" rel="noreferrer">
                                <ChevronButton label={t('notifications.button')} />
                            </a>
                        </Styled.ButtonWrapper>
                    </Styled.NotificationsContent>

                    <Styled.MainNotification isChecked={isChecked[0]}>
                        <Switcher isChecked={isChecked[0]} onClick={() => null} />

                        <div>
                            {t('notifications.list.1.title')}
                        </div>
                    </Styled.MainNotification>

                    <Styled.NotificationSettings>
                        {renderCardInfoItems}
                    </Styled.NotificationSettings>
                </Styled.NotificationsWrapper>

                <Styled.LimitsWrapper>
                    <Styled.LimitsContent>
                        <Styled.Title>
                            {t('limits.title')}
                        </Styled.Title>

                        <Styled.Text>
                            <Trans t={t} i18nKey="limits.text" />
                        </Styled.Text>

                        <div>
                            <a href={pricingPersonalPath} rel="noreferrer">
                                <ChevronButton label={t('limits.button')} />
                            </a>
                        </div>
                    </Styled.LimitsContent>

                    <img src={LimitsPng} alt="View of limits settings" />
                </Styled.LimitsWrapper>
            </Styled.ContentWrapper>
        </SectionWrapper>
    );
};
