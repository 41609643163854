import styled from 'styled-components';

export const PrivacyPolicyWrapper = styled.div`
    .t1 tr > *:nth-child(1) {
        width:30%;
    }

    .t1 tr > *:nth-child(2) {
        width:70%;
    }

    .t2 tr > *:nth-child(1) {
        width:34%;
    }

    .t2 tr > *:nth-child(2) {
        width:33%;
    }
    
    .t2 tr > *:nth-child(3) {
        width:33%;
    }
`;
