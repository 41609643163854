import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import {
    breakPoints,
    desktopHorizontalPadding,
    mainWidth,
    mobileHorizontalPadding,
    standardTransition,
    tabletHorizontalPadding,
} from 'config/layout/layout';

const xsMobileBreakpoint = 340;

export const FooterEnd = styled.div`
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 19px;
    color: ${globalColors.fontColors.grey.dark};
    font-weight: 500;
    font-family: 'Prompt';
    text-align: center;
    padding: 35px ${desktopHorizontalPadding}px 40px;

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding-bottom: 100px;
    }
`;

export const FooterTopWrapper = styled.div`
    background-color: ${globalColors.backgrounds.grey.base};
    padding: 55px 0;
    font-family: 'Prompt';
`;

export const FooterTopContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: ${mainWidth}px;
    padding: 0 ${desktopHorizontalPadding}px 80px;
    margin: 0 auto;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr 1fr;
        padding: 0 ${tabletHorizontalPadding}px 70px;
        column-gap: 30px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        padding: 0 ${mobileHorizontalPadding}px 70px;
        row-gap: 55px;
    }

    @media screen and (max-width: ${xsMobileBreakpoint}px) {
        padding: 0 30px 70px;
    }

    a,
    svg {
        ${standardTransition};

        &:hover {
            color: ${globalColors.backgrounds.green.light};
            fill: ${globalColors.backgrounds.green.light};
        }
    }
`;

export const FooterText = styled.div`
    font-size: 16px;
    line-height: 26px;
    color: ${globalColors.fontColors.black};
    font-weight: 400;

    a {
        color: ${globalColors.fontColors.black};
        text-decoration: none;
    }

    @media screen and (max-width: ${xsMobileBreakpoint}px) {
        font-size: 15px;
    }
`;

export const FooterTitle = styled.div`
    font-size: 19px;
    line-height: 24px;
    color: ${globalColors.fontColors.grey.dark};
    font-weight: 500;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 18px;

    &:after {
        content: '';
        display: block;
        height: 2px;
        width: 120px;
        background-color: ${globalColors.backgrounds.green.light};
        position: absolute;
        left: 0;
        bottom: 0;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding-bottom: 14px;
        margin-bottom: 13px;
    }
`;

export const FooterList = styled.ul`
    display: grid;
    gap: 19px;
    padding-left: 0;
    list-style: none;
`;

export const StyledFooterListElement = styled.li`
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-gap: 11px;
    ${standardTransition};

    &:hover {
        svg {
            g {
                stroke: ${globalColors.backgrounds.green.light};
            }
        }
    }
    a {
        font-size: 16px;
        color: ${globalColors.fontColors.black};
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: ${globalColors.backgrounds.green.light};
        }
    }
`;

export const FooterSocialMediaList = styled.div`
    width: 130px;
    display: flex;
    justify-content: space-between;
`;

export const FooterLabel = styled.div`
    font-size: 14px;
    line-height: 24px;
    color: ${globalColors.fontColors.grey.dark};
    font-weight: 300;
    margin: 0 auto;
    max-width: ${mainWidth}px;
    padding: 0 ${desktopHorizontalPadding}px;

    a {
        color: ${globalColors.fontColors.green.base};
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 0 ${tabletHorizontalPadding}px;
        font-size: 12px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 0 ${mobileHorizontalPadding}px;
        text-align: justify;
    }

    @media screen and (max-width: ${xsMobileBreakpoint}px) {
        padding: 0 30px;
    }
`;

export const Addons = styled.div``;
