import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Trans } from 'react-i18next';
import { TickSVG } from 'assets/icons/TickSVG';
import { ChevronWrapper, PricingTableAdditionalInfo, PricingTableHeading } from 'pages/Pricing/Pricing.styled';

import Chevron from 'components/atoms/Chevron/Chevron';
import { ChevronDirection } from 'components/atoms/Chevron/Chevron.types';
import { stringToAttributeString } from 'utils/string-tools';

import { PricingTableMobileRowProps } from '../../PricingTable.types';

import {
    additionalInfoExists, additionalInfoPerPlanType, getTickColorPerPlanType, rowExists, setMobileTextPerPlanType
} from './PricingTableMobileRow.helpers';

export const PricingTableMobileRow:React.FC<PricingTableMobileRowProps> = ({ row, planType, index }) => {

    const [isOpened, toggleOpen] = useState(false);

    return rowExists(row, planType) ? (
        <PricingTableHeading
            index={index}
            isOpened={isOpened}
            hasAdditionalInfo={additionalInfoExists(row, planType)}
            onClick={() => toggleOpen(!isOpened)}
            key={stringToAttributeString(setMobileTextPerPlanType(row, planType))}
        >
            <div style={{ paddingTop: '3px' }}>
                <TickSVG fill={getTickColorPerPlanType(planType)} />
            </div>

            <div>
                <Trans>
                    {setMobileTextPerPlanType(row, planType)}
                </Trans>
            </div>

            {additionalInfoExists(row, planType) && (
                <>
                    <div style={{ display: 'grid', gridColumn: '2/3' }}>
                        <AnimateHeight height={isOpened ? 'auto' : 0} duration={200}>
                            <PricingTableAdditionalInfo>
                                <Trans>
                                    {additionalInfoPerPlanType(row, planType)}
                                </Trans>
                            </PricingTableAdditionalInfo>
                        </AnimateHeight>
                    </div>

                    <ChevronWrapper>
                        <Chevron
                            direction={
                                isOpened
                                    ? ChevronDirection.UP
                                    : ChevronDirection.DOWN
                            }
                        />
                    </ChevronWrapper>
                </>
            )}
        </PricingTableHeading>
    ) : null;
};
