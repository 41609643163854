import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { globalColors } from 'assets/globalColors';
import { breakPoints, minViewportHeightForScrollingNavBar, standardTransition } from 'config/layout/layout';
import { additionalNavbarDesktopBreakPoint, smallerDesktopNavbarHeight, tabletNavbarHeight } from 'pages/Main/Navbar/Navbar.styled';

export const limitedAccessBarHeight = '50px';

const Wrapper = styled.div<{ showBanner: boolean, scrollingDown: boolean }>`
    display: ${({ showBanner }) => (showBanner ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: sticky;
    height: 40px;
    left: 0;
    right: 0;
    z-index: 5;
    top: ${({ scrollingDown }) => (scrollingDown ? '-50px' : '98px')};
    color: ${globalColors.fontColors.white};
    background-color: ${globalColors.backgrounds.black};
    font-size: 17px;
    min-height: ${limitedAccessBarHeight};
    line-height: 1;
    font-weight: 300;
    ${standardTransition};

    @media screen and (max-width: ${additionalNavbarDesktopBreakPoint}px) { 
        top: ${({ scrollingDown }) => (scrollingDown ? '-40px' : smallerDesktopNavbarHeight)}; 
    } 

    @media screen and (max-width: ${breakPoints.tablet}px) {
        height: 45px;
        font-size: 14px;
        top: ${({ scrollingDown }) => (scrollingDown ? '-40px' : tabletNavbarHeight)}; 
        position: sticky; 
    } 

    @media screen and (max-width: ${breakPoints.mobile}px) {
        height: 65px;  
        font-size: 14px; 
        top: ${tabletNavbarHeight};
        top: ${({ scrollingDown }) => (scrollingDown ? '-65px' : tabletNavbarHeight)}; 
        position: sticky;
    } 

    @media screen and (max-height: ${minViewportHeightForScrollingNavBar}) {
        top: 0;
        position: relative;
    }
`;

const InnerWrapper = styled.div`
   color: ${globalColors.fontColors.white};
   padding: 20px 50px;
   text-align: center;

   @media screen and (max-width: ${breakPoints.mobile}px) {
         right: 25px;
         top: 18px;

    }
`;

const ReadMoreLink = styled(Link)`
   color: ${globalColors.backgrounds.green.base};
   min-width: 100px;
   font-weight: 400;
`;

const CloseButton = styled.div`
    div {
        position: absolute;
        right: 35px;
        top: 13px;
        border-radius: 25px ;
        height: 25px;
        width: 25px;

        @media screen and (max-width: ${breakPoints.mobile}px) {
         right: 25px;
         top: 18px;

    }
}`;

export default {
    Wrapper, InnerWrapper, ReadMoreLink, CloseButton
};
