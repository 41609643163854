import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { standardTransitionTime } from 'config/layout/layout';

export const StyledSwitcher = styled.div<{ isChecked: boolean }>`
   display: inline-block;
    width: 47px;
    height: 26px;
    border-radius: 13px;
    position: relative;
    transition: 0.3s;
    background-color: ${({ isChecked }) => (isChecked ? globalColors.backgrounds.green.base : '#b5b5b5')};
    cursor: pointer;

    &:after {
        width: 22px;
        height: 22px;
        border-radius: 11px;
        background-color: ${globalColors.backgrounds.white};
        position: absolute;
        left: ${({ isChecked }) => (!isChecked ? '3px' : '22px')};
        top: 0;
        bottom: 0;
        margin: auto;
        content: '';
        display: block;
        transition: ${standardTransitionTime};
    }
`;
