import styled from 'styled-components';

import {
    breakPoints,
    WELCOME_GIRL_ECLIPSE_Z_INDEX,
    WELCOME_GIRL_EST_BAL_TAB_Z_INDEX,
    WELCOME_GIRL_MONTH_AVAIL_TAB_Z_INDEX,
    WELCOME_GIRL_Z_INDEX,
} from 'config/layout/layout';

export const additionalDesktopWelcomeSectionBreakpoint1 = 1250;
export const additionalDesktopWelcomeSectionBreakpoint2 = 1200;
export const additionalDesktopWelcomeSectionBreakpoint3 = 1100;

export const additionalTabletWelcomeSectionBreakpoint1 = 920;
export const additionalTabletWelcomeSectionBreakpoint2 = 850;

export const WelcomeGirlContainer = styled.div`
    position: relative;
    height: 570px;
    width: 600px;

    @media screen and (max-width: ${additionalDesktopWelcomeSectionBreakpoint1}px) {
        transform: scale(0.9);
        margin-left: -20px;
    }

    @media screen and (max-width: ${additionalDesktopWelcomeSectionBreakpoint2}px) {
        transform: scale(0.85);
        margin-left: -60px;
        margin-top: -30px;
    }

    @media screen and (max-width: ${additionalDesktopWelcomeSectionBreakpoint3}px) {
        transform: scale(0.8);
        margin-left: -80px;
        margin-top: -80px;
    }

    @media screen and (max-width: ${additionalTabletWelcomeSectionBreakpoint1}px) {
        transform: scale(0.65);
        margin-left: -110px;
        margin-top: -90px;
    }

    @media screen and (max-width: ${additionalTabletWelcomeSectionBreakpoint2}px) {
        transform: scale(0.5);
        margin-left: -150px;
        margin-top: -135px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        margin: 0 auto;
        transform: scale(0.8);
        width: unset;
    }

    img.girl {
        position: absolute;
        z-index: ${WELCOME_GIRL_Z_INDEX};
        bottom: 114px;
        left: 44px;
    }

    svg.big-keo {
        position: absolute;
        height: 393px;
        left: 228px;
    }

    svg.eclipse {
        position: absolute;
        bottom: 97px;
        width: 365px;
        z-index: ${WELCOME_GIRL_ECLIPSE_Z_INDEX};
        left: 20px;
    }

    svg.monthly-available-limit-tab {
        position: absolute;
        width: 234px;
        top: 10px;
        left: 44px;
        z-index: ${WELCOME_GIRL_MONTH_AVAIL_TAB_Z_INDEX};
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4));
    }

    svg.estimated-balance-tab {
        position: absolute;
        width: 238px;
        bottom: 200px;
        z-index: ${WELCOME_GIRL_EST_BAL_TAB_Z_INDEX};
        right: 0;
        filter: drop-shadow(5px 7px 4px rgb(0 0 0 / 0.4));

        @media screen and (max-width: ${breakPoints.mobile}px) {
            left: 300px;
            right: unset;
        }
    }

    img.eclipse-shadow {
        position: absolute;
        z-index: 1;
        bottom: 0;
    }
`;

export const Gradient = styled.div`
    background: radial-gradient(
        circle closest-side at 70% 50%,
        #c2ff948a 0%,
        white 100%
    );
`;
