import React, { useRef } from 'react';

import { stringToAttributeString } from 'utils/string-tools';

import { ScrollListItem } from './ScrollListItem/ScrollListItem';
import { ScrollListWrapper } from './ScrollList.styled';
import { ScrollListProps } from './ScrollList.types';

const ScrollList: React.FC<ScrollListProps> = ({ list }) => {

    // const [activeItemIndex, updateActiveIndex] = useState(0);

    const scrollList = useRef(null);

    // Temporary comment out below animation. Maybe will be useful in the feature

    // const updateIndexHandler = (i: number) => {
    //     const maxIndex = i < list.length
    //         ? updateActiveIndex(i)
    //         : updateActiveIndex(list.length - 1);

    //     return maxIndex;
    // };

    // Animation handler - active indicator
    // const scrollHandler = () => {

    //     const heightPerItem = whyOkeoTrackLength / list.length;

    //     const distanceToTop = document.getElementById(ID.Smart)!.getBoundingClientRect().top;

    //     // eslint-disable-next-line no-plusplus
    //     for (let i = 0; i <= list.length; i++) {
    //         if (
    //             // Set the index as active if it's not too low and not too high at the same time.
    //             distanceToTop - heightPerItem < (whyOkeoTrackLength - ((i) * heightPerItem))
    //             && distanceToTop + heightPerItem > (heightPerItem * (list.length - i - 4))) {
    //             updateIndexHandler(i);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', scrollHandler, true);

    //     return () => {
    //         window.removeEventListener('scroll', scrollHandler, true);
    //     };
    // }, [activeItemIndex]);

    if (Array.isArray(list) && list.length > 0) {
        return (
            <ScrollListWrapper ref={scrollList}>
                {list.map(({ text }) => (
                    <ScrollListItem
                        text={text}
                        // isActive={index === activeItemIndex}
                        isActive={false}
                        key={`scroll-list-item-${stringToAttributeString(text)}`}
                    />
                ))}
            </ScrollListWrapper>
        );
    }

    // eslint-disable-next-line no-console
    console.warn('ScrollList: list is empty');

    return null;
};

export default ScrollList;
