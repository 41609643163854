import { Trans } from 'react-i18next';
import React, { useRef, useState } from 'react';

import {
    CardMaskMobilePng,
    CardMaskPng,
    CardPng,
    HandPng
} from 'assets/images';
import useOnScreen from 'hooks/useIsVisible';
import { TickSVG } from 'assets/icons/TickSVG';
import useWindowSize from 'hooks/useWindowSize';
import { ID } from 'config/layout/layout.types';
import { globalColors } from 'assets/globalColors';
import { Button } from 'components/atoms/Button/Button';
import { stringToAttributeString } from 'utils/string-tools';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './Card.styled';
import CardBanner from './CardBanner/CardBanner';
import { CardListElement, CardProps } from './Card.types';


export const Card: React.FC<CardProps> = ({ t }) => {

    const [bannerOpened, setBannerOpened] = useState(false);

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    const { width } = useWindowSize();
    const isCustomMobile = width && width <= 900;

    const cardInfoList: CardListElement[] = t('list', { returnObjects: true });

    const renderCardInfoItems = Object.values(cardInfoList).map((listElement) => (
        <li key={`personal-account-info-${stringToAttributeString(listElement.title)}`}>
            <TickSVG fill={globalColors.backgrounds.green.light!} />
            {listElement.title}
        </li>
    ));


    return (
        <SectionWrapper id={ID.Card}>
            <>
                <Styled.SectionWrapper>
                    <Styled.ContentWrapper>
                        <Styled.GreenLight src={isCustomMobile ? CardMaskMobilePng : CardMaskPng} />

                        <Styled.AnimationWrapper>
                            <img src={CardPng} className="card" alt="Levitating card" />

                            <img src={HandPng} className="hand" alt="Hand" />
                        </Styled.AnimationWrapper>

                        <Styled.TextWrapper>
                            <Styled.Title>
                                <div style={{ display: 'inline' }}>
                                    <Trans t={t} i18nKey="title.label" />
                                </div>

                                <Underline ref={ref} isVisible={isVisible} additionalStyles="padding-bottom: 5px;">
                                    {t('title.underlinedLabel')}
                                </Underline>
                            </Styled.Title>

                            <Styled.List>
                                {renderCardInfoItems}
                            </Styled.List>

                            <Button onClick={() => setBannerOpened(true)} label={t('buttons.signUp')} />
                        </Styled.TextWrapper>
                    </Styled.ContentWrapper>
                </Styled.SectionWrapper>

                <CardBanner opened={bannerOpened} closeAction={() => setBannerOpened(false)} />
            </>
        </SectionWrapper>
    );
};
