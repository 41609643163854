/* eslint-disable jsx-a11y/no-autofocus */
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useEffect } from 'react';

import useIpLocation from 'hooks/useIpLocation';
import Chevron from 'components/atoms/Chevron/Chevron';
import CountryFlag from 'components/atoms/CountryFlag/CountryFlag';
import { DropdownState } from 'components/atoms/Dropdown/Dropdown.types';
import { FlagName } from 'components/atoms/CountryFlag/CountryFlag.types';
import { ChevronDirection } from 'components/atoms/Chevron/Chevron.types';

import {
    DropdownContainer,
    DropdownLabel,
    DropdownOptionContainer,
    DropdownOptionIcon,
    DropdownOptions,
    DropdownOptionText,
    DropdownOptionWrapper,
    SearchInput,
    SelectedOptonWrapper,
} from './CountryDropdown.styled';
import { CountryOption, DropdownProps } from './CountryDropdown.types';
import { createCountriesOptions, filterCountries } from './CountryDropdown.helpers';


const CountryDropdown: React.FC<DropdownProps> = ({ onChange, label }) => {

    const ipLocalisation = useIpLocation();

    const { t } = useTranslation('dictionaries');
    const options = createCountriesOptions(t('countryCodes', { returnObjects: true }));
    const [currentOption, setCurrentOption] = React.useState<CountryOption | false>(false);

    useEffect(() => {
        if (ipLocalisation) {
            const matchingOption = options.find((option) => option.value === ipLocalisation);

            if (matchingOption) {
                setCurrentOption(matchingOption);
                onChange(matchingOption);
            }
        }
    }, [ipLocalisation]);

    const [dropdownState, setDropdownState] = React.useState(DropdownState.CLOSED);
    const isDropdownOpen = dropdownState === DropdownState.OPEN;

    const [searchText, setSearchText] = React.useState('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const toggleDropdown = () => {
        setDropdownState(
            dropdownState === DropdownState.CLOSED
                ? DropdownState.OPEN
                : DropdownState.CLOSED,
        );
    };

    const handleOptionClick = (option: CountryOption) => {
        setCurrentOption(option);
        onChange(option);
        toggleDropdown();
    };

    const handleDivFocus = () => {
        setDropdownState(DropdownState.OPEN);
    };

    const closeDropdown = () => {
        setDropdownState(DropdownState.CLOSED);
    };

    const filteredRecords = filterCountries(options, searchText);

    return (
        <DropdownContainer onMouseLeave={closeDropdown}>
            <DropdownOptionContainer onClick={toggleDropdown} tabIndex={0} onFocus={handleDivFocus}>

                <DropdownLabel active={isDropdownOpen || !!currentOption}>{label}</DropdownLabel>

                {isDropdownOpen ? (
                    <SearchInput
                        type="text"
                        name="text"
                        id="text"
                        autoFocus
                        value={searchText}
                        onChange={handleInputChange}
                    />
                ) : (
                    <SelectedOptonWrapper>
                        {currentOption && (
                            <>
                                <CountryFlag name={currentOption.value as FlagName} />

                                <div className="dropdown-label">{currentOption.name}</div>
                            </>
                        )}

                    </SelectedOptonWrapper>
                )}

                <div style={{ paddingRight: '12px' }}>
                    <Chevron
                        direction={
                            isDropdownOpen
                                ? ChevronDirection.UP
                                : ChevronDirection.DOWN
                        }
                    />
                </div>
            </DropdownOptionContainer>

            {isDropdownOpen && (
                <DropdownOptions tabIndex={0} onFocus={handleDivFocus} onBlur={closeDropdown}>
                    {filteredRecords.map((option) => (
                        <DropdownOptionWrapper
                            key={`${option.key}-${option.value}`}
                            onClick={() => handleOptionClick(option)}
                            className="dropdown-wrapper"
                        >
                            <DropdownOptionIcon>
                                <CountryFlag name={option.value as FlagName} />
                            </DropdownOptionIcon>

                            <DropdownOptionText>
                                {option.name}
                            </DropdownOptionText>
                        </DropdownOptionWrapper>
                    ))}
                </DropdownOptions>
            )}
        </DropdownContainer>
    );
};

export default CountryDropdown;
