import React, { useRef } from 'react';
import { SystemPng } from 'assets/images';
import useOnScreen from 'hooks/useIsVisible';

import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { TabList } from 'components/atoms/TabList/TabList';
import { ListElement } from 'components/atoms/TabList/TabList.types';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { ID } from 'config/layout/layout.types';

import {
    FaqContentAbsoluteImage,
    FaqContentWrapper,
    FaqRelativeWrapper,
    FaqTitle,
    FaqTitleBolded,
} from './Faq.styled';
import { FaqProps } from './Faq.types';

export const Faq: React.FC<FaqProps> = ({ t }) => {
    const faqList: ListElement[] = t('list', { returnObjects: true });

    const underLineRef = useRef();

    const isUnderLineVisible = useOnScreen(underLineRef);

    return (
        <SectionWrapper id={ID.FAQ} additionalStyles="overflow: hidden;">
            <FaqContentWrapper>
                <div>
                    <FaqTitle>{t('title.label')}</FaqTitle>
                    <FaqTitleBolded>
                        <Underline ref={underLineRef} isVisible={isUnderLineVisible}>
                            {t('title.labelBolded')}
                        </Underline>
                    </FaqTitleBolded>
                    <TabList t={t} listData={faqList} />
                    <div>
                        {/*  TODO: Move me to env variables */}
                        <a href="https://help.okeo.com/" target="_blank" rel="noreferrer"><ChevronButton label={t('buttons.check.label')} /></a>
                    </div>
                </div>

                <FaqRelativeWrapper>
                    <FaqContentAbsoluteImage src={SystemPng} alt={t('backgroundAlt.label')} />
                </FaqRelativeWrapper>
            </FaqContentWrapper>
        </SectionWrapper>
    );
};
