/* eslint-disable jsx-a11y/no-autofocus */
import React, { ChangeEvent } from 'react';

import Chevron from 'components/atoms/Chevron/Chevron';
import { ChevronDirection } from 'components/atoms/Chevron/Chevron.types';

import { DropdownState } from 'components/atoms/Dropdown/Dropdown.types';
import { filterCountries as filterCards } from 'components/atoms/CountryDropdown/CountryDropdown.helpers';
import {
    DropdownContainer,
    DropdownLabel,
    DropdownOptionContainer,
    DropdownOptions,
    DropdownOptionText,
    DropdownOptionWrapper,
    SearchInput,
    SelectedOptonWrapper,
} from './CardsDropdown.styled';
import { CardOption, DropdownProps } from './CardsDropdown.types';

const CardsDropdown: React.FC<DropdownProps> = ({ onChange, label }) => {

    const options = [
        { key: 'VIRTUAL_CARDS', value: 'VIRTUAL_CARDS', name: "Virtual card" },
        { key: 'PHYSICAL_CARDS', value: 'PHYSICAL_CARDS', name: "Physical card" }
    ];

    const [currentOption, setCurrentOption] = React.useState<CardOption | false>(false);

    const [dropdownState, setDropdownState] = React.useState(DropdownState.CLOSED);

    const [searchText, setSearchText] = React.useState('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const toggleDropdown = () => {
        setDropdownState(
            dropdownState === DropdownState.CLOSED
                ? DropdownState.OPEN
                : DropdownState.CLOSED,
        );
    };

    const handleOptionClick = (option: CardOption) => {
        setCurrentOption(option);
        onChange(option);
        toggleDropdown();
    };

    const handleDivFocus = () => {
        setDropdownState(DropdownState.OPEN);
    };

    const closeDropdown = () => {
        setDropdownState(DropdownState.CLOSED);
    };

    const isDropdownOpen = dropdownState === DropdownState.OPEN;

    const filteredOptions: CardOption[] = filterCards(options, searchText);

    return (
        <DropdownContainer onMouseLeave={closeDropdown}>
            <DropdownOptionContainer onClick={toggleDropdown} tabIndex={0} onFocus={handleDivFocus}>

                <DropdownLabel active={isDropdownOpen || !!currentOption}>{label}</DropdownLabel>

                {isDropdownOpen ? (
                    <SearchInput
                        type="text"
                        name="text"
                        id="text"
                        autoFocus
                        value={searchText}
                        onChange={handleInputChange}
                    />
                ) : (
                    <SelectedOptonWrapper>
                        {currentOption && (
                            <div className="dropdown-label">{currentOption.name}</div>
                        )}
                    </SelectedOptonWrapper>
                )}

                <div style={{ paddingRight: '12px' }}>
                    <Chevron
                        direction={
                            isDropdownOpen
                                ? ChevronDirection.UP
                                : ChevronDirection.DOWN
                        }
                    />
                </div>
            </DropdownOptionContainer>

            {isDropdownOpen && (
                <DropdownOptions tabIndex={0} onFocus={handleDivFocus} onBlur={closeDropdown}>
                    {filteredOptions.map((option) => (
                        <DropdownOptionWrapper
                            key={`${option.key}-${option.value}`}
                            onClick={() => handleOptionClick(option)}
                            className="dropdown-wrapper"
                        >
                            <DropdownOptionText>
                                {option.name}
                            </DropdownOptionText>
                        </DropdownOptionWrapper>
                    ))}
                </DropdownOptions>
            )}
        </DropdownContainer>
    );
};

export default CardsDropdown;
