import React from 'react';
import { Trans } from 'react-i18next';
import { globalColors } from 'assets/globalColors';
import { TickSVG } from 'assets/icons/TickSVG';
import {
    PricingTableAdditionalInfo,
    PricingTableCell, PricingTableHeading,
    PricingTableRow, PricingTableTitle,
    PricingTableWrapper
} from 'pages/Pricing/Pricing.styled';
import { PricingRowContent, PricingTableContent } from 'pages/Pricing/Pricing.types';

import { stringToAttributeString } from 'utils/string-tools';

// @ts-ignore
export const PricingTableDesktop: React.FC<PricingTableProps> = ({ translation, noHeader }) => translation && Object.values(translation)
    // @ts-ignore
    .map((table: PricingTableContent) => {

        const tableContent = table.content.map((
            row: PricingRowContent
        ) => (
            <PricingTableRow key={stringToAttributeString(row.headingColumn)}>
                <PricingTableHeading>
                    <div>
                        <Trans>
                            {row.headingColumn}
                        </Trans>
                    </div>

                    {/* TODO: Handle markup for tableCells and additionalInfo */}
                    {row.additionalInfo && (
                        <PricingTableAdditionalInfo>
                            <Trans>
                                {row.additionalInfo}
                            </Trans>
                        </PricingTableAdditionalInfo>
                    )}
                </PricingTableHeading>
                <PricingTableCell>
                    {row.column2 === '<greentick>' ? <TickSVG fill={globalColors.backgrounds.green.light!} /> : (
                        <Trans>
                            {row.column2}
                        </Trans>
                    )}
                </PricingTableCell>
                <PricingTableCell>
                    {row.column3 === '<yellowtick>' ? <TickSVG fill="#FFD00E" /> : (
                        <Trans>
                            {row.column3}
                        </Trans>
                    )}
                </PricingTableCell>
                <PricingTableCell>
                    {row.column4 === '<orangetick>' ? <TickSVG fill={globalColors.backgrounds.orange.base} /> : (
                        <Trans>
                            {row.column4}
                        </Trans>
                    )}
                </PricingTableCell>
            </PricingTableRow>
        ));

        return (
            <PricingTableWrapper>
                {!noHeader && <PricingTableTitle>{table.title}</PricingTableTitle>}
                {tableContent}
            </PricingTableWrapper>
        );
    });
