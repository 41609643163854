import { COOKIES_PREFERENCES_LOCAL_STORAGE_KEY, CookiesPreferencesFromLocalStorage } from "./CookiesPreferencesProvider.types";

const isValidCookiesPreferences = (obj: unknown): obj is CookiesPreferencesFromLocalStorage => (
    typeof obj === "object"
    && obj !== null
    && "preferences" in obj
    && "statistics" in obj
    && "marketing" in obj
    && typeof (obj as CookiesPreferencesFromLocalStorage).preferences === "boolean"
    && typeof (obj as CookiesPreferencesFromLocalStorage).statistics === "boolean"
    && typeof (obj as CookiesPreferencesFromLocalStorage).marketing === "boolean"
);

const getInitialCookiesPreferencesFromLocalStorageState = (): CookiesPreferencesFromLocalStorage | undefined => {
    const cookiesPreferences = localStorage.getItem(COOKIES_PREFERENCES_LOCAL_STORAGE_KEY);

    let result: CookiesPreferencesFromLocalStorage | undefined;

    if (cookiesPreferences) {
        try {
            const parsedPreferences = JSON.parse(cookiesPreferences);

            if (isValidCookiesPreferences(parsedPreferences)) {
                result = parsedPreferences;
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn("Error parsing cookies preferences:", error);
        }
    }

    return result;
};

export { getInitialCookiesPreferencesFromLocalStorageState };
