import React from 'react';

const TransferArrows: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="156.5" height="46" viewBox="0 0 156.5 46">
        <defs>
            <clipPath id="a"><rect width="22.159" height="8.273" transform="translate(0 0)" /></clipPath>
            <clipPath id="b"><rect width="9.721" height="1.754" transform="translate(0 0)" /></clipPath>
        </defs>
        <g transform="translate(-31 -3483)">
            <rect width="1" height="156.5" transform="translate(31 3505.5) rotate(-90)" fill="#dcdcdc" />
            <g transform="translate(86 3529) rotate(-90)">
                <g transform="translate(0)" fill="#f0f2ef" stroke="#dcdcdc" strokeWidth="1">
                    <circle cx="23" cy="23" r="23" stroke="none" />
                    <circle cx="23" cy="23" r="22.5" fill="none" />
                </g>
                <g transform="translate(11.135 13.134)">
                    <path d="M5.377,1.243,3.365,3.26H9.5V5.013H3.365L5.377,7.025,4.139,8.273,0,4.134,4.139,0Z" transform="translate(0 10.216)" fillRule="evenodd" />
                    <g transform="translate(0 0)" clipPath="url(#a)"><path d="M22.159,4.134,18.02,8.273,16.783,7.025l2.012-2.012H0V3.26H18.794L16.783,1.243,18.02,0Z" transform="translate(0 0)" fillRule="evenodd" /></g>
                    <g transform="translate(11.722 13.475)" clipPath="url(#b)"><path d="M9.721,1.754H7.846V0H9.721Zm-3.9,0H3.949V0H5.824ZM1.875,0V1.754H0V0Z" transform="translate(0 0)" fillRule="evenodd" /></g>
                </g>
            </g>
        </g>
    </svg>
);

export default TransferArrows;
