import { globalColors } from "assets/globalColors";
import styled from "styled-components";

const green = globalColors.backgrounds.green.light;
const lightGray = globalColors.backgrounds.grey.base;

export const rotatingAnimation = () => `
    @keyframes animate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    animation-name: animate;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
`;

const Spinner = styled.div<{ strokeWidth: number, size: number }>`
    position: relative;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    background: linear-gradient(${green}, ${lightGray}, ${lightGray});
    ${rotatingAnimation()}

    &:after {
        content: '';
        position: absolute;
        top: 5%;
        left: 5%;
        right: 5%;
        bottom: 5%;
        background: ${lightGray};
        border: solid white ${({ strokeWidth }) => strokeWidth}px;
        border-radius: 50%;
    }

    span {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: linear-gradient(${green}, ${lightGray}, ${lightGray});
        left: 0;
        right: 0;
        margin: auto;

        &:nth-child(1) {
        filter: blur(${({ strokeWidth }) => strokeWidth * 0.5}px);
        }

        &:nth-child(2) {
            filter: blur(${({ strokeWidth }) => strokeWidth}px);
        }

        &:nth-child(3) {
            filter: blur(${({ strokeWidth }) => strokeWidth * 2.5}px);
        }

        &:nth-child(4) {
            filter: blur(${({ strokeWidth }) => strokeWidth * 5}px);
        }
    }
`;

export default { Spinner };
