import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { languages, TranslationKey } from 'config/i18n/i18n.types';

import { getLanguagePreference } from 'utils/languagePreference';
import resources from '../../assets/locales';

import { i18nNamespaces } from './i18n.types';

i18n
    .use(initReactI18next)
    .init({
        lng: getLanguagePreference() || TranslationKey.EN,
        resources,
        debug: false,
        ns: i18nNamespaces,
        fallbackLng: TranslationKey.EN,
        supportedLngs: languages,
    });

export default i18n;
