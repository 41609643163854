import { useEffect, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useOnScreen(ref: any) {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    useEffect(() => {
        if (ref.current) observer.observe(ref.current);

        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    });

    return isIntersecting;
}
