import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } from 'config/environment';
import { removeHotjarCookies } from './Hotjar.helpers';

export const Hotjar: React.FC = () => {

    useEffect(() => {
        if (HOTJAR_ID && HOTJAR_SNIPPET_VERSION) {
            if (!window.location.href.includes('localhost')) {
                hotjar.initialize(Number(HOTJAR_ID), Number(HOTJAR_SNIPPET_VERSION));
            } else {
            // eslint-disable-next-line no-console
                console.warn(
                    'Hotjar is not initialized for localhost'
                );
            }
        }

        return () => {
            removeHotjarCookies();
        };

    }, []);

    return null;
};
