
import React from 'react';


import Styled from './PricingTypeSwitcher.styled';
import { PricingType } from '../Pricing.types';
import { SwitcherProps } from './PricingTypeSwitcher.types';


export const PricingTypeSwitcher: React.FC<SwitcherProps> = ({ pricingType, setPricingType }) => (
    <Styled.SwitcherWrapper>
        <Styled.SwitcherContentWrapper isChecked={pricingType === PricingType.EEA}>
            <div role="button" aria-hidden="true" onClick={() => setPricingType(PricingType.EEA)}>EEA</div>
            <div role="button" aria-hidden="true" onClick={() => setPricingType(PricingType.NONEEA)}>Non-EEA</div>
        </Styled.SwitcherContentWrapper>
    </Styled.SwitcherWrapper>
);
