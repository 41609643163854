import React, { FC } from 'react';
import { ProductAndFeeTermsPDF } from 'assets/files';
import { Circle } from 'assets/icons/Circle';

import { StyledFooterListElement } from './Footer.styled';
import { FooterListElememtValues } from './Footer.types';

const createFooterLink = (label: string, url: string) => {
    switch (url) {
        case 'product-and-fee-terms':
            return <a href={ProductAndFeeTermsPDF} target="_blank" rel="noreferrer">{label}</a>;

        default:
            return <a href={url} target="_blank" rel="noreferrer">{label}</a>;
    }
};

export const FooterListElement: FC<FooterListElememtValues> = ({ label, url }) => (
    <StyledFooterListElement>
        <Circle />
        {createFooterLink(label, url)}
    </StyledFooterListElement>
);
