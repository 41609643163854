import React, { useEffect } from 'react';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import ZendeskIcon from 'assets/icons/ZendeskIcon';

import { ZendeskChatProps } from 'components/atoms/ZendeskChat/ZendeskChat.types';
import { ZENDESK_KEY } from 'config/environment';
import { ID } from 'config/layout/layout.types';

import { ZendeskButton, ZendeskChatContainer } from './ZendeskChat.styled';

const isCookieBannerOpen = () => {
    const cookieBanner = document.getElementById(ID.CookieBanner);
    const isOpen = cookieBanner ? cookieBanner!.classList.contains('active') : false;

    return isOpen;
};

const onZendeskLoaded = () => {
    ZendeskAPI('webWidget', 'hide');
    // ZendeskAPI('webWidget', 'updateSettings', zendeskSettings);
    ZendeskAPI('webWidget:on', 'close', () => ZendeskAPI('webWidget', 'hide'));
};

const openZendeskChat = () => {
    ZendeskAPI('webWidget', 'open');
    ZendeskAPI('webWidget', 'show');
};

const ZendeskChat: React.FC<ZendeskChatProps> = () => {
    useEffect(() => {
        isCookieBannerOpen();
    }, []);

    return (
        <ZendeskChatContainer isCookieBannerOpen={isCookieBannerOpen()} id={ID.Zendesk}>
            <Zendesk
                defer
                zendeskKey={ZENDESK_KEY}
                onLoaded={() => onZendeskLoaded()}
            />
            <ZendeskButton
                type="button"
                onClick={() => openZendeskChat()}
                title="Open Zendesk Widget"
            >
                <ZendeskIcon />
            </ZendeskButton>
        </ZendeskChatContainer>
    );
};

ZendeskChat.defaultProps = {};

export default ZendeskChat;
