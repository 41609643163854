/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { AffiliateProgramPDF } from 'assets/files';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';

export const Affiliate: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>General Terms and Conditions of OKEO Affiliate Program</LegalTitle>

            <div>
                <Tooltip name="tnc-download-btn" label="Download Terms & Conditions">
                    <RoundUrlButton
                        primary
                        external
                        link={AffiliateProgramPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>Last update: 6 November 2024</LastUpdate>

        <LegalContent>
            <ol>
                <li><a href="#page_1">About OKEO</a></li>
                <li><a href="#page_2">Glossary</a></li>
                <li><a href="#page_3">Scope and Purpose</a></li>
                <li><a href="#page_4">Eligibility and Enrolment</a></li>
                <li><a href="#page_5">Affiliate Obligations</a></li>
                <li><a href="#page_6">Fees and Payment Terms</a></li>
                <li><a href="#page_7">Program Restrictions and Limitations</a></li>
                <li><a href="#page_8">Branding and Intellectual Property</a></li>
                <li><a href="#page_9">Confidentiality and Data Protection</a></li>
                <li><a href="#page_10">Indemnification and Liability</a></li>
                <li><a href="#page_11">Final Provisions</a></li>
            </ol>

            <h3 id="page_1" className="scroll-margin">1. About OKEO</h3>
            Okeo Payments UAB is a company incorporated and registered under the Laws of the Republic of
            Lithuania, with legal entity code 305219549, with registered address at Gedimino Ave. 20, LT-01103
            Vilnius, Lithuania, hereinafter referred to as OKEO.
            <br />
            <br />
            OKEO is a holder of
            {' '}
            <a href="http://https://www.lb.lt/en/licences-1/view_license?id=1967" target="_blank" rel="noopener noreferrer">Electronic Money Institution License No. 71</a>
. The supervising authority overseeing
            activities of OKEO is the Bank of Lithuania. The data about OKEO is collected and stored in the
            Register of Legal Entities of the Republic of Lithuania.
            <h3 id="page_2" className="scroll-margin">2. Glossary</h3>
            <div className="table-header table-1-5">
                <div>Term</div>
                <div>Meaning</div>
            </div>
            <div className="table-1-5">
                <div>Account</div>
                <div>An account opened within OKEO upon referral by Affiliate, held in the name of the Client.</div>
            </div>
            <div className="table-1-5">
                <div>Affiliate</div>
                <div>A business entity (regardless whether it has legal personality or not) which participates in the Affiliate Program.</div>
            </div>
            <div className="table-1-5">
                <div>Affiliate Cooperation Agreement</div>
                <div>An agreement between Affiliate and OKEO outlining the specific terms and conditions under which the Affiliate will participate in the Affiliate Program.</div>
            </div>
            <div className="table-1-5">
                <div>Affiliate Program, Program</div>
                <div>
                    A structured marketing initiative operated by OKEO in which Affiliates
                    are motivated to refer New Clients to OKEO.
                </div>
            </div>
            <div className="table-1-5">
                <div>Client</div>
                <div>
                    A business entity (regardless whether it has legal personality or not) to which the Services are provided.
                </div>
            </div>
            <div className="table-1-5">
                <div>Fees</div>
                <div>
                    Remuneration for effective referral of New Clients made under these
                    General Terms and Conditions and Affiliate Cooperation Agreement.
                </div>
            </div>
            <div className="table-1-5">
                <div>New Client</div>
                <div>
                    A business entity that has not previously opened an Account or
                    engaged in any services with OKEO prior to being referred by an
                    Affiliate through the Affiliate Program.
                </div>
            </div>
            <div className="table-1-5">
                <div>Party</div>
                <div>OKEO or the Affiliate (jointly referred to as the Parties).</div>
            </div>
            <div className="table-1-5">
                <div>SEPA Transfer</div>
                <div>
                    Euro-denominated transactions within the SEPA (Single Euro Payments
                    Area) network.
                </div>
            </div>
            <div className="table-1-5">
                <div>Services, OKEO Services</div>
                <div>
                    Electronic Money and Payment Services provided by OKEO to its Clients,
                    including the service of opening the Account in the name of the Client.
                </div>
            </div>


            <div className="list-table list-table-header">
                <div>3.</div>
                <div>
                    <h3 id="page_3" className="scroll-margin">Scope and Purpose</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>3.1</div>
                <div>
                    The purpose of the Affiliate Program is to establish a mutually beneficial partnership between
                    OKEO and the Affiliates. The Program aims to motivate Affiliates to refer New Clients to
                    OKEO, who will then open the Accounts and utilize OKEO Services. Through this
                    collaboration, Affiliates can earn commissions (Fees) based on the successful referrals of the
                    New Clients, while OKEO expands its Clients’ base and market reach.
                </div>
            </div>
            <div className="list-table">
                <div>3.2</div>
                <div>
                    This Program is designed exclusively for Affiliates that are business entities.
                </div>
            </div>
            <div className="list-table">
                <div>3.3</div>
                <div>
                    The relationship between OKEO and Affiliates is established by these General Terms and
                    Conditions, Affiliate Cooperation Agreement, the laws of the Republic of Lithuania, other
                    applicable laws and the principles of reasonableness, fairness and good faith.
                </div>
            </div>
            <div className="list-table">
                <div>3.4</div>
                <div>
                    These General Terms and Conditions come into force and become binding for the Affiliate
                    when the Affiliate and OKEO conclude the Affiliate Cooperation Agreement.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>4.</div>
                <div>
                    <h3 id="page_4" className="scroll-margin">Eligibility and Enrolment</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>4.1</div>
                <div>
                    To be eligible to participate in the Affiliate Program, the Affiliate must meet the following criteria:
                    <div className="list-table list-table-first">
                        <div>4.1.1</div>
                        <div>The Affiliate must be a legally registered business entity in its respective jurisdiction.</div>
                    </div>
                    <div className="list-table">
                        <div>4.1.2</div>
                        <div>The Affiliate must have a valid business registration number and tax identification number.</div>
                    </div>
                    <div className="list-table">
                        <div>4.1.3</div>
                        <div>If Affiliate runs a licensed activity, it must hold a valid license to conduct such activity.</div>
                    </div>
                    <div className="list-table">
                        <div>4.1.4</div>
                        <div>The Affiliate must not be engaged in any activities that are illegal or prohibited by applicable laws and regulations.</div>
                    </div>
                    <div className="list-table">
                        <div>4.1.5</div>
                        <div>The Affiliate must not have any prior record of fraud, misconduct, or breach of agreement with OKEO or any other financial institution.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>4.2</div>
                <div>
                    The enrolment process for the Affiliate Program is as follows:
                    <div className="list-table list-table-first">
                        <div>4.2.1</div>
                        <div>To apply for the Affiliate Program, interested legal entities must provide general information about their business as per OKEO request.</div>
                    </div>
                    <div className="list-table">
                        <div>4.2.2</div>
                        <div>OKEO will review the submitted information to verify the eligibility of the applicant. This process may include background checks and compliance reviews.</div>
                    </div>
                    <div className="list-table">
                        <div>4.2.3</div>
                        <div>During verification process OKEO may request the applicant to submit some documents to confirm applicant’s suitability for the Program as well as ask additional questions.</div>
                    </div>
                    <div className="list-table">
                        <div>4.2.4</div>
                        <div>OKEO will notify the applicant of the approval or rejection of their application via email. </div>
                    </div>
                    <div className="list-table">
                        <div>4.2.5</div>
                        <div>If the application is approved, the Affiliate and OKEO will sign the Affiliate Cooperation Agreement, which specifies the Fees and other relevant terms of cooperation agreed between the Parties.</div>
                    </div>
                    <div className="list-table">
                        <div>4.2.6</div>
                        <div>If the application is rejected, the applicant will be notified of the reasons for rejection. OKEO reserves the right to reject any application at its sole discretion, without providing detailed reasons.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>4.3</div>
                <div>
                    The Affiliate must continue to meet the eligibility criteria throughout their participation in the Program. OKEO reserves the right to conduct periodic reviews to ensure compliance.
                </div>
            </div>
            <div className="list-table">
                <div>4.4</div>
                <div>
                    Any changes to the Affiliate’s business status, ownership, or other material changes must be promptly reported to OKEO via e-mail.
                </div>
            </div>
            <div className="list-table">
                <div>4.5</div>
                <div>
                    OKEO reserves the right to suspend or terminate the Affiliate’s participation in the Program if the Affiliate is found to be in breach of any eligibility criteria or terms of these General Terms and Conditions or the Affiliate Cooperation Agreement.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>5.</div>
                <div>
                    <h3 id="page_5" className="scroll-margin">Affiliate Obligations</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>5.1</div>
                <div>
                    The Affiliate agrees to refer and recommend OKEO Services to third parties, in particular their own clients. These recommendations must be with the highest ethical standards and made in good faith with the belief that the referred client will benefit from the OKEO Services.
                </div>
            </div>
            <div className="list-table">
                <div>5.2</div>
                <div>
                    The Affiliate shall comply with all applicable laws and regulations in the jurisdictions where they operate. This includes, but is not limited to, compliance with anti-money laundering (AML) regulations, data protection laws, and financial regulations.
                </div>
            </div>
            <div className="list-table">
                <div>5.3</div>
                <div>
                    The Affiliate shall conduct its business in a manner that reflects positively on OKEO. This
                    includes:
                    <div className="list-table list-table-first">
                        <div>5.3.1</div>
                        <div>Providing accurate and truthful information about the OKEO Services.</div>
                    </div>
                    <div className="list-table">
                        <div>5.3.2</div>
                        <div>Avoiding any misrepresentation or misleading statements.</div>
                    </div>
                    <div className="list-table">
                        <div>5.3.3</div>
                        <div>Acting in the best interest of their clients when recommending OKEO Services.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>5.4</div>
                <div>
                    OKEO reserves the right to conduct periodic reviews of the Affiliate’s activities to ensure
                    compliance with the General Terms.
                </div>
            </div>
            <div className="list-table">
                <div>5.5</div>
                <div>
                    OKEO may audit the Affiliate’s records and practices related to the Affiliate Program to
                    ensure compliance with these General Terms and Conditions. The Affiliate shall undertake
                    reasonable efforts to facilitate such audit, including providing access to the relevant records.
                </div>
            </div>
            <div className="list-table">
                <div>5.6</div>
                <div>
                    The Affiliate must maintain open and effective communication channels with OKEO, including
                    promptly responding to any inquiries or requests for information received from OKEO.
                </div>
            </div>
            <div className="list-table">
                <div>5.7</div>
                <div>
                    To effectively receive Fees under the Affiliate Program, the Affiliate must provide a bank
                    account number eligible for receiving SEPA transfers and held in the Affiliate’s name.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>6.</div>
                <div>
                    <h3 id="page_6" className="scroll-margin">Fees and Payment Terms</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>6.1</div>
                <div>
                    Affiliates will earn Fees based on the successful referral of the New Clients. The Fees are
                    detailed in the Affiliate Cooperation Agreement.
                </div>
            </div>
            <div className="list-table">
                <div>6.2</div>
                <div>
                    A referral qualifies for a Fee only when the referred entity successfully becomes a New
                    Client.
                </div>
            </div>
            <div className="list-table">
                <div>6.3</div>
                <div>
                    The Fees will be calculated after the end of each calendar month, and payment will be made
                    to the Affiliate within 30 calendar days following the end of the month for which the Fees
                    are due.
                </div>
            </div>
            <div className="list-table">
                <div>6.4</div>
                <div>
                    OKEO will generate and provide regular reports detailing the status of referrals and the
                    Fees. These reports will be provided to Affiliate along with the payment of the Fees.
                </div>
            </div>
            <div className="list-table">
                <div>6.5</div>
                <div>
                    All Fees are paid in Euro via SEPA transfers to the bank account provided by the Affiliate.
                </div>
            </div>
            <div className="list-table">
                <div>6.6</div>
                <div>
                    The Affiliates are responsible for any taxes, duties, or other governmental charges imposed
                    on the Fees paid to them. OKEO may withhold taxes from the Fee payments if required by
                    the law. The Affiliate will be provided with documentation of any taxes withheld.
                </div>
            </div>
            <div className="list-table">
                <div>6.7</div>
                <div>
                    If a New Client’s Account is closed or reversed due to fraud, chargebacks, or non-compliance
                    with OKEO’s policies, the corresponding Fee will be deducted from future payments to the
                    Affiliate.
                </div>
            </div>
            <div className="list-table">
                <div>6.8</div>
                <div>
                    A minimum payout threshold of €25 is established. If the Affiliate’s Fees do not reach this
                    threshold, OKEO may, at its sole discretion, accumulate the Fees until the threshold is met,
                    at which point payment may be processed.
                </div>
            </div>
            <div className="list-table">
                <div>6.9</div>
                <div>
                    The Affiliate must report to OKEO any discrepancies in Fees calculations or payments within
                    30 calendar days of receiving the Fees statement. OKEO will review and resolve any reported
                    discrepancies or disputes within 10 business days. Adjustments will be made in the next
                    payment cycle if necessary.

                </div>
            </div>
            <div className="list-table">
                <div>6.10</div>
                <div>
                    Upon termination of the Affiliate’s participation in the Program, OKEO will process any
                    outstanding payments of Fees within 10 business days of termination, subject to the terms
                    outlined in these General Terms and Conditions.
                </div>
            </div>
            <div className="list-table">
                <div>6.11</div>
                <div>
                    Any Fees earned by the Affiliate may be forfeited if the Affiliate is found to be in violation
                    of the General Terms and Conditions as specified in clause 10.8 et seq.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>7.</div>
                <div>
                    <h3 id="page_7" className="scroll-margin">Program Restrictions and Limitations</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>7.1</div>
                <div>
                    Affiliates must not engage in any fraudulent activities to generate referrals, including
                    creating fake accounts, misrepresenting OKEO Services, or incentivizing potential New
                    Clients through misleading means.
                </div>
            </div>
            <div className="list-table">
                <div>7.2</div>
                <div>
                    Affiliates must not use unauthorized or inappropriate marketing methods, such as spamming,
                    unsolicited emails, or false advertising to promote OKEO Services.
                </div>
            </div>
            <div className="list-table">
                <div>7.3</div>
                <div>
                    Participation in the Program does not grant the Affiliate exclusive rights to promote OKEO
                    Services. OKEO reserves the right to engage with other affiliates, partners, or marketing
                    channels.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>8.</div>
                <div>
                    <h3 id="page_8" className="scroll-margin">Branding and Intellectual Property</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>8.1</div>
                <div>
                    The Affiliate is granted a non-exclusive, non-transferable, revocable license to use the OKEO
                    trademarks, logos, and other branding materials
                    {' '}
                    <b>(“OKEO Branding”)</b>
                    {' '}
solely for the purpose
                    of promoting the OKEO Services to their clients.
                </div>
            </div>
            <div className="list-table">
                <div>8.2</div>
                <div>
                    OKEO brand book specifying OKEO Branding will be shared with the Affiliate via e-mail or
                    other electronic means.
                </div>
            </div>
            <div className="list-table">
                <div>8.3</div>
                <div>
                    Any marketing or promotional materials created by the Affiliate that reference OKEO must
                    be approved by OKEO prior to their use.
                </div>
            </div>
            <div className="list-table">
                <div>8.4</div>
                <div>
                    The Affiliate shall not alter, modify, or create derivative works of OKEO Branding. The Affiliate
                    shall use OKEO Branding as provided, without changes to design, color, or proportions.
                </div>
            </div>
            <div className="list-table">
                <div>8.5</div>
                <div>
                    All intellectual property rights in OKEO Branding and any materials provided by OKEO,
                    including but not limited to trademarks, logos, content, and software, are properly licensed
                    to OKEO or constitute its exclusive property and shall remain the exclusive property of
                    OKEO or the respective licensors.
                </div>
            </div>
            <div className="list-table">
                <div>8.6</div>
                <div>
                    Nothing in these General Terms and Conditions shall be construed as transferring any
                    intellectual property rights to the Affiliate. The Affiliate acknowledges that it has no rights,
                    title, or interest in OKEO Branding or other OKEO’s intellectual property except as expressly
                    granted in these General Terms and Conditions.
                </div>
            </div>
            <div className="list-table">
                <div>8.7</div>
                <div>
                    The Affiliate shall take all reasonable steps to protect OKEO’s intellectual property rights and
                    shall promptly notify OKEO of any known or suspected infringement of OKEO’s intellectual
                    property.
                </div>
            </div>
            <div className="list-table">
                <div>8.8</div>
                <div>
                    The Affiliate shall use OKEO Branding and intellectual property solely for the purpose of
                    promoting OKEO Services under the Affiliate Program. Any other use is strictly prohibited
                    unless expressly authorized in writing by OKEO.
                </div>
            </div>
            <div className="list-table">
                <div>8.9</div>
                <div>
                    The Affiliate shall immediately inform OKEO of any unauthorized use of OKEO Branding or
                    other intellectual property by third parties.
                </div>
            </div>
            <div className="list-table">
                <div>8.10</div>
                <div>
                    Any unauthorized use or misuse of OKEO Branding or other intellectual property by the
                    Affiliate shall be considered a material breach of these General Terms and may result in
                    termination of the Affiliate’s participation in the Program and legal action.
                </div>
            </div>

            <div className="list-table">
                <div>8.11</div>
                <div>
                    Upon termination or expiration of the Affiliate Cooperation Agreement or the entire Affiliate
                    Program, the license to use OKEO Branding shall immediately terminate. The Affiliate shall
                    cease all use of OKEO Branding and return or destroy any materials containing OKEO
                    Branding as instructed by OKEO.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>9.</div>
                <div>
                    <h3 id="page_9" className="scroll-margin">Confidentiality and Data Protection</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>9.1</div>
                <div>
                    The Parties agree to keep confidential all information exchanged during the course of
                    the Affiliate Program. This includes, but is not limited to, business strategies, financial data,
                    client lists, referral details, and proprietary information
                    {' '}
                    <b>("Confidential Information").</b>
                </div>
            </div>
            <div className="list-table">
                <div>9.2</div>
                <div>
                    The Parties shall use Confidential Information solely to perform the obligations under
                    the Affiliate Program. The Parties shall not disclose any Confidential Information to any third
                    parties without the prior written consent of the other Party, except as required by law.
                </div>
            </div>
            <div className="list-table">
                <div>9.3</div>
                <div>
                    The Parties agree to implement reasonable measures to protect the confidentiality of
                    the Confidential Information, ensuring it is not disclosed, misused, or compromised.
                </div>
            </div>
            <div className="list-table">
                <div>9.4</div>
                <div>
                    Confidential Information does not include information that:
                    <div className="list-table list-table-first">
                        <div>9.4.1</div>
                        <div>Is or becomes publicly known without the breach of these General Terms by the Party;</div>
                    </div>
                    <div className="list-table">
                        <div>9.4.2</div>
                        <div>Is lawfully received from a third party without any obligation of confidentiality;</div>
                    </div>
                    <div className="list-table">
                        <div>9.4.3</div>
                        <div>Is independently developed by a Party without reference to the Confidential Information.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>9.5</div>
                <div>
                    The confidentiality obligations of the Parties under these General Terms and Conditions shall
                    continue to be in effect for a period of 5 years after the termination or expiration of
                    the cooperation between the Parties.
                </div>
            </div>
            <div className="list-table">
                <div>9.6</div>
                <div>
                    Upon termination of the cooperation, the Parties shall return or securely destroy all
                    Confidential Information, unless retention is required by law or for legitimate business
                    purposes.
                </div>
            </div>
            <div className="list-table">
                <div>9.7</div>
                <div>
                    The Parties shall comply with all applicable data protection and privacy laws, including but
                    not limited to the General Data Protection Regulation (GDPR) and other relevant legislations.
                </div>
            </div>
            <div className="list-table">
                <div>9.8</div>
                <div>
                    The Parties shall ensure that any personal data is collected, processed, and stored securely
                    and in compliance with applicable data protection laws.
                </div>
            </div>
            <div className="list-table">
                <div>9.9</div>
                <div>
                    The Parties shall uphold the rights of data subjects, including rights to access, rectify, erase
                    personal data, and restrict or object to processing.
                </div>
            </div>
            <div className="list-table">
                <div>9.10</div>
                <div>
                    The Parties shall implement appropriate technical and organizational measures to protect
                    personal data against unauthorized access, accidental loss, destruction, or damage.
                </div>
            </div>
            <div className="list-table">
                <div>9.11</div>
                <div>
                    In the event of a data breach involving personal data related to the Affiliate Program, a
                    Party shall promptly notify the other Party and take all necessary steps to mitigate the impact
                    and comply with legal notification requirements.
                </div>
            </div>
            <div className="list-table">
                <div>9.12</div>
                <div>
                    OKEO is committed to protecting the personal data of its clients and partners. OKEO data
                    protection practices are governed by its Privacy Policy (
                    <a href="https://okeo.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">https://okeo.com/legal/privacy-policy</a>
). The Affiliate is encouraged to review the OKEO Privacy Policy to understand how
                    OKEO collects, uses, and protects the personal data.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>10.</div>
                <div>
                    <h3 id="page_10" className="scroll-margin">Indemnification and Liability</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>10.1</div>
                <div>
                    The Affiliate agrees to indemnify, defend, and hold harmless OKEO, its officers, directors,
                    employees, and agents from and against any and all claims, liabilities, damages, losses, and
                    expenses (including reasonable attorneys’ fees) arising out of or in any way connected with:
                    <div className="list-table list-table-first">
                        <div>10.1.1</div>
                        <div>The Affiliate’s participation in the Program,</div>
                    </div>
                    <div className="list-table">
                        <div>10.1.2</div>
                        <div>Any breach of these General Terms and the Affiliate Cooperation Agreement by the Affiliate;</div>
                    </div>
                    <div className="list-table">
                        <div>10.1.3</div>
                        <div>Any violation of applicable laws or regulations by the Affiliate;</div>
                    </div>
                    <div className="list-table">
                        <div>10.1.4</div>
                        <div>Any infringement of third-party rights, including intellectual property rights, by the Affiliate;</div>
                    </div>
                    <div className="list-table">
                        <div>10.1.5</div>
                        <div>Any negligent or wrongful acts or omissions by the Affiliate, its employees, or agents.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>10.2</div>
                <div>
                    OKEO makes no guarantees regarding the Affiliate’s earnings under this Affiliate Program.
                    The Affiliate acknowledges that earnings depend on the actual utilization level of OKEO
                    Services by the New Clients as specified in the Affiliate Cooperation Agreement as well as
                    acceptance of the referred entities as the New Clients by OKEO.
                </div>
            </div>
            <div className="list-table">
                <div>10.3</div>
                <div>
                    OKEO shall not be liable for any indirect, incidental, special, or consequential damages arising
                    out of or in connection with the Affiliate’s participation in the Program, including but not
                    limited to, loss of revenue, profits, or business opportunities, even if OKEO has been advised
                    of the possibility of such damages.
                </div>
            </div>
            <div className="list-table">
                <div>10.4</div>
                <div>
                    OKEO total liability to the Affiliate for any claims arising out of or relating to the Program
                    shall not exceed the total amount of Fees paid to the Affiliate in the six (6) months preceding
                    the event giving rise to the claim.
                </div>
            </div>
            <div className="list-table">
                <div>10.5</div>
                <div>
                    OKEO provides the Affiliate Program on an "as is" and "as available" basis. OKEO disclaims
                    all warranties, express or implied, including but not limited to, implied warranties of
                    merchantability, fitness for a particular purpose, and non-infringement.
                </div>
            </div>
            <div className="list-table">
                <div>10.6</div>
                <div>
                    OKEO shall not be liable for any actions or omissions of the third parties, including New
                    Clients, that may affect the Affiliate’s participation in the Program.
                </div>
            </div>

            <div className="list-table">
                <div>10.7</div>
                <div>
                    Any claim or cause of action arising out of or relating to the Affiliate Program must be filed
                    within one (1) year after such claim or cause of action arose, or it shall be forever barred.
                </div>
            </div>
            <div className="list-table">
                <div>10.8</div>
                <div>
                    The following violations committed by the Affiliate may result in applying by OKEO a
                    contractual penalty in the amount of €5,000 for each breach:
                    <div className="list-table">
                        <div>10.8.1</div>
                        <div>
                            Non-compliance with these General Terms and Conditions or the Affiliate
                            Cooperation Agreement;
                        </div>
                    </div>
                    <div className="list-table">
                        <div>10.8.2</div>
                        <div>
                            Breach of marketing guidelines provided by OKEO;
                        </div>
                    </div>
                    <div className="list-table">
                        <div>10.8.3</div>
                        <div>
                            Breach of confidentiality obligations outlined in these General Terms and Conditions;
                        </div>
                    </div>
                    <div className="list-table">
                        <div>10.8.4</div>
                        <div>
                            Breach of any other contractual obligations under these General Terms and
                            Conditions and the Affiliate Cooperation Agreement.
                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>10.9</div>
                <div>
                    Contractual Penalties shall be enforced by OKEO upon becoming aware of any violation
                    committed by the Affiliate.
                </div>
            </div>
            <div className="list-table">
                <div>10.10</div>
                <div>
                    The contractual penalties for any breach shall include the forfeiture of any Fees due to the
                    Affiliate. OKEO shall have the right to withhold payment of any Fees in the event of a breach.
                </div>
            </div>
            <div className="list-table">
                <div>10.11</div>
                <div>
                    If the actual damage suffered by OKEO as a result of the Affiliate's breach exceeds the
                    amount of the contractual penalty (i.e., the forfeited Fees), OKEO reserves the right to seek
                    full compensation from the Affiliate for the total amount of damages incurred.
                </div>
            </div>


            <div className="list-table list-table-header">
                <div>11.</div>
                <div>
                    <h3 id="page_11" className="scroll-margin">Final Provisions</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>11.1</div>
                <div>
                    These terms and Conditions shall be governed by and construed in accordance with the
                    laws of the Republic of Lithuania. Any disputes arising out of or in connection with the
                    General Terms shall be resolved by the court having jurisdiction over the seat of OKEO.
                </div>
            </div>
            <div className="list-table">
                <div>11.2</div>
                <div>
                    OKEO reserves the right to modify or amend the General Terms and Conditions at any time.
                    Affiliates will be notified of any significant changes through email at least 60 calendar days
                    before such changes take effect. Continued participation in the Affiliate Program implies
                    the acceptance of the revised General Terms and Conditions by the Affiliate.
                </div>
            </div>
            <div className="list-table">
                <div>11.3</div>
                <div>
                    If any provision of the General Terms and Conditions is found to be invalid, illegal, or
                    unenforceable by a court of competent jurisdiction, such provision shall be deemed modified
                    to the extent necessary to render it enforceable without losing its intent, or, if no such
                    modification is possible, shall be severed from the General Terms. The remaining provisions
                    shall continue in full force and effect.
                </div>
            </div>
            <div className="list-table">
                <div>11.4</div>
                <div>
                    Neither Party shall be liable for any failure or delay in the performance of its obligations
                    under the General Terms if such failure or delay is due to causes beyond its reasonable
                    control, including but not limited to, acts of God, natural disasters, war, terrorism, labor
                    strikes, epidemics, pandemics, or governmental actions. The Party affected by a force
                    majeure event shall promptly notify the other Party of the occurrence of such event and
                    shall use all reasonable efforts to mitigate the effects of the force majeure event on its
                    performance of the General Terms.
                </div>
            </div>
            <div className="list-table">
                <div>11.5</div>
                <div>
                    For any questions or concerns regarding the Affiliate Program or the General Terms and
                    Conditions, Affiliates may contact OKEO at:
                    {' '}
                    <a href="mailto:hello@okeo.com">hello@okeo.com</a>
.
                </div>
            </div>
        </LegalContent>

    </LegalWrapper>
);
