/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Trans } from 'react-i18next';
import React, { useRef, useState } from 'react';

import useOnScreen from 'hooks/useIsVisible';
import { ID } from 'config/layout/layout.types';
import { breakPoints } from 'config/layout/layout';
import { Button } from 'components/atoms/Button/Button';
import { PricingPersonalSectionPng } from 'assets/images';
import { stringToAttributeString } from 'utils/string-tools';
import { CircledTickSVG } from 'assets/icons/CircledTickSVG';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { ONBOARDING_PERSONAL_URL } from 'config/environment';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './PricingPersonal.styled';
import { PricingPersonalListElement, PricingPersonalProps, PricingTextListElement } from './PricingPersonal.types';


export const PricingPersonal: React.FC<PricingPersonalProps> = ({ t }) => {

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    const [residencyType, setResidencyType] = useState(true);

    const textList: PricingTextListElement[] = t('textList', { returnObjects: true });
    const pricingList: PricingPersonalListElement[] = t('pricingList', { returnObjects: true });
    const pricingListEEA: PricingPersonalListElement[] = t('pricingListEEA', { returnObjects: true });

    const selectedPricingList = residencyType ? pricingListEEA : pricingList;

    const renderItems = Object.values(selectedPricingList).map((listElement) => (
        <li key={`personal-PricingPersonal-info-${stringToAttributeString(listElement.title)}`}>
            <div className="title">{listElement.title}</div>
            <div className="description">{listElement.description}</div>
        </li>
    ));

    const textListItems = Object.values(textList).map((listElement) => (
        <li key={`personal-PricingPersonal-text-${stringToAttributeString(listElement.title)}`}>
            <CircledTickSVG />

            <div className="title">{listElement.title}</div>
        </li>
    ));

    const residencySwitcher = (
        <Styled.SwitcherWrapper>
            <Styled.SwitcherContentWrapper isChecked={residencyType}>
                <div role="button" onClick={() => setResidencyType(true)}>EEA Resident</div>

                <div role="button" onClick={() => setResidencyType(false)}>World (Non-EEA)</div>
            </Styled.SwitcherContentWrapper>
        </Styled.SwitcherWrapper>
    );

    return (
        <SectionWrapper id={ID.PricingPersonal} additionalStyles="overflow: hidden; display: flex; justify-content: center; background-color: #F0F2EF;">
            <>
                <Styled.Title>
                    <div>
                        <Trans t={t} i18nKey="title.label" />
                    </div>
                    <Underline ref={ref} isVisible={isVisible}>
                        {t('title.underlinedLabel')}
                    </Underline>
                </Styled.Title>

                <Styled.UpperContent>
                    <picture>
                        <source srcSet={PricingPersonalSectionPng} media={`(max-width: ${breakPoints.tablet}px)`} />
                        <img src={PricingPersonalSectionPng} alt="zero-euro" />
                    </picture>

                    <ul>
                        {textListItems}
                    </ul>
                </Styled.UpperContent>

                <Styled.SwitcherContainer>
                    {residencySwitcher}
                </Styled.SwitcherContainer>

                <Styled.ContentWrapper>
                    <Styled.LowerContent>
                        <Styled.SwitcherContainer mobile>
                            {residencySwitcher}
                        </Styled.SwitcherContainer>

                        <ul>
                            {renderItems}
                        </ul>
                    </Styled.LowerContent>
                </Styled.ContentWrapper>

                <Styled.ButtonsWrapper>
                    {/* <a href={PricingPersonalSectionPng} download> */}
                    <Button
                        disabled
                        label={t('buttons.download.label')}
                        // buttonTheme={ButtonTheme.OUTLINE_ORANGE}
                    />
                    {/* </a> */}

                    <a href={ONBOARDING_PERSONAL_URL} target="_blank" rel="noreferrer">
                        <Button label={t('buttons.join.label')} />
                    </a>
                </Styled.ButtonsWrapper>
            </>
        </SectionWrapper>
    );
};
