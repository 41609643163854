import React from 'react';
import ArrowDown from 'assets/icons/ArrowDown';

import ChevronContainer from './Chevron.styled';
import { ChevronProps } from './Chevron.types';

const Chevron: React.FC<ChevronProps> = ({
    direction, fillColor, margin, width, height,
}) => (
    <ChevronContainer direction={direction} margin={margin}>
        <ArrowDown fill={fillColor} width={width} height={height} />
    </ChevronContainer>
);

export default Chevron;
