import styled from 'styled-components';

import { breakPoints } from 'config/layout/layout';

const NavbarButtonsContainer = styled.div`
    @media (max-width: ${breakPoints.mobile}px) {
        padding-bottom: 20px;
        margin: 0 -15px;
        display: flex;
        justify-content: space-evenly;

        button {
            font-size: 13px;
            padding: 0 20px;
            margin: 0;
        }
    }
`;

export { NavbarButtonsContainer };
