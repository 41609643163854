import styled from 'styled-components';

import {
    breakPoints,
    desktopHorizontalPadding,
    mainWidth,
    minViewportHeightForScrollingNavBar,
    NAVBAR_CONTAINER_Z_INDEX,
} from 'config/layout/layout';

export const additionalNavbarDesktopBreakPoint = 1600;

export const desktopNavbarHeight = '98px';
export const smallerDesktopNavbarHeight = '78px';
export const tabletNavbarHeight = '66px';

const NavbarContainer = styled.header`
    position: sticky;
    top: 0;
    left: 0;
    background: white;
    z-index: ${NAVBAR_CONTAINER_Z_INDEX};
    box-shadow: #0000000a 1px 1px 8px;
    max-width: 100vw;

    @media screen and (max-height: ${minViewportHeightForScrollingNavBar}) {
        position: relative;
    }
`;

const NavbarContent = styled.div`
    height: ${desktopNavbarHeight};
    width: 100%;
    max-width: ${mainWidth}px;
    padding: 0 ${desktopHorizontalPadding}px;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-gap: 20px;
    margin: auto;
    align-items: center;
    z-index: ${NAVBAR_CONTAINER_Z_INDEX};

    @media screen and (max-width: ${additionalNavbarDesktopBreakPoint}px) {
        height: ${smallerDesktopNavbarHeight};
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: auto 1fr;
        padding: 0 80px;
        height: ${tabletNavbarHeight};
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 0 35px;
    }
 
    @media screen and (max-width: 380px) {
        padding: 0 25px 0 20px;
    }
`;

const NavbarTabletMenu = styled.div``;

const NavbarMobileMenu = styled.div``;

export {
    NavbarContainer, NavbarContent, NavbarTabletMenu, NavbarMobileMenu
};
