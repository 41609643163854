import React from 'react';

import { Seo } from 'utils/Seo';

import Faq from './sections/FAQ/Faq';
import Smart from './sections/Smart/Smart';
import Welcome from './sections/Welcome/Welcome';
import WhyOkeo from './sections/WhyOkeo/WhyOkeo';
import Empower from './sections/Empower/Empower';
import { HomeProps } from './Home.types';

export const Home: React.FC<HomeProps> = () => (
    <>
        <Seo />
        <Welcome />
        <WhyOkeo />
        <Smart />
        <Empower />
        <Faq />
    </>
);
