/* eslint-disable consistent-return */
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { TFunction } from 'react-i18next';
import Pin from 'assets/icons/Pin';
import { LocationMark, LocationMarkActive } from 'assets/images';
import useOnScreen from 'hooks/useIsVisible';
import {
    handlePinColor, MAP_CONFIG, mapLanguage, markers, zoom
} from 'pages/Contact/Map/Map.helpers';

import { TranslationWithMarkup } from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup.layout';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { GOOGLE_MAP_KEY } from 'config/environment';

import {
    AdvancedMarker, APIProvider, Map, MapCameraChangedEvent, MapCameraProps
} from '@vis.gl/react-google-maps';

import Styled from './Map.styled';


export const MapComponent: React.FC<{ t: TFunction }> = ({ t }) => {
    const [activeMarker, setActiveMarker] = useState(1);
    const [cameraProps, setCameraProps] = useState<MapCameraProps>({ center: markers[activeMarker], zoom });

    const handleCameraChange = React.useCallback((ev: MapCameraChangedEvent) => setCameraProps(ev.detail), []);

    const text = (key: string) => t(`mapSection.${key}`);

    useEffect(() => {
        setCameraProps({ ...cameraProps, center: markers[activeMarker] });
    }, [activeMarker]);


    const ref = useRef();
    const isVisible = useOnScreen(ref);

    return (
        <Styled.MapSection>
            <Styled.InfoBox>
                <Styled.Title>{text('title')}</Styled.Title>
                <Styled.Address>
                    <TranslationWithMarkup translationKey={text('address')} />
                </Styled.Address>
                <div>
                    <TranslationWithMarkup translationKey={text('information')} />
                </div>
                <Styled.UnderlinedLabel>
                    <Underline ref={ref} isVisible={isVisible}>
                        {text('underlinedLabel')}
                    </Underline>
                </Styled.UnderlinedLabel>

                <Styled.OfficeBox
                    onClick={() => setActiveMarker(0)}
                    fill={handlePinColor(activeMarker === 0)}
                >

                    <Styled.PinWrapper>
                        <Pin />
                    </Styled.PinWrapper>

                    <div>
                        <TranslationWithMarkup translationKey={text('office.1')} />
                    </div>
                </Styled.OfficeBox>

                <Styled.OfficeBox
                    onClick={() => setActiveMarker(1)}
                    fill={handlePinColor(activeMarker === 1)}
                >
                    <Styled.PinWrapper>
                        <Pin />
                    </Styled.PinWrapper>

                    <div>
                        <TranslationWithMarkup translationKey={text('office.2')} />
                    </div>
                </Styled.OfficeBox>
            </Styled.InfoBox>

            <APIProvider apiKey={GOOGLE_MAP_KEY} language={mapLanguage}>
                <Map
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...cameraProps}
                    mapId={MAP_CONFIG.mapId}
                    style={{ height: '100%', transition: '0.3s' }}
                    gestureHandling="greedy"
                    disableDefaultUI
                    onCameraChanged={handleCameraChange}

                >
                    {markers.map((latLng, index) => (
                        <AdvancedMarker
                            key={latLng.title}
                            onClick={() => setActiveMarker(index)}
                            position={latLng}
                            icon={latLng.lat === markers[activeMarker].lat ? LocationMarkActive : LocationMark}

                        >
                            <img alt="pin" src={latLng.lat === markers[activeMarker].lat ? LocationMarkActive : LocationMark} />
                        </AdvancedMarker>
                    ))}
                </Map>
            </APIProvider>
        </Styled.MapSection>
    );
};
