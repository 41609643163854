import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import { ContactTab } from '../ContactTab/ContactTab';
import { ContactTabCarousselProps } from '../ContactTabs.types';

export const ContactTabsCaroussel: React.FC<ContactTabCarousselProps> = ({ options, contactTiles }) => (
    <Splide
        options={options}
        className="contact-tabs"
    >
        {contactTiles.map((tile, index) => (
            <SplideSlide>
                <ContactTab tile={tile} index={index} />
            </SplideSlide>
        ))}
    </Splide>
);
