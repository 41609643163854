import { Dispatch, SetStateAction } from "react";

const COOKIES_PREFERENCES_LOCAL_STORAGE_KEY = "cookies_preferences";

interface CookiesPreferencesFromLocalStorage {
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
}

interface CookiesPreferencesProviderProps {
    children: React.ReactNode;
}

const defaultCookiesPreferences: CookiesPreferencesFromLocalStorage = {
    preferences: false,
    statistics: false,
    marketing: false,
};

interface CookiesPreferencesContextProps {
    cookiesPreferences: CookiesPreferencesFromLocalStorage | undefined
    setCookiesPreferences: Dispatch<SetStateAction<CookiesPreferencesFromLocalStorage | undefined>>
}

export {
    defaultCookiesPreferences,
    COOKIES_PREFERENCES_LOCAL_STORAGE_KEY
};

export type { CookiesPreferencesContextProps, CookiesPreferencesProviderProps, CookiesPreferencesFromLocalStorage };

