import React, { useEffect } from 'react';
// import { useDetectClickOutside } from 'react-detect-click-outside';

import Chevron from '../Chevron/Chevron';
import { ChevronDirection } from '../Chevron/Chevron.types';

import {
    DropdownContainer,
    DropdownOptionContainer,
    DropdownOptionIcon,
    DropdownOptions,
    DropdownOptionText,
    DropdownOptionWrapper,
} from './Dropdown.styled';
import { DropdownOption, DropdownProps, DropdownState } from './Dropdown.types';

const Dropdown: React.FC<DropdownProps> = ({
    options, onOptionSelect, selected, displayLabel
}) => {

    const [currentOption, setCurrentOption] = React.useState(selected);
    const [dropdownState, setDropdownState] = React.useState(
        DropdownState.CLOSED,
    );

    useEffect(() => {
        setCurrentOption(selected);
    }, [selected]);

    const handleToggle = () => {
        setDropdownState(
            dropdownState === DropdownState.CLOSED
                ? DropdownState.OPEN
                : DropdownState.CLOSED,
        );
    };

    const handleOptionClick = (option: DropdownOption) => {
        setCurrentOption(option);
        onOptionSelect(option);
        handleToggle();
    };

    // const closeDropdown = () => {
    //     setDropdownState(DropdownState.CLOSED);
    // };

    // const ref = useDetectClickOutside({ onTriggered: closeDropdown });

    return (
        <DropdownContainer>
            <DropdownOptionContainer onClick={handleToggle} displayLabel={displayLabel}>
                <DropdownOptionIcon className="dropdown-icon">{currentOption.icon}</DropdownOptionIcon>

                {displayLabel && <div className="dropdown-label">{currentOption.label}</div>}

                <Chevron
                    direction={
                        dropdownState === DropdownState.OPEN
                            ? ChevronDirection.UP
                            : ChevronDirection.DOWN
                    }
                />
            </DropdownOptionContainer>

            {dropdownState === DropdownState.OPEN && (
                <DropdownOptions>
                    {options.map((option) => (
                        <DropdownOptionWrapper
                            key={`${option.label}-${option.value}`}
                            onClick={() => handleOptionClick(option)}
                            className="dropdown-wrapper"
                        >
                            <DropdownOptionText>
                                {displayLabel ? option.label : option.value}
                            </DropdownOptionText>

                            <DropdownOptionIcon>
                                {option.icon}
                            </DropdownOptionIcon>
                        </DropdownOptionWrapper>
                    ))}
                </DropdownOptions>
            )}
        </DropdownContainer>
    );
};

export default Dropdown;
