import { globalColors } from 'assets/globalColors';
import {
    additionalNavbarDesktopBreakPoint, desktopNavbarHeight, smallerDesktopNavbarHeight, tabletNavbarHeight
} from 'pages/Main/Navbar/Navbar.styled';
import styled, { css } from 'styled-components';

import { breakPoints } from 'config/layout/layout';

export const EmpowerTitle = styled.div`
    font-size: 29px;
    line-height: 38px;
    font-weight: 400;
    position: relative;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 21px;
        max-width: 490px;
        margin: 0 auto;
    }
`;

export const EmpowerText = styled.div`
    font-size: 19px;
    line-height: 27px;
    font-weight: 300;
    padding: 50px 0 60px;
    position: relative;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 14px;
        line-height: 22px;
        padding: 35px 0;
        max-width: 455px;
        margin: 0 auto;
    }
`;

export const empowerBackgroundStyles = css`
    background-size: clamp(90vw, 1900px, 180vw) auto;
    background-position-x: 44%;
    background-position-y: ${desktopNavbarHeight};
    background-attachment: fixed;

    overflow: hidden;

    @media (max-width: ${additionalNavbarDesktopBreakPoint}px) {
        background-position-y: ${smallerDesktopNavbarHeight};
    }

    @media (max-width: 1100px) {
        background-size: clamp(130vw,1900px,150vw) auto;
        background-position-y: ${tabletNavbarHeight};
    }

    @media (max-width: 500px) {
        background-size: clamp(130vw,1900px,270vw) auto;
        background-position-x: 45%;
    }
`;

export const scrolledEmpowerBackgroundStyles = css`
    background-size: clamp(90vw, 1900px, 180vw) auto;
    background-position-x: calc(44% - 8px);
    background-position-y: 0;
    background-attachment: initial;

    overflow: hidden;

    @media (max-width: 1100px) {
        background-size: clamp(130vw, 1900px, 150vw) auto;
    }

    @media (max-width: 500px) {
        background-size: clamp(130vw, 1900px, 270vw) auto;
        background-position-x: 45%;
    }
`;

export const EmpowerContentWrapper = styled.div`
    color: ${globalColors.fontColors.black};
    text-align: center;
    padding: 20px 0 75px;
    max-width: 620px;
    margin: 0 auto;
    position: relative;

    &:before {
        content: '';
        display: block;
        width: 100vw;
        position: absolute;
        height: calc(100% + 105px);
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        background: linear-gradient(
            180deg,
            rgba(241,241,241,0) 0%,
            rgb(241, 241, 241) 70%
        );
    }
`;

export const EmpowerBackgroundSpacing = styled.div`
    position: relative;
    height: 460px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        height: 360px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        height: 300px;
    }
`;
