import { WithTranslation } from 'react-i18next';

export type PlanType = PlanVariant.FREE | PlanVariant.LITE | PlanVariant.PREMIUM;

export interface PricingTableContent {
    title: string,
    content: PricingRowContent[]
}

export enum PricingType {
    EEA = 'EEA',
    NONEEA = 'NONEEA',
}

export enum PlanVariant {
    FREE = 'free',
    LITE = 'lite',
    PREMIUM = 'premium',
}

export interface PricingRowContent {
    headingColumn: string;
    additionalInfo?: string;
    column2: string;
    column3: string;
    column4: string;
}

export interface PricingMobileRowContent {
    free: string;
    lite: string;
    premium: string;
    additionalInfoFree?: string;
    additionalInfoLite?: string;
    additionalInfoPremium?: string;
}

export interface PricingOwnProps {}

export type PricingProps = PricingOwnProps & WithTranslation;
