import { css } from 'styled-components';
import { globalColors } from 'assets/globalColors';

import { Device } from './layout.types';

export const minViewportHeightForScrollingNavBar = '650px';

export const breakPoints = {
    tablet: 1100,
    mobile: 767,
};
export const desktopHorizontalPadding = 30;
export const mainWidth = 1200 + 2 * desktopHorizontalPadding;

export const tabletHorizontalPadding = 80;
export const mobileHorizontalPadding = 35;

export const ZENDESK_Z_INDEX = 32;
export const NAVBAR_TRANS_SELECT_CONTAINER_Z_INDEX = 31;
export const HAMBURGER_NAVBAR_MENU_CONTENT_Z_INDEX = 30;

export const WELCOME_GIRL_Z_INDEX = 10;
export const WELCOME_GIRL_EST_BAL_TAB_Z_INDEX = 8;
export const WELCOME_GIRL_MONTH_AVAIL_TAB_Z_INDEX = 7;
export const COOKIE_BANNER_Z_INDEX = 33;
export const COOKIE_BANNER_CLEAR_COOKIES_MODAL_Z_INDEX = 34;
export const NAVBAR_CONTAINER_Z_INDEX = 10;

export const BANNER_Z_INDEX = 32;

export const TAB_1_Z_INDEX = 3;
export const TAB_2_Z_INDEX = 2;
export const TAB_3_Z_INDEX = 1;

export const TAB_LIST_Z_INDEX = 2;
export const WELCOME_GIRL_ECLIPSE_Z_INDEX = 2;
export const LEFT_WELCOME_CONTENT_Z_INDEX = 2;
export const LEFT_WHY_OKEO_CONTENT_Z_INDEX = 2;
export const PRICING_CONTENT_WRAPPER_Z_INDEX = 2;
export const PRICING_TOP_SECTION_Z_INDEX = 1;

export const FAQ_IMAGE_Z_INDEX = 1;

export const successColor = globalColors.backgrounds.green.light;
export const infoColor = globalColors.backgrounds.blue.dark;
export const warningColor = globalColors.backgrounds.yellow.dark;
export const errorColor = globalColors.backgrounds.orange.dark;

export const getDeviceByWidth = (width: number | undefined): Device => {
    let device = Device.Desktop;

    if (width) {
        if (width <= breakPoints.mobile) {
            device = Device.Mobile;
        } else if (width <= breakPoints.tablet) {
            device = Device.Tablet;
        }
    } else {
        // eslint-disable-next-line no-console
        console.info(`[getDeviceByWidth] width is not defined, defaulting to ${Device.Desktop}`);
    }

    return device;
};

export const standardTransitionTime = '0.3s';

export const standardTransition = css`
    transition: ${standardTransitionTime} ease;
`;

export const standardBorderColor = globalColors.fontColors.grey.dark;

export const standardBottomBorder = css`
    border-bottom: 1px solid ${standardBorderColor};
`;

export const standardBorder = css`
    border: 1px solid ${standardBorderColor};
`;
