import React from 'react';
import { getContactIconPerIndex } from 'pages/Contact/ContactTabs/ContactTab/ContactTab.helpers';
import {
    ContactImage, ContactImageWrapper, ContactTabText, ContactTabTitle, ContactTabWrapper
} from 'pages/Contact/ContactTabs/ContactTab/ContactTab.styled';

import { TranslationWithMarkup } from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup.layout';
import { stringToAttributeString } from 'utils/string-tools';

import { ContactTabProps } from '../ContactTabs.types';

export const ContactTab: React.FC<ContactTabProps> = ({ tile, index }) => (
    <ContactTabWrapper>
        <ContactTabTitle>{tile.title}</ContactTabTitle>
        <ContactTabText>
            <TranslationWithMarkup translationKey={tile.text} />
        </ContactTabText>

        <ContactImageWrapper>
            <ContactImage src={getContactIconPerIndex(index)} alt={stringToAttributeString(tile.title)} index={index} />
        </ContactImageWrapper>
    </ContactTabWrapper>
);
