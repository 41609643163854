import { standardTransition } from "config/layout/layout";
import styled, { css } from "styled-components";

const CustomCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const CustomLabel = styled.label`
  position: relative;
  padding-left: 30px;
  cursor: pointer;
`;

const commonCheckboxStyles = css`
  content: '';
  width: 18px;
  height: 18px;
  display: inline-block;
  align-self: start;
  position: absolute;
  left: 0;
  top: 0;
`;

const CheckboxBackground = styled.div`
  ${commonCheckboxStyles};
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(138, 138, 138);
`;

const Checkmark = styled.div<{ isChecked: boolean }>`
  display: block;
  position: absolute;
  transform: scale(0) rotate(0deg);
  top: 2px;
  left: 6px;
  width: 7px;
  height: 10px;
  border-style: solid;
  border-width: 0px 2px 2px 0px;
  border-color: #fff;
  ${standardTransition};

  ${({ isChecked }) => isChecked && `
    transform: scale(1) rotate(55deg);
    border-color: rgb(98, 200, 49);
  `}
`;

export default {
    Checkmark,
    CustomLabel,
    HiddenCheckbox,
    CheckboxBackground,
    CustomCheckboxWrapper,
};
