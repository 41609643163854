import { CloudPng, KeysPng, SafePng } from 'assets/images';

export const getContactIconPerIndex = (index: number) => {
    switch (index) {
        case 0:
            return SafePng;
        case 1:
            return CloudPng;
        case 2:
            return KeysPng;
        default:
            return SafePng;
    }
};
