/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useEffect, useState } from 'react'; import { Link } from 'react-router-dom';

import CloseIcon from 'assets/icons/Close';
import { Device } from 'config/layout/layout.types';
import { getMenuItems } from 'config/menu/main/main';
import { HamburgerIcon } from 'assets/icons/Hamburger';
import { Button } from 'components/atoms/Button/Button';
import { ONBOARDING_URL } from 'config/environment';

import { PageTypeSwitcher } from 'components/atoms/PageTypeSwitcher/PageTypeSwitcher';
import { ENABLE_PERSONAL_PAGE } from 'config/featureFlags/featureFlags';
import NavbarButtons from '../NavbarButtons/NavbarButtons';
import NavbarTranslationSelect from '../NavbarTranslationSelect/NavbarTranslationSelect';

import {
    HamburgerNavbarMenuContainer,
    HamburgerNavbarMenuContent,
    JoinButtonWrapper,
} from './HamburgerNavbarMenu.styled';
import {
    HamburgerMenuState,
    HamburgerNavbarMenuProps,
} from './HamburgerNavbarMenu.types';

export const HamburgerNavbarMenu: React.FC<HamburgerNavbarMenuProps> = ({
    t,
    device,
}) => {
    const [menuState, setMenuState] = useState(HamburgerMenuState.Closed);


    useEffect(() => {
        if (menuState === HamburgerMenuState.Open) {
            document.querySelector("body")!.classList.add("noScroll");
        } else {
            document.querySelector("body")!.classList.remove("noScroll");
        }

        return () => {
            document.querySelector("body")!.classList.remove("noScroll");
        };
    }, [menuState]);


    const icon = menuState === HamburgerMenuState.Closed ? (
        <HamburgerIcon />
    ) : (
        <CloseIcon />
    );

    const topButtons = device === Device.Mobile ? (
        <a href={ONBOARDING_URL} target="_blank" rel="noreferrer">
            <JoinButtonWrapper>
                <Button label={`${t('buttons.join.label')}`} />
            </JoinButtonWrapper>
        </a>
    ) : (
        <NavbarButtons t={t} />
    );

    const menuItems = getMenuItems(t).map(({ label, url }) => (
        <Link
            key={label}
            onClick={() => setMenuState(HamburgerMenuState.Closed)}
            to={url}
        >
            <li>{label}</li>
        </Link>
    ));

    const onClickHandler = () => setMenuState((prev) => (prev === HamburgerMenuState.Open ? HamburgerMenuState.Closed : HamburgerMenuState.Open));

    return (
        <HamburgerNavbarMenuContainer>

            {menuState === HamburgerMenuState.Closed
                ? topButtons
                : <NavbarTranslationSelect t={t} />}

            <div role="button" onClick={onClickHandler} style={{ cursor: 'pointer' }}>
                {icon}
            </div>

            <HamburgerNavbarMenuContent menuState={menuState}>
                <div>
                    { ENABLE_PERSONAL_PAGE && (
                        <div style={{ textAlign: 'center' }}>
                            <PageTypeSwitcher closeMenuHandler={() => setMenuState(HamburgerMenuState.Closed)} />
                        </div>
                    )}
                    <ul>
                        {menuItems}
                    </ul>
                </div>

                <NavbarButtons t={t} />
            </HamburgerNavbarMenuContent>
        </HamburgerNavbarMenuContainer>
    );
};
