import React from 'react';

import { VERSION_DETAILS } from 'config/environment';

import Styled from './Version.styled';
import { VersionProps } from './Version.types';

export const Version: React.FC<VersionProps> = () => (
    <Styled.Version>
        <Styled.ItemBackground>
            <div>
                <b>CI Commit Short SHA</b>
            </div>
            <div>{VERSION_DETAILS.ciCommitShortSha}</div>
        </Styled.ItemBackground>

        <Styled.ItemBackground>
            <div>
                <b>CI Commit Timestamp</b>
            </div>
            <div>
                {new Date(VERSION_DETAILS.ciCommitTimestamp).toString()}
            </div>
        </Styled.ItemBackground>

        <Styled.ItemBackground>
            <div>
                <b>CI Job Started At</b>
            </div>
            <div>{new Date(VERSION_DETAILS.ciJobStartedAt).toString()}</div>
        </Styled.ItemBackground>
    </Styled.Version>
);
