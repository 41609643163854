import React from "react";
import { Helmet } from "react-helmet-async";


const Seo: React.FC = () => {
    const url = window.location.href.replace("www.", '');

    return (
        <Helmet>
            <link rel="canonical" href={url} />
        </Helmet>
    );
};

export { Seo };
