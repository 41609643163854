import React from 'react';
import {
    PricingTableRow,
    PricingTableWrapper,
} from 'pages/Pricing/Pricing.styled';
import { PricingMobileRowContent } from 'pages/Pricing/Pricing.types';
import { PricingTableMobileProps } from 'pages/Pricing/PricingTable/PricingTable.types';
import { PricingTableMobileRow } from 'pages/Pricing/PricingTable/PricingTableMobile/PricingTableMobileRow/PricingTableMobileRow';

export const PricingTableMobile: React.FC<PricingTableMobileProps> = ({ translation, planType }) => (
    <PricingTableWrapper>
        <PricingTableRow>
            {translation.map((row: PricingMobileRowContent, index: number) => (
                <PricingTableMobileRow
                    row={row}
                    planType={planType}
                    index={index}
                />
            ))}
        </PricingTableRow>
    </PricingTableWrapper>
);
