import { globalColors } from 'assets/globalColors';
import styled from 'styled-components';

import { breakPoints } from 'config/layout/layout';

export const SmartTitle = styled.div`
    font-size: 24px;
    letter-spacing: 0px;
    line-height: 50px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
`;

export const SmartTitleBolded = styled.div`
    font-size: 38px;
    letter-spacing: 0px;
    line-height: 50px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 28px;
    }
`;

export const SmartSubTitle = styled.div`
    font-size: 29px;
    line-height: 38px;
    font-weight: 400;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 21px;
    }
`;

export const SmartText = styled.div`
    font-size: 19px;
    line-height: 27px;
    font-weight: 300;
    padding: 50px 0 60px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 14px;
        line-height: 22px;
        max-width: 435px;
        padding: 35px 0;
    }
`;

export const SmartContentWrapper = styled.div`
    display: grid;
    gap: 80px;
    grid-template-columns: 1fr 520px;
    padding-top: 85px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding-top: 55px;
        grid-template-columns: 1fr 400px;
        gap: 25px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        padding-top: 42px;
        gap: 35px;
        margin: 0 auto;
        max-width: 450px;
    }
`;

export const SmartContentAbsoluteImage = styled.img`
    position: absolute;
    height: 950px;
    top: -140px;
    left: -40%;
`;

export const SmartInnerWrapper = styled.div`
    padding: 100px 0 110px;
    color: ${globalColors.fontColors.black};

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 65px 0;
    }
`;

export const SmartTitleWrapper = styled.div`
    text-align: center;
`;

export const SmartTab = styled.div<{ align: AlignSetting }>`
    width: 520px;
    height: 92px;
    padding: 0 30px;
    transition: 0.5s ease;
    display: grid;
    align-items: ${({ align }) => align};
    box-shadow: 0px 3px 3.5px 1px rgba(0, 0, 0, 0.06);
    background-color: ${globalColors.backgrounds.white};

    &:hover {
        box-shadow: 0px 3px 3.5px 1px rgba(0, 0, 0, 0.16);
    }

    img {
        width: 100%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        width: 100%;
        height: 61px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 0 18px;
        height: clamp(53px, 15vw, 90px);
    }
`;

// TODO: Find working type for ref. Temporary
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SmartTabsWrapper = styled.div<{ ref: any }>`
    display: grid;
    gap: 15px;
    padding: 5px 0;
    overflow-y: hidden;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-auto-rows: 0fr;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-row-start: 1;
        justify-content: center;
        max-width: 450px;
        margin: 0 auto;
        gap: 10px;
    }
`;
