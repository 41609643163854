import { globalColors } from 'assets/globalColors';
import { PricingGradient } from 'pages/Pricing/Pricing.styled';
import styled from 'styled-components';

import { breakPoints } from 'config/layout/layout';

const ContentWrapper = styled.div`
    padding: 60px 0 80px;

    a {
        color: ${globalColors.fontColors.green.base};
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 70px 0 80px;
    }
`;

const Title = styled.h1`
    font-size: 29px;
    letter-spacing: 0px;
    line-height: 38px;
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    margin: 0;
    padding-bottom: 30px;


    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 27px;
        padding-bottom: 20px;
    }
`;

const ContactGradient = styled(PricingGradient)`
    bottom: -250px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        top: unset;
        bottom: -300px;
    }
`;

const GradientWrapper = styled.div`
    height: 1px;
    position: relative;
`;

const ActionsWrapper = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;

    svg {
        margin-top: 3px;
    }
`;

export const contactButtonStyles = `
    display: flex;
    align-items: center;
    gap: 15px;
`;

const EmailAction = styled.div`
    font-size: 18px;
    text-align: center;
`;

const InfoTitle = styled.div`
    font-size: 19px;
    line-height: 38px;
    font-weight: 500;
    text-align: center;
    padding: 80px 0 15px;
`;

const InfoSubtitle = styled.div`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
`;

const InfoText = styled.div`
    font-size: 16px;
    line-height: 27px;
    text-align: center;

    padding-top: 100px;

    a {
        color: ${globalColors.fontColors.black};
        font-weight: 500;
        text-decoration: underline;
    }
`;

export default {
    ContentWrapper, Title, ContactGradient, GradientWrapper, ActionsWrapper, EmailAction, InfoTitle, InfoSubtitle, InfoText
};
