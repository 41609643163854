enum HOSTNAMES {
    LOCALHOST = 'localhost',
    DEV = 'main.local.dev.okeo.xyz',
    DEMO = 'okeo-demo.com',
}

// PERSONAL PAGE
const hostnamesToEnablePersonalPage = [HOSTNAMES.LOCALHOST, HOSTNAMES.DEV, HOSTNAMES.DEMO];
const ENABLE_PERSONAL_PAGE = hostnamesToEnablePersonalPage.includes(window.location.hostname as HOSTNAMES);


export { ENABLE_PERSONAL_PAGE };
