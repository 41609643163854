import { deleteCookieByNameAndDomain } from "../Cookies.helpers";
import { HOTJAR_COOKIE_NAME } from "../Cookies.types";

const getHotjarCookies = () => {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());

    const hotjarRelatedCookies = [HOTJAR_COOKIE_NAME];
    const regex = new RegExp(
        `(${hotjarRelatedCookies.join("|")})`,
        "i"
    );

    const hotjarCookies = cookies.filter((cookie) => regex.test(cookie));

    return hotjarCookies.map((cookie) => cookie.slice(0, cookie.indexOf("=")).trim());
};

const removeHotjarCookies = () => {
    const hotjarCookies = getHotjarCookies();

    const secondLevelDomain = window.location.hostname
        .split(".")
        .slice(-2)
        .join(".");

    if (hotjarCookies.length > 0) {
        hotjarCookies.forEach((cookieName) => {
            deleteCookieByNameAndDomain(cookieName, secondLevelDomain);
        });
    }
};

export {
    removeHotjarCookies
};
