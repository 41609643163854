import React from 'react';

import { MainWidth, StyledSectionWrapper } from './SectionWrapper.styled';
import { SectionWrapperProps } from './SectionWrapper.types';

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
    id,
    children,
    backgroundColor,
    backgroundImage,
    additionalStyles,
}) => (
    <StyledSectionWrapper
        id={id}
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        additionalStyles={additionalStyles}
    >
        <MainWidth>
            {children}
        </MainWidth>
    </StyledSectionWrapper>
);
