import React, { useRef } from 'react';
import { ZendeskAPI } from 'react-zendesk';
import ZendeskIcon from 'assets/icons/ZendeskIcon';
import useOnScreen from 'hooks/useIsVisible';
import ContactTabs from 'pages/Contact/ContactTabs/ContactTabs';

import { Button } from 'components/atoms/Button/Button';
import { ButtonTheme } from 'components/atoms/Button/Button.types';
import { MainWidth, StyledSectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper.styled';
import { TranslationWithMarkup } from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup.layout';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { Seo } from 'utils/Seo';

import { MapComponent } from './Map/Map';
import Styled, { contactButtonStyles } from './Contact.styled';
import { ContactProps } from './Contact.types';

export const Contact: React.FC<ContactProps> = ({ t }) => {

    const ref = useRef();
    const isVisible = useOnScreen(ref);

    const openZendeskChat = () => {
        ZendeskAPI('webWidget', 'open');
        ZendeskAPI('webWidget', 'show');
    };

    return (
        <StyledSectionWrapper>
            <MainWidth>
                <Seo />
                <Styled.ContentWrapper>
                    <Styled.GradientWrapper>
                        <Styled.ContactGradient />
                    </Styled.GradientWrapper>

                    <Styled.Title>
                        {t('title.label')}
                        <Underline ref={ref} isVisible={isVisible}>
                            {t('title.underlinedLabel')}
                        </Underline>
                    </Styled.Title>

                    <ContactTabs t={t} />

                    <Styled.ActionsWrapper>
                        <Button
                            buttonTheme={ButtonTheme.ORANGE}
                            onClick={() => openZendeskChat()}
                            additionalStyles={contactButtonStyles}
                        >
                            <ZendeskIcon />
                            {t('actions.chat')}
                        </Button>
                    </Styled.ActionsWrapper>

                    <Styled.EmailAction>
                        <TranslationWithMarkup translationKey={t('actions.mail')} />
                    </Styled.EmailAction>

                    <Styled.InfoTitle>
                        {t('subtitle.label')}
                    </Styled.InfoTitle>

                    <Styled.InfoSubtitle>
                        <TranslationWithMarkup translationKey={t('subtitle.text')} />
                    </Styled.InfoSubtitle>

                    <MapComponent t={t} />

                    <Styled.InfoText>
                        <TranslationWithMarkup translationKey={t('legal')} />
                    </Styled.InfoText>

                </Styled.ContentWrapper>
            </MainWidth>
        </StyledSectionWrapper>
    );

};
