import styled from 'styled-components';
import { globalColors } from 'assets/globalColors';
import { HowToOpenMaskPng, SmartphoneFramePng } from 'assets/images';
import { breakPoints, standardTransition } from 'config/layout/layout';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 384px 1fr;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 318px 1fr;
        gap: 30px;
    }

    @media screen and (max-width: 880px) {
        margin-left: -40px;
        margin-right: -40px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        margin-left: 0;
        margin-right: 0;
    }
`;

const Title = styled.h3`
    font-size: 32px;
    letter-spacing: 0px;
    line-height: 48px;
    font-weight: 400;
    margin: 0 0 100px;
    text-align: center;
    padding: 65px 0 0;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 24px;
        line-height: 38px;
        margin: 0 0 66px;
        padding: 37px 0 0;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 40px 0 38px;
        margin: 0;
    }

    ${Underline} {
        font-weight: 500;
    }
`;

const Switcher = styled.div<{ active: boolean }>`
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
    padding: 15px 0;
    position: relative;
    ${standardTransition};
    box-shadow: 0px 0x 0px rgba(0, 0, 0, 0);
    border-bottom: 1px solid ${globalColors.backgrounds.grey.dark};

    &:after {
        left: 0;
        bottom: -1px;
        content: '';
        position: absolute;
        width: 100px;
        height: 3px;
        display: none;
        background-color: ${globalColors.backgrounds.green.base};
    }

    ${({ active }) => active && `
        background-color: white;
        box-shadow: 0px 13px 18px #00000014;
        border-bottom: 1px solid rgba(0, 0, 0, 0);

        &:after {
            display: block;
        }
    `}

    .number {
        font-size: 64px;
        line-height: 97px;
        font-weight: 500;
        text-align: center;
    }

    .text {
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            font-size: 18px;
            line-height: 24px;
        }
    }
`;

const LeftContentWrapper = styled.div`
    padding-bottom: 86px;
`;

const RightContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: end;
    position: relative;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        justify-content: end;
    }
`;

const GreenLight = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${HowToOpenMaskPng});
    background-repeat: no-repeat;
    background-position: bottom center;
`;

const Smartphone = styled.div`
    width: 473px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: url(${SmartphoneFramePng});
    position: relative;
    z-index: 1;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        width: 100%;
        max-width: 473px;
    }

    img {
        width: 100%;
        vertical-align: bottom;
    }
`;

const SwitchersWrapper = styled.div`
    padding-bottom: 50px;
`;

export default {
    Title,
    Switcher,
    GreenLight,
    Smartphone,
    ContentWrapper,
    SwitchersWrapper,
    LeftContentWrapper,
    RightContentWrapper,
};
