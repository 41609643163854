import React from 'react';

import Styled from './CountryFlag.styled';
import { CountryFlagProps } from './CountryFlag.types';

export const CountryFlag: React.FC<CountryFlagProps> = ({ name, t }) => (
    <Styled.CountryFlag
        title={t('extras.flag_of', { country: t(`${name}.label`) })}
        name={name}
    />
);
