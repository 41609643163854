import React from 'react';

export const Circle: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 7 7">
        <g id="Ellipse_17" data-name="Ellipse 17" fill="none" stroke="#000" strokeWidth="1.6">
            <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
            <circle cx="3.5" cy="3.5" r="2.7" fill="none" />
        </g>
    </svg>
);
