import styled from 'styled-components';

import { breakPoints, standardTransition } from 'config/layout/layout';

export const ScrollListItemWrapper = styled.li<{ isActive?: boolean }>`
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 10px;
    align-items: center;
    font-size: 19px;
    letter-spacing: 0px;
    line-height: 27px;
    font-weight: 300;
    ${standardTransition};
    min-height: 34px;
    font-weight: ${({ isActive }) => isActive && '600'};
    transform: translateX(${({ isActive }) => (isActive ? '-35px' : '0')});

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;
        transform: translateX(${({ isActive }) => (isActive ? '-31px' : '0')});
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        transform: translateX(${({ isActive }) => (isActive ? '-18px' : '0')});
    }
`;
