import React from 'react';
import i18next, { WithT } from 'i18next';

import RoundedCountryFlag from 'assets/icons/RoundedCountryFlag/RoundedCountryFlag.layout';
import { RoundedCountryFlags } from 'assets/icons/RoundedCountryFlag/RoundedCountryFlag.types';
import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown.types';
import { TranslationKey } from 'config/i18n/i18n.types';
import { getLanguagePreference, setLanguagePreference } from 'utils/languagePreference';
import { accumulativeRoutePath } from 'config/path/path';

import { useLocation } from 'react-router-dom';
import { NavbarTranslationSelectContainer } from './NavbarTranslationSelect.styled';

const countryDropdownOptions: DropdownOption[] = [
    { value: TranslationKey.EN, label: 'English', icon: <RoundedCountryFlag name={RoundedCountryFlags.GB} /> },
    { value: TranslationKey.LT, label: 'Lithuanian', icon: <RoundedCountryFlag name={RoundedCountryFlags.LT} /> },
    { value: TranslationKey.PL, label: 'Polish', icon: <RoundedCountryFlag name={RoundedCountryFlags.PL} /> },
];

const handleOnOptionSelect = (option: DropdownOption) => {
    const languagePreference = getLanguagePreference();

    i18next.changeLanguage(option.value);

    if (languagePreference) {
        setLanguagePreference(option.value);
    }
};


const getLanguage = (lang: string) => countryDropdownOptions.find(
    (option) => option.value.includes(lang)
) || countryDropdownOptions[0];

export const NavbarTranslationSelect: React.FC<WithT> = () => {
    const { pathname } = useLocation();

    const selectedLanguage = getLanguage(i18next.language);

    return (
        <NavbarTranslationSelectContainer>
            {/* block language change for a accumulative page */}
            {!pathname.includes(accumulativeRoutePath) && (
                <Dropdown
                    selected={selectedLanguage}
                    options={countryDropdownOptions}
                    onOptionSelect={handleOnOptionSelect}
                />
            ) }
        </NavbarTranslationSelectContainer>
    );
};
