import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToId = () => {
    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        if (hash === '') {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 0);
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);

                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 0);
        }
    }, [pathname, hash, key]);
};

export default useScrollToId;
