import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { ProductAndFeeTermsPDF } from 'assets/files';
import { PricingTable } from 'pages/Pricing/PricingTable/PricingTable';

import { Button } from 'components/atoms/Button/Button';
import { ButtonTheme } from 'components/atoms/Button/Button.types';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { MainWidth, StyledSectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper.styled';

import { Seo } from 'utils/Seo';
import PricingTabs from './PricingTabs/PricingTabs';
import {
    BluredBorder,
    PricingButtonWrapper,
    PricingContentWrapper,
    PricingGradient,
    PricingText,
    PricingTitle,
    PricingTopSection,
} from './Pricing.styled';
import {
    PlanVariant, PricingMobileRowContent, PricingProps, PricingRowContent, PricingType
} from './Pricing.types';
import { PricingTypeSwitcher } from './PricingTypeSwitcher/PricingTypeSwitcher';

export const Pricing: React.FC<PricingProps> = ({ t }) => {

    const [pricingType, setPricingType] = useState(PricingType.EEA);
    const [isOpened, toggleOpen] = useState(false);
    const [activePlanType, setActivePlanType] = useState(PlanVariant.FREE);

    const pricingTable: PricingRowContent[] = t(`table${pricingType}`, { returnObjects: true });
    const mobilePricingTranslation: PricingMobileRowContent[] = t(`mobileTable${pricingType}`, { returnObjects: true });

    const foldedPricingTable: PricingRowContent[] = t('hiddenTable', { returnObjects: true });
    const foldedPricingMobileTable: PricingMobileRowContent[] = t('mobileHiddenTable', { returnObjects: true });

    const toggleBottonLabel = isOpened ? t('buttons.fold.label') : t('buttons.seeAll.label');


    return (
        <StyledSectionWrapper additionalStyles="position: relative;">
            <MainWidth>
                <Seo />
                <PricingContentWrapper>
                    <PricingTopSection>
                        <div>
                            <PricingTitle>{t('title.label')}</PricingTitle>
                            <PricingText>{t('text.label')}</PricingText>
                            <PricingTypeSwitcher pricingType={pricingType} setPricingType={setPricingType} />
                        </div>

                        <PricingTabs t={t} switchPlanType={setActivePlanType} pricingType={pricingType} />

                        <BluredBorder />

                        <PricingGradient />
                    </PricingTopSection>

                    <PricingTable
                        translation={pricingTable}
                        mobileTranslation={mobilePricingTranslation}
                        planType={activePlanType}
                    />

                    <AnimateHeight height={isOpened ? 'auto' : 0} duration={500} id="folded-pricing-table">
                        <PricingTable
                            translation={foldedPricingTable}
                            mobileTranslation={foldedPricingMobileTable}
                            planType={activePlanType}
                            noHeader
                        />
                    </AnimateHeight>

                    <ChevronButton
                        label={toggleBottonLabel}
                        onClick={() => toggleOpen(!isOpened)}
                        margin="20px 0 0 -13px"
                    />

                    <PricingButtonWrapper>
                        <a href={ProductAndFeeTermsPDF} target="_blank" rel="noreferrer">
                            <Button
                                buttonTheme={ButtonTheme.OUTLINE_ORANGE}
                                label={t('buttons.download.label')}
                            />
                        </a>
                    </PricingButtonWrapper>
                </PricingContentWrapper>
            </MainWidth>
        </StyledSectionWrapper>
    );
};
