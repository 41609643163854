import React from 'react';
import AnimateHeight from 'react-animate-height';
import RoundedCountryFlag from 'assets/icons/RoundedCountryFlag/RoundedCountryFlag.layout';
import { RoundedCountryFlags } from 'assets/icons/RoundedCountryFlag/RoundedCountryFlag.types';

import Chevron from 'components/atoms/Chevron/Chevron';
import { ChevronDirection } from 'components/atoms/Chevron/Chevron.types';
import CountryFlag from 'components/atoms/CountryFlag/CountryFlag';
import TranslationWithMarkup from 'components/atoms/TranslationWithMarkup/TranslationWithMarkup';
import { stringToAttributeString } from 'utils/string-tools';

import {
    Bold,
    Div,
    Grid,
    TabListElementContent,
    TabListElementTitle,
    TabListElementWrapper,
    TabListTitleWrapper,
} from './TabListElement.styled';
import { TabListElementProps } from './TabListElement.types';

export const TabListElement: React.FC<TabListElementProps> = ({
    listElementData,
    activeTab,
    index,
    onClick,
    t,
}) => {

    const idTitle = stringToAttributeString(listElementData[1].title);

    const isActive = index === activeTab;

    const onClickWithScroll = () => {
        /* Temporarly disabled scrolling to active tab */
        const navBarHeight = document.querySelector('header')?.offsetHeight;

        setTimeout(() => {
            const scrollOffset = (navBarHeight || 0) + 5;
            const element = document.getElementById(`tab-list-element-${index}`);
            const scrollTopValue = element!.getBoundingClientRect().top + window.pageYOffset - scrollOffset;

            if (!isActive) {
                window.scrollTo({ top: scrollTopValue, behavior: 'smooth' });
            }
        }, 300);
        onClick();
    };

    return (
        <li id={`tab-list-element-${index}`}>
            <TabListElementWrapper index={index}>
                <TabListElementTitle
                    onClick={onClickWithScroll}
                    role="listitem"
                >
                    <TabListTitleWrapper bolded={isActive}>
                        {listElementData[1].title}
                    </TabListTitleWrapper>

                    <Chevron
                        margin="0 0 0 10px"
                        direction={
                            isActive
                                ? ChevronDirection.UP
                                : ChevronDirection.DOWN
                        }
                    />
                </TabListElementTitle>

                <AnimateHeight
                    height={isActive ? 'auto' : 0}
                    duration={200}
                    id={`${idTitle}-content`}
                >
                    <TabListElementContent>
                        <TranslationWithMarkup
                            t={t}
                            translationKey={`list.${listElementData[0]}.text`}
                            components={{
                                div: <Div />,
                                grid: <Grid />,
                                b: <Bold />,
                                AustriaFlag: <CountryFlag name="AT" />,
                                BelgiumFlag: <CountryFlag name="BE" />,
                                BulgariaFlag: <CountryFlag name="BG" />,
                                CroatiaFlag: <CountryFlag name="HR" />,
                                CyprusFlag: <CountryFlag name="CY" />,
                                CzechFlag: <CountryFlag name="CZ" />,
                                DenmarkFlag: <CountryFlag name="DK" />,
                                EstoniaFlag: <CountryFlag name="EE" />,
                                FinlandFlag: <CountryFlag name="FI" />,
                                FranceFlag: <CountryFlag name="FR" />,
                                GermanyFlag: <CountryFlag name="DE" />,
                                GreeceFlag: <CountryFlag name="GR" />,
                                HungaryFlag: <CountryFlag name="HU" />,
                                IcelandFlag: <CountryFlag name="IS" />,
                                ItalyFlag: <CountryFlag name="IT" />,
                                LatviaFlag: <CountryFlag name="LV" />,
                                LiechtensteinFlag: <CountryFlag name="LI" />,
                                LithuaniaFlag: <CountryFlag name="LT" />,
                                LuxemburgFlag: <CountryFlag name="LU" />,
                                MaltaFlag: <CountryFlag name="MT" />,
                                NetherlandsFlag: <CountryFlag name="NL" />,
                                NorwayFlag: <CountryFlag name="NO" />,
                                PolandFlag: <CountryFlag name="PL" />,
                                PortugalFlag: <CountryFlag name="PT" />,
                                RomaniaFlag: <CountryFlag name="RO" />,
                                SlovakiaFlag: <CountryFlag name="SK" />,
                                SloveniaFlag: <CountryFlag name="SI" />,
                                SpainFlag: <CountryFlag name="ES" />,
                                SwedenFlag: <CountryFlag name="SE" />,
                                EnglandFlag: <CountryFlag name="GB" />,
                                IrelandFlag: <CountryFlag name="IE" />,
                                BulgariaRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.BG} />,
                                SwitzerlandRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.CH} />,
                                ChechRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.CZ} />,
                                DenmarkRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.DK} />,
                                EuroRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.EU} />,
                                EnglandRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.GB} />,
                                CroatiaRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.HR} />,
                                HungaryRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.HU} />,
                                NorwayRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.NO} />,
                                PolandRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.PL} />,
                                RomaniaRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.RO} />,
                                SwedenRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.SE} />,
                                AmericaRoundedFlag: <RoundedCountryFlag name={RoundedCountryFlags.US} />,
                            }}
                        />
                    </TabListElementContent>
                </AnimateHeight>
            </TabListElementWrapper>
        </li>
    );
};
