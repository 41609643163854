import { standardTransition } from '@okeo/components';
import { AccumulativeBannerState } from 'components/atoms/accumulativeBanner/AccumulativeBanner.types';
import styled from 'styled-components';

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const StyledMain = styled.main<{ accumulativeBannerState: string | null, showBanner: boolean }>`
    margin-top: ${({ accumulativeBannerState, showBanner }) => (accumulativeBannerState === AccumulativeBannerState.OPEN && showBanner ? "40px" : "0")};
    max-width: 100vw;
    ${standardTransition};
`;

export { MainContainer };
