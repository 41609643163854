import { globalColors } from 'assets/globalColors';
import { GOOGLE_MAP_ID } from 'config/environment';
import { MapConfig } from './Map.types';

// Deprecated - Advenced Markers use GCP cloud global mapStyles
// export const mapStyles = [
//     {
//         featureType: 'water',
//         elementType: 'geometry',
//         stylers: [{ color: '#dcdcdc' }],
//     },
//     {
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#606367' }],
//     },
//     {
//         featureType: 'poi',
//         elementType: 'labels.icon',
//         stylers: [{ visibility: 'off' }]
//     },
//     {
//         featureType: 'transit',
//         elementType: 'labels.icon',
//         stylers: [{ visibility: 'off' }]
//     },
//     {
//         featureType: 'poi.parks',
//         elementType: 'geometry',
//         stylers: [{ color: '#dfdfdf' }],
//     },
//     {
//         featureType: 'landscape.man_made',
//         stylers: [{ color: '#ededed' }],
//     },
//     {
//         featureType: 'road',
//         elementType: 'geometry',
//         stylers: [{ color: '#ffffff' }],
//     },
// ];

const zoom = 15;
const markers = [
    { lat: 54.687553210411295, lng: 25.278325323294855, title: 'office-1' },
    { lat: 54.68359724624391, lng: 25.278992742329482, title: 'office-2' }
];

const MapTypeId = 'okeo-map';

const MAP_CONFIG: MapConfig = {
    id: MapTypeId,
    label: 'Okeo',
    mapId: GOOGLE_MAP_ID,
    mapTypeId: MapTypeId,
};

const mapLanguage = localStorage.getItem('i18nextLng') || 'en';

const handlePinColor = (active: boolean) => (active ? globalColors.backgrounds.green.light : globalColors.backgrounds.grey.dark);

export {
    zoom,
    markers,
    MapTypeId,
    MAP_CONFIG,
    mapLanguage,
    handlePinColor
};
