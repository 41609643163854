// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/react-in-jsx-scope */
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import {
    centrolinkLogo,
    epcLogo,
    inventiLogo,
    lbLogo,
    mambuLogo,
    ondatoLogo,
    sepaLogo,
    swiftLogo,
} from 'assets/logos';

import { CompanyLogoData } from './Companies.types';

export const getCompaniesLogos = (): CompanyLogoData[] => [
    {
        name: 'SEPA',
        logoUrl: sepaLogo,
    },
    {
        name: 'SWIFT',
        logoUrl: swiftLogo,
    },
    {
        name: 'MAMBU',
        logoUrl: mambuLogo,
    },
    {
        name: 'ONDATO',
        logoUrl: ondatoLogo,
    },
    {
        name: 'INVENTI',
        logoUrl: inventiLogo,
    },
    {
        name: 'EPC',
        logoUrl: epcLogo,
    },
    {
        name: 'LB',
        logoUrl: lbLogo,
    },
    {
        name: 'CENTROLINK',
        logoUrl: centrolinkLogo,
    },
];

const companiesLogos = getCompaniesLogos();

export const companiesCarouselOptions: Options = {
    autoplay: true,
    arrows: false,
    speed: 800,
    interval: 2000,
    type: 'loop',
    waitForTransition: true,
    direction: 'ltr',
    pagination: false,
    perMove: 1,

};

export const renderCompaniesCarousel = (options: Options) => (
    <Splide
        options={options}
    >
        {companiesLogos.map(({ name, logoUrl }) => (
            <SplideSlide key={`${name}`}>
                <img src={logoUrl} alt={name} key={name} />
            </SplideSlide>
        ))}
    </Splide>
);
