import { ACCUMULATIVE_BANNER_SESSION_STATE_NAME, AccumulativeBannerState } from "./AccumulativeBanner.types";



const getAccumulativeBannerSessionState = () => window.sessionStorage.getItem(ACCUMULATIVE_BANNER_SESSION_STATE_NAME);

const setAccumulativeBannerSessionState = (state: AccumulativeBannerState) => window.sessionStorage.setItem(ACCUMULATIVE_BANNER_SESSION_STATE_NAME, state);


export {
    getAccumulativeBannerSessionState, setAccumulativeBannerSessionState
};
