export interface GlobalColors {
    backgrounds: Backgrounds;
    fontColors: FontColors;
}

export interface FontColors {
    grey: ColorBrightnessExtended;
    black: string;
    white: string;
    orange: string;
    green: ColorBrightness;
}

export interface Backgrounds {
    black: string;
    white: string;
    yellow: ColorBrightness;
    orange: ColorBrightness;
    green: ColorBrightness;
    blue: ColorBrightness;
    grey: ColorBrightness;
}

export interface ColorBrightness {
    base: string;
    light?: string;
    dark?: string;
    darker?: string;
}

export interface ColorBrightnessExtended {
    base: string;
    light: string;
    dark: string;
    darker: string;
    xdark: string;
}

export const globalColors: GlobalColors = {
    backgrounds: {
        black: '#000',
        white: '#fff',
        yellow: {
            light: '#FFE121',
            base: '#FFC400',
            dark: '#F6BD00',
        },
        orange: {
            light: '#FFAF2C',
            base: '#FC7E1D',
            dark: '#E66604',
        },
        green: {
            base: '#62C831',
            light: '#51C817',
        },
        grey: {
            base: '#F1F1F1',
            light: '#FCFCFC',
            dark: '#C1C1C1',
            darker: '#BBBBBB',
        },
        blue: {
            light: '#A4F0FF',
            base: '#25D5F9',
            dark: '#05A8C9',
        },
    },

    fontColors: {
        grey: {
            light: '#D1D1D1',
            base: '#BBBBBB',
            dark: '#8A8A8A',
            darker: '#7D7D7D',
            xdark: '#7F7F7F',
        },

        green: {
            light: '#51C817',
            base: '#62C831',
            dark: '#4E9716',
        },

        black: '#000000',
        white: '#FFFFFF',
        orange: '#FC7E1D',
    },
} as const;
