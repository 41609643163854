import flags from 'assets/images/flags32.png';
import styled from 'styled-components';

import { CountryFlagStyled, flagPositions } from './CountryFlag.types';

const countryFlagAttrs = ({ name }: CountryFlagStyled) => {
    const position = (name && flagPositions[name]) || 0;

    return { style: { backgroundPositionY: -position - 5 } };
};

const CountryFlag = styled.span.attrs<CountryFlagStyled>(
    countryFlagAttrs
)<CountryFlagStyled>`
    display: inline-block;
    height: 22px;
    width: 30px;
    background-image: url(${flags});
    background-position-x: -1px;
    box-shadow: 0 0 3px 1px #00000038;
`;

export default { CountryFlag };
