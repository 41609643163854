import { globalColors } from "assets/globalColors";
import { breakPoints, standardTransition, standardTransitionTime } from "config/layout/layout";
import styled from "styled-components";


const SwitcherWrapper = styled.div`
    overflow: hidden;
    padding: 1px 4px 1px 0px;
    border-radius: 21px;
    display: inline-flex;
    width: 100%;
`;

const SwitcherContentWrapper = styled.div<{ isChecked: boolean }>`
    display: inline-flex;
    height: 38px;
    border-radius: 21px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 8px 20px;
    background-color: #eaeaea;
    font-size: 15px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    width: 199px;
    margin-top:1rem;
    ${standardTransition};
    > div {
        position: relative;
        font-size: 15px;
        z-index: 1;
        width:100%;
        height: 100%;
        &:first-child {
            ${({ isChecked }) => (isChecked && `
                color: #ffff;
                font-weight: 600;
            `)};

            &:hover {
                ${({ isChecked }) => (!isChecked && `
                 color: ${globalColors.backgrounds.green.light};  
            `)};}
            
        }

        &:last-child {
            text-align: right;

            ${({ isChecked }) => (!isChecked && `
                color: #ffff;
                font-weight: 600;
            `)};

            &:hover {
                ${({ isChecked }) => (isChecked && `
                 color: ${globalColors.backgrounds.green.light};  
            `)};}
        }
    }


    &:after {
        height: 38px;
        border: 1px solid #D3D3D3;
        border-radius: 21px;
        position: absolute;
        background:${globalColors.backgrounds.green.light} 0% 0% no-repeat padding-box;
        top: 0;
        bottom: 0;
        margin: auto;
        content: '';
        display: block;
        transition: ${standardTransitionTime};

        ${({ isChecked }) => (isChecked ? 'left: 0' : 'left: 100px')};
        ${({ isChecked }) => (isChecked ? 'width: 100px' : 'width: 100px')};
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
            margin: 1rem auto;
    }
`;

export default {
    Text,
    SwitcherWrapper,
    SwitcherContentWrapper,
};
