import React, { FC } from 'react';

export const X: FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 20 20">
        <path
            id="social-x"
            d="M 11.902344 8.464844 L 19.347656 0 L 17.582031 0 L 11.117188 7.347656 L 5.957031 0 L 0 0 L 7.808594 11.113281 L 0 19.988281 L 1.765625 19.988281 L 8.589844 12.226562 L 14.042969 19.988281 L 20 19.988281 Z M 9.484375 11.210938 L 8.695312 10.105469 L 2.398438 1.300781 L 5.109375 1.300781 L 10.191406 8.40625 L 10.980469 9.511719 L 17.585938 18.75 L 14.875 18.75 Z M 9.484375 11.210938 "
        />
    </svg>
);
