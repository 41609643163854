import { PricingTabWrapper } from 'pages/Pricing/Pricing.styled';
import styled from 'styled-components';

import { breakPoints } from 'config/layout/layout';

export const ContactTabWrapper = styled(PricingTabWrapper)`
    text-align: left;
    padding: 39px 41px;
    max-width: 100% !important;
    display: grid;
    grid-template-rows: auto 1fr auto;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        height: 100%;
        padding: 26px 33px;
    }
`;

export const ContactTabTitle = styled.div`
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    padding-bottom: 30px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 19px;
    }
`;

export const ContactTabText = styled.div`
    font-size: 17px;
    line-height: 26px;
    min-height: 150px;
    padding-bottom: 40px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;
        min-height: unset;
    }
`;

export const ContactImage = styled.img<{ index: number }>`
    max-width: 80px;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;

    ${({ index }) => index === 2 && `
        max-width: 110px;
        transform: translate(-50%,-50%) rotate(30deg);
    `}

    @media screen and (max-width: ${breakPoints.tablet}px) {
        max-width: 75px;

        ${({ index }) => index === 2 && 'max-width: 100px;'}
    }
`;

export const ContactImageWrapper = styled.div`
    width: 100px;
    height: 100px;
    position: relative;
    margin: 0 auto;
`;
