import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Seo } from 'utils/Seo';


import { PageType, setPageType } from 'config/path/path.helpers';
import { setAccumulativeBannerSessionState } from 'components/atoms/accumulativeBanner/AccumulativeBanner.helpers';
import { AccumulativeBannerState } from 'components/atoms/accumulativeBanner/AccumulativeBanner.types';
import { TranslationKey } from 'config/i18n/i18n.types';
import { getLanguagePreference } from 'utils/languagePreference';

import { useLocation } from 'react-router-dom';
import { accumulativeRoutePath } from 'config/path/path';
import Account from './sections/Account/Account';
import HowToOpen from './sections/HowToOpen/HowToOpen';
import PricingAccumulative from './sections/PricingAccumulative/PricingAccumulative';
import { HomePersonalProps } from './HomeAccumulative.types';


export const HomeAccumulative: React.FC<HomePersonalProps> = () => {
    const { i18n } = useTranslation();
    const languagePreference = getLanguagePreference();
    const { pathname } = useLocation();

    const isAccumulative = pathname.includes(accumulativeRoutePath);

    useEffect(() => {
        setPageType(PageType.ACCUMULATIVE);
        setAccumulativeBannerSessionState(AccumulativeBannerState.CLOSED);

        // INFO: ACCUMULATIVE PAGE IS ONLY IN ENGLISH
        i18n.changeLanguage(TranslationKey.EN);

        return () => {
            i18n.changeLanguage(languagePreference || TranslationKey.EN);

            // TODO: after personal will be available
            // set previous pageType once user leave accumulative
            setPageType(PageType.BUSINESS);
        };
    }, [isAccumulative]);

    return (
        <>
            <Seo />
            <Account />
            <HowToOpen />
            <PricingAccumulative />
        </>
    );
};
