import { globalColors } from 'assets/globalColors';
import {
    additionalNavbarDesktopBreakPoint,
    smallerDesktopNavbarHeight
} from 'pages/Main/Navbar/Navbar.styled';
import styled from 'styled-components';

import {
    breakPoints,
    minViewportHeightForScrollingNavBar,
    PRICING_CONTENT_WRAPPER_Z_INDEX,
    PRICING_TOP_SECTION_Z_INDEX
} from 'config/layout/layout';

const desktopLeftColumnWidth = '33%';
const additionalDesktopBreakpoint = '1150px';

export const PricingGradient = styled.div`
    position: absolute;
    bottom: 0;
    background: radial-gradient(
        circle,
        rgba(194, 255, 148, 0.46) 0%,
        rgba(255, 255, 255, 0) 45%
    );
    width: 100%;
    height: 800px;
    transform: scaleX(2.2);
    z-index: -1;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        top: -25%;
        bottom: unset;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        top: -400px;
        bottom: unset;
        width: 800px;
        left: -280px;
    }
`;

export const PricingTopSection = styled.div`
    display: grid;
    grid-template-columns: ${desktopLeftColumnWidth} 1fr;
    gap: 15px;
    position: sticky;
    padding-top: 60px;
    background-color: ${globalColors.backgrounds.white};
    z-index: ${PRICING_TOP_SECTION_Z_INDEX};

    @media screen and (max-width: ${additionalNavbarDesktopBreakPoint}px) {
        top: ${smallerDesktopNavbarHeight};
    }

    @media screen and (max-width: ${additionalDesktopBreakpoint}) {
        grid-template-columns: 35% 65%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding-top: 80px;
        display: block;
        position: static;
        background-color: unset;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding-bottom: 20px;
    }

    @media screen and (max-height: ${minViewportHeightForScrollingNavBar}) {
        top: -15px;
    }
`;

export const PricingTitle = styled.h1`
    font-size: 29px;
    letter-spacing: 0px;
    line-height: 38px;
    margin: 20px 0 30px;
    padding-right: 15px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 27px;
        line-height: 33px;
        margin-top: 0;
        padding-right: 0;
    }
`;

export const BluredBorder = styled.div`
    height: 30px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255) 0%,
        rgb(255, 255, 255, 0) 100%
    );
    margin-bottom: -30px;
    grid-column: 1/3;
`;

export const PricingText = styled.h2`
    font-size: 19px;
    line-height: 27px;
    font-weight: 300;
    padding-right: 15px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 22px;
        padding-right: 0;
    }
`;

export const PricingContentWrapper = styled.div`
    padding-bottom: 65px;
    position: relative;
    z-index: ${PRICING_CONTENT_WRAPPER_Z_INDEX};
`;

export const PricingTabsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
`;

export const PricingTabWrapper = styled.div`
    text-align: center;
    padding: 35px 15px 23px;
    position: relative;
    background: ${globalColors.backgrounds.white};
    filter: drop-shadow(0px 3px 3.5px rgba(0, 0, 0, 0.35));

    button {
        text-transform: capitalize;

        @media screen and (max-width: ${additionalDesktopBreakpoint}) {
            width: 100%;
            max-width: 155px;
            padding: 0 15px;
        }
    }

    @media screen and (max-width: ${additionalDesktopBreakpoint}) {
        max-width: 275px;
        margin: 0 auto;
        width: 100%;
    }
`;

export const PricingIconTabWrapper = styled.div`
    position: absolute;
    width: 22px;
    height: 22px;
    left: 10px;
    top: 10px;
`;

export const PricingTabBoldedLabel = styled.div`
    font-size: 19px;
    letter-spacing: 0px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
`;

export const PricingTabPlanType = styled.div`
    font-size: 24px;
    letter-spacing: 0px;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 10px;
`;

export const PricingTabCost = styled.span`
    font-size: 48px;
    line-height: 38px;
    font-weight: 500;
`;

export const PricingTabSign = styled.span`
    font-size: 28px;
    line-height: 38px;
    font-weight: 400;
`;

export const PricingTabPeriod = styled.div`
    font-size: 16px;
    line-height: 38px;
    font-weight: 300;
    padding-bottom: 20px;
`;

export const PricingTableWrapper = styled.div`
    @media screen and (max-width: ${breakPoints.tablet}px) {
        .pricing-heading-wrapper {
            display: grid;
            grid-row-gap: 1px;
            background-color: ${globalColors.backgrounds.grey.darker};

            > div {
                background-color: ${globalColors.backgrounds.white};
            }
        }
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        .pricing-content-wrapper {
            > div {
                display: grid;
                grid-row-gap: 1px;
                background-color: ${globalColors.backgrounds.grey.darker};

                > div {
                    background-color: ${globalColors.backgrounds.white};
                }
            }
        }
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding: 0;
    }
`;

export const PricingTableTitle = styled.h3`
    font-size: 19px;
    line-height: 1;
    font-weight: 600;
    margin: 0;
    padding: 26px 0 25px;
    border-bottom: 3px solid ${globalColors.backgrounds.green.base};
    max-width: ${desktopLeftColumnWidth};

    @media screen and (max-width: ${additionalDesktopBreakpoint}) {
        max-width: 40%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        max-width: 50%;
        font-size: 16px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        display: none;
    }
`;

export const TableHeadingWrapper = styled.div`
`;

export const PricingTableHeading = styled.div<{
    index?: number;
    hasAdditionalInfo?: boolean;
    isOpened?: boolean;
}>`
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    font-weight: 300;
    padding: 10px 0;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        grid-row: ${({ index }) => (index || index === 0) && index + 1};
        min-height: 46px;
        height: 100%;
        border-bottom: 1px solid #bbb;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding-right: 25px;
        position: relative;
        display: grid;
        grid-template-columns: 40px 1fr;
        cursor: ${({ hasAdditionalInfo }) => hasAdditionalInfo && 'pointer'};
    }

    span {
        font-size: 14px;
        line-height: 18px;
        color: #000000;
        font-weight: 500;
        display: block;

        a {
            color: ${globalColors.backgrounds.green.base};
        }
    }
`;

export const PricingTableCell = styled.div<{ index?: number }>`
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    display: grid;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-row: ${({ index }) => (index || index === 0) && index + 1};
        font-size: 15px;
        padding: 13px 0 10px 15px;
        min-height: 46px;
        height: 100%;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #bbb;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
    }
`;

export const PricingTableRow = styled.div`
    display: grid;
    grid-template-columns: ${desktopLeftColumnWidth} 1fr 1fr 1fr;
    border-bottom: 1px solid ${globalColors.backgrounds.grey.darker};
    align-items: center;
    gap: 5px;

    @media screen and (max-width: ${additionalDesktopBreakpoint}) {
        grid-template-columns: 40% 1fr 1fr 1fr;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 50% 1fr;
        border-bottom: none;
        gap: 0;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`;

export const PricingTableAdditionalInfo = styled.div<{ isOpened?: boolean }>`
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
    padding-top: 8px;
    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 18px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        padding-top: 15px;
        padding-bottom: 10px;
    }
`;

export const TablesWrapper = styled.div`
`;

export const ChevronWrapper = styled.div`
    right: 10px;
    top: 10px;
    position: absolute;
`;

export const PricingButtonWrapper = styled.div`
    padding: 40px 0 5px;
    text-align: center;
`;
