/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { TermsAndConditionsPDF } from 'assets/files';
import { FilesIcon } from 'assets/icons/FilesIcon';
import { PaperTextIcon } from 'assets/icons/PaperTextIcon';
import {
    LastUpdate,
    LegalContent,
    LegalTitle,
    LegalWrapper,
    TitleWithButtonsRow
} from 'pages/Legal/Legal.styled';

import { RoundUrlButton } from 'components/atoms/RoundUrlButton/RoundUrlButton';
import { Tooltip } from 'components/atoms/Tooltip/Tooltip';
import { Seo } from 'utils/Seo';

export const TermsAndConditions: React.FC = () => (
    <LegalWrapper>
        <Seo />
        <TitleWithButtonsRow>
            <LegalTitle>Terms and Conditions for Business Clients</LegalTitle>

            <div>
                <Tooltip name="tnc-history-btn" label="Go to previous version">
                    <RoundUrlButton
                        external
                        link="https://help.okeo.com/hc/en-us/articles/7402051753617-Terms-and-Conditions-History"
                    >
                        <FilesIcon />
                    </RoundUrlButton>
                </Tooltip>

                <Tooltip name="tnc-download-btn" label="Download Terms & Conditions">
                    <RoundUrlButton
                        primary
                        external
                        link={TermsAndConditionsPDF}
                    >
                        <PaperTextIcon />
                    </RoundUrlButton>
                </Tooltip>
            </div>
        </TitleWithButtonsRow>

        <LastUpdate>Last update: 21 April 2022 </LastUpdate>

        <LegalContent>
            <ol>
                <li><a href="#page_1">About OKEO</a></li>
                <li><a href="#page_2">Glossary</a></li>
                <li><a href="#page_3">Scope and Purpose</a></li>
                <li><a href="#page_4">Registration</a></li>
                <li><a href="#page_5">Issuance and redemption of Electronic Money</a></li>
                <li><a href="#page_6">Account and Transfers management</a></li>
                <li><a href="#page_7">Initiation of Payment Orders</a></li>
                <li><a href="#page_8">Handling of errors in Payment Orders</a></li>
                <li><a href="#page_9">Execution of Payment Orders</a></li>
                <li><a href="#page_10">Additional rules on Accounts management</a></li>
                <li><a href="#page_11">Fees</a></li>
                <li><a href="#page_12">Prohibited actions</a></li>
                <li><a href="#page_13">Communication</a></li>
                <li><a href="#page_14">Client information</a></li>
                <li><a href="#page_15">Amendments. Validity. Termination</a></li>
                <li><a href="#page_16">Suspensions of Services</a></li>
                <li><a href="#page_17">Liability of the Parties</a></li>
                <li><a href="#page_18">Final provisionss</a></li>
            </ol>
            <h3 id="page_1" className="scroll-margin">1. About OKEO</h3>
        Okeo Payments UAB is a company incorporated and registered under the Law of Lithuania, with legal entity code
        305219549, with registered address at Gedimino pr. 20, LT-01103 Vilnius, Lithuania, hereinafter referred to as OKEO.

        OKEO is a holder of
            {' '}
            <a href="https://www.lb.lt/en/enforcement-measures-1/view_license?id=1967">Electronic Money Institution License No.71. </a>
        The supervising authority overseeing activities of OKEO is the Bank of Lithuania (Gedimino pr. 6, LT-01103 Vilnius,
        Lithuania, phone no.: +370 800 50 500, e-mail:
            {' '}
            <a href="mailto:info@lb.lt">info@lb.lt</a>
, with more information
        available at
            {' '}
            <a href="https://www.lb.lt/en/contacts" target="_blank" rel="noopener noreferrer">https://www.lb.lt/en/contacts</a>
).
        The data about OKEO is collected and stored in the Register of Legal Entities of the Republic of Lithuania.
            <h3 id="page_2" className="scroll-margin">2. Glossary</h3>
            <div className="table-header table-1-5">
                <div>Term</div>
                <div>Meaning</div>
            </div>
            <div className="table-1-5">
                <div>Acceptable Language</div>
                <div>Lithuanian or English languages.</div>
            </div>
            <div className="table-1-5">
                <div>Account, OKEO Account or Payment account</div>
                <div>
An account opened within OKEO, held in the name of the Client, which is used for the execution of Payment
                Transactions.

                </div>
            </div>
            <div className="table-1-5">
                <div>Beneficial Owner</div>
                <div>
A natural person who ultimately owns or controls the Business Client or a natural person on whose behalf
                the Payment Transaction or other activity related to the Account is being conducted.

                </div>
            </div>
            <div className="table-1-5">
                <div>Business Client or Client</div>
                <div>A business entity (regardless whether it has legal personality or not) to which the Services are provided.</div>
            </div>
            <div className="table-1-5">
                <div>Business Day</div>
                <div>
Any week day, other than Saturdays, Sundays and bank holidays specified in the Labour Code of the Republic
                of Lithuania.

                </div>
            </div>
            <div className="table-1-5">
                <div>Durable Medium</div>
                <div>
Any medium which enables the Payment Service User to store
                information addressed personally to him in a way accessible for future
                reference for a period of time adequate to the purposes of
                the information and which allows the unchanged reproduction of
                the information stored.

                </div>
            </div>
            <div className="table-1-5">
                <div>Electronic Money</div>
                <div>Money transferred to and held on the Account for execution of the Payment Transactions.</div>
            </div>
            <div className="table-1-5">
                <div>Fees</div>
                <div>Remuneration for the Services or reimbursement of costs payable to OKEO.</div>
            </div>
            <div className="table-1-5">
                <div>Foreign State</div>
                <div>A state which is not a Member State.</div>
            </div>
            <div className="table-1-5">
                <div>Identification</div>
                <div>
Identification and verification of the identity of the Client, User or related person in accordance with
                the procedure established by OKEO.

                </div>
            </div>
            <div className="table-1-5">
                <div>International Transfer</div>
                <div>A Transfer between OKEO and another financial institution, other than SEPA Transfer, and typically involving the currency conversion.</div>
            </div>
            <div className="table-1-5">
                <div>Join OKEO</div>
                <div>
A part of the OKEO System, with graphical user interface dedicated for
                    prospective Clients, accessible via internet at
                    <a href="https://join.okeo.com" target="_blank" rel="noopener noreferrer"> https://join.okeo.com</a>
                ,
                via which the Users and the prospective Users can apply for a registration
                of new Client.
                </div>
            </div>
            <div className="table-1-5">
                <div>Local Time</div>
                <div>
The time zone applicable for Vilnius, Lithuania, i.e. Eastern European
                Summer Time (EEST, UTC+03:00) or Eastern European Time (EET or
                UTC+02:00).

                </div>
            </div>
            <div className="table-1-5">
                <div>Member State</div>
                <div>A member state of the European Union or European Economic Area.</div>
            </div>
            <div className="table-1-5">
                <div>My OKEO</div>
                <div>
A part of the OKEO System, with graphical user interface dedicated for
                Clients, accessible via internet at
                    {' '}
                    <a href="https://my.okeo.com" target="_blank" rel="noopener noreferrer">https://my.okeo.com</a>
                    {' '}
after providing the
                User Credentials and passing the Strong Customer Authentication, via
                which the Users can check OKEO Account balances, initiate Payment
                Transactions and use other Services.

                </div>
            </div>
            <div className="table-1-5">
                <div>Party</div>
                <div>OKEO or the Client (jointly referred to as the Parties).</div>
            </div>
            <div className="table-1-5">
                <div>Password</div>
                <div>A secret string of characters that allows a User to log in to the OKEO System.</div>
            </div>
            <div className="table-1-5">
                <div>Payee</div>
                <div>A natural or legal person who is the intended recipient of the funds underlying a Payment Transaction.</div>
            </div>
            <div className="table-1-5">
                <div>Payer</div>
                <div>
A natural or legal person who holds a Payment Account and initiates a Payment Order from that Payment
                Account.

                </div>
            </div>
            <div className="table-1-5">
                <div>Payment Order</div>
                <div>
An instruction made by a Client, or on Client’s behalf, requesting the execution of the Payment
                Transaction.

                </div>
            </div>
            <div className="table-1-5">
                <div>Payment Service Provider</div>
                <div>A body as defined in Article 6 of the Law of the Republic of Lithuania on Payments.</div>
            </div>
            <div className="table-1-5">
                <div>Payment Services</div>
                <div>
Execution of Payment Transactions, including transfers of funds on a
                Payment Account or on a payment account with another Payment Service
                Provider, embracing execution of credit transfers, including standing
                orders;
                Issuing of Payment Instruments and/or acquiring of Payment
                Transactions;
                Operations required for operating a Payment Account.

                </div>
            </div>
            <div className="table-1-5">
                <div>Payment Transaction or Transfer</div>
                <div>Transferring or withdrawing the funds, to or from the Account, initiated by a Payer or by a Payee.</div>
            </div>
            <div className="table-1-5">
                <div>Registration</div>
                <div>
A process during which the User identity is confirmed and the basis for
                the business relationship commencement with the Client is evaluated by
                OKEO.

                </div>
            </div>
            <div className="table-1-5">
                <div>Representative</div>
                <div>
A natural person that uses or intends to use the Services on behalf of
                the Client, including:
                a natural person who has a right to act on behalf of the Business Client
                under articles of association or other similar document,
                a natural person who has a right to act on behalf of the Business Client or
                to use some of the Services available to the Client based on other
                arrangements, e.g. power of attorney.

                </div>
            </div>
            <div className="table-1-5">
                <div>SEPA Transfer</div>
                <div>
Euro-denominated transactions within the SEPA (Single Euro Payments
                Area) network.

                </div>
            </div>
            <div className="table-1-5">
                <div>Services</div>
                <div>
Electronic Money and Payment Services provided by the Company to its
                Clients, including the service of opening the Account in the name of
                the Client.

                </div>
            </div>
            <div className="table-1-5">
                <div>Strong Customer Authentication</div>
                <div>
An authentication based on the use of two or more elements categorised
                as knowledge (something only the user knows), possession (something
                only the user possesses) and inherence (something the user is) that are
                independent, in that the breach of one does not compromise
                the reliability of the others, and is designed in such a way as to protect
                the confidentiality of the authentication data.
                The Strong Customer Authentication under the mechanism provided by
                the OKEO System typically requires the User to type in the code (received
                in SMS) in the My OKEO interface. The Strong Customer Authentication is
                required to access My OKEO, confirm the Payment Transaction or
                authenticate other sensitive operation in the System.

                </div>
            </div>
            <div className="table-1-5">
                <div>System or OKEO System</div>
                <div>
A configured and integrated software and hardware infrastructure, that
                enables access and use of the Services.

                </div>
            </div>
            <div className="table-1-5">
                <div>Unique Identifier</div>
                <div>
A combination of letters, numbers or symbols specified to the Payment
                Service User by the Payment Service Provider and to be provided by
                the Payment Service User to identify unambiguously the other Payment
                Service User and/or his payment account for a Payment Transaction.

                </div>
            </div>
            <div className="table-1-5">
                <div>User or PaymentService User</div>
                <div>
A physical person, represented by a User Account created by OKEO
                System after successful Registration.

                </div>
            </div>
            <div className="table-1-5">
                <div>User Account</div>
                <div>
The result of the Registration process of the User in the OKEO System,
                during which personal data of the User are saved, a login and Password
                are set and the access rights of the User to the System are assigned.

                </div>
            </div>
            <div className="table-1-5">
                <div>User Credentials</div>
                <div>Combination of Password and login.</div>
            </div>
            <div className="list-table list-table-header">
                <div>3.</div>
                <div>
                    <h3 id="page_3" className="scroll-margin">Scope and Purpose</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>3.1</div>
                <div>
These Terms and Conditions refer to Electronic Money and Payment Services provided by OKEO to its
                Business Clients and prospective Business Clients, and constitute general terms of electronic money
                issuer (OKEO) and electronic money holder (the Client) agreement and the general payment services
                agreement.

                </div>
            </div>
            <div className="list-table">
                <div>3.2</div>
                <div>
The relationship between OKEO and its Client is established by these Terms and Conditions, the law of
                the Republic of Lithuania, other applicable laws and the principles of reasonableness, fairness and good
                faith.

                </div>
            </div>
            <div className="list-table">
                <div>3.3</div>
                <div>
The following constitute an integral part of the Terms and Conditions:
                    {' '}
                    <a href="https://okeo.com/legal/complaints-policy/" target="_blank" rel="noopener noreferrer">Complaints Policy</a>
,
                    <a href="https://okeo.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
, Product
                and
                Fee Terms

                </div>
            </div>
            <div className="list-table">
                <div>3.4</div>
                <div>
By accepting these Terms and Conditions the Client and the User agree that the Terms and Conditions
                as well as other documents governing the provision of the Services and the OKEO System interface are
                provided in the English language.

                </div>
            </div>
            <div className="list-table">
                <div>3.5</div>
                <div>
These Terms and Conditions come into force when the Client’s Representative expresses by electronic
                means his/her consent to comply with them.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>4.</div>
                <div>
                    <h3 id="page_4" className="scroll-margin">Registration</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>4.1</div>
                <div>The Client might enter into legal relationship with OKEO through the Client’s Representative.</div>
            </div>
            <div className="list-table">
                <div>4.2</div>
                <div>
The Client might enter into the legal relationship with OKEO only if the Client and the Representative(s)
                pass the Registration. The Registration is performed remotely according to the internal policies of OKEO,
                and involves non-face-to-face identification measures, including those provided via the service provider
                Ondato UAB (entity code 303342439).

                </div>
            </div>
            <div className="list-table">
                <div>4.3</div>
                <div>
The Registration is initiated by the Representative via Join OKEO application. Join OKEO is the main
                interface via which the information regarding the prospective Client and related persons is provided to
                OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>4.4</div>
                <div>
OKEO may require successful identification of Users and Beneficial Owners before the access to
                the Account is given. During the Registration process OKEO may also require the identification of
                the shareholders, directors, other Users or other physical persons related to the Client and provision of
                the Services. By accepting the Terms and Conditions the Client and the Representative agree that
                the aforementioned persons may be identified during the Registration process and consecutively after
                the successful Registration, upon the OKEO request.

                </div>
            </div>
            <div className="list-table">
                <div>4.5</div>
                <div>
The prospective Client is obliged to provide correct and complete data requested in the Registration form,
                including the information regarding the Beneficial Owner(s) of the Client. OKEO has the right to request
                additional information or documents related to the operations of the Client, during the Registration
                process and consecutively after the successful Registration.

                </div>
            </div>
            <div className="list-table">
                <div>4.6</div>
                <div>
If the Client does not provide requested information or documents under the terms specified by OKEO,
                OKEO has the right to suspend the Client’s Registration process.

                </div>
            </div>
            <div className="list-table">
                <div>4.7</div>
                <div>
OKEO reserves the right to obtain or verify information, data or documents regarding the Client, Users
                or related persons by itself or by using the services of third parties for the purpose of provision of the
                Services.

                </div>
            </div>
            <div className="list-table">
                <div>4.8</div>
                <div>As a result of successful Registration, the Account is opened in the name of the Client.</div>
            </div>
            <div className="list-table">
                <div>4.9</div>
                <div>
Each User associated with the Client is obliged to set the Password. Once the Password is successfully
                set, the User is granted an access to the System and to the Services.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>5.</div>
                <div>
                    <h3 id="page_5" className="scroll-margin">Issuance and redemption of Electronic Money</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>5.1</div>
                <div>
Money (funds) held on the OKEO Account is considered Electronic Money. Each time OKEO receives
                the money transferred to the Account, OKEO issues Electronic Money at the nominal value equal to the
                transferred amount and credits (increases) Client’s Account balance with that amount.

                </div>
            </div>
            <div className="list-table">
                <div>5.2</div>
                <div>
Electronic Money held on the Account is not a deposit and OKEO does not, in any circumstances, pay any
                interest on Electronic Money balance held on the OKEO Account or does not provide any other benefits
                associated with the time period the Electronic Money is stored on the Account.

                </div>
            </div>
            <div className="list-table">
                <div>5.3</div>
                <div>
At the request of the Representative, the Electronic Money held on the Account shall be redeemed at the
                nominal value, except for the cases set forth in the Terms and Conditions or in the applicable laws.

                </div>
            </div>
            <div className="list-table">
                <div>5.4</div>
                <div>The Electronic Money redemption can be initiated any time when the Services are available.</div>
            </div>
            <div className="list-table">
                <div>5.5</div>
                <div>
The Representative shall request the redemption of Electronic Money by placing a Payment Order to
                transfer the Electronic Money from the Account to other account specified in the Payment Order.

                </div>
            </div>
            <div className="list-table">
                <div>5.6</div>
                <div>
No specific conditions for Electronic Money redemption, that would differ from the standard conditions
                for the Payment Transactions, shall be applied. The amount of redeemed Electronic Money is indicated
                by the Representative, however cannot be higher than the available balance on the Account after
                consideration of the related Fees.

                </div>
            </div>
            <div className="list-table">
                <div>5.7</div>
                <div>
If the Representative terminates the Terms and Conditions and agreements concluded with OKEO on
                behalf of the Client and requests closure of the Account, or OKEO terminates the provision of the Services
                to the Client, Electronic Money held on the Client’s Account shall be transferred to the account within
                other Payment Service Provider indicated by the Representative, provided that the Electronic Money can
                be transferred to the indicated account under the payment schemas supported by OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>5.8</div>
                <div>
In the circumstances described above OKEO has the right to deduct, from the Client’s Account balance,
                the amounts that are owed to OKEO, e.g. Fees for Services provided by OKEO and other unpaid amounts,
                including but not limited to, fines and damages incurred by OKEO due to the breach of the Terms and
                Conditions or other obligations committed by the Client, which have been imposed by international
                payment card organizations, other financial institutions or state institutions. In the event of a dispute
                between OKEO and the Client, OKEO has the right to detain money under dispute until the dispute is
                resolved.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>6.</div>
                <div>
                    <h3 id="page_6" className="scroll-margin">Account and Transfers management</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>6.1</div>
                <div>
The User may manage the Account(s) via My OKEO – check the Account(s) balance(s) and the history of
                Payment Transactions and other operations associated with the Account(s), including all applied Fees
                deducted from the Client’s Account balance during a selected period of time.

                </div>
            </div>
            <div className="list-table">
                <div>6.2</div>
                <div>
The User shall provide the User Credentials and pass the Strong Customer Authentication to log in to the
                System via My OKEO. After maximum five (5) attempts of unsuccessful logging to the System the User
                Account is blocked and the User receives instructions how the access can be restored.

                </div>
            </div>
            <div className="list-table">
                <div>6.3</div>
                <div>
The User is obliged to ensure security and secrecy of the User Credentials. In case the Password becomes
                (or possibly becomes) known to the third parties, the User is obliged to immediately change the
                Password. To further enhance the security the User is obliged to:
                    <div className="list-table list-table-first">
                        <div>6.3.1</div>
                        <div>
keep the login credentials, codes (received e.g. in SMS) and any other means of Authentication
                        safe from the theft or unauthorized access by the third parties;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>6.3.2</div>
                        <div>
ensure that all electronic devices that are being used to access OKEO System have adequate and
                        updated software installed to ensure the security of the devices and the networks being used
                        (e.g. antivirus software and firewalls);

                        </div>
                    </div>
                    <div className="list-table">
                        <div>6.3.3</div>
                        <div>
immediately contact OKEO if he/she reasonably suspects that any of the login credentials, codes
                        or the devices/mobile numbers are lost, stolen or otherwise breached by the unauthorized third
                        parties, or he/she has become aware of any suspicious activity related to the OKEO System
                        access.

                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>6.4</div>
                <div>
Each Account is a multicurrency account, i.e. funds may be transferred from and to the Account in
                different currencies available within OKEO Services. The list of currencies supported by OKEO under
                the Account is indicated in the Product and Fee Terms.

                </div>
            </div>
            <div className="list-table">
                <div>6.5</div>
                <div>
The Account allows for storage of Electronic Money, for transfer of money from and to the Account and
                for conversion of money from one currency to another currency. Each Client shall have at least one active
                Account to have the access to the aforementioned Services.

                </div>
            </div>
            <div className="list-table">
                <div>6.6</div>
                <div>

                Transfers from and to the Account may be executed on Client’s behalf:
                    <div className="list-table list-table-first">
                        <div>6.6.1</div>
                        <div>from or to another Account held by OKEO in the name of the Client,</div>
                    </div>
                    <div className="list-table">
                        <div>6.6.2</div>
                        <div>from or to another Account held by OKEO in the name of another Client,</div>
                    </div>
                    <div className="list-table">
                        <div>6.6.3</div>
                        <div>
from or to the Member State or Foreign State bank account or account held by other Payment
                        Service Provider, provided that such account is reachable within the payment schemas
                        supported by OKEO.

                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>6.7</div>
                <div>
The payment schemas supported by OKEO and available within the Services are indicated in the Product
                and Fee Terms.

                </div>
            </div>
            <div className="list-table">
                <div>6.8</div>
                <div>
In case the Account balance in the selected currency is insufficient for the execution of the Payment
                Order, i.e. it is lower than the sum of the Payment Transaction amount and the related Fees, the Payment
                Transaction is not executed.

                </div>
            </div>
            <div className="list-table">
                <div>6.9</div>
                <div>
The Client’s confirmations, orders, requests, notifications and other actions performed through websites
                of third persons or other places by the Users logging into the System and identifying himself/herself in
                this way are treated as conclusion and execution of an agreement between OKEO and the Client.

                </div>
            </div>
            <div className="list-table">
                <div>6.10</div>
                <div>
The Client and the User(s) acknowledge that OKEO Account will be used solely for the purposes related
                to the Client’s trade, business or profession and will not be used as a personal account of a user.

                </div>
            </div>
            <div className="list-table">
                <div>6.11</div>
                <div>
Payment Transaction and some other actions in My OKEO (e.g. password change) are executed only after
                successful Strong Customer Authentication. Each time the Strong Customer Authentication is required,
                the User is notified by the System.

                </div>
            </div>
            <div className="list-table">
                <div>6.12</div>
                <div>
The User can change the authorization limits for the Payment Transactions (the limits) via My OKEO, at
                any time and at his own discretion. The User may check the current limits via My OKEO. The Client will be
                informed about the new limits entry into force via My OKEO interface or relevant notification channel
                (e.g. via e-mail).

                </div>
            </div>
            <div className="list-table">
                <div>6.13</div>
                <div>
OKEO reserves the right to impose the limits on the volume of Payment Transactions executed by the
                Clients and on the Electronic Money balances held on the Accounts. OKEO may charge additional Fees
                for excessive Payment Transactions or excessive Account balances.

                </div>
            </div>
            <div className="list-table">
                <div>6.14</div>
                <div>
OKEO reserves the right not to execute the Transfers to the banks, other Payment Service Providers and
                the Payees to which the Payment Transactions are forbidden according to the OKEO internal policies.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>7.</div>
                <div>
                    <h3 id="page_7" className="scroll-margin">Initiation of Payment Orders</h3>
                </div>
            </div>
            <div className="list-table">
                <div>7.1</div>
                <div>
The User is obliged to provide Payment Orders for the execution of the Payment Transactions in
                accordance with the instructions specified in the System and valid at the moment of the Payment Order
                placement.

                </div>
            </div>
            <div className="list-table">
                <div>7.2</div>
                <div>
In order to initiate a Payment Transaction via the internet, the User must fill in a Payment Order in My
                OKEO and submit it for execution. The following information must be provided by the User in
                the Payment Order:
                    <ul>
                        <li>
Payment Transaction Beneficiary (Payee) name (including full name and surname – if the Payee
                        is a natural person, or full company name – if the Payee is a business entity),

                        </li>
                        <li>Payment Transaction Beneficiary (Payee) account (serving as Unique identifier),</li>
                        <li>Payment Transaction amount,</li>
                        <li>Payment Transaction currency,</li>
                        <li>Client’s Account to be debited, in the form of IBAN number,</li>
                        <li>Client’s Account currency (determining the currency balance to be debited),</li>
                        <li>Description of the Payment Transaction.</li>
                    </ul>
                </div>
            </div>
            <div className="list-table">
                <div>7.3</div>
                <div>

                The following information may be requested to be provided by the User in relation to the Payment Order:
                    <ul>
                        <li>Payment Transaction Beneficiary (Payee) address,</li>
                        <li>
Documents related to the Payment Transaction (e.g. invoice constituting the basis for
                        the Transfer).

                        </li>
                    </ul>
                </div>
            </div>
            <div className="list-table">
                <div>7.4</div>
                <div>
The User submits a Payment Order for execution in My OKEO with a dedicated button (e.g. “Send”). User’s
                authorization of the Payment Transaction in the System (e.g. via typing the code, received in SMS, in
                My OKEO interface) is equivalent to giving consent to OKEO to execute the Payment Order. If additional
                authorization is not required for a particular Payment Transaction (e.g. due to exemptions applicable for
                Strong Customer Authentication procedure), confirmation of the Payment Order with a dedicated button
                (e.g. “Send”) is equivalent to giving the consent to OKEO to initiate and execute the Payment Order.

                </div>
            </div>
            <div className="list-table">
                <div>7.5</div>
                <div>
Effective submission of the Payment Order by the User in the System is an agreement of the Client to
                execute the Payment Transaction and it cannot be cancelled, unless the execution of the Payment
                Transaction has not been started. The status of the Payment Transaction shall be visible in My OKEO.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>8.</div>
                <div>
                    <h3 id="page_8" className="scroll-margin">Handling of errors in Payment Orders</h3>
                </div>
            </div>
            <div className="list-table">
                <div>8.1</div>
                <div>
Payment Orders submitted on behalf of the Client shall comply with requirements for submission of such
                Payment Orders and their content, as set by the legal acts or payment organizations or by OKEO.
                Payment Orders submitted on behalf of the Client shall be formulated clearly, unambiguously, shall be
                executable and contain clearly stated will of the Client.

                </div>
            </div>
            <div className="list-table">
                <div>8.2</div>
                <div>
OKEO does not undertake the responsibility for errors, discrepancies, repetitions or contradictions in
                Payment Orders submitted on behalf of the Client, including but not limited to correctness of requisites
                of the Payment Order submitted on behalf of the Client. If the Payment Order submitted on behalf of the
                Client does not contain enough data or contains deficiencies, OKEO, regardless of the nature of
                deficiencies in the Payment Order, can choose to either refuse to execute such Payment Order, or to
                execute it in accordance with the data given in the Payment Order if technically possible.

                </div>
            </div>
            <div className="list-table">
                <div>8.3</div>
                <div>
If the User indicates incorrect data of the Payee or the Payer and the Payment Transaction is executed
                according to the Payment Order placed by the User (e.g. the User indicates a wrong account number),
                OKEO will make all reasonable efforts to trace the Payment Transaction and will seek to recover the funds
                involved in the Payment Transaction. If it is not possible to recover the funds, it shall be considered that
                OKEO has fulfilled its obligations properly and shall not repay the transferred amount to the Client. In
                such case the Client’s Representative shall directly contact the person or the entity that has received the
                Transfer regarding the return of the funds. OKEO, upon written request of the Representative, is obliged
                to provide all relevant information about such Payment Transaction to the Representative on a Durable
                Medium. OKEO may charge additional fees on the Client for funds recovery or Payment Transaction
                investigation process.

                </div>
            </div>
            <div className="list-table">
                <div>8.4</div>
                <div>
If the User submits an incorrect Payment Order or indicates incorrect data, but the Payment Transaction
                has not been executed yet, the User may request cancellation of the Payment Order.

                </div>
            </div>
            <div className="list-table">
                <div>8.5</div>
                <div>
In all cases when OKEO receives a Payment Order but the Payment Transaction cannot be executed due
                to the errors in the Payment Order or insufficient information, OKEO undertakes reasonable effort to
                inform the User about possible issues with the Payment Transaction before it is initiated or, if the
                Payment Transaction has been initiated, to track and inform the User about the Payment Transaction
                status.

                </div>
            </div>
            <div className="list-table">
                <div>8.6</div>
                <div>
If OKEO cannot execute a Payment Transaction indicated in the Payment Order due to User errors made
                in the Payment Order, the Payment Order may be cancelled and funds may be returned to the Client if it
                is technically possible.

                </div>
            </div>
            <div className="list-table">
                <div>8.7</div>
                <div>
The User, having noticed that the money has been credited to the Account by mistake or in other way
                that has no legal basis, is obliged to notify OKEO about it immediately and not later than within 1 (one)
                Business Days after noticing the wrongly credited money. The User has no right to dispose of the money
                that does not belong to the Client. In such cases OKEO has the right and the Client gives an irrevocable
                consent to deduct the money from the wrongly credited Account without the Client’s order. If the amount
                of money on the Client’s Account is insufficient to debit the money credited by mistake, the Client
                unconditionally commits to repay OKEO the money credited by mistake within 5 (five) Business Days from
                the receipt of such request from OKEO.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>9.</div>
                <div>
                    <h3 id="page_9" className="scroll-margin">Execution of Payment Orders</h3>
                </div>
            </div>
            <div className="list-table">
                <div>9.1</div>
                <div>Payment Orders for internal Transfers (i.e. Transfers within OKEO) are executed immediately (meaning within minutes), e.g. when both the Payer and the Payee are holders of OKEO Accounts.</div>
            </div>

            <div className="list-table">
                <div>9.2</div>
                <div>

                For the SEPA Transfers following general rules apply regarding the timeline of the Payment Orders execution::
                    <ul>
                        <li>
Payment Orders placed on the Business Days before 4:30 p.m. Local Time are executed the same
                        Business Day,

                        </li>
                        <li>
Payment Orders placed on the Business Days after 4:30 p.m. Local Time are executed on the next
                        Business Day,

                        </li>
                        <li>
Payment Orders placed on the days other than Business Days are executed on the first Business
                        Day after the day when the Payment Order was placed.

                        </li>
                    </ul>
                </div>
            </div>

            <div className="list-table">
                <div>9.3</div>
                <div>
                For the International Transfers the following general rules apply regarding the timeline of the Payment Orders execution:
                    <ul>
                        <li>Payment Orders placed on the Business Days before 2:00 p.m. Local (Summer) Time are executed the same Business Day,</li>
                        <li>Payment Orders placed on the Business Days before 1:00 p.m. Local (Winter) Time are executed the same Business Day,</li>
                        <li>Payment Orders placed on the Business Days after 2:00 p.m. Local (Summer) Time are executed on the next Business Day,</li>
                        <li>Payment Orders placed on the Business Days after 1:00 p.m. Local (Winter) Time are executed on the next Business Day,</li>
                        <li>Payment Orders placed on the days other than Business Days are executed on the first Business Day after the day when the Payment Order was placed.</li>
                    </ul>
                </div>
            </div>
            <div className="list-table">
                <div>9.4</div>
                <div>
In those cases where the Payment Order contains errors or OKEO has reasonable doubts regarding the
                Payment Transaction as stated in 9.7 and 9.8, the execution time might be longer than stated in 9.1, 9.2 and
                9.3.

                </div>
            </div>
            <div className="list-table">
                <div>9.5</div>
                <div>
All of the Payment Orders that the System receives are placed in a priority-based queue. Subsequently,
                Payment Transactions are executed according to the queue, with execution occurring either immediately
                or as soon as preceding Payment Transactions are completed.

                </div>
            </div>
            <div className="list-table">
                <div>9.6</div>
                <div>
OKEO has the right to record and store any Payment Orders submitted by any of the means agreed on
                with OKEO, and to record and store information about all Payment Transactions performed on behalf of
                the Client or otherwise affecting the balance of the Client’s Account. Records mentioned in this clause
                may be submitted by OKEO to the Client or third persons, who have the right to receive such data under
                the basis set forth in the applicable laws, as evidence confirming the submission of Payment Orders or
                execution of Payment Transactions.

                </div>
            </div>
            <div className="list-table">
                <div>9.7</div>
                <div>
OKEO has the right to refuse execution of the Payment Order in case of a reasonable doubt whether the
                Payment Order has been submitted by the authorized Representative of the Client, or whether the
                Payment Order or the submitted documents are legitimate. In such cases, OKEO has the right to demand
                from the Client to additionally confirm the submitted Payment Order or submit documents confirming
                the rights of persons to manage the funds held on the Account or other documents indicated by OKEO
                in a way acceptable to OKEO and at expense of the Client. In cases mentioned in this point OKEO acts
                with the aim to protect legal interests of the Client, OKEO or other persons. Thus, OKEO does not
                undertake the responsibility for losses which may arise due to refusal of the execution of the Payment
                Transaction related to the submitted Payment Order.

                </div>
            </div>
            <div className="list-table">
                <div>9.8</div>
                <div>
Before execution of the Payment Transaction in line with the Payment Order submitted on behalf of the
                Client, OKEO has a right to demand from the Client documents which prove the legal source of money
                related to the Payment Order. In case the Client does not submit such documents, OKEO has the right to
                refuse to execute the Payment Transaction related to that Payment Order.

                </div>
            </div>
            <div className="list-table">
                <div>9.9</div>
                <div>
OKEO has the right to involve third parties to partially or fully execute the Payment Transaction instructed
                on behalf of the Client, if it is in the Client’s interest or the nature of the Payment Transaction requires
                so. If the Payment Transaction requires sending and executing the Payment Transaction further by
                another financial institution, but this institution suspends the Payment Transaction, OKEO is not
                responsible for such actions of that financial institution, but makes attempts to find out the reasons for
                the suspension of the Payment Transaction. OKEO has the right to suspend or terminate the execution
                of the Payment Transaction of the Client, if required by law or in case it is necessary for other reasons
                beyond the control of OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>9.10</div>
                <div>
If OKEO refuses to execute the Payment Transaction in line with the Payment Order submitted on behalf
                of the Client, OKEO shall immediately inform the Client thereon or create necessary conditions for the
                Client to get acquainted with such notification, except when such notification is technically impossible or
                forbidden by legal acts.

                </div>
            </div>
            <div className="list-table">
                <div>9.11</div>
                <div>
OKEO shall not accept Payment Orders or execute Payment Transactions associated with the Account on
                which the funds are arrested, or when the right of the Client to manage the funds on the Account is
                otherwise legally limited, or in case operations are suspended by the applicable legal acts. This will not
                apply for the specified amount of the Client’s funds which cannot be arrested or legally limited by the
                applicable legal acts.

                </div>
            </div>
            <div className="list-table">
                <div>9.12</div>
                <div>
If money transferred under the Payment Transaction is returned due to reasons beyond the control of
                OKEO (e.g. inaccurate data in the Payment Order, the account of the Payee is closed, etc.), the returned
                amount is credited to the Account of the Client. Fees paid by the Client for the Payment Transaction
                execution are not returned. Other Fees related to returning of the money can be deducted from the
                Client’s Account balance (e.g. additional fee might be  charged  for the  currency   conversion  movements   in case  of the  payment  reversal, etc.).

                </div>
            </div>
            <div className="list-table">
                <div>9.13</div>
                <div>
OKEO has the right to return any incoming Payment Transaction to its initiator if the Payment Transaction
                appears suspicious in any way, and to charge the applicable Fees to the Client’s account. The Fees may
                include the standard charges for a wire transfer or a SWIFT confirmation procedure to identify the
                account number of the issuer and allocate the returned funds there. The Fee may vary depending on the
                destination, currency and, if applies, foreign currency conversion rate.

                </div>
            </div>

            <div className="list-table">
                <div>9.14</div>
                <div>
The Client and the User agree that OKEO will transfer personal data of the User and to third parties
                directly related to the execution of the Payment Transactions, such as international payment card
                organizations, companies processing information about payments by payment cards, the provider of
                Payment Services of the Payee, the operator of the payment system for execution of the Payment
                Transaction, intermediaries of the provider of Payment Services of the Client and the Payee, currency exchange providers.

                </div>
            </div>

            <div className="list-table">
                <div>9.15</div>
                <div>
If necessary or required by institutions of other states, OKEO has the right to receive additional
                information   (e.g.   name   and   surname   or   the   title   of   the   Payee,   a   payment   code,   a   copy   of
                the identity document)  and other documents necessary to ensure Anti-Money Laundering and
                Counter Terrorism Financing implementation measures  required for the appropriate execution
                of the Payment Transaction

                </div>
            </div>

            <div className="list-table">
                <div>9.16</div>
                <div>
When executing Payment Transactions initiated on behalf of the Client, OKEO shall transmit to the
                Payment Service Provider of the Payee the information (including personal data of the User) associated
                with the Payment Order.

                </div>
            </div>

            <div className="list-table">
                <div>9.17</div>
                <div>
                When executing Payment Transactions involving the currency conversion, initiated on behalf of a Clients, OKEO shall transmit to the Currency Exchange Provider the information (including personal data of the User and the Payee) associated with the Payment Order (e.g. personal details from the following categories: the Client’s and Payee's name and number of the payment account, Client’s identification number, title of the Payment). OKEO may also make available other personal data if required by the Currency Exchange Provider, including: the Client's and Payee’s date of birth, the origin of assets which are the subject of the transaction, information about the circumstances of the transaction and other data which identify both the Client and the Payee.
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>10.</div>
                <div>
                    <h3 id="page_10" className="scroll-margin">Additional rules on Accounts management</h3>
                </div>
            </div>
            <div className="list-table">
                <div>10.1</div>
                <div>
                The Client agrees that OKEO, when providing the Services, may use services provided by the third parties, including but not limited to, banks, electronic money institutions, payment institutions, currency exchange providers and other financial institutions. OKEO is entitled to attract the third parties for the provision of the Services without prior agreement with the Client or consent of the Client.
                </div>
            </div>
            <div className="list-table">
                <div>10.2</div>
                <div>
Certain Clients may be subject to the advance payment requirement which allows OKEO a better
                protection against possible risks and expenses associated with particular Accounts. OKEO notifies such
                Clients of the requirement and the advance payment is only imposed with prior agreement by the Client.
                    <div className="list-table list-table-first">
                        <div>10.2.1</div>
                        <div>
Advance payment is kept for 30 (thirty) calendar days after the Account has been closed. If
                        additional expenses associated with the Account emerge after the Account has been closed,
                        OKEO may retain the advance payment for the period longer than 30 (thirty) calendar days.
                        OKEO also has a right to charge emerged expenses from the advance payment. If the amount
                        of the advance payment is not enough, OKEO might claim reimbursement of other expenses
                        appeared from the activity of the Client in the court.

                        </div>
                    </div>
                    <div className="list-table">
                        <div>10.2.2</div>
                        <div>OKEO may release the advance payment before the Account is closed.</div>
                    </div>
                    <div className="list-table">
                        <div>10.2.3</div>
                        <div>OKEO does not pay any interest on the advance payment.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>10.3</div>
                <div>
OKEO will inform the Client immediately about any noted suspected or detected fraud, as well as about
                any security threat, using e-mail, text message and, optionally, via telephone conversation.

                </div>
            </div>
            <div className="list-table">
                <div>10.4</div>
                <div>
The Client and the User acknowledge that before placing the Payment Order they shall assess the Payee
                and the basis of the underlying Payment Transaction with diligence and reasonable care, in order to
                mitigate the risk of deception.

                </div>
            </div>
            <div className="list-table">
                <div>10.5</div>
                <div>
OKEO reserves the right not to open the Account or close the Account without providing a reason to a
                Client.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>11.</div>
                <div>
                    <h3 id="page_11" className="scroll-margin">Fees</h3>
                </div>
            </div>
            <div className="list-table">
                <div>11.1</div>
                <div>
Fees for the Services (the Fees), are stated in the “Product and Fee Terms” and provided on the OKEO
                website or on a Durable Medium to a Client. Product and Fee Terms are considered an integral part of
                these Terms and Conditions. In case of discrepancies between the Fees stated directly on the website
                (i.e. its general content, e.g. home page) or other marketing materials and the Fees described in the
                Product and Fee Terms, the content of the latter prevails.

                </div>
            </div>
            <div className="list-table">
                <div>11.2</div>
                <div>
OKEO is entitled to unilaterally amend the Fees, including but not limited to changing the amount,
                frequency or time frame for charging any fees related to the Services. OKEO shall notify the Client of
                these changes at least 60 (sixty) calendar days before the new or amended Fees take effect. Once
                the notification is given, the Client has the right to withdraw from the contracts concluded with OKEO as
                described in points 15.4 and 15.5.

                </div>
            </div>
            <div className="list-table">
                <div>11.3</div>
                <div>
                Each time the User places the Payment Order from the balance in the currency that is different from the currency of the Payment Transaction, the User shall be informed, before the Payment Order is confirmed, about the currency conversion rate for the Payment Transaction and the Fees associated with the conversion. The currency conversion rate for the Payment Transaction is valid for a limited amount of time. Additional fees for the currency conversion might be applied during non-business hours.
                </div>
            </div>
            <div className="list-table">
                <div>11.4</div>
                <div>
OKEO may charge Fees if the received funds have to be converted to another currency before it is
                possible to credit them to the Client’s Account.

                </div>
            </div>
            <div className="list-table">
                <div>11.5</div>
                <div>
A bank or other Payment Service Provider may apply the fees for transferring money from or to
                the Client’s Account.

                </div>
            </div>
            <div className="list-table">
                <div>11.6</div>
                <div>
All of the Fees are non-refundable. All of the Fees are paid and charged in EUR unless otherwise stated
                in the Product and Fee Terms.

                </div>
            </div>
            <div className="list-table">
                <div>11.7</div>
                <div>
OKEO has a right to charge the Fees for non-closed Accounts irrespective whether the Client performs
                the Payment Transactions or not.

                </div>
            </div>
            <div className="list-table">
                <div>11.8</div>
                <div>
OKEO has a right to deduct the Fees from the funds held on the Account. Fees are debited to the Client’s
                Account and decrease its balance.

                </div>
            </div>
            <div className="list-table">
                <div>11.9</div>
                <div>
The Client commits to ensure that there will be sufficient funds on the Account and in the relevant
                currency to cover all the Fees payable to OKEO. If the Client does not ensure sufficient funds to cover the
                Fees payable to OKEO, OKEO may continue charging the Fees, including the penalty fees, or close the
                Account without prior order or consent of the Client.

                </div>
            </div>
            <div className="list-table">
                <div>11.10</div>
                <div>
The User confirms that he/she has carefully read and are acquainted with the content of the Product and
                Fee Terms.

                </div>
            </div>
            <div className="list-table">
                <div>11.11</div>
                <div>
OKEO is obliged to provide the Client, at the beginning of the calendar year, with the statement of Fees
                and commissions charged during previous calendar year. The statement is sent by OKEO via e-mail to
                the e-mail address provided by the Client and visible in My OKEO. Upon the termination of the Terms
                and Conditions the statement of the Fees and commissions is provided for the period since the beginning
                of the calendar year until the day of agreement termination.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>12.</div>
                <div>
                    <h3 id="page_12" className="scroll-margin">Prohibited actions</h3>
                </div>
            </div>
            <div className="list-table">
                <div>12.1</div>
                <div>

                When using the Services or in connection to the Services, the Client and the User are forbidden from:
                    <div className="list-table list-table-first">
                        <div>12.1.1</div>
                        <div>
violating the rights of OKEO or its parent undertaking to trademarks, copyrights, commercial
                        secrets and other intellectual property rights;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>12.1.2</div>
                        <div>providing false, misleading or incorrect information;</div>
                    </div>
                    <div className="list-table">
                        <div>12.1.3</div>
                        <div>transferring or receiving money acquired in illegal manner;</div>
                    </div>
                    <div className="list-table">
                        <div>12.1.4</div>
                        <div>
spreading computer malware and undertaking other measures which could cause the System
                        malfunctions, damage or distraction of information or cause other damage to the systems and
                        equipment;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>12.1.5</div>
                        <div>
undertaking any other deliberate measures which could cause the System malfunctions,
                        damage or distraction of information or cause other damage to the systems, equipment or
                        third parties;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>12.1.6</div>
                        <div>providing financial services without a prior consent of OKEO;</div>
                    </div>
                    <div className="list-table">
                        <div>12.1.7</div>
                        <div>
organising illegal gambling, trading in guns, drugs, prescription medicine, steroids,
                        pornographic production, unlicensed lottery or illegal trade of software;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>12.1.8</div>
                        <div>
providing other services or products which are prohibited by the laws or in conflict with the
                        public order or good morals.

                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>12.2</div>
                <div>
The Client and the User are obliged to provide information requested by OKEO regarding Client’s
                operations and the usage of the Services.

                </div>
            </div>
            <div className="list-table">
                <div>12.3</div>
                <div>The Client and the User are obliged to cooperate with OKEO in investigation of potential violations.</div>
            </div>
            <div className="list-table list-table-header">
                <div>13.</div>
                <div>
                    <h3 id="page_13" className="scroll-margin">Communication</h3>
                </div>
            </div>
            <div className="list-table list-table-first">
                <div>13.1</div>
                <div>
The Client and the User agree and confirm that that communication from OKEO handled via the System
                (e.g. messages sent to and available in My OKEO) is considered to be submitted properly and delivered
                effectively to the Client and the User. It is considered that the Client has received communication within
                24 hours since it has been made available in My OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>13.2</div>
                <div>
All communication between the Client and OKEO, or between the User associated with the Client and
                OKEO, shall be performed in the Acceptable Language.

                </div>
            </div>
            <div className="list-table">
                <div>13.3</div>
                <div>
The Client and the User acknowledge that OKEO is a digital-first organisation and as a general rule does
                not provide the paper documents and does not use the seal.

                </div>
            </div>
            <div className="list-table">
                <div>13.4</div>
                <div>
The Client and the User are obliged to keep the telephone number, e-mail address and other information,
                submitted while Registration or at later stage, up to date and immediately, and not later than within 5
                (five) Business Days since the change, inform OKEO about the change. If the User uses his telephone
                number or e-mail address as a login to My OKEO, the term specified in this clause shall be replaced with
                1 (one) Business Day with respect to this information.

                </div>
            </div>
            <div className="list-table">
                <div>13.5</div>
                <div>
Each time the information indicated in 13.4 changes, OKEO has the right to request and obtain relevant
                documents or other proof underlying the change.

                </div>
            </div>
            <div className="list-table">
                <div>13.6</div>
                <div>
The Client and the User acknowledge that all communication with OKEO shall be performed using the
                contact details as provided to OKEO and visible in My OKEO, unless such communication is performed
                directly through the system enabled by OKEO (e.g. dedicated customer support system).

                </div>
            </div>
            <div className="list-table">
                <div>13.7</div>
                <div>
The Client and the User confirm that they have access to the internet and agree that OKEO may provide
                the Clients and the Users with non-confidential or not personally addressed information by publishing it
                on the
                    {' '}
                    <a href="https://okeo.com/" target="_blank" rel="noopener noreferrer">OKEO website</a>
.

                </div>
            </div>
            <div className="list-table">
                <div>13.8</div>
                <div>
OKEO has the right to change the information on its website any time. OKEO has no obligation to notify
                Clients or Users about such change, unless it is a change to the Terms and Conditions, Product and Fee
                Terms,
                    {' '}
                    <a href="https://okeo.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                    {' '}
or
                other legal
                documents related directly to Services.

                </div>
            </div>
            <div className="list-table">
                <div>13.9</div>
                <div>
OKEO is obligated to provide to the Client, upon Client request, information about the Terms and
                Conditions, as well as any other information, related to the Services, on a Durable Medium in the format
                selected by OKEO. Additionally, OKEO will provide information related to the Services on regular basis on
                the
                    {' '}
                    <a href="https://okeo.com/" target="_blank" rel="noopener noreferrer">OKEO website</a>
.

                </div>
            </div>
            <div className="list-table">
                <div>13.10</div>
                <div>
Before the conclusion of the agreement or offer to conclude the agreement, OKEO provides
                the possibility to familiarize with these Terms and Conditions by publishing its content directly on a
                dedicated website (and giving the possibility to download the current version from the aforementioned
                website) or providing it on a Durable Medium to a Client.

                </div>
            </div>

            <div className="list-table">
                <div>13.11</div>
                <div>
OKEO intends to keep on its website the subsequent (including non-current) versions of the Terms and
                Conditions published on its website.

                </div>
            </div>

            <div className="list-table">
                <div>13.12</div>
                <div>
                OKEO will never ask Users to (the list is not exhaustive):
                    <ul>
                        <li>provide any confidential information (e.g., passwords, PINs) over the phone or via email;</li>
                        <li>connect remotely to the User’s device;</li>
                        <li>open a link, leading to a domain other than okeo.com.</li>
                    </ul>
                    <div>The User is obliged to immediately report to OKEO any suspicious activity or inquiries.</div>
                </div>
            </div>

            <div className="list-table list-table-header">
                <div>14.</div>
                <div>
                    <h3 id="page_14" className="scroll-margin">Client information</h3>
                </div>
            </div>
            <div className="list-table">
                <div>14.1</div>
                <div>
OKEO considers data privacy matters as those of the supreme importance and therefore adopts strict
                rules on protection of the personal data and other sensitive data related to the Clients. OKEO undertakes
                to protect any Client-related information in accordance with the
                    <a href="https://okeo.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
, other
                internal
                policies and
                the applicable laws.

                </div>
            </div>
            <div className="list-table">
                <div>14.2</div>
                <div>
The Client and the User agree that the Personal Data is being processed according to the
                    <a href="https://okeo.com/legal/privacy-policy/" target="_blank" rel="noopener noreferrer"> Privacy Policy </a>
                and that they have familiarised themselves with its content.

                </div>
            </div>
            <div className="list-table">
                <div>14.3</div>
                <div>
The Client agrees that OKEO may record or monitor telephone conversations and other communication
                between Clients and the OKEO staff, or between the Users associated with the Client and the OKEO staff,
                in order to improve the quality of services or for the verification purposes.

                </div>
            </div>
            <div className="list-table">
                <div>14.4</div>
                <div>
OKEO cannot guarantee that the information sent over the internet when using the OKEO website is fully
                secure. The Clients provide the information at their own risk.

                </div>
            </div>
            <div className="list-table">
                <div>14.5</div>
                <div>
OKEO stores personal information about the Client and the User and the history of the Payment
                Transactions and other operations related to the Account, as required by the applicable law. Closing of
                the OKEO Account does not result in the disposal of such data, unless the Client or the User requests
                such disposal in pursuit of his/her legal rights.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>15.</div>
                <div>
                    <h3 id="page_15" className="scroll-margin">Amendments. Validity. Termination</h3>
                </div>
            </div>
            <div className="list-table">
                <div>15.1</div>
                <div>The Terms and Conditions are valid for an indefinite period.</div>
            </div>
            <div className="list-table">
                <div>15.2</div>
                <div>

                These Terms and Conditions are subject to change from time to time in order to:
                    <div className="list-table list-table-first">
                        <div>15.2.1</div>
                        <div>reflect new business, legal and other circumstances affecting OKEO;</div>
                    </div>
                    <div className="list-table">
                        <div>15.2.2</div>
                        <div>make the Terms and Conditions more transparent and, if applicable, correct the mistakes;</div>
                    </div>
                    <div className="list-table">
                        <div>15.2.3</div>
                        <div>ensure reasonable and competitive business operations;</div>
                    </div>
                    <div className="list-table">
                        <div>15.2.4</div>
                        <div>represent changes in market conditions, banking practices or cost of the Services.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>15.3</div>
                <div>
OKEO shall notify the Client about any change of the Terms and Conditions not later than 60 (sixty)
                calendar days before intended changes. Information about the intended changes shall be communicated
                to the Client and announced on the website of OKEO or communicated via e-mail.

                </div>
            </div>
            <div className="list-table">
                <div>15.4</div>
                <div>
Once the notification is given, The Client has the right to withdraw from the contracts concluded with
                OKEO free of charge by giving written termination notice to OKEO (e.g. via e-mail) before the new or
                amended Terms and Conditions come into force. If the termination notice is not given before the new or
                amended Terms and Conditions come into force, they are considered accepted by the Client.

                </div>
            </div>
            <div className="list-table">
                <div>15.5</div>
                <div>
The right of withdrawal from the contract stated in clause 15.4 shall not apply if only the following
                applies:
                    <div className="list-table list-table-first">
                        <div>15.5.1</div>
                        <div>Terms and Conditions were changed due to changes in laws;</div>
                    </div>
                    <div className="list-table">
                        <div>15.5.2</div>
                        <div>the costs of the Services are reduced;</div>
                    </div>
                    <div className="list-table">
                        <div>15.5.3</div>
                        <div>Terms and Conditions are changed in a way that put the Client in a more favourable position</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>15.6</div>
                <div>
The Client is entitled to unilaterally terminate the Terms and Conditions with a prior 15 (fifteen) calendar
                days’ written notice (including via e-mail) to OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>15.7</div>
                <div>
OKEO is entitled to unilaterally terminate the Terms and Conditions and cease provision of the Services
                with a prior 30 (thirty) calendar days’ written notice (including via e-mail) to the Client. OKEO is not
                obliged
                to specify the reason.

                </div>
            </div>
            <div className="list-table">
                <div>15.8</div>
                <div>
OKEO can immediately terminate business relationship with the Client, including all concluded
                agreements between the Parties, and cease provision of Services in the following cases:
                    <div className="list-table list-table-first">
                        <div>15.8.1</div>
                        <div>
the Client does not ensure sufficient funds on the Account to cover the Fees and other charges
                        due;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>15.8.2</div>
                        <div>
the Client is in breach (or it is probable that the Client is in breach) of any relevant country law
                        or regulation;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>15.8.3</div>
                        <div>the Client breaches representations and warranties provided;</div>
                    </div>
                    <div className="list-table">
                        <div>15.8.4</div>
                        <div>
certain conduct by the Client or specific circumstances occur, which are specified in other
                        internal rules of OKEO as reasons for the termination of the business relationship;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>15.8.5</div>
                        <div>OKEO believes that the Client no longer adheres to the OKEO Client profile.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>15.9</div>
                <div>
Upon the termination of the Terms and Conditions by the Client, OKEO is obligated to provide the Client
                (free of charge, on a Durable Medium in the format selected by OKEO) with the Client’s Account Payment
                Transactions’ history for the period of not less than 36 months (or for the whole term of the agreement
                if it came into force less than 36 months before its termination). OKEO is not obliged to provide
                information specified in this paragraph if the Client voluntarily refuses to receive such information
                notifying OKEO about that on paper or on another Durable Medium before the termination of the Terms
                and Conditions.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>16.</div>
                <div>
                    <h3 id="page_16" className="scroll-margin">Suspensions of Services</h3>
                </div>
            </div>
            <div className="list-table">
                <div>16.1</div>
                <div>
OKEO, at its own discretion, has a right to unilaterally, and without prior notice to the Client, apply one
                or several of the following measures:
                    <div className="list-table list-table-first">
                        <div>16.1.1</div>
                        <div>suspend execution of the Services;</div>
                    </div>
                    <div className="list-table">
                        <div>16.1.2</div>
                        <div>limit Client’s or User’s access to the Account or User Account;</div>
                    </div>
                    <div className="list-table">
                        <div>16.1.3</div>
                        <div>refuse to provide the Services.</div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>16.2</div>
                <div>

                Measures indicated in clause 16.1 can be applied in the following cases:
                    <div className="list-table list-table-first">
                        <div>16.2.1</div>
                        <div>
the Client or the User violated the Terms and Conditions or there is a justified suspicion that
                        such violation took place;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.2</div>
                        <div>
actions of the Client or the User can harm reputation of OKEO or might cause losses, monetary
                        sanctions or other negative consequences to OKEO;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.3</div>
                        <div>submitted Payment Order is incomplete or incorrect;</div>
                    </div>
                    <div className="list-table">
                        <div>16.2.4</div>
                        <div>the circumstances beyond the control of OKEO have occurred;</div>
                    </div>
                    <div className="list-table">
                        <div>16.2.5</div>
                        <div>
cases laid down by the applicable laws, which allow OKEO to apply measures indicated in
                        clause 16.1, took place;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.6</div>
                        <div>
OKEO becomes aware of the User’s death, Client’s lack of legal capacity, bankruptcy or an
                        insolvency;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.7</div>
                        <div>
OKEO becomes aware of the dispute over the operation of Client’s Account or Client’s
                        ownership of funds on the Account or that an interest in Client’s Account has been claimed by
                        a third party;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.8</div>
                        <div>
OKEO has reasons to believe that the Representative or the User or related third persons
                        behave in an incorrect manner towards the officials of OKEO (e.g. in a frightening, threatening
                        or aggressive manner);

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.9</div>
                        <div>
OKEO has reasons to believe that the Representative has used or is still using the Account(s)
                        while the competency or authorisation of the Representative raises doubts;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.10</div>
                        <div>
OKEO has reasons to believe that the Client, or Payment Transactions related to the Client’s
                        Account, are subject to an international payments sanction regime;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.11</div>
                        <div>
OKEO has reasons to believe that the Client, the User or related third persons have used or are
                        using the Account illicitly or fraudulently;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.12</div>
                        <div>
the Client is failing to provide to OKEO the information or documentation, in the scope as
                        stated in these Terms and Conditions, when it is required by OKEO or in a form which is
                        acceptable by OKEO;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.13</div>
                        <div>the Client’s Account has never been used, or has not been used for an extended period of time;</div>
                    </div>
                    <div className="list-table">
                        <div>16.2.14</div>
                        <div>OKEO reasonably suspects the dishonestly of the Client’s or User’s actions;</div>
                    </div>
                    <div className="list-table">
                        <div>16.2.15</div>
                        <div>
there is a legal requirement to terminate relations with the Client, or a court or any other
                        authority requires suspending or restricting the operations on the Account or the provision of
                        any other Services;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>16.2.16</div>
                        <div>
OKEO reasonably suspects an unauthorized or fraudulent use of money on the Account or of
                        the Payment Instruments.

                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>16.3</div>
                <div>
OKEO shall immediately inform the Client about application of the measures indicated in the clause 16.1
                and about the possibility to return the money which belongs to the Client within 3 (three) Business Days
                since provision of the instructions by the Client regarding the money redemption (see clause 5.5 for
                redemption options), except for the cases when provision of such information would weaken safety
                measures or is forbidden by the legal acts.

                </div>
            </div>
            <div className="list-table">
                <div>16.4</div>
                <div>
In case OKEO reasonably suspects that someone has hacked the System or the Account or the User
                Account, OKEO has a right to suspend the provision of some or all Services to the Client.

                </div>
            </div>
            <div className="list-table">
                <div>16.5</div>
                <div>
In case OKEO reasonably suspects or finds out about illegal purchase or unauthorized usage of
                the Payment Instruments, or about facts or suspicions that personalized safety features of the Payment
                Instrument have become known or may be used by third persons, or OKEO has reasonable suspicions
                that funds on the Account or Payment Instrument may be illegally used by third persons, OKEO can
                suspend all Payment Transactions associated with that Payment Instrument.

                </div>
            </div>
            <div className="list-table">
                <div>16.6</div>
                <div>
Suspension or termination of the Services does not exempt the Client from the execution of all
                responsibilities to OKEO which have arisen till the suspension or termination.

                </div>
            </div>
            <div className="list-table">
                <div>16.7</div>
                <div>
When concluded agreements between the Client and OKEO are terminated, the Client shall provide
                instruction for redemption of the Electronic Money from the Client’s Accounts. The Client shall be obliged
                to perform all necessary actions to redeem Electronic Money. In case the Client does not choose a
                method to redeem the Electronic Money or does not perform necessary actions to redeem the Electronic
                Money, OKEO can redeem Electronic Money of the Client by the method of redemption of Electronic
                Money which is available at the moment of the redemption.

                </div>
            </div>
            <div className="list-table">
                <div>16.8</div>
                <div>
OKEO can suspend Client’s access to the Services without any prior notice in case the validity of
                the authorisation of the Representative has expired or the Representative authorisation has been
                cancelled or when OKEO was not informed about the renewal of such authorisation.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>17.</div>
                <div>
                    <h3 id="page_17" className="scroll-margin">Liability of the Parties</h3>
                </div>
            </div>
            <div className="list-table">
                <div>17.1</div>
                <div>
The breaching Party is liable for all fines, forfeits and other losses which the other Party incurs due to
                the
                violation of the Terms and Conditions and other internal rules or agreements concluded by the Parties.
                The breaching Party undertakes to reimburse direct damage incurred due to such liability to the affected
                Party. In all cases, liability of OKEO is limited by the following provisions:
                    <div className="list-table list-table-first">
                        <div>17.1.1</div>
                        <div>
OKEO shall only be liable for direct damages caused by direct and essential breach of
                        the Terms and Conditions made by OKEO, and only for damages which could have been
                        foreseen by OKEO at the time of breaching of the Terms and Conditions, other internal rules
                        or agreements concluded;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.1.2</div>
                        <div>
The amount of compensation for damages caused by violation of the Terms and Conditions,
                        internal rules or concluded agreements shall not exceed the monthly average of the Fees for
                        the last 3 months, paid to OKEO by the Client for the Services, or over the shorter period if the
                        Services has been provided over the shorter period. This restriction is applied for the total
                        amount of all violations during the month. In case the aforementioned average cannot be
                        calculated due to objective reasons, the compensation cannot exceed 500 EUR;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.1.3</div>
                        <div>
In any case, OKEO shall not be liable for non-receipt of profit and income by the Client, loss of
                        reputation of the Client, loss or failure of Client’s business, and indirect damages;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.1.4</div>
                        <div>
Limitations of OKEO liability shall not be applied if such limitations are prohibited by
                        the applicable law.

                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>17.2</div>
                <div>
The Client shall be obliged to leave OKEO and its partners engaged in the provision of the Services
                harmless and free from any claim, expenses or costs (including legal expenses and fines) that OKEO
                incurs or suffers due to the breach of the Terms and Conditions or breach of any applicable law or
                regulation.

                </div>
            </div>
            <div className="list-table">
                <div>17.3</div>
                <div>
OKEO does not guarantee uninterrupted System operations, because System operations may be
                influenced and disordered by many factors beyond control of OKEO. OKEO shall undertake all reasonable
                efforts to ensure uninterrupted System’s operations and availability, however, OKEO shall not be liable
                for consequences resulting from the System operation disorders if such disorders occur not due to the
                fault of OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>17.4</div>
                <div>
Cases when OKEO limits access to the System temporarily, but not longer than for 10 hours, due to the
                System repair or maintenance works and other similar cases, and when OKEO informs the Client about
                such cases at least 3 (three) Business Days in advance, shall not be considered System operation
                disorders.

                </div>
            </div>
            <div className="list-table">
                <div>17.5</div>
                <div>

                OKEO is not liable for:
                    <div className="list-table list-table-first">
                        <div>17.5.1</div>
                        <div>
Payment Transactions concerning the funds held on the Account if the Client or the User did
                        not protect the Password or the User Credentials, and as a result they have become known
                        to the other persons,

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.2</div>
                        <div>
illegal actions and operations of third persons performed using counterfeited or illegal
                        documents or illegally received data;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.3</div>
                        <div>errors and late or missed transactions made by banks, billing systems and other third parties;</div>
                    </div>
                    <div className="list-table">
                        <div>17.5.4</div>
                        <div>
consequences arising from disturbances of fulfilment of any OKEO obligations caused by a
                        third party which is beyond control of OKEO;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.5</div>
                        <div>
consequences arising from the suspension, termination or limitation of access to the Services,
                        if these actions were performed by OKEO in line with these Terms and Conditions,

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.6</div>
                        <div>
any loss or inconvenience which results from the Client’s failure to keep the Client data up to
                        date, as required by these Terms and Conditions;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.7</div>
                        <div>
goods and services purchased using the Account, both towards the Client as well as towards
                        other party, which receives payments from the Account;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.8</div>
                        <div>
for a failure to fulfil by the Client its own contractual obligations and related damages, in case
                        it was caused due to OKEO fulfilling duties determined by the law;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.5.9</div>
                        <div>any disruption of the provision of the Services;</div>
                    </div>
                    <div className="list-table">
                        <div>17.5.10</div>
                        <div>
actions of the banks, other Payment Service Providers and other third parties actions which
                        are beyond the control of OKEO;

                        </div>
                    </div>

                    <div className="list-table">
                        <div>17.5.11</div>
                        <div>
losses bared due to Payment Transactions if the Client incurred them due to Client’s or User’s
                        dishonest actions or gross negligence or by not adhering to these Terms and Conditions;

                        </div>
                    </div>

                    <div className="list-table">
                        <div>17.5.12</div>
                        <div>
any indirect or consequential losses, including but not limited to, loss of profit, loss of
                        reputation.

                        </div>
                    </div>

                    <div className="list-table">
                        <div>17.5.13</div>
                        <div>consequences arising because of malicious activities in the environments beyond OKEO‘s control</div>
                    </div>

                </div>
            </div>
            <div className="list-table">
                <div>17.6</div>
                <div>The Client is fully liable for correctness of data, orders and documents submitted to OKEO.</div>
            </div>
            <div className="list-table">
                <div>17.7</div>
                <div>
The Client bears all the losses that have arisen due to unauthorized Payment Transactions and lost, stolen
                or illegally acquired Payment Instruments.

                </div>
            </div>
            <div className="list-table">
                <div>17.8</div>
                <div>
The Client bears all the losses that have arisen due to unauthorized Payment Transactions and lost, stolen
                or illegally acquired Payment Instruments.
                    <div className="list-table list-table-first">
                        <div>17.8.1</div>
                        <div>
The Client shall comply with the rules regulating issuance and usage of the Payment
                        Instruments, as stated in these Terms and Conditions and in the terms and conditions of using
                        Payments Instruments which were provided while ordering the Payment Instrument.

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.8.2</div>
                        <div>
If the Client finds out about a loss, theft, illegal acquisition or unauthorized usage of the
                        Payment Instrument, about facts and suspicions that personalized security features of his/her
                        Payment Instrument(s) have become known to or can be used by third persons, the Client
                        shall notify OKEO or the party indicated by OKEO immediately, in accordance with the rules
                        regulating issuance and usage of the Payment Instrument provided in these Terms and
                        Conditions and in the terms and conditions of using Payments Instruments which were
                        provided while ordering the Payment Instrument;

                        </div>
                    </div>
                    <div className="list-table">
                        <div>17.8.3</div>
                        <div>
The Client and the User shall undertake all possible measures to protect personalized security
                        features of the Payment Instrument after the Payment Instrument has been issued.

                        </div>
                    </div>
                </div>
            </div>
            <div className="list-table">
                <div>17.9</div>
                <div>
If the Client’s or User’s actions resulted in losses, fines or legal costs incurred by OKEO, OKEO has the
                right to debit the respective amounts from the Client’s Account(s). If these funds are not sufficient to
                cover the losses, fines and legal costs, OKEO can apply to the court.

                </div>
            </div>
            <div className="list-table">
                <div>17.10</div>
                <div>
The Client shall check information about Payment Transactions performed on the Account and notify
                OKEO about unauthorized or improperly executed Payment Transactions, and about any other errors,
                inconsistencies or inaccuracies noticed. The notification shall be submitted not later than 60 (sixty)
                calendar days after the day when OKEO, according to the Client, has performed the unauthorized
                Payment Transaction or has performed the Payment Transaction improperly. If the Client does not
                submit specified notifications within the time period indicated, it is considered that the Client has
                unconditionally agreed to the Payment Operations, that had been executed on the Account.

                </div>
            </div>
            <div className="list-table">
                <div>17.11</div>
                <div>
The Client shall submit to OKEO any information about illegal login attempts (successful and
                unsuccessful) to the Account or other illegal actions related to the Account, and undertake all reasonable
                measures indicated by OKEO in order to help in investigating the illegal actions.

                </div>
            </div>
            <div className="list-table">
                <div>17.12</div>
                <div>
The Party is relieved from the liability for failure to comply with the Terms and Conditions or other
                internal rules or agreements concluded in case the Party proves that obligations have not been executed
                due to circumstances of force majeure which are proven in accordance with the procedure established
                by the law. The Client shall notify OKEO about the force majeure in writing (e.g. via e-mail) within 10
                (ten)
                calendar days after the day of occurrence of such circumstances.

                </div>
            </div>
            <div className="list-table">
                <div>17.13</div>
                <div>
The Client is solely and fully liable for the payment of taxes (or any state duties) in the country of
                the Client’s tax residency. The Client is solely liable for the registration and declaration of the Client’s
                accounts according to the legislative acts of the country of the Client’s residency.

                </div>
            </div>
            <div className="list-table list-table-header">
                <div>18.</div>
                <div>
                    <h3 id="page_18" className="scroll-margin">Final provisions</h3>
                </div>
            </div>
            <div className="list-table">
                <div>18.1</div>
                <div>
The Terms and Conditions shall be governed by and interpreted in accordance with the laws of the
                Republic of Lithuania.

                </div>
            </div>
            <div className="list-table">
                <div>18.2</div>
                <div>
If any part of these Terms and Conditions is found invalid, unlawful or unenforceable, other terms and
                conditions shall continue to be valid and binding for the Parties.

                </div>
            </div>
            <div className="list-table">
                <div>18.3</div>
                <div>
Lithuanian deposit guarantee scheme does not apply to the accounts opened within OKEO. In case OKEO
                becomes insolvent, you may lose the money held in the accounts opened within OKEO. However, OKEO
                ensures that Clients’ funds are kept on a separate account opened at the Bank of Lithuania or other
                eligible financial institution, for the benefit of Clients of OKEO, and this account will not be used to
                keep
                funds of OKEO or other parties which are not considered to be Clients of the Services offered by OKEO.

                </div>
            </div>
            <div className="list-table">
                <div>18.4</div>
                <div>
The Client and the User acknowledge that the disputes between OKEO and the Client can be solved
                through negotiations or other means according to the rules stated in the
                    <a href="https://okeo.com/legal/complaints-policy/" target="_blank" rel="noopener noreferrer"> Complaints Policy</a>
                    {' '}
and
                confirm
                that they have familiarised themselves with its content.

                </div>
            </div>
        </LegalContent>
    </LegalWrapper>
);
