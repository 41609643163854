import React from 'react';

import { BePartBgJpg } from 'assets/images';
import { Button } from 'components/atoms/Button/Button';
import { getPageType, PageType } from 'config/path/path.helpers';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';
import { ONBOARDING_PERSONAL_URL, ONBOARDING_URL } from 'config/environment';

import { BePartProps } from './BePart.types';
import { BePartContentWrapper, BePartText, BePartTitle } from './BePart.styled';


export const BePart: React.FC<BePartProps> = ({ t }) => {

    const url = getPageType() === PageType.PERSONAL ? ONBOARDING_PERSONAL_URL : ONBOARDING_URL;

    return (
        <SectionWrapper id="be-part" backgroundImage={BePartBgJpg}>
            <BePartContentWrapper>
                <BePartTitle>
                    {t('title.label')}
                </BePartTitle>
                <BePartText>
                    {t('text.label')}
                </BePartText>
                <div>
                    <a href={url} target="_blank" rel="noreferrer">
                        <Button label={t('buttons.join.label')} />
                    </a>
                </div>
            </BePartContentWrapper>
        </SectionWrapper>
    );
};
