import styled from 'styled-components';

import { defaultCarouselPaginationStyles } from 'components/atoms/Companies/Companies.styled';
import { breakPoints, standardTransition } from 'config/layout/layout';

export const PricingTabsCarrouselWrapper = styled.div`
    max-width: 100%;
    padding-bottom: 30px;

    .pricing-tabs {
        width: 140%;
        left: 50%;
        transform: translate(calc(-50% - 20px), 0);

        .splide__track {
            padding: 15px 0;

            .splide__list {
                gap: 20px;
            }

            @media screen and (max-width: ${breakPoints.tablet}px) {
                display: block;
                margin: 0 auto;
            }

            @media screen and (max-width: ${breakPoints.mobile}px) {
                max-width: 280px;
                overflow: visible;
            }
        }

        .splide__slide {
            > div {
                opacity: 0.35;
                ${standardTransition};
                transform: scale(0.9);
            }

            &.is-active {
                > div {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }

        .splide__pagination {
            bottom: -25px;
            width: 200px;
            margin: auto;
            left: 30px;

            ${defaultCarouselPaginationStyles};
        }
    }
`;
