import { ResourceKey } from 'i18next';

import { TranslationKey, TranslationNamespace } from 'config/i18n/i18n.types';

import bepartEn from './en-GB/bepart.json';
import dictionariesEn from './en-GB/dictionaries.json';
import contactEn from './en-GB/contact.json';
import countriesEn from './en-GB/countries.json';
import empowerEn from './en-GB/empower.json';
import faqEn from './en-GB/faq.json';
import footerEn from './en-GB/footer.json';
import navbarEn from './en-GB/navbar.json';
import pricingEn from './en-GB/pricing.json';
import smartEn from './en-GB/smart.json';
import welcomeEn from './en-GB/welcome.json';
import whyOkeoEn from './en-GB/why-okeo.json';
import bepartLt from './lt-LT/bepart.json';
import contactLt from './lt-LT/contact.json';
import countriesLt from './lt-LT/countries.json';
import empowerLt from './lt-LT/empower.json';
import faqLt from './lt-LT/faq.json';
import footerLt from './lt-LT/footer.json';
import navbarLt from './lt-LT/navbar.json';
import pricingLt from './lt-LT/pricing.json';
import smartLt from './lt-LT/smart.json';
import welcomeLt from './lt-LT/welcome.json';
import whyOkeoLt from './lt-LT/why-okeo.json';
import accountAccumulativLt from './lt-LT/accumulative/accountAccumulative.json';
import bepartPl from './pl-PL/bepart.json';
import contactPl from './pl-PL/contact.json';
import countriesPl from './pl-PL/countries.json';
import empowerPl from './pl-PL/empower.json';
import faqPl from './pl-PL/faq.json';
import footerPl from './pl-PL/footer.json';
import navbarPl from './pl-PL/navbar.json';
import pricingPl from './pl-PL/pricing.json';
import smartPl from './pl-PL/smart.json';
import welcomePl from './pl-PL/welcome.json';
import whyOkeoPl from './pl-PL/why-okeo.json';

import accountEn from './en-GB/personal/account.json';
import benefitsEn from './en-GB/personal/benefits.json';
import dedicatedIbanEn from './en-GB/personal/dedicatedIban.json';
import paymentsEn from './en-GB/personal/payments.json';
import openEn from './en-GB/personal/open.json';
import cardEn from './en-GB/personal/card.json';
import pricingPersonalEn from './en-GB/personal/pricingPersonal.json';
import trustEn from './en-GB/personal/trust.json';
import calculatorEn from './en-GB/personal/calculator.json';
import notificationsAndLimitsEn from './en-GB/personal/notificationsAndLimits.json';
import howToOpenEn from './en-GB/personal/howToOpen.json';
import faqPersonalEn from './en-GB/personal/faqPersonal.json';



import accountAccumulativeEn from './en-GB/accumulative/accountAccumulative.json';
import openAccumulativeEn from './en-GB/accumulative/openAccumulative.json';
import pricingAccumulativeEn from './en-GB/accumulative/pricingAccumulative.json';
import howToOpenAccumulativeEn from './en-GB/accumulative/howToOpenAccumulative.json';



type Locales = {
    [language: string]: {
        [ns in TranslationNamespace]: ResourceKey;
    };
};

const enLocales: Locales = {
    [TranslationKey.EN]: {
        [TranslationNamespace.NAVBAR]: navbarEn,
        [TranslationNamespace.BEPART]: bepartEn,
        [TranslationNamespace.FAQ]: faqEn,
        [TranslationNamespace.WELCOME]: welcomeEn,
        [TranslationNamespace.EMPOWER]: empowerEn,
        [TranslationNamespace.WHY_OKEO]: whyOkeoEn,
        [TranslationNamespace.SMART]: smartEn,
        [TranslationNamespace.PRICING]: pricingEn,
        [TranslationNamespace.FOOTER]: footerEn,
        [TranslationNamespace.COUNTRIES]: countriesEn,
        [TranslationNamespace.CONTACT]: contactEn,
        [TranslationNamespace.DICTIONARIES]: dictionariesEn,

        [TranslationNamespace.ACCOUNT]: accountEn,
        [TranslationNamespace.BENEFITS]: benefitsEn,
        [TranslationNamespace.DEDICATED_IBAN]: dedicatedIbanEn,
        [TranslationNamespace.PAYMENTS]: paymentsEn,
        [TranslationNamespace.OPEN]: openEn,
        [TranslationNamespace.CARD]: cardEn,
        [TranslationNamespace.PRICING_PERSONAL]: pricingPersonalEn,
        [TranslationNamespace.TRUST]: trustEn,
        [TranslationNamespace.CALCULATOR]: calculatorEn,
        [TranslationNamespace.NOTIFICATIONS_AND_LIMITS]: notificationsAndLimitsEn,
        [TranslationNamespace.HOW_TO_OPEN]: howToOpenEn,
        [TranslationNamespace.FAQ_PERSONAL]: faqPersonalEn,

        [TranslationNamespace.ACCOUNT_ACCUMULATIVE]: accountAccumulativeEn,
        [TranslationNamespace.OPEN_ACCUMULATIVE]: openAccumulativeEn,
        [TranslationNamespace.PRICING_ACCUMULATIVE]: pricingAccumulativeEn,
        [TranslationNamespace.HOW_TO_OPEN_ACCUMULATIVE]: howToOpenAccumulativeEn,
    },
};

const plLocales: Locales = {
    [TranslationKey.PL]: {
        [TranslationNamespace.NAVBAR]: navbarPl,
        [TranslationNamespace.BEPART]: bepartPl,
        [TranslationNamespace.FAQ]: faqPl,
        [TranslationNamespace.WELCOME]: welcomePl,
        [TranslationNamespace.EMPOWER]: empowerPl,
        [TranslationNamespace.WHY_OKEO]: whyOkeoPl,
        [TranslationNamespace.SMART]: smartPl,
        [TranslationNamespace.PRICING]: pricingPl,
        [TranslationNamespace.FOOTER]: footerPl,
        [TranslationNamespace.COUNTRIES]: countriesPl,
        [TranslationNamespace.CONTACT]: contactPl,
        [TranslationNamespace.DICTIONARIES]: dictionariesEn,

        [TranslationNamespace.ACCOUNT]: accountEn,
        [TranslationNamespace.BENEFITS]: benefitsEn,
        [TranslationNamespace.DEDICATED_IBAN]: dedicatedIbanEn,
        [TranslationNamespace.PAYMENTS]: paymentsEn,
        [TranslationNamespace.OPEN]: openEn,
        [TranslationNamespace.CARD]: cardEn,
        [TranslationNamespace.PRICING_PERSONAL]: pricingPersonalEn,
        [TranslationNamespace.TRUST]: trustEn,
        [TranslationNamespace.CALCULATOR]: calculatorEn,
        [TranslationNamespace.NOTIFICATIONS_AND_LIMITS]: notificationsAndLimitsEn,
        [TranslationNamespace.HOW_TO_OPEN]: howToOpenEn,
        [TranslationNamespace.FAQ_PERSONAL]: faqPersonalEn,

        [TranslationNamespace.ACCOUNT_ACCUMULATIVE]: accountAccumulativeEn,
        [TranslationNamespace.OPEN_ACCUMULATIVE]: openAccumulativeEn,
        [TranslationNamespace.PRICING_ACCUMULATIVE]: pricingAccumulativeEn,
        [TranslationNamespace.HOW_TO_OPEN_ACCUMULATIVE]: howToOpenAccumulativeEn,
    },
};

const ltLocales: Locales = {
    [TranslationKey.LT]: {
        [TranslationNamespace.NAVBAR]: navbarLt,
        [TranslationNamespace.BEPART]: bepartLt,
        [TranslationNamespace.FAQ]: faqLt,
        [TranslationNamespace.WELCOME]: welcomeLt,
        [TranslationNamespace.EMPOWER]: empowerLt,
        [TranslationNamespace.WHY_OKEO]: whyOkeoLt,
        [TranslationNamespace.SMART]: smartLt,
        [TranslationNamespace.PRICING]: pricingLt,
        [TranslationNamespace.FOOTER]: footerLt,
        [TranslationNamespace.COUNTRIES]: countriesLt,
        [TranslationNamespace.CONTACT]: contactLt,
        [TranslationNamespace.DICTIONARIES]: dictionariesEn,

        [TranslationNamespace.ACCOUNT]: accountEn,
        [TranslationNamespace.BENEFITS]: benefitsEn,
        [TranslationNamespace.DEDICATED_IBAN]: dedicatedIbanEn,
        [TranslationNamespace.PAYMENTS]: paymentsEn,
        [TranslationNamespace.OPEN]: openEn,
        [TranslationNamespace.CARD]: cardEn,
        [TranslationNamespace.PRICING_PERSONAL]: pricingPersonalEn,
        [TranslationNamespace.TRUST]: trustEn,
        [TranslationNamespace.CALCULATOR]: calculatorEn,
        [TranslationNamespace.NOTIFICATIONS_AND_LIMITS]: notificationsAndLimitsEn,
        [TranslationNamespace.HOW_TO_OPEN]: howToOpenEn,
        [TranslationNamespace.FAQ_PERSONAL]: faqPersonalEn,

        [TranslationNamespace.ACCOUNT_ACCUMULATIVE]: accountAccumulativLt,
        [TranslationNamespace.OPEN_ACCUMULATIVE]: openAccumulativeEn,
        [TranslationNamespace.PRICING_ACCUMULATIVE]: pricingAccumulativeEn,
        [TranslationNamespace.HOW_TO_OPEN_ACCUMULATIVE]: howToOpenAccumulativeEn,
    },
};

export default { ...enLocales, ...plLocales, ...ltLocales };
