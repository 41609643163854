import { Trans } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';

import useOnScreen from 'hooks/useIsVisible';
import useWindowSize from 'hooks/useWindowSize';
import { getDeviceByWidth } from 'config/layout/layout';
import { Device, ID } from 'config/layout/layout.types';
import { Button } from 'components/atoms/Button/Button';
import { Underline } from 'pages/Home/sections/Welcome/Welcome.styled';
import { ONBOARDING_PERSONAL_URL } from 'config/environment';
import { HowToOpen1Png, HowToOpen2Png, HowToOpen3Png } from 'assets/images';
import { SectionWrapper } from 'components/atoms/SectionWrapper/SectionWrapper';

import Styled from './HowToOpen.styled';
import { HowToOpenProps } from './HowToOpen.types';


const images = [
    HowToOpen1Png,
    HowToOpen2Png,
    HowToOpen3Png,
];

export const HowToOpen: React.FC<HowToOpenProps> = ({ t }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);

    const { width } = useWindowSize();
    const device = getDeviceByWidth(width);
    const isMobile = device === Device.Mobile;

    const [activeSwitcher, setActiveSwitcher] = useState(1);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveSwitcher((prevSwitcher) => (prevSwitcher === 3 ? 1 : prevSwitcher + 1));
        }, 3000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <SectionWrapper id={ID.HowToOpen} additionalStyles="background-color: #F0F2EF">
            <>
                <Styled.Title>
                    <div>
                        <Trans t={t} i18nKey="title" />
                    </div>
                    <Underline ref={ref} isVisible={isVisible} additionalStyles="padding-bottom: 5px; text-transform: uppercase;">
                        {t('underlineTitle')}
                    </Underline>
                </Styled.Title>
                <Styled.ContentWrapper>
                    <Styled.LeftContentWrapper>
                        <Styled.SwitchersWrapper>
                            <Styled.Switcher active={activeSwitcher === 1}>
                                <div className="number">1</div>
                                <div className="text">
                                    <Trans t={t} i18nKey="list.1.title" />
                                </div>
                            </Styled.Switcher>
                            <Styled.Switcher active={activeSwitcher === 2}>
                                <div className="number">2</div>
                                <div className="text">
                                    <Trans t={t} i18nKey="list.2.title" />
                                </div>
                            </Styled.Switcher>
                            <Styled.Switcher active={activeSwitcher === 3}>
                                <div className="number">3</div>
                                <div className="text">
                                    <Trans t={t} i18nKey="list.3.title" />
                                </div>
                            </Styled.Switcher>
                        </Styled.SwitchersWrapper>

                        <div>
                            <a href={ONBOARDING_PERSONAL_URL} target="_blank" rel="noreferrer">
                                <Button label={t('button')} />
                            </a>
                        </div>
                    </Styled.LeftContentWrapper>

                    {!isMobile && (
                        <Styled.RightContentWrapper>
                            <Styled.GreenLight />

                            <Styled.Smartphone>
                                <img src={images[activeSwitcher - 1]} alt="" />
                            </Styled.Smartphone>
                        </Styled.RightContentWrapper>
                    )}
                </Styled.ContentWrapper>
            </>
        </SectionWrapper>
    );
};
