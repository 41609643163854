import React from "react";

export const CircledTickSVG: React.FC = () => (
    <svg id="Group_6040" data-name="Group 6040" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" y1="-0.364" x2="0.595" y2="1.749" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#6dde36" />
                <stop offset="1" stopColor="#49a51e" stopOpacity="0.729" />
            </linearGradient>
        </defs>
        <g id="Group_6037" data-name="Group 6037" transform="translate(0 0)">
            <rect id="Area_DISPLAY_ELEMENTS:Label_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE1_" data-name="Area [DISPLAY_ELEMENTS:Label][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE1]" width="26" height="26" rx="13" fill="url(#linear-gradient)" />
        </g>
        <g id="checkmark" transform="translate(2.529 2.551)">
            <g id="Group_5422" data-name="Group 5422" transform="translate(3.056 5.035)">
                <path id="Path_4887" data-name="Path 4887" d="M19.823,9.9l-7.989,7.989L8.545,14.6,6.9,16.244l4.934,4.934,9.634-9.634Z" transform="translate(-6.9 -9.9)" fill="#fff" />
            </g>
            <path id="Path_4888" data-name="Path 4888" d="M0,0H21.259V21.259H0Z" fill="none" />
        </g>
    </svg>
);
