import styled, { css } from 'styled-components';

import { globalColors } from 'assets/globalColors';
import { standardTransition } from 'config/layout/layout';

import { ButtonProps, ButtonTheme } from './Button.types';


export const greenButtonTheme = css`
    background: linear-gradient(169deg, #6dde36 0%, rgba(73,165,30,0.73) 100%);
    color: ${globalColors.fontColors.white};
    border: none;

    &:hover {
        background: linear-gradient(169deg, #4E9716 0%, #4E9716 100%);
    }
`;

export const yellowButtonTheme = css`
    color: ${globalColors.fontColors.white};
    background-image: linear-gradient(169deg, #ffe121 0%, #ffc400 100%);
    border: none;

    &:hover {
        background: linear-gradient(169deg, #F6BD00 0%, #F6BD00 100%);
    }
`;

export const orangeButtonTheme = css`
    color: ${globalColors.fontColors.white};
    background-image: linear-gradient(169deg, #ffaf2c 0%, #fc7e1d 100%);
    border: none;
    fill: ${globalColors.fontColors.white};

    &:hover {
        background-image: linear-gradient(169deg, #e66604 0%, #e66604 100%);
    }
`;

export const outlineOrangeButtonTheme = css`
    color: ${globalColors.fontColors.orange};
    background-color: ${globalColors.backgrounds.white};
    border: 1px solid ${globalColors.backgrounds.grey.dark};

    &:hover {
        background-color: ${globalColors.backgrounds.orange.base};
        color: ${globalColors.fontColors.white};
        border-color: ${globalColors.backgrounds.orange.base};
    }
`;

const disabledStyles = css`
    pointer-events: none;
    background: ${globalColors.fontColors.grey.base} !important;
    border: 1px solid ${globalColors.fontColors.grey.base} !important;
`;

// TODO: Have no idea why it doesn't work with the styled-components
// const getButtonTheme = (buttonTheme: ButtonTheme | undefined) => {
//     console.log(buttonTheme);

//     switch (buttonTheme) {
//         case ButtonTheme.GREEN:
//             return greenTheme;
//         case ButtonTheme.YELLOW:
//             return yellowTheme;
//         case ButtonTheme.ORANGE:
//             return orangeTheme;
//         case ButtonTheme.OUTLINE_ORANGE:
//             return outlineOrangeTheme;
//         default:
//             return 'color: red;';
//     }
// };

export const StyledButton = styled.button<ButtonProps>`
    border-radius: 24px;
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 600;
    font-family: 'Prompt';
    text-align: center;
    min-width: 100px;
    padding: 0 32px;
    height: 48px;
    cursor: pointer;
    ${standardTransition};

    ${({ buttonTheme }) => buttonTheme === ButtonTheme.GREEN && greenButtonTheme};
    ${({ buttonTheme }) => buttonTheme === ButtonTheme.YELLOW && yellowButtonTheme};
    ${({ buttonTheme }) => buttonTheme === ButtonTheme.ORANGE && orangeButtonTheme};
    ${({ buttonTheme }) => buttonTheme === ButtonTheme.OUTLINE_ORANGE && outlineOrangeButtonTheme};
    ${({ disabled }) => disabled && disabledStyles};
    ${({ margin }) => margin && `margin: ${margin}`};
    ${({ additionalStyles }) => additionalStyles && additionalStyles};
`;
