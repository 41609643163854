import styled from 'styled-components';

import { defaultCarouselPaginationStyles } from 'components/atoms/Companies/Companies.styled';
import { breakPoints, standardTransition } from 'config/layout/layout';

export const ContactTabsCarrouselWrapper = styled.div`
    max-width: 100%;
    padding-bottom: 30px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding-bottom: 70px;
    }

    .contact-tabs {
        width: 140%;
        left: 50%;
        transform: translate(calc(-50% - 20px), 0);

        .splide__track {
            padding: 15px 0;

            .splide__list {
                gap: 20px;
            }

            @media screen and (max-width: ${breakPoints.tablet}px) {
                display: block;
                margin: 0 auto;
            }

            @media screen and (max-width: ${breakPoints.mobile}px) {
                max-width: 280px;
                overflow: visible;
            }
        }

        .splide__slide {
            > div {
                opacity: 0.35;
                ${standardTransition};
            }

            &.is-active {
                > div {
                    opacity: 1;
                }
            }
        }

        .splide__pagination {
            bottom: -25px;
            width: 200px;
            margin: auto;
            left: 30px;

            ${defaultCarouselPaginationStyles};
        }
    }
`;

export const ContactTabsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 23px;
    padding-bottom: 60px;
`;
