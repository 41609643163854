import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RoundedCountryFlag from 'assets/icons/RoundedCountryFlag/RoundedCountryFlag.layout';
import { RoundedCountryFlags } from 'assets/icons/RoundedCountryFlag/RoundedCountryFlag.types';

import Dropdown from 'components/atoms/Dropdown/Dropdown';
import { DropdownOption } from 'components/atoms/Dropdown/Dropdown.types';
import { TranslationKey } from 'config/i18n/i18n.types';
import { applicantsPrivacyPolicyLTRoutePath, applicantsPrivacyPolicyRoutePath } from 'config/path/path';

import { ApplicationsPrivacyPolicyVersionSelectorContainer } from './ApplicationsPrivacyPolicyVersionSelector.styled';

const countryDropdownOptions: DropdownOption[] = [
    { value: TranslationKey.EN, label: 'English', icon: <RoundedCountryFlag name={RoundedCountryFlags.GB} /> },
    { value: TranslationKey.LT, label: 'Lithuanian', icon: <RoundedCountryFlag name={RoundedCountryFlags.LT} /> },
];

export const ApplicationsPrivacyPolicyVersionSelector: React.FC = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const routeChange = (path: string) => {
        navigate(path);
    };

    const handleOnOptionSelect = (option: DropdownOption) => {
        switch (option.value) {
            case TranslationKey.EN:
                return routeChange(applicantsPrivacyPolicyRoutePath);
            case TranslationKey.LT:
                return routeChange(applicantsPrivacyPolicyLTRoutePath);

            default:
                return routeChange(applicantsPrivacyPolicyRoutePath);
        }
    };

    const initialValue = location.pathname.includes(applicantsPrivacyPolicyRoutePath)
        ? countryDropdownOptions[0]
        : countryDropdownOptions[1];

    return (
        <ApplicationsPrivacyPolicyVersionSelectorContainer>
            <Dropdown
                selected={initialValue}
                options={countryDropdownOptions}
                onOptionSelect={handleOnOptionSelect}
            />
        </ApplicationsPrivacyPolicyVersionSelectorContainer>
    );
};
