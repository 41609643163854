import { ButtonTheme } from 'components/atoms/Button/Button.types';
import { OkeoIconVariant } from 'components/atoms/OkeoIcon/OkeoIcon.types';

import { PlanType } from './Pricing.types';

export const getPlanTypeIconVariant = (planType: PlanType) => {
    switch (planType) {
        case 'free':
            return OkeoIconVariant.GREEN;
        case 'lite':
            return OkeoIconVariant.YELLOW;
        case 'premium':
            return OkeoIconVariant.ORANGE;
        default:
            return OkeoIconVariant.GREEN;
    }
};

export const getPlanTypeButtonTheme = (planType: PlanType) => {
    switch (planType) {
        case 'free':
            return ButtonTheme.GREEN;
        case 'lite':
            return ButtonTheme.YELLOW;
        case 'premium':
            return ButtonTheme.ORANGE;
        default:
            return ButtonTheme.GREEN;
    }
};
