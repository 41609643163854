import styled from 'styled-components';
import { breakPoints, mobileHorizontalPadding } from 'config/layout/layout';

const smallDesktopBreakpoint = '1255px';

const Title = styled.h3`
    font-size: 28px;
    letter-spacing: 0px;
    line-height: 38px;
    font-weight: 600;
    margin: 0 0 45px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 22px;
        line-height: 29px;
        max-width: 500px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        margin: 0 0 26px;
    }
`;

const Text = styled.div`
    font-size: 18px;
    font-weight: 300;
    line-height: 27px;
    margin: 0 0 45px;
    max-width: 540px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        font-size: 15px;
        line-height: 24px;
        margin: 0 0 26px;
        max-width: 460px;
    }
`;

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 560px 1fr;
    gap: 75px;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        grid-template-columns: 1fr;
        gap: 0;
        max-width: 500px;
        margin: 0 auto;
    }
`;

const LeftContent = styled.div`
    display: flex;
    justify-content: center;

    @media screen and (max-width: ${breakPoints.tablet}px) {
        justify-content: start;
        max-width: 500px;
    }

    @media screen and (max-width: ${breakPoints.mobile}px) {
        margin: 0 -${mobileHorizontalPadding}px;
        margin-top: unset;
        max-width: unset;
        justify-content: center;
    }
`;
const RightContent = styled.div`
    width: 588px;
    padding-top: 82px;

    @media screen and (max-width: ${smallDesktopBreakpoint}) {
        width: 100%;
    }

    @media screen and (max-width: ${breakPoints.tablet}px) {
        padding: 30px 0 45px;
    }
`;


export default {
    Text,
    Title,
    LeftContent,
    RightContent,
    ContentWrapper,
};
