import React from 'react';
import { Trans } from 'react-i18next';
import { globalColors } from 'assets/globalColors';
import { TickSVG } from 'assets/icons/TickSVG';

import {
    Anchor,
    Code,
    Divider,
    Emphasis,
    ListItem,
    OrderedList,
    Paragraph,
    Quotation,
    Strong,
    Underline,
    UnorderedList,
} from './TranslationWithMarkup.styled';
import { TranslationWithMarkupProps } from './TranslationWithMarkup.type';

const defaultComponents: Record<string, React.ReactNode> = {
    p: <Paragraph />,
    strong: <Strong />,
    em: <Emphasis />,
    u: <Underline />,
    q: <Quotation />,
    code: <Code />,
    ol: <OrderedList />,
    ul: <UnorderedList />,
    li: <ListItem />,
    a: <Anchor />,
    hr: <Divider />,
    br: <br />,
    greentick: <TickSVG fill={globalColors.backgrounds.green.light!} />,
    yellowtick: <TickSVG fill="#FFD00E" />,
    orangetick: <TickSVG fill={globalColors.backgrounds.orange.base} />,
};

export const TranslationWithMarkup: React.FC<TranslationWithMarkupProps> = ({
    translationKey,
    components,
    values,
    namespace,
    t,
    parent,
}) => (
    <Trans
        i18nKey={translationKey}
        ns={namespace}
        t={t}
        values={{ ...values }}
        components={{ ...defaultComponents, ...components }}
        parent={parent}
    />
);
