import React from 'react';

import { StyledButton } from './Button.styled';
import { ButtonProps, ButtonTheme } from './Button.types';

export const Button: React.FC<ButtonProps> = ({
    label = '',
    onClick,
    buttonTheme = ButtonTheme.GREEN,
    type = 'button',
    disabled,
    margin,
    additionalStyles,
    children,
}) => (
    <StyledButton
        onClick={onClick}
        type={type}
        buttonTheme={buttonTheme}
        disabled={disabled}
        margin={margin}
        additionalStyles={additionalStyles}
    >
        {children || label}
    </StyledButton>
);
