import styled from "styled-components";

import { breakPoints } from "config/layout/layout";


const Wrapper = styled.div`
    position: relative;

    img {
        width: 100%;
        max-width: 486px;
        margin-top: -67px;
        display: block;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            width: unset;
            max-width: auto;
            max-height: 410px;
            margin-top: 0;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            max-width: 560px;
            max-height: 500px;
        }
    }

    picture {
        display: contents;
    }

    .element {
        width: 100%;
        max-width: 325px;
        position: absolute;
        right: -17%;
        opacity: 0;
        animation: elementAnimation 5s infinite;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            max-width: 310px;
            right: -50%;
        }

        @media screen and (max-width: ${breakPoints.tablet}px) {
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    @keyframes elementAnimation {
        0%, 15% {
            opacity: 0;
            transform: translateY(0);
        }
        20%, 80% {
            opacity: 1;
            transform: translateY(-15px);
        }
        85%  {
            opacity: 0;
            transform: translateY(0);
        }
    }

    .element-1 {
        top: 44%;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            top: 25%;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            top: 50%;
        }
    }

    .element-2 {
        top: 60%;
        animation-delay: 0.6s;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            top: 45%;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            top: 65%;
        }
    }

    .element-3 {
        top: 76%;
        animation-delay: 1.2s;

        @media screen and (max-width: ${breakPoints.tablet}px) {
            top: 65%;
        }

        @media screen and (max-width: ${breakPoints.mobile}px) {
            top: 80%;
        }
    }
`;

export default { Wrapper };
