import { TFunction } from "i18next";

import {
    faqSection,
    pricingRoutePath,
    welcomeRoutePath,
    contactRoutePath,
    faqPersonalSection,
    pricingPersonalPath,
    welcomePersonalRoutePath,
    welcomeAccumulativeRoutePath,
    pricingaccumulativePath,
} from "config/path/path";
import { getPageType, PageType } from "config/path/path.helpers";

export const getMenuItems = (t: TFunction) => {

    if (getPageType() === PageType.PERSONAL) {

        return [
            {
                label: t("menu.services.label"),
                url: welcomePersonalRoutePath,
            },
            { label: t("menu.pricing.label"), url: pricingPersonalPath },
            { label: t("menu.faq.label"), url: faqPersonalSection },
            { label: t("menu.contact.label"), url: contactRoutePath },
        ];
    }

    if (getPageType() === PageType.ACCUMULATIVE) {

        return [
            {
                label: t("menu.services.label"),
                url: welcomeAccumulativeRoutePath,
            },
            { label: t("menu.pricing.label"), url: pricingaccumulativePath },
            { label: t("menu.faq.label"), url: faqSection },
            { label: t("menu.contact.label"), url: contactRoutePath },
        ];
    }

    return [
        { label: t("menu.services.label"), url: welcomeRoutePath },
        { label: t("menu.pricing.label"), url: pricingRoutePath },
        // { label: t('menu.aboutUs.label'), url: aboutUsRoutePath },
        { label: t("menu.faq.label"), url: faqSection },
        { label: t("menu.contact.label"), url: contactRoutePath },
    ];
};
