import React, { useState } from 'react';

import { stringToAttributeString } from 'utils/string-tools';

import { TabListElement } from './TabListElement/TabListElement';
import { TabListWrapper } from './TabList.styled';
import { TabListProps } from './TabList.types';

export const TabList: React.FC<TabListProps> = ({ listData, t }) => {

    const [activeTab, updateActiveTab] = useState(0);

    const renderItems = Object.entries(listData).map((listElement, index) => (
        <TabListElement
            t={t}
            index={index}
            listElementData={listElement}
            key={`tab-elem-${stringToAttributeString(listElement[1].title)}`}
            activeTab={activeTab}
            onClick={() => updateActiveTab(index)}
        />
    ));

    return (
        <TabListWrapper>
            {renderItems}
        </TabListWrapper>
    );
};
